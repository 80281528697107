import 'react-h5-audio-player/lib/styles.css';

import React, { Component } from 'react';
import { SetsuffleAttachments, SetsuffleCurrentAudio, addbyStudentGoal, addbyStudentRestartGoal, getStudentGoalInfo } from './student_action';

import AudioPlayer from 'react-h5-audio-player';
import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { modal } from '../library/modal';
import submitGoalConfirmation from './modal/modal_confirm_submit_goal';
import { toHtml } from '../library/function';

var dateFormat = require('dateformat');
class StudentGoalInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setTab: 'nav-upload',
      count: 0,
      selectedOption: false,
      start: false,
      error: 0,
      success: 1,
      attempt:0,
      reinforcementUploadedFile: '',
      reinforcementVideo : false,
      reinforcementImage : false,
      successMessage: ''
    };
    this.handleCheckAns = this.handleCheckAns.bind(this);
    this.handleSubmitStartGoal = this.handleSubmitStartGoal.bind(this);
    this.handleSubmitReStartGoal =this.handleSubmitReStartGoal.bind(this);
  }

  componentDidMount() {
    var _this = this;
    var goal_id = this.props.match.params.goal_id;
    if (goal_id !== undefined) {
      this.setState({ isLoading: true });

      window.addEventListener('beforeunload', this.keepOnPage);
      this.props.getStudentGoalInfo(goal_id, function (err, result) {
        if (result) {
          //console.log("Result",result);
          var attachments = result.data.data.image;
          //console.log("CA",total_attemp);
          var suffleCurrentAudio = {
            audio_url: attachments[0].audio_url,
            id: attachments[0]._id,
            title: attachments[0].title
          }
          _this.props.SetsuffleAttachments(attachments);
          _this.props.SetsuffleCurrentAudio(suffleCurrentAudio);
          _this.setState({ isLoading: false });
        }
      });
    }
  }

  keepOnPage(e) {
    var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
    e.returnValue = message;
    return message;
  }

  setIntevalTimeout(index) {
    var s = document.getElementById('que-'+index+'-q-right-ans').style,f = false,c1 = '#4169e1',c2 = '#ffffff';
    setInterval(function() {
      s.backgroundColor = f ? c1 : c2;
      s.color = f ? c2 : c1;
      f = !f;
    }, 500);
  }

  setIntevalTimeout2(index) {
    var s = document.getElementById('que-'+index+'-q-right-ans').style,f = false,c1 = '#ffffff',c2 = '#ffffff';
    setInterval(function() {
      s.backgroundColor = f ? c1 : c2;
      s.color = f ? c2 : c1;
      f = !f;
    }, 10);
  }

  handleCheckAns1(e,index,data,right_ans,elem) {
    this.setState({ error: 0, success : 1, successMessage: '' });
    if(right_ans == 'No'){
      this.setIntevalTimeout(index);
      this.setState({ error: 1, success : 0, successMessage: 'Please play audio once again and select the correct answer!!!' });
    }
    else {
      //this.setIntevalTimeout2(index);
      if(this.state.attempt == 0){
        this.setState({ attempt : 1 });
        document.getElementById('que-'+index+'-q-right-ans').classList.remove("q-right-ans");
      } else {
          this.setState({ attempt : 0});
          if(document.getElementsByClassName('heading-question').length > index){
            document.getElementById('heading-question-'+(index+1)).classList.remove('tobeshow');
            document.getElementById('heading-question-'+index).classList.remove('tobeshow');
            for(let i=1;i<=document.getElementsByClassName('heading-question').length;i++){
              document.getElementById('heading-question-'+i).classList.add('tobehide');
            }
            document.getElementById('heading-question-'+(index+1)).classList.add('tobeshow');
            //document.getElementById("ques-"+(index+1)).classList.toggle("row-reverse");
            //console.log("ID ===>",document.getElementById("ques-"+(index+1)).id);
            if((document.getElementById("ques-"+(index+1)).id == 'ques-4') || (document.getElementById("ques-"+(index+1)).id == 'ques-6') || (document.getElementById("ques-"+(index+1)).id == 'ques-8') || (document.getElementById("ques-"+(index+1)).id == 'ques-2')){
              document.getElementById("ques-"+(index+1)).classList.add("row-reverse");
              this.setState({ attempt : 1});
            }
            //console.log("Question No ===> ","ques-"+(index+1));
          } else {
            this.submitGoalConfirmationModal();
          }
        }
      }
  }

  handleCheckAns(e, index, data) {
    document.getElementById('wrong-answer').innerHTML = '';
    this.setState({ count: this.state.count + 1 })
    this.setState({ start: true })
    if (this.props.current_audio.id == data._id) {
      //alert(" great !  you are select right answer ")
      this.props.attchment[index].ans_status = true;
      var arrdata = [];
      arrdata = this.props.attchment.filter(item => item.ans_status == undefined && item.ans_status != true)

      if (arrdata.length) {
        var suffleCurrentAudio = {
          audio_url: arrdata[0].audio_url,
          id: arrdata[0]._id,
          title: arrdata[0].title
        }
        this.props.SetsuffleCurrentAudio(suffleCurrentAudio);
        this.setState({ count: 0 });
      }
    }
    else {
      //alert("ohh ! you are select wrong one...")
      document.getElementById('wrong-answer').innerHTML = "Please listen question again and select the correct answer!!!";
      //setTimeout(document.getElementById('wrong-answer').innerHTML = '', 5000);
    }
    let counter_index = this.props.attchment.findIndex(data => data._id == this.props.current_audio.id);
    this.props.attchment[counter_index].attempt = this.state.count + 1;
    this.props.SetsuffleAttachments(this.props.attchment.sort(() => Math.random() - 0.5));
  }

  handleSubmitStartGoal(e) {
    e.preventDefault();
    this.props.addbyStudentGoal(this.props.match.params.goal_id, function (err, result) { });
  }
  handleSubmitReStartGoal(e,revisionCounter) {
    e.preventDefault();
    this.props.addbyStudentRestartGoal(this.props.match.params.goal_id,revisionCounter, function (err, result) { });
  }
  
  renderGoalimages(images) {
    if (images !== null && images.length > 0) {
      return images.map((img, index) => {
        //console.log("Main ==>",img.main);
        //if(img.main == 0){
          var qClass = 'col-sm-12 mb-3'+' main--'+img.main;
          if (this.props.current_audio.id == img._id && img.main == 1) {
            qClass = 'col-sm-12 mb-3 q-right-ans'+' main--'+img.main;
          }
          return (
            <div className={qClass} key={'attchment_' + index} onClick={(e) => this.handleCheckAns(e, index, img)} style={{ "cursor": "pointer" }}>
              <div className={"quiz-question-variation"}>
                <img src={img.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ 'height': '7rem', 'width': '7rem' }} />
              </div>
            </div>
  
          );
        //}
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No Data Found!</td>
        </tr>
      );
    }
  }

  renderGoalimages1(images, question_no, _id) {
    if (images !== null && images.length > 0) {
      let total_column = 5;
      return images.map((img, index) => {
          var qClass = 'pr-0 first-div q-wrong-ans col-md-32 col-md-'+total_column+' mb-3'+' main--'+img.main+' inner-'+index;
          var id_no = "internal-"+index;
          var right_ans = 'No';
          if (_id == img._id) {
            id_no = "que-"+question_no+'-q-right-ans';
            qClass = 'pr-0 first-div col-md-32 col-md-'+total_column+' mb-3 q-right-ans'+' main--'+img.main+' inner-'+index;
            right_ans = 'Yes';
          }
          return (
            <div className={qClass} id={id_no} key={'attchment_' + index} data-id={"internal-"+index} onClick={(e) => this.handleCheckAns1(e,question_no, img,right_ans,this)} style={{ "cursor": "pointer" }}>
              <div className={"quiz-question-variation"}>
                  <img src={img.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ 'height': '7rem', 'width': '7rem' }} />
              </div>
            </div>
          );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No Data Found!</td>
        </tr>
      );
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="col-sm-12 mt-3">
          <div className="alert alert-warning">
            <strong>Oops! </strong> {this.props.errorMessage}
          </div>
        </div>

      )
    }
  }

  submitGoalConfirmationModal() {
    modal.add(submitGoalConfirmation, {
      title: "Do you want to submit goal?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      data: {
        'attchment': this.props.attchment,
        'goal_id': this.props.match.params.goal_id
      }
    });
  }

  renderReinforcementMedia(url){

    let fileUrl = "";
    let fileExtension = "jpg";
    if(url != ''){
      fileUrl = url;
      fileExtension = fileUrl.substr(-4);
      //console.log("===>",fileExtension);
    }
    
    if(fileExtension == 'mpeg' || fileExtension == 'mp4' || fileExtension == 'avi' || fileExtension == 'wmv' || fileExtension == 'mkv' || fileExtension == 'webm'){
      return (
        <td className="text-left videourl"><Link onClick={() => window.open(url, "_blank")}> <ReactPlayer url={url} style={{width:104}} /></Link></td>
      )
    }

    if(fileExtension == 'jpg' || fileExtension == 'jpeg' || fileExtension == 'png' || fileExtension == 'bmp' || fileExtension == 'gif'){
      return (
        <td className="text-left"><Link onClick={() => window.open(url, "_blank")}> <img src={url} className="img-thumbnail" alt="image" /></Link></td>
      )
    }  
  }
  
  render() {
    const { goal_info, errorMessage, attchment, current_audio, imageArray, totalAnswers } = this.props;
    var { isLoading, start } = this.state;
    if (!goal_info || !current_audio || !imageArray) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    if (errorMessage) {
      isLoading = false;
    }
    var total_attemp = 0;
    if (attchment.length) {
      total_attemp = attchment.filter(item => item.ans_status == true).length;
    }
    var divStyle = {};
    if (goal_info?.studentGoalStatus !== "started") {
      divStyle = {
        pointerEvents: 'none',
        opacity: '.2',
      };
    }

    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content p-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0"> <i className="fa fa-info mr-1" aria-hidden="true"></i> Goal Details Info - <span className="text text-primary">{goal_info.title ? goal_info.title : ""} </span> </h4>
                </div>

                <div className="card-body">
                  <div className="tab-content" id="nav-tabContent">
                    <div className="card bg-light">
                      <div className="card-body mt-3">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              {/* <tr>
                                <img src="https://image.shutterstock.com/image-vector/man-icon-vector-260nw-1040084344.jpg" className="img-thumbnail rounded" alt="image" />
                              </tr> */}
                              {
                                goal_info?.createdBy && goal_info?.createdBy.name && <tr>
                                <td className="first  border-top-0 font-weight-bold  h6">Teacher Name</td>
                                <td className="second border-top-0 col-6">{goal_info?.createdBy.name} <span className="text-primary">{goal_info.createdBy.email}</span></td>
                              </tr>
                              }
                              
                              <tr>
                                <td className="first  font-weight-bold  h6">Assign Goal :</td>
                                <td className="second border-top-0 col-6">{goal_info?.student_name}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weight-bold  h6">Goal  Details :</td>
                                <td className="second border-top-0 col-6">{toHtml(goal_info.description)}</td>
                              </tr>

                              <tr>
                                <td className="first  font-weight-bold  h6">Goal  Status :</td>
                                <td className="second border-top-0 col-6">{goal_info.studentGoalStatus !== undefined ? <span className="second badge bg-success">{goal_info.studentGoalStatus}</span> : <span className="second badge bg-warning">Not Completed</span>}  {goal_info.completionDateTime !== undefined && dateFormat(goal_info.completionDateTime)}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weight-bold h6 ">Date OF Creation</td>
                                <td className="second "> <span className="">
                                  : <i className="fa fa-calendar mr-1"></i>

                                  {dateFormat(goal_info?.createdTime, "mm-dd-yyyy")}
                                </span></td>
                              </tr>
                              <tr>
                                <td className="first  font-weight-bold h6 ">Date OF Mastered</td>
                                <td className="second "> <span className="">
                                  : <i className="fa fa-calendar mr-1"></i>

                                  {dateFormat(goal_info?.dateMastered, "mm-dd-yyyy")}
                                </span></td>
                              </tr>
                              {
                                goal_info.studentGoalStatus == 'completed' && <tr>
                                  <td className="first  font-weight-bold h6 ">Attempts</td>
                                  <td className="second "> <span className="">
                                    {goal_info?.score}
                                  </span></td>
                                </tr>
                              }

                              {
                                goal_info.studentGoalStatus == 'completed' &&  <tr>
                                  <td className="first  font-weight-bold h6 ">Rewards</td>
                                  <td className="second "> <span className="">
                                    {goal_info?.reinforcementData?.rewards}
                                  </span></td>
                                </tr>
                              }

                              {/* {
                                goal_info.studentGoalStatus == 'completed' &&  <tr>
                                <td className="first  font-weight-bold h6 ">Score</td>
                                <td className="second "> <span className="">
                                  {(parseFloat(goal_info?.reinforcementData?.rewards) / parseFloat(goal_info?.score)).toFixed(3)}
                                </span></td>
                              </tr>
                              } */}
                            </tbody>
                          </table>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header h5 bg-white row">
                  <div className="text-primary col-md-4">{goal_info.title ? goal_info.title : ""}</div>
                  {
                    goal_info?.studentGoalStatus !== "completed" &&
                        <div className='text-center col-md-6'><small className='text-danger text-small small' id="wrong-answer"></small></div>
                  }

                  {
                    goal_info?.studentGoalStatus !== "completed"
                      ?
                        <div className="pull-right text-right col-md-2">
                          {/* {total_attemp} out of {attchment.length ? (attchment.length/2) : 0} */}
                          {
                            (goal_info.studentGoalStatus !== "started")
                              ?
                              <Link to={"/student/play/1"} className="btn  btn-primary ml-2" onClick={(e) => this.handleSubmitStartGoal(e)}>Start</Link>
                              :
                              null
                          }
                          {(total_attemp*2) == attchment.length && <Link onClick={() => this.submitGoalConfirmationModal()} className="btn btn-danger ml-2">Submit</Link>}
                        </div>
                      :
                      <div className="pull-right">
                        {/* <h5 className=" badge bg-success">Goal Completed</h5> */}
                        <Link to={"/student/play/1"} className="btn btn-primary ml-2" onClick={(e) => this.handleSubmitReStartGoal(e,goal_info.revisionCounter)}>Restart Your Goal</Link></div>
                  }

                </div>

                
                <div className="card-body p-0 " style={divStyle}>
                  {this.renderAlert()}
                  <div className="mb-3">

                    {
                      attchment.length == total_attemp*2 && <div className='congratulations-goals text-danger'>Assessment Completed. Please Submit!</div>
                    }

                    {
                      attchment.map((data,index) => {
                        //imageArray.map((mapdata,index1) => {
                          //if(mapdata.data == data.img_url) {
                            //console.log("Add URL ===>",data.img_url);
                        return(
                            <div id={"heading-question-"+(index+1)} className={index == 0 ? "tobeshow card-header d-flex heading-question question-"+(index+1) : "tobehide card-header d-flex heading-question question-"+(index+1)}>
                              <div className='row'>
                                <div className="col-md-12">
                                  <div className='row'>
                                    <div className='col-md-12'>
                                    
                                      <h6><span className="text-danger">Question : </span>  {data?.title} </h6>
                                      <AudioPlayer
                                        src={data?.audio_url}
                                        onPlay={e => console.log("onPlay")}
                                      />
                                      {
                                        this.state.successMessage != '' && <div className='correctAnswer'>{this.state.successMessage}</div>
                                      }
                                    </div>
                                  </div>
                                  <br />
                                </div>
                                <div className='col-md-12'>
                                  <div className="row" id={"ques-"+(index+1)}>
                                    
                                    {this.renderGoalimages1(totalAnswers,index+1,data?._id)}
                                  </div>
                                </div>
                              </div>
                            </div>
                        );
                      //}
                    //})
                      })
                    }

                    <div className=" card-footer bg-transparent">
                      <div className="pull-right pb-2">
                        {goal_info?.studentGoalStatus !== "completed" && total_attemp == attchment.length && <Link onClick={() => this.submitGoalConfirmationModal()} className="btn btn-danger">Submit</Link>}
                      </div>
                    </div>
                  </div>
                </div>

                {goal_info?.reinforcementData && <div className="card mt-3">
                  <div className="card-header">
                    <h5 className="mb-0">Goal Reinformcement History </h5>
                  </div>
                  <div className="card-body">

                    <div className=" react-bs-table-container">
                      <table className="table table-responsive">
                        <thead>
                          <tr>
                            <th className="text-left">File</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Discription</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>

                            {this.renderReinforcementMedia(goal_info?.reinforcementData.attachments[0])}

                            {
                              this.state.reinforcementVideo && <td className="text-left"><Link onClick={() => window.open(goal_info?.reinforcementData?.attachments[0], "_blank")}> <img src={goal_info?.reinforcementData?.attachments[0]} className="img-thumbnail" alt="image" /></Link></td>
                            }
                            
                            <td className="text-center">{goal_info?.reinforcementData?.name}</td>
                            <td className="text-center"> {toHtml(goal_info?.reinforcementData?.description)}</td>
                            <td className="text-center">{goal_info?.reinforcementData?.isActive == true ? "Active" : "Deactive"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  console.log("Goals Information ",state.student.goal_student_info);
  return {
    goal_info: state.student.goal_student_info,
    current_audio: state.student.current_audio,
    attchment: state.student.set_attchment,
    totalAnswers: state.student.goal_student_info && state.student.goal_student_info.contentData.image.length > 0 ? state.student.goal_student_info.contentData.image : [],
    imageArray: state.student.goal_student_info && state.student.goal_student_info.imagesArray ? state.student.goal_student_info.imagesArray : [],
    errorMessage: state.student.error
  }
}

export default connect(mapStateToProps, { getStudentGoalInfo, addbyStudentGoal, addbyStudentRestartGoal ,SetsuffleCurrentAudio, SetsuffleAttachments })(StudentGoalInfo);
