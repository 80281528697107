import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import BatchFormModal from './modal/model_batch';
import BatchInformationModal from './modal/model_batch_information';
import DeshHeader from './desh_header';
import FullCalendar from "@fullcalendar/react";
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import dayGridPlugin from "@fullcalendar/daygrid";
import { getBatches } from './teacher_action';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { modal } from '../library/modal';
import moment from "moment";
import timeGridPlugin from "@fullcalendar/timegrid";

// must manually import the stylesheets for each plugin


class BatchCalender extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      calendarWeekends: true,

    };
    this.applySortBy = this.applySortBy.bind(this);
  }

  componentDidMount() {
    let accessData = 'Granted';
    var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
    if(endDate == 'Invalid date'){
      accessData = 'NoAccess';
    }
    if(moment().isAfter(endDate, 'days')) {
      accessData = 'NoAccess';
    }
    if(accessData == 'NoAccess'){
      this.props.history.push('/pricing');
    }
  }

  componentWillMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getBatches(undefined, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  toggleWeekends = () => {
    this.setState({
      // update a property
      calendarWeekends: !this.state.calendarWeekends
    });
  };

  gotoPast = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
  };

  handleEventClick(arg) {
    var batchData = {};
    batchData.batchDate = arg.event.start;
    batchData.name = arg.event.title;
    batchData._id = arg.event._def.extendedProps.batchId;
    batchData.description = arg.event._def.extendedProps.description;
    batchData.status = arg.event._def.extendedProps.status;
    batchData.subjectId = arg.event._def.extendedProps.subjectId;
    batchData.studentList = arg.event._def.extendedProps.studentList;
    batchData.endTime = arg.event._def.extendedProps.endtime;
    batchData.startTime = arg.event._def.extendedProps.starttime;
    modal.add(BatchInformationModal, {
      title: arg.event.title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      batchData: batchData,
    });
  };

  applySortBy(e) {
    this.setState({ isLoading: true });
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getBatches(e.target.value, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  BatchModel(title, batchData) {
    modal.add(BatchFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      batchData: batchData,
    });
  }
  render() {
    const { batch_list } = this.props;
    const { isLoading } = this.state;

    if (!batch_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }


    var calendarEventsarray = [];
    for (var i in batch_list) {
      var date = 'Thu Feb 11 2021 11:19:04 GMT+0530 (India Standard Time)';
      calendarEventsarray.push(
        {
          title: batch_list[i].name,
          batchId: batch_list[i]._id,
          start: batch_list[i].batchDate,
          description: batch_list[i].description,
          status: batch_list[i].status,
          endtime: batch_list[i].endTime,
          starttime: batch_list[i].startTime,
          subjectId: batch_list[i].subjectId,
          studentList: batch_list[i].studentList
        });
    }
    return (

      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content container mt-4">
              <div className="page" id="page-batch">
                <div className="card demo-app-calendar">
                  <div className="card-header content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Assessment List
                    </h5>
                    <div style={{'float':'right'}}>
                      <select onChange={(e) => this.applySortBy(e)} className="mr-2">
                        <option value="">-- Choose --</option>
                        <option value="Pending">Upcoming</option>
                        <option value="Ongoing">InProgress</option>
                        <option value="Completed">Complete</option>
                      </select>
                      <button onClick={() => this.BatchModel('Add New', '')} className="text-primary mt-2 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button>
                      {/* <button onClick={() => this.BatchModel('Add Assessment', '')} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New</b></button> */}
                    </div>

                  </div>


                  <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    displayEventTime={false}
                    selectable={true}
                    ref={this.calendarComponentRef}
                    weekends={this.state.calendarWeekends}
                    events={calendarEventsarray}
                    eventClick={this.handleEventClick}

                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>






    );

  }
}

function mapStateToProps(state) {

  return {
    batch_list: state.teacher.batch_list,

  }
}
export default connect(mapStateToProps, { getBatches })(BatchCalender);
