import axios from 'axios';
import * as config from '../system/config';
import { createAlert } from '../../src/library/function';

export function sendContactMail(formData, callback) {
    return function(dispatch) {
      axios({
        method: 'post',
        url: `${config.API_URL}/contact-us`,
        data: {
          "name": formData.name,
          "email": formData.email,
          "phoneNumber": formData.phoneNumber,
          "description": formData.description
        },
      })
      .then(response => {
        if (response.data.data !== undefined && response.data.err === undefined) {
            createAlert({message:'Your contact has been send successfully please check your E-mail.'});
            callback(null, response.data.data);
          } else {
            callback(response.data.err, null);
          }
      })
      .catch(response => {
      });
    }
  }