import { Field, reduxForm } from 'redux-form'
import React, { Component } from 'react';
import { getStudentTeacherCommunication, getRecentChatsWithUsers, saveChatData, getProfile } from './student_action';
import { input, textarea } from '../library/elements';
//import Chat from '../../src/chat/inbox';
import DeshHeader from './desh_header';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import moment from "moment";
import { toHtml } from '../library/function';

const nl2br = require('react-nl2br');

var dateFormat = require('dateformat');

class StudentCommunication extends Component {

    constructor(props) {
        super(props);
        this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
        this.state = {
            isLoading: false,
            receiverID: '',
            setTab: 'nav-upload',
        };
    }

    handleProfileSubmit() {
        document.getElementById('error_message').innerHTML = '';
        let sender_id = document.getElementById('sender_id').value;
        let sender_name = document.getElementById('sender_name').value;
        let sender_photo = document.getElementById('sender_photo').value;
        let receiver_id = document.getElementById('receiver_id').value;
        let receiver_name = document.getElementById('receiver_name').value;
        let receiver_photo = document.getElementById('receiver_photo').value;
        let message = document.getElementById('message').value;
        if (receiver_id == '') {
            document.getElementById('error_message').innerHTML = 'Please select receiver';
            return false;
        } else if (message == '') {
            document.getElementById('error_message').innerHTML = 'Please enter your message';
            return false;
        } else {
            const data = {
                "sender": sender_id,
                "senderName": sender_name,
                "receiver": receiver_id,
                "receiverName": receiver_name,
                "senderPhoto": sender_photo,
                "receiverPhoto": receiver_photo,
                "parentTeacherString" : sender_id+'-'+receiver_id, // First Should be parent and second should be teacher
                "message": message
            };
            this.props.saveChatData(data, function (err, result) {
                console.log("Chat Sending Results ==>", result, err);
                if (result) { 
                    //setTimeout(document.getElementById("#"+sender_id).click(), 3000);
                    //document.getElementById("#"+sender_id).click();
                }
            });

            document.getElementById('error_message').innerHTML = '';
            document.querySelector('#message').value = '';
            document.getElementById("message").value = '';
            document.getElementById("message").innerHTML = '';
            this.state = { isLoading: false };

        }
    }

    componentDidMount() {
        var _this = this;
        this.props.getRecentChatsWithUsers(function (err, result) {
            if (result) {
                _this.setState({ isLoading: false });
            }
        });
    }
    
    renderUserChats(list) {
        if (list?.length > 0) {
            return list.map((data) => {
                //console.log("Data ==>", data.sender, "====", cookie.load('parent_id'));
                let photo_url = "https://learning3ly.com/static/media/logo.edade388.png";
                if (data.photo != '' && data.photo != undefined) {
                    photo_url = data.photo;
                }
                if(data.senderPhoto && data.senderPhoto != '' && data.senderPhoto != undefined){
                    photo_url = data.senderPhoto;
                }

                let is_same_day = moment(data.createdAt).format("MM/DD/YYYY hh:mm A");
                if (moment(data.createdAt).isSame(moment(), 'day')) {
                    is_same_day = moment(data.createdAt, "HH:mm:ss").format("LT");
                }

                let chat_position = "chat-left";
                let namee = data.senderName;
                
                if (data.sender == cookie.load('student_id')) {
                    chat_position = "chat-right";
                    namee = "You";
                }
                
                console.log("Photo url",photo_url);
                return (
                    <li className={chat_position}>
                        <div className="chat-avatar">
                            <img src={data.senderPhoto} alt="Retail Admin" />
                            {/* <div className="chat-name">{data.sender}</div> */}
                            <div className="chat-name">{namee}</div>
                        </div>
                        <div className="chat-text ml-2">{nl2br(data.message)} </div>
                        <div className="chat-hour">{is_same_day}
                            {/* <span className="fa pl-2 fa-check-circle"></span> */}
                        </div>
                    </li>
                );
            });
        }
    }

    render() {
        const { handleSubmit, errorMessage } = this.props;
        //const { chat_list, parent_teacher_chats} = this.props;
        const { chat_list } = this.props;
        const { isLoading } = this.state;
        if (chat_list === undefined) {
            return (
                <div className="task-undefined"><Loading /></div>
            );
        }

        return (
            <div className="page material-ui" id="page-batch-info">
                {isLoading ? <Loading /> : null}
                <div className="wrapper ">
                    <DeshHeader />
                    <div className="main-panel">
                        <div className="content container">
                            <div className="page" id="page-batch">
                                {/* <Chat /> */}
                                <div className="page chat-box mb-5">
                                    <div className="container">
                                        <div className="page-heading mb-3">
                                            <div className="d-flex justify-content-between pt-3 breadcrumb-container">
                                                <div className="p-2  mt-2 mb-2 breadcrumb-title font-weight-bold">
                                                    Communication
                                                </div>
                                                <div className="page-heading-breadcrumb">
                                                </div>
                                            </div>
                                        </div>

                                        <div className="content-wrapper">
                                            <div className="row gutters">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div className="card m-0">
                                                        <div className="row no-gutters">
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-9 col-9">
                                                                <div className="selected-user">
                                                                    <span>
                                                                        <img id="chat_image" src="https://learning3ly.com/static/media/logo.edade388.png" alt="Receiver" className='chattopimage' />
                                                                        <span className="name text-success" id="chat_title">Welcome To communication screen</span>
                                                                    </span>
                                                                </div>
                                                                <div className="chat-container" id="chat-container">

                                                                    {/* {
                                                                    this.state.isLoading == false && chat_list.parent_teacher_chats && <ul className="chat-box chatContainerScroll">
                                                                    {this.state.isLoading == false && this.renderUserChats(parent_teacher_chats)}
                                                                    </ul>
                                                                } */}
                                                                    {
                                                                        this.state.isLoading == false && chat_list.getUserByType && <ul className="chat-box chatContainerScroll">
                                                                            {this.state.isLoading == false && this.renderUserChats(chat_list.getUserChatsss)}
                                                                        </ul>
                                                                    }

                                                                    <div className="form-group mt-3 mb-0">
                                                                        <input type="hidden" id="sender_id" value={cookie.load('student_id')} />
                                                                        <input type="hidden" id="sender_name" value={cookie.load('login_user')} />
                                                                        <input type="hidden" id="sender_photo" value={cookie.load('login_photo')} />
                                                                        <input type="hidden" id="receiver_id" value={cookie.load('student_teacher_id')} />
                                                                        <input type="hidden" id="receiver_name" value={cookie.load('student_teacher_email')} />
                                                                        <input type="hidden" id="receiver_photo" value={cookie.load('teacherPhoto')} />

                                                                        {/* <textarea id="message" className='form-control'></textarea> */}
                                                                        <div className='wrapper-chat'>
                                                                        
                                                                            <div className="bottom-default-chat" id="bottom-default-chat">
                                                                                <div className="row mt-2">
                                                                                    <div className='col-md-12'>
                                                                                        <textarea id="message" className='form-control' placeholder='Write Your Message Here......'></textarea>
                                                                                        <div id="error_message" className='text-danger'></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mt-2">
                                                                                    <div className='col-md-2'>
                                                                                        <button action="saveChat" onClick={this.handleProfileSubmit.bind(this)} type="submit" disabled={isLoading} className="md-btn btn btn btn-primary btn-block mb-1"><i className="fa fa-pencil-circle"></i> {isLoading ? 'Loading...' : 'Send'}</button>
                                                                                    </div>
                                                                                    <div className='col-md-5'></div>
                                                                                    <div className='col-md-5'></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        chat_list: state.student.get_student_recent_chat_list,
        teacherId: state.student.student_profile ? state.student.student_profile.teacherId : ''
    }
}

function validate(formProps) {
    const errors = {}
    // if (!formProps.message) {
    //   errors.message = 'Required message';
    // }
    return errors;
}

StudentCommunication = reduxForm({
    form: 'saveChat',
    validate: validate
})(StudentCommunication);


export default connect(mapStateToProps, { getRecentChatsWithUsers, getStudentTeacherCommunication, getProfile, saveChatData })(StudentCommunication);