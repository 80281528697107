import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { input, Loading } from '../../library/elements';
import { addCategory, updateCategory, validationNull } from '../teacher_action';
class CategoryFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.validationNull();
  }

  handleFormSubmit(formProps) {

    var _this = this;
    this.setState({ isLoading: true });
    this.props.validationNull();

    if (this.props.categoryData) {
      formProps.categoryId = this.props.categoryData._id;
      this.props.updateCategory(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addCategory(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }

  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }



  render() {
    const { handleSubmit, errorMessage } = this.props;
    var { isLoading } = this.state;

    if (errorMessage) {isLoading = false;
    }
    return (
      <div className="sk-form">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-8">
              <Field name="category" type="text" component={input} label="* Category Name" className="form-control" />
            </div>
            
            <div className="col-sm-4">
              <Field

                name="isActive"
                component={isActive =>
                  <div>
                    <select className="form-select" aria-label="Default select example"
                      name="isActive"
                      className="form-control"
                      value={isActive.input.value}
                      onChange={(event) => {
                        isActive.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select Status--</option>
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </select>
                    <span>
                      {isActive.meta.touched && ((isActive.meta.error && <div className="error text-danger">{isActive.meta.error}</div>) || (isActive.meta.warning && <div className="error text-danger">{isActive.meta.warning}</div>))}
                    </span>
                  </div>

                } />
            </div>
          </div>


          <div className="row">

            <div className="col-sm-9">
              {
                this.props.categoryData
                  ?
                  <button action="form_category" className=" mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Category'}
                  </button>
                  :
                  <button action="form_category" className=" mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Category'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.category) {
    errors.category = 'Required Category Name';
  }
  if (formProps.isActive === undefined) {
    errors.isActive = 'Select Status ';
  } else if (formProps.isActive === '') {
    errors.isActive = 'Select Status';
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  var iniCategory = {
    attachment: [0]
  };
  if (ownProps.categoryData) {
    var iniCategory = ownProps.categoryData;
  }

  return {
    errorMessage: state.teacher.error,
    initialValues: iniCategory,

  }
}

CategoryFormModal = reduxForm({
  form: 'form_category',
  validate: validate
})(CategoryFormModal);


export default connect(mapStateToProps, { addCategory, updateCategory, validationNull })(CategoryFormModal);
