import { Editor, InputImage, Loading, input } from '../../library/elements';
import { Field, FieldArray, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { addTask, getAllSubjects, getStudents, updateTask, validationNull } from '../parent_action';

import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { connect } from 'react-redux';

class ParentStudentTaskFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      allowStudent: this.props.taskData._id !== undefined ? false : true
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.validationNull();
    this.props.getAllSubjects();
    this.props.getStudents(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  handleFormSubmit(formProps) {
    var _this = this;
    this.setState({ isLoading: true });
    var studentIdList = formProps?.studentId?.length > 0 ? formProps.studentId.split(',') : [];

    const data = new FormData();
    var attachment = formProps.attachment;
    delete formProps.attachment;

    data.append('name', formProps.name);
    data.append('studentIdList', JSON.stringify(studentIdList));
    data.append('taskDateTime', formProps.taskDateTime);
    data.append('completionDateTime', formProps.completionDateTime);
    data.append('description', formProps.description);
    data.append('subjectId', formProps.subjectId ? formProps.subjectId : '6013a9c0be28bb1884356a0b');
    data.append('isActive', formProps.isActive);
    for (var mi in attachment) {
      if (attachment[mi]['image'] !== undefined) {
        data.append('attachment', attachment[mi]['image'][0]);
      }
    }
    if (this.props.taskData) {

      data.append('taskId', this.props.taskData._id);
      this.props.updateTask(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addTask(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }

  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }

  renderimages(reinforcementImages) {
    if (reinforcementImages.length) {
      var images_data = reinforcementImages.map((img, index) => {
        //console.log("img ===> ",img.link);
        return (
          <div className="col-lg-4 col-sm-6 col-6 mt-2 mb-2 text-center" key={'task-image-' + index}>
            <Link onClick={() => window.open(img.link, "_blank")}>
              <div className="card alc-card alc-card-fancy alc-hover-fancy mb-4">
                <div className="alc-card-fancy-image">
                  <img src={img.link} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '100%', height: '12rem' }} />
                </div>
                <div className="card-footer alc-card-fancy-content text-center">
                  <h4 className="card-title alc-card-title"><i className="fa fa-eye"></i></h4>
                </div>
              </div>
            </Link>
            {/* <Link className="text-center btn btn-sm btn-danger btn-delete-action" onClick={() => this.deleteImage(img)}>
              <i className="fa fa-close fa-1x text-white"></i>
            </Link> */}
          </div>

        );

      });

      return (
        images_data
      );
    }
  }

  render() {
    const { handleSubmit, subject_list, student_list, errorMessage } = this.props;
    var { isLoading } = this.state;
    //console.log("Task Data =======>",this.props.taskData.attachment.length);
    if (errorMessage) {
      isLoading = false;
    }

    // {
    //   this.props.taskData.attachment.length
    //     ?
    //     <div className="card mb-3">
    //       <div className="card-header">Past Upload  Attchment</div>
    //       <div className="card-body">
    //         <div className="row">
    //           {this.renderimages(this.props.taskData.attachment)}
    //         </div>
    //       </div>
    //     </div>
    //     :
    //     null
    // }


    
    if (student_list === undefined || subject_list === undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    var studentListData = [];
    for (var i in student_list) {
      studentListData.push({ label: student_list[i].firstName, value: student_list[i].userId });
    }
    var subjectData = [];
    for (var v in subject_list) {
      subjectData.push(
        { label: subject_list[v].name, value: subject_list[v]._id });
    }


    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <ul className="card-stripe-ul d-flex">
        {fields.map((item, index) =>
          <li key={"add_image_" + index} className="card-stripe-ul-li">
            <span className="badge badge-danger remove-image"
              onClick={() => fields.remove(index)}
            >
              <i className="fa fa-close"></i>
            </span>
            <Field name={`${item}.image`} type="text" component={InputImage} label="Image" className="form-control" />
          </li>
        )}
        <li className="fileUpload btn btn-outline-default p-0" onClick={() => fields.push({})}><i className="fa fa-plus-circle fa-5x"></i></li>
      </ul>
    )
    return (
      <div className="sk-form">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-12">
              <Field name="name" type="text" component={input} label="Task Name" className="form-control" />
            </div>
          </div>
          {
            this.state.allowStudent &&
            <div className="form-group row">
              <div className="col-12">
                <label className="control-label">Students</label>
                <Field name="studentId" component={studentId => {
                  return (
                    <>
                      <Select multi simpleValue
                        value={studentId.input.value}
                        placeholder="Select Students"
                        options={studentListData}
                        onChange={(e) => {
                          studentId.input.onChange(e);
                        }} />
                      <span>
                        {studentId.meta.touched && ((studentId.meta.error && <div className="error text-danger">{studentId.meta.error}</div>) || (studentId.meta.warning && <div className="error text-danger">{studentId.meta.warning}</div>))}
                      </span>
                    </>
                  )
                }} className="form-control" />
              </div>
            </div>
          }
          <div className="form-group row">
            <div className="col-12">
              <label className="control-label">Subject</label>
              <Field name="subjectId" component={subjectId => {
                return (
                  <>
                    <Select simpleValue
                      value={subjectId.input.value}
                      placeholder="Select Subject"
                      options={subjectData}
                      onChange={(e) => {
                        subjectId.input.onChange(e);

                      }} />
                    <span>

                      {subjectId.meta.touched && ((subjectId.meta.error && <div className="error text-danger">{subjectId.meta.error}</div>) || (subjectId.meta.warning && <div className="error text-danger">{subjectId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12">
              <label> Description</label>
              <Field name="description" type="text" component={Editor} className="form-control" />
            </div>
          </div>

          <div className="form-group row">

            <div className="col-sm-6">
              <label className="control-label">Start Date</label>
              <Field name={`taskDateTime`} component={taskDateTime => {
                return (
                  <div className="dt-picker">
                    <DateTimePicker
                      name="taskDateTime"
                      value={!taskDateTime.input.value ? new Date() : (taskDateTime.input.value !== '0000-00-00' ? new Date(taskDateTime.input.value) : null)}
                      onChange={(date) => {
                        taskDateTime.input.onChange(date);
                      }}
                    />
                    <span>

                      {taskDateTime.meta.touched && ((taskDateTime.meta.error && <div className="error text-danger">{taskDateTime.meta.error}</div>) || (taskDateTime.meta.warning && <div className="error text-danger">{taskDateTime.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
            </div>
            <div className="col-sm-6">
              <label className="control-label">Due Date</label>
              <Field name={`completionDateTime`} component={completionDateTime => {
                return (
                  <div className="dt-picker">
                    <DateTimePicker
                      name="completionDateTime"
                      value={!completionDateTime.input.value ? new Date() : (completionDateTime.input.value !== '0000-00-00' ? new Date(completionDateTime.input.value) : null)}
                      onChange={(date) => {
                        completionDateTime.input.onChange(date);
                      }}
                    />
                    <span>

                      {completionDateTime.meta.touched && ((completionDateTime.meta.error && <div className="error text-danger">{completionDateTime.meta.error}</div>) || (completionDateTime.meta.warning && <div className="error text-danger">{completionDateTime.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
            </div>

          </div>
          {
      this.props.taskData.attachment && this.props.taskData.attachment.length > 0 && <div className="card mb-3">
        <div className="card-header">Past Upload  Attchment</div>
        <div className="card-body">
                  <div className="row">
                    {this.renderimages(this.props?.taskData?.attachment)}
                  </div>
                </div>
      </div>
    }

          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header  bg-light">
                  <h6>Upload File</h6>
                </div>
                <div className="card-body card-stripe">
                  <FieldArray name="attachment" component={addMoreImage} />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-6">
              <label className="form-lable required">isActive</label>
              <Field

                name="isActive"
                component={isActive =>
                  <div>
                    <select
                      name="isActive"
                      className="form-control"
                      value={isActive.input.value}
                      onChange={(event) => {
                        isActive.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </select>
                    <span>
                      {isActive.meta.touched && ((isActive.meta.error && <div className="error text-danger">{isActive.meta.error}</div>) || (isActive.meta.warning && <div className="error text-danger">{isActive.meta.warning}</div>))}
                    </span>
                  </div>

                } />
            </div>
          </div>

          <div className="row">

            <div className="col-sm-9">
              {
                this.props.batchData
                  ?
                  <button action="form_task" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Task'}
                  </button>
                  :
                  <button action="form_task" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Task'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.name) {
    errors.name = 'Required Task Name';
  }

  if (formProps.studentId === undefined) {
    errors.studentId = 'Select Students ';
  } else if (formProps.studentId === '') {
    errors.studentId = 'Select Students';
  }
  if (formProps.completionDateTime === undefined) {
    errors.completionDateTime = 'Select Date ';
  } else if (formProps.completionDateTime === '') {
    errors.completionDateTime = 'Select Date';
  }
  if (formProps.taskDateTime === undefined) {
    errors.taskDateTime = 'Select Date ';
  } else if (formProps.taskDateTime === '') {
    errors.taskDateTime = 'Select Date';
  }
  if (formProps.isActive === undefined) {
    errors.isActive = 'Select Status ';
  } else if (formProps.isActive === '') {
    errors.isActive = 'Select Status';
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  var iniTask = {};
  if (ownProps.taskData) {
    var iniTask = ownProps.taskData;
    iniTask.studentId = ownProps.taskData.studentId;
  }
  return {
    errorMessage: state.account.error,
    initialValues: iniTask,
    subject_list: state.teacher.all_subject_list,
    student_list: state.teacher.student_list,

  }
}

ParentStudentTaskFormModal = reduxForm({
  form: 'form_task',
  validate: validate
})(ParentStudentTaskFormModal);


export default connect(mapStateToProps, { addTask, updateTask, getAllSubjects, getStudents, validationNull })(ParentStudentTaskFormModal);
