
import {
    BLOG_LIST,
    GET_ALL_TEACHER_BLOGS_LIST,
    GET_BLOG_INFO,
    VALIDATION_ERROR,
} from './blog_action';

export default function BlogReducer(state = {}, action) {
    switch (action.type) {
        case VALIDATION_ERROR:
            return { ...state, error: action.payload }
        case BLOG_LIST:
            return { ...state, blog_list: action.payload.data }
        case GET_ALL_TEACHER_BLOGS_LIST:
            return { ...state, teacher_blogs_list: action.payload.data }
        case GET_BLOG_INFO:
            return { ...state, blog_info: action.payload.data }
        default:
            return state;


    }
}