import React from 'react';
import {  Breadcrumbs } from '../library/elements';
import owner from '../images/owner-new.png';
class About extends React.Component {
    render() {

        var breadcrumbs = [];
        breadcrumbs.push({ text: 'Home', value: '/' });
        return (
            <div className="page bg-white mt-5" id="page-about">
                {/* top part  */}
                <div className="container">

                    <div className="page-heading mt-6">

                        <div className="d-flex justify-content-between pt-3 breadcrumb-container">
                            <div className="p-2  mt-2 mb-2 breadcrumb-title text-primary font-weight-bold">
                             About
                            </div>
                            <div className="page-heading-breadcrumb">
                                <Breadcrumbs breadcrumbs={breadcrumbs} />
                            </div>
                        </div>

                    </div>

                    <div className="page-about-container mb-0">
                        <div className="row">
                            <div className="col-sm-12 col-lg-9 col-md-10">
                                <div className="top-introduction">

                                    <p className='justify_content'>My name is <b className='text-primary font-weight-bold'>Jamie Maloney</b> and I am the founder of <b className='text-primary font-weight-bold'>Learning3ly</b>. I spent 5 years teaching for Pittsburgh Public School within an Autistic Support Classroom. When my second son was born I resigned from teaching and began working as a Behavior Analyst. I was providing school and home based services to children and families when the Covid pandemic began. I found myself learning to provide services remotely. I was seeing and hearing the struggles and realities teachers, parents and students were facing in regards to the current education situation. This is when the idea of Learning3ly began. I said to myself “There has to be another way for these students to access their curriculum, for parents to support their children and for teachers to reach their students.” This is where the idea of 3 came from. Teachers, parents, and students. This program was made for all of you.</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-md-2">
                                <img className="img img-fluid" src={owner} style={{height:'70%'}} alt="Jamie Maloney" />
                            </div>
                        </div>
                    </div>
                    {/* bottom part  */}
                    <div className="page-about-container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5 col-md-5">
                                <div className="top-introduction">
                                    <h5 className='text-primary'>What we Are:</h5>
                                    <p className='justify_content'>An eLearning platform for children with specific learning needs. A tool for parents and teachers to create and support their children through an individualized learning program. Learning3ly will allow teachers to choose specific goals, reinforcers and prompting levels for each student. The program will gather the students' data and generate graphs for teachers. The teacher can align each student's program with their current goals to make data collection easier and more efficient. Learning3ly will then generate an individualized intensive teaching session utilizing errorless teaching procedures based on the specifics chosen. The session will be presented using audio of the teacher/service providers voice and prompting. Learning3ly will easily allow the teacher to communicate all information with the student's family.</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-4 col-md-4">
                                <div className="top-introduction">
                                    <h5 className='text-primary'>Who we are:</h5>
                                    <p className='justify_content'>The mission of Learning3ly is to provide a user-friendly program for teachers, parents, and students to allow success through individualized, repetitive practice. Learning3ly will provide the necessary measures for a student to increase access to their specific goals. Increased presentation will result in quicker mastery and longer maintenance of mastered skills. Learning3ly will easily allow the teacher to communicate all information with the students' family.</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-md-3">
                                <div className="top-introduction">
                                    <h5 className='text-primary'>Why Learning3ly:</h5>
                                    <p className='justify_content'>The idea behind Learning3ly is three minds working towards one success! Teachers/Service Providers(one), Parents(two) and Students(three) all working together to maximize a child's learning possibilities. Learning3ly is for ANY service provider or teacher working with a child who could benefit from individualized goals and instruction.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default About;