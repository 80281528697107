import { Field, reduxForm } from 'redux-form';
import { Loading, input } from '../src/library/elements';
import React, { Component } from 'react';
import { subscribeNewsletter, validationNull } from '../src/teacher/teacher_action';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading: false
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
      }

    handleFormSubmit(formProps) {
        this.props.subscribeNewsletter(formProps, function (err, result) {
          if (result) {
            console.log("Result", result);
          }
        });
      }

    render() {
        const {handleSubmit, newsletter_response } = this.props;

        const queryParams = new URLSearchParams(window.location.search);
        const audioUrl = queryParams.get('audioUrl');
        let footer_params = "";
        if(audioUrl && audioUrl != "" && audioUrl !== undefined){
            footer_params = " audioscreen";
        }
        

        return (
            <footer id="footer" className={cookie.load('student') || cookie.load('parent') || cookie.load('teacher') ? "d-none footer alc-footer "+footer_params : "footer alc-footer "+footer_params} >
                <div className="container pt-4">

                    <div className="row">

                        <div className="col-lg-3 col-md-3">
                            {/* <Link to="/" className="alc-logo alc-c-white"><h1>Learning3ly</h1></Link> */}
                            <h5 className="mt-3 font-weight-bold">Our Location</h5>
                            <hr className="mb-0 d-md-block alc-hr alc-hr-primary" />
                            <ul className="list-unstyled">
                                <li className="alc-nav-link alc-c-white alc-lh-2">
                                    <i className="fa fa-map-marker mr-2 mt-2 ml-1"></i><span>Pennsylvania, US</span>
                                </li>
                                <li className="alc-nav-link alc-c-white alc-lh-2">
                                    <i className="fa fa-phone-square mr-2 mt-2"></i><span>+1 724 553 4517</span>
                                </li>
                                <li className="alc-nav-link alc-c-white alc-lh-2">
                                    <i className="fa fa-envelope mr-2 mt-2"></i><span>info@learning3ly.com</span>
                                </li>

                            </ul>

                        </div>

                        <hr className="clearfix d-md-none mb-0" />



                        <div className="col-lg-2 col-md-2  m-footer-block">
                            <h5 className="my-2 font-weight-bold d-none d-md-block">Follow</h5>
                            <hr className="d-none d-md-block alc-hr alc-hr-primary" />
                            <div className="d-md-none title" data-target="#marketplace" data-toggle="collapse">
                                <div className="mt-3 font-weight-bold">Follow
                                    <div className="float-right navbar-toggler">
                                        <i className="fa fa-angle-down"></i>
                                        <i className="fa fa-angle-up"></i>
                                    </div>
                                </div>
                                <hr className="mb-0 d-md-block alc-hr alc-hr-primary" />
                            </div>

                            {/* <ul className="list-unstyled collapse" id="marketplace">
                                <li><a className="alc-nav-link alc-c-white alc-lh-2" href="https://www.facebook.com/Learning3ly/" target="_blank"><i className="fa fa-facebook"></i> Like US on Facebook</a></li>
                                <li><a className="alc-nav-link alc-c-white alc-lh-2" href="https://twitter.com/Learning3ly" target="_blank"><i className="fa fa-twitter"></i> Follow US on twitter</a></li>
                            </ul> */}

                            <ul className="list-unstyled collapse" id="marketplace">
                                <li><a className="alc-nav-link alc-c-white alc-lh-2" href="https://twitter.com/Learning3ly" target="_blank"><i className="fa fa-twitter alc-icon-social mr-2 fa-lg"></i> Twitter</a></li>
                                <li><a className="alc-nav-link alc-c-white alc-lh-2" href="https://www.facebook.com/Learning3ly/" target="_blank"><i className="fa fa-facebook alc-icon-social mr-2 fa-lg"></i> Facebook</a>
                                </li>
                                <li><a className="alc-nav-link alc-c-white alc-lh-2" href="https://www.instagram.com/learning3ly/" target="_blank"><i className="fa fa-instagram alc-icon-social mr-2 fa-lg"></i> Instagram</a>
                                </li>
                                <li><a className="alc-nav-link alc-c-white alc-lh-2" href="https://www.linkedin.com/in/learning-threely-403572203/" target="_blank"><i className="fa fa-linkedin alc-icon-social mr-2 fa-lg"></i> Linkedin</a></li>
                            </ul>
                        </div>

                        <hr className="clearfix d-md-none mb-0" />
                        <div className="col-lg-2 col-md-2 m-footer-block">
                            <h5 className="my-2 font-weight-bold d-none d-md-block">Legal</h5>
                            <hr className="d-none d-md-block alc-hr alc-hr-primary" />
                            <div className="d-md-none title" data-target="#about" data-toggle="collapse">
                                <div className="mt-3 font-weight-bold">Legal
                                    <div className="float-right navbar-toggler">
                                        <i className="fa fa-angle-down"></i>
                                        <i className="fa fa-angle-up"></i>
                                    </div>
                                </div>
                                <hr className="mb-0 d-md-block alc-hr alc-hr-primary" />
                            </div>
                            <ul className="list-unstyled collapse" id="about">
                                <li><Link className="alc-nav-link alc-c-white alc-lh-2" to="/privacy"><i className="fa fa-lock" ></i> Privacy</Link></li>

                                <li><Link className="alc-nav-link alc-c-white alc-lh-2" to="/terms-of-service"><i className="fa fa-check-square"></i> Terms of Service</Link></li>

                                <li><Link className="alc-nav-link alc-c-white alc-lh-2" ><i className="fa fa-archive"></i> Cookies</Link></li>
                            </ul>
                        </div>

                        <hr className="clearfix d-md-none mb-0" />
                        <div className="col-lg-2 col-md-2 m-footer-block">
                            <h5 className="my-2 font-weight-bold d-none d-md-block">Demonstration</h5>
                            <hr className="d-none d-md-block alc-hr alc-hr-primary" />
                            <div className="d-md-none title" data-target="#about" data-toggle="collapse">
                                <div className="mt-3 font-weight-bold">Demonstration
                                    <div className="float-right navbar-toggler">
                                        <i className="fa fa-angle-down"></i>
                                        <i className="fa fa-angle-up"></i>
                                    </div>
                                </div>
                                <hr className="mb-0 d-md-block alc-hr alc-hr-primary" />
                            </div>
                            <ul className="list-unstyled collapse" id="about">
                                
                            <li><a target={"_blank"} className="alc-c-white alc-lh-2" href="https://www.youtube.com/watch?v=FlEuaD5KY6c"><i className="fa fa-map-marker" ></i> Learning3ly</a></li>
                                
                                <li><a target={"_blank"} className="alc-nav-link alc-c-white alc-lh-2" href="https://www.youtube.com/watch?v=xnhOqc9ZlsQ"><i className="fa fa-lock" ></i> Teacher</a></li>

                                <li><a target={"_blank"} className="alc-nav-link alc-c-white alc-lh-2" href="https://www.youtube.com/watch?v=R2q_j7UMhxs"><i className="fa fa-check-square"></i> Student</a></li>

                                <li><a className="alc-nav-link alc-c-white alc-lh-2" href="https://www.youtube.com/watch?v=httY9gHLnMc" target="_blank"><i className="fa fa-archive"></i> Parent</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-3  m-footer-block">
                            <h5 className="my-2 font-weight-bold d-none d-md-block">Subscribe Newsletter</h5>
                            <hr className="d-none d-md-block alc-hr alc-hr-primary" />
                            <div className="d-md-none title" data-target="#store" data-toggle="collapse">
                                <div className="mt-3 font-weight-bold">Subscribe Newsletter
                                    <div className="float-right navbar-toggler">
                                        <i className="fa fa-angle-down"></i>
                                        <i className="fa fa-angle-up"></i>
                                    </div>
                                </div>
                                <hr className="mb-0 d-md-block alc-hr alc-hr-primary" />
                            </div>
                            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                <div>
                                <Field name="email" type="text" component={input} label="Email"/>
                                </div>
                                {
                                    newsletter_response && newsletter_response.msg != "" && <div className='text-success'>
                                        {newsletter_response.msg}
                                    </div>
                                }
                                <div>
                                <button action="form_newsletter" className=" mb-2  mb-2 mb-2 btn alc-btn mt-2 w-50 alc-btn-action alc-btn-larg">Subscribe</button>
                                </div>
                            </form>
                            {/* <ul className="list-unstyled collapse" id="store">
                                <li><Link className="alc-nav-link alc-c-white alc-lh-2" to="/"><i className="fa fa-apple" ></i> App Store</Link></li>
                                <li><Link className="alc-nav-link alc-c-white alc-lh-2" to="/"><i className="fa fa-google"></i> Google Play</Link></li>
                                <li><Link className="alc-nav-link alc-c-white alc-lh-2" ><i className="fa fa-gamepad" ></i> Kindle Fire</Link></li>
                            </ul> */}
                        </div>

                        <hr className="clearfix d-md-none mb-0" />

                    </div>


                </div>
                <div className="container pt-3 pb-4">
                    <div className="row">
                        <div className="col-sm-5">
                            <p className="mb-0 mt-2 alc-c-white alc-font-small">
                                Learning3ly - Simplifying Education System @ 2022.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <span className="hidden-md-down pull-left">
                                <a className="btn btn-primary mr-2" href="https://apps.apple.com/in/app/learning3ly/id1622780641" target="_blank"><i className="fa fa-apple" ></i></a>
                                <a className="btn btn-primary" href="https://play.google.com/store/apps/details?id=com.learning3ly" target="_blank"><i className="fa fa-android"></i></a>
                                {/* <a className="alc-btn-social" href="https://www.amazon.in/KINDLE-FIRE-HD-16GB-BLACK/dp/B00IKPYKWG" alt="kindle fire" target="_blank"><i className="fa fa-gamepad" ></i></a> */}
                            </span>
                        </div>
                        <div className="col-sm-4">
                            <p className="mb-0 mt-2 pull-right float-right alc-c-white alc-font-small">
                                Powered By: <a href="https://www.cloudswiftsolutions.com" target="_blank" class="text-white">Cloudswift Solutions</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    };
}

function validate(formProps) {
    const errors = {}
    if (!formProps.email) {
      errors.email = 'Required Email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
      errors.email = 'Invalid Email Address'
    }
    return errors
}

Footer = reduxForm({
    form: 'form_newsletter',
    enableReinitialize: true,
    validate: validate
  })(Footer);
  

function mapStateToProps(state) {
    //console.log("state.teacher.newsletter_response==>",state.teacher.newsletter_response);
    return {
        authenticated: state.account.authenticated,
        authenticated_parent: state.account.authenticated_parent,
        authenticated_student: state.account.authenticated_student,
        teacher_profile: state.account.teacher_profile,
        newsletter_response: state.teacher.newsletter_response
    };
}

export default connect(mapStateToProps, {subscribeNewsletter})(Footer);
