import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from './images/home.png';

var ReactRotatingText = require('react-rotating-text');

class CommonHome extends Component {

  render() {
    const { authenticated, authenticated_student, authenticated_parent } = this.props;
    if(authenticated){
      this.props.history.push('/dashboard');
    }
    if(authenticated_parent){
      this.props.history.push('/parent_dashboard');
    }
    if(authenticated_student){
      this.props.history.push('/student_dashboard');
    }
    if(!authenticated){
      this.props.history.push('/signin');
    }

    return (
      <div className="common-home">

     <div className="first-section"
        style={{  
          backgroundImage: `url(${logo})`,
        }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="first-section-content text-center cm-frm">

                  <h2 className="wow bounceIn  animated slide-text" style={{'visibility': 'visible','-webkit-animation-delay': '0.6s','-moz-animation-delay': '0.6s', 'animation-delay': '0.6s'}}>  Learning3ly  Help for  <ReactRotatingText
                  items={['Projects' ,'Tutorials', 'Teaching']}
                  pause={1500}
                  className="text-warning"
                  emptyPause={1000}
                  typingInterval={100}
                  deletingInterval={100}
                  />
                </h2>
               
                 {
                  (!authenticated && !authenticated_parent && !authenticated_student)
                  ?
                  <Link to="/signup"  className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-large" >Register Now!</Link>
                  :
                 null
                 }
               <div className="overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="services-area bg ptb-100 text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="section-title">
                  <h3>We provide a Triangular Model of Learning !</h3>
                  <p className="hidden-lg-down">We provide key enrichment and clear pathways to success.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12"></div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h4 className="mb-3"> Personalized Instruction</h4>
                <h6 className="mb-3">Individualized instruction and goals to meet </h6>
                <h6 className="mb-3">each child's specific needs</h6>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12"></div>
            </div>

         
            <div className="row triangle-lookup-section">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="triangle-left">
                <h4 className="mb-3"> Data Collection</h4>
                <h6 className="mb-3">Data Collection to drive educational decision making.</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">

                <div className="triangle"></div>

              </div>



              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="triangle-right">
                <h4 className="mb-3"> Communication</h4>
                <h6 className="mb-3">Consistent collaboration and communication between all team members.</h6>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>

    );
  }
}


function mapStateToProps(state) {

  return {
    authenticated: state.account.authenticated,
    authenticated_parent: state.account.authenticated_parent,
    authenticated_student: state.account.authenticated_student
  }
}

export default connect(mapStateToProps, {})(CommonHome);
