import * as config from '../system/config';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';

import cookie from 'react-cookie';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const [name, setName] = useState(cookie.load("login_user"));
  const [email, setEmail] = useState(cookie.load("login_email"));
  const [selectedPlanLabel, setselectedPlanLabel] = useState(props.selectedPlanLabel);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setErrorMsg('');

    const paymentMethodObj = {
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email
      },
    };
    const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

    stripePaymentMethodHandler({
      result: paymentMethodResult,
      payableAmount: props.payableAmount,
      paymentStartDate: props.paymentStartDate,
      paymentEndDate: props.paymentEndDate,
      paymentPackageName: props.paymentPackageName,
      paymentUserId: props.paymentUserId,
      paymentLoginUser: props.paymentLoginUser,
      paymentRole: props.paymentRole
    }, handleResponse);
  };

  const stripePaymentMethodHandler = async (data, cb) => {
    const { payableAmount, result, paymentStartDate, paymentEndDate, paymentPackageName, paymentUserId, paymentLoginUser, paymentRole } = data;
    if (result.error) {
      // show error in payment form
      cb(result);
    } else {
      const paymentResponse = await stripePayment({
        payment_method_id: result.paymentMethod.id,
        name: result.paymentMethod.billing_details.name,
        email: result.paymentMethod.billing_details.email,
        amount: (parseFloat(payableAmount) * 100),
        "startDate": paymentStartDate,
        "endDate": paymentEndDate,
        "totalAmount": (parseFloat(payableAmount) * 100),
        "packageName": paymentPackageName,
        "userId": paymentUserId,
        "loginUser": paymentLoginUser,
        "role": paymentRole,
      });
      if(paymentResponse.success == true){
        
        let placedOrderData = {
          payment_method_id: result.paymentMethod.id,
          name: result.paymentMethod.billing_details.name,
          email: result.paymentMethod.billing_details.email,
          amount: (parseFloat(payableAmount) * 1),
          "startDate": paymentStartDate,
          "endDate": paymentEndDate,
          "totalAmount": (parseFloat(payableAmount) * 1),
          "packageName": paymentPackageName,
          "userId": paymentUserId,
          "loginUser": paymentLoginUser,
          "role": paymentRole,
        }

        let placed = await placeOrder(placedOrderData);
        
        if(placed) {
          setSuccessMsg(paymentResponse.message+'. Please do relogin for loading your updated package.');
          cookie.remove('teacher', { path: '/' });
          cookie.remove('parent', { path: '/' });
          cookie.remove('student', { path: '/' });
          cookie.remove("is_login");
          cookie.remove("login_user");
          cookie.remove("login_photo");
          cookie.remove("packageEndDate");
          cookie.remove("packageStartDate");
          cookie.remove("loginType");
          setTimeout(() => {
            window.location.href = '/signin';
          },5000);
        }
      } else {
        setErrorMsg(paymentResponse.message);   
      }
      //cb(paymentResponse);
    }
  }

  const placeOrder = async data => {
    const res = await fetch(config.API_URL+`/teacher/package-purchase`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': cookie.load("teacher")
      },
      body: JSON.stringify(data),
    })
    return await res.json();
  }

  // place backend API call for payment
  const stripePayment = async data => {
    const res = await fetch(config.API_URL+`/teacher/package-verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': cookie.load("teacher")
      },
      body: JSON.stringify(data),
    })
    return await res.json();
  }

  // callback method to handle the response
  const handleResponse = response => {
    setLoading(false);
    if (response.error) {
      setErrorMsg(typeof response.error === 'string' ? response.error : response.error.message);
      return;
    }
    props.setPaymentCompleted(response.success ? true : false);
  };

  const setFormAgain = () => {
    window.location.href = '/pricing';
  }

  return (
    <React.Fragment>

      <div className='columns w-100'>
        <ul className='price payment-screen'>
          <li>
            <h4 className="d-flex justify-content-between align-items-center mb-3 bg-primary header">
              <span className="text-white font-20">Please enter your card details :</span>
            </h4>
            <h6><span className='text-primary'>{selectedPlanLabel}</span></h6>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3 text-left">
                  <label htmlFor="cc-name">Name on card</label>
                  <input
                    id="cc-name"
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3 text-left">
                  <label htmlFor="cc-email">Email</label>
                  <input
                    id="cc-email"
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3 text-left">
                  <label htmlFor="cc-number">Card Number</label>
                  <CardNumberElement
                    id="cc-number"
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3 text-left">
                  <label htmlFor="expiry">Expiration Date</label>
                  <CardExpiryElement
                    id="expiry"
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
                <div className="col-md-6 mb-3 text-left">
                  <label htmlFor="cvc">CVC</label>
                  <CardCvcElement
                    id="cvc"
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>

              <hr className="mb-4" />
              <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY  $${props.payableAmount}`}
              </button>
              {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
              {successMsg && <div className="text-success mt-2">{successMsg}</div>}

              <br /><br />
              <a className="text-secondary" onClick={() => setFormAgain()} type="submit" disabled={loading}>
                Go Back
              </a>
            </form>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}