import React, {Component, PropTypes} from 'react';
import { htmlDecode } from '../function';
import _ from 'lodash';
class MultiOptionCheckbox extends Component {
  constructor() {
    super();
    this.getCurrentValues = this.getCurrentValues.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getCurrentValues() {
    const { field } = this.props;
    const { value, initialValue } = field;

    let previousValues = [];
    if (!_.isUndefined(value) && value !== '') {
      previousValues = value;
    }
    else if (!_.isUndefined(initialValue) && initialValue !== '') {
      previousValues = initialValue;
    }

    const currentValues = _.isArray(previousValues) ? [...previousValues] : [previousValues];

    return currentValues;
  }

  handleChange(event, id) {
    const {field} = this.props;
    const {onChange} = field;
    const values = this.getCurrentValues();

    if (event.target.checked) {
      values.push(id);
    }
    else {
      values.splice(values.indexOf(id), 1);
    }
    return onChange(values);
  }

    render() {
        const {label, options, field, title, key } = this.props;
        const {onBlur} = field;
        const values = this.getCurrentValues();
        return (
          <div>
            {
              options.map(option => {
                const isChecked = values.indexOf(option.option_value_id) > -1;
                return (
                  <label className="checkbox-label" key={"product.option.value.checkbox."+option.option_value_id}>
                    {htmlDecode(option)}
                    <input
                      {...field}
                      type="checkbox"
                      onChange={event => this.handleChange(event, option.option_value_id)}
                      onBlur={() => onBlur(values)}
                      checked={isChecked}
                      value={option.option_value_id}
                    />
                    <span className="checkmark"></span>
                  </label>
                );
              })
            }
          </div>
        );
    }
}


export default MultiOptionCheckbox;
