import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ModalViewCommentFiles from './modal/goal_attachment';
import { connect } from 'react-redux';
import { getGoalList } from './student_action';
import { modal } from '../library/modal';

var dateFormat = require('dateformat');
class StudentGoalList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };
    this.actionViewList = this.actionViewList.bind(this);
    this.actionWordsViewList = this.actionWordsViewList.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }
  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getGoalList(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }




  ViewContentAttachment(title, documents, type) {
    modal.add(ModalViewCommentFiles, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      documents: documents,
      type: type,
    });
  }
  actionViewList(cell, row) {
    return (
      row.image !== undefined && row.image.length > 0 ?
        <Link onClick={() => this.ViewContentAttachment('View Goal Attachment', row.image, 'image')} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-eye mr-1"></i>Attchment</Link>
        :
        <span className="text-center"> - </span>
    )
  }
  actionWordsViewList(cell, row) {
    return (
      row.words !== undefined && row.words.length > 0 ?
        <Link onClick={() => this.ViewContentAttachment('View Words', row.words, 'word')} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-eye mr-1"></i>Words Attchment</Link>
        :
        <span className="text-center"> - </span>
    )
  }


  statusFormat(cell, row) {
    if (row.isActive === "active") {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else if (row.isActive === "mastered") {
      return (
        <div className="badge bg-warning ">Mastered</div>
      );
    } else if (row.isActive === "discontinued") {
      return (
        <div className="badge bg-danger ">Discontinued</div>
      );
    }
  }
  URLFormatter(cell, row) {
    let url = "/student/goal_info/" + row._id;
    return (
      <Link to={url}>
        <span className="text-primary">
          {row.title}
        </span>
      </Link>

    );
  }
  DateCreatedFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.batchDate, "mm/dd/yyyy")}</span>


    );
  }
  DateModifiedFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.batchDate, "mm/dd/yyyy")}</span>


    );
  }
  SubmitStatusFormat(cell, row) {
    if (row.studentGoalStatus !== undefined) {
      return (
        <Link className="badge bg-success">{row.studentGoalStatus}</Link>
      )
    }
    else {
      return (
        <div className="badge bg-warning ">Not Completed</div>
      )
    }
  }
  render() {
    const { goal_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!goal_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    const Status = {
      Active: 'Active',
    };
    const SubmitStatus = {
      "completed": 'completed',
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Goal List
                    </h5>


                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                      keyField='_id'
                      data={(goal_list != undefined && goal_list.length ? goal_list : [])}
                      bordered={false}
                      striped={false}
                      pagination={true}
                      fetchInfo={{ dataTotalSize: goal_list.length }}
                      options={{
                        sizePerPage: this.state.sizePerPage,
                        page: this.state.currentPage,
                        hideSizePerPage: true,
                        paginationShowsTotal: this.renderPaginationShowsTotal,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSortChange: this.onSortChange
                      }}
                    >
                      <TableHeaderColumn
                        dataField='title'
                        columnClassName={''}
                        filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        width='400px'
                        dataFormat={this.URLFormatter}
                        dataSort={true}>
                        Goal Title
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='createdTime'
                        columnClassName={''}
                        dataAlign="left"
                        dataFormat={this.DateCreatedFormat}>
                        Date Assigned
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='dateMastered'
                        columnClassName={''}
                        dataAlign="left"
                        dataFormat={this.DateModifiedFormat}>
                        Date Completed
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='words'
                        columnClassName={''}
                        dataAlign="left"
                        dataFormat={this.actionWordsViewList}>
                        Score (in %)
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='studentGoalStatus' dataAlign="center" dataFormat={this.SubmitStatusFormat} filter={{ type: "SelectFilter", options: SubmitStatus, placeholder: "- Select Submit Status -" }} dataSort>Submit Status</TableHeaderColumn>
                      <TableHeaderColumn dataField='isActive' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort>Status</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    );

  }
}

function mapStateToProps(state) {
  return {
    goal_list: state.student.goal_list
  }
}
export default connect(mapStateToProps, { getGoalList })(StudentGoalList);
