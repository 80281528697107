import * as config from '../../src/system/config';

import axios from 'axios';
import cookie from 'react-cookie';
import { createAlert } from '../library/function';

var fs = require('fs');
export const BLOG_LIST = 'BLOG_LIST';
export const GET_ALL_TEACHER_BLOGS_LIST = 'GET_ALL_TEACHER_BLOGS_LIST';
export const GET_BLOG_INFO = 'GET_BLOG_INFO';

export const VALIDATION_ERROR = "VALIDATION_ERROR";

export function getBlogList(callback) {
  console.log("Blog Lists ====> ",`${config.API_URL + config.API_GET_BLOG_LIST}?_id=${cookie.load('teacher_id')}`);
    return function (dispatch) {
        axios({
            method: 'GET',
            url: `${config.API_URL + config.API_GET_BLOG_LIST}?_id=${cookie.load('teacher_id')}`,
            headers: {
              Authorization: cookie.load('teacher'),
            },
        })
            .then(response => {
                dispatch({ type: BLOG_LIST, payload: response.data })
                callback(true, response);
            })
            .catch(error => {
            })
    }
}
export function updateBlog(formData, callback){ 
    return function (dispatch) {
      axios({
        method: 'put',
        headers: {
          Authorization: cookie.load('teacher'),
        },
        url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BLOG}?_id=${cookie.load('teacher_id')}`,
        data: formData
      })
        .then(response => {
          if (!response.data.err && response.data.data) {
            dispatch(getBlogList(undefined,callback));
            callback(null, response);
            createAlert({ message: 'You Blog has been Updated.' });
          } else {
            
            dispatch(validation(response.data.err));
            callback(null, false);
          }
        })
        .catch(error => {
          if (error.response) {

            dispatch(validation("Enter Your Valid  Data"));
            callback(null, false);
          }
        });
    }
  }
  
  export function addBlog(formData, callback) {

    // console.log(`${config.API_URL + config.API_INSERT_UPDATE__DETELE_BLOG}?_id=${cookie.load('teacher_id')}`,formData);
    // return false;

    return function (dispatch) {
  
      axios({
        method: 'POST',
        url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BLOG}?_id=${cookie.load('teacher_id')}`,
        data: formData,
        headers: {
          Authorization: cookie.load('teacher'),
        },
      })
        .then(response => {
          if (!response.data.err && response.data) {
            dispatch(getBlogList(undefined,callback));
            callback(null, response);
            createAlert({ message: response.data.msg });
          } else {
            dispatch(validation(response.data.err));
            callback(null, false);
          }
        })
        .catch(error => {
          if (error.response) {
            dispatch(validation("Enter Your Valid  Data"));
            callback(null, false);
          }
        });
    }
  }
  
  export function deleteBlog(blogId, callback) {
    return function(dispatch) {
      
      axios({
        method: 'delete',
        url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BLOG}?_id=${cookie.load('teacher_id')}`,
        data: {
          blogId:blogId,

        },
        headers: {
          Authorization: cookie.load('teacher'),
        },
      })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
    }
  }

  //  Front Side

  export function getAllTeacherBlogList(filter_keyword,callback) {

    return function (dispatch) {
  
       axios({
        method: 'get',
        url: `${config.API_URL + config.API_GET_ALL_TEACHER_BLOGS_LIST +'?subject='+filter_keyword}`,
      })
        .then(response => {
  
          dispatch({ type: GET_ALL_TEACHER_BLOGS_LIST, payload: response.data });
          callback(null, response);
        })
        .catch(response => {
          //(response);
        });
    }
  }
  export function getBlogInfo(blog_id, callback) {
    return function (dispatch) {
      axios({
        method: 'get',
        url: `${config.API_URL + config.API_GET_BLOG_INFO +"/"+blog_id}`,
      })
        .then(response => {
          dispatch({ type: GET_BLOG_INFO, payload: response.data });
          callback(null, response);
        })
        .catch(error => {
          if (error.response) {
            callback(null, false);
          }
        });
    }
  }
  //  end Front Side
  export function validation(error) {
    return {
      type: VALIDATION_ERROR,
      payload: error
    }
  }
  export function validationNull() {
    return {
      type: VALIDATION_ERROR,
      payload: null
    }
  }