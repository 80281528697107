import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { getGoalInfo, getReinforcementList, updateGoalRenforcement } from './teacher_action';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import Select from 'react-select';
import { connect } from 'react-redux';
import { toHtml } from '../library/function';

var dateFormat = require('dateformat');
class GoalInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setTab: 'nav-upload',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

  }

  componentDidMount() {
    var _this = this;
    var goal_id = this.props.match.params.goal_id;
    if (goal_id !== undefined && goal_id !== 'add') {
      this.setState({ isLoading: true });
      this.props.getGoalInfo(goal_id, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    }
    this.props.getReinforcementList(function (err, result) { })

  }
  componentWillReceiveProps(nextProps) {
    var _this = this;
    if (nextProps.match.params.goal_id !== this.props.match.params.goal_id) {
      this.props.getGoalInfo(nextProps.params.goal_id, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    }
  }

  handleFormSubmit(formProps) {
    var _this = this;
    this.setState({ isLoading: true });
    formProps.studentId = this.props.goal_info.studentId;
    formProps.goal_id = this.props.goal_info._id;
    this.props.updateGoalRenforcement(formProps, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });

  }


  loadProductTab(tab) {
    this.setState({ setTab: tab });
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }

  renderGoalHistory(goal_data) {
    if (goal_data?.length > 0) {
      return goal_data.map((goal_ans) => {
        return (
          <tr key={'history_' + goal_ans._id}>
            <td className="text-left">{goal_ans.title}</td>
            <td className="text-center"><img src={goal_ans.img_url} alt="ans" style={{ "height": "50px", "width": "50px" }} /></td>
            <td className="text-center"> {goal_ans.attempt}</td>
            <td className="text-center">{this.props.goal_info.student_name}</td>

          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No  Log Data Found!</td>
        </tr>
      );
    }
  }
  renderimages(Contentimages) {
    if (Contentimages.length) {
      var images_data = Contentimages.map((img, index) => {
        return (
          <div className="col-lg-4 col-sm-6 col-6 mt-2 mb-2 text-center" key={'task-image-' + index}>
            <Link onClick={() => window.open(img.img_url, "_blank")}>

              <div className="card alc-card alc-card-fancy alc-hover-fancy">
                <div className="alc-card-fancy-image">


                  <img src={img.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '100%', height: '15rem' }} />

                </div>
                <div className="card-footer alc-card-fancy-content text-center">
                  <h4 className="card-title alc-card-title"><i className="fa fa-eye"></i></h4>
                </div>
              </div>
            </Link>
          </div>
        );

      });

      return (


        images_data


      );
    }
  }

  render() {

    const { handleSubmit, goal_info, reinforcement_list } = this.props;
    const { isLoading, setTab } = this.state;

    if (goal_info === undefined) {
      return (
        <div className="task-undefined"><Loading /></div>
      );
    }
    console.log("_---goal_info", goal_info)

    var reinforcementData = [];
    for (var i in reinforcement_list) {
      reinforcementData.push({ 'label': reinforcement_list[i].name, 'value': reinforcement_list[i]._id })
    }

    return (
      <div className="page material-ui mt-5 mb-5" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content p-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0"> <i className="fa fa-info mr-1" aria-hidden="true"></i> Goal Details Info </h4>
                </div>

                <div className="card-body">
                  <div className="tab-content" id="nav-tabContent">

                    <div className="card bg-light">
                      <div className="card-body mt-3">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <img src="https://image.shutterstock.com/image-vector/man-icon-vector-260nw-1040084344.jpg" className="img-thumbnail rounded" alt="image" />
                              </tr>
                              <tr>
                                <td className="first  border-top-0 font-weight-bold  h6">Student Name</td>
                                <td className="second border-top-0 col-6">{goal_info?.student_name}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weight-bold  h6">Assign Goal :</td>
                                <td className="second border-top-0 col-6">{goal_info?.title}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weight-bold  h6">Goal  Details :</td>
                                <td className="second border-top-0 col-6">{toHtml(goal_info.description)}</td>
                              </tr>

                              <tr>
                                <td className="first  font-weight-bold  h6">Goal  Status :</td>
                                <td className="second border-top-0 col-6">{goal_info.studentGoalStatus !== undefined ? <span className="second badge bg-success">{goal_info.studentGoalStatus}</span> : <span className="second badge bg-warning">Not Completed</span>}  {goal_info.completionDateTime !== undefined && dateFormat(goal_info.completionDateTime)}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weight-bold  h6">Reinformcement Details :</td>
                                <td className="second border-top-0 col-6"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">
                  <h5 className="mb-0"> Student Goal Workspace </h5>
                </div>
                <div className="card-body">

                  <div className=" react-bs-table-container">
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th className="text-left">Hint</th>
                          <th className="text-center">Object</th>
                          <th className="text-center">No of Attemp</th>
                          <th className="text-center">Added by</th>

                        </tr>
                      </thead>
                      <tbody>
                        {this.renderGoalHistory(goal_info.answerData)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>


              <div className="card mt-3">
                <div className="card-header h5 bg-white"><i className="fa fa-info mr-1" aria-hidden="true"></i> Goal Content Information - <span className="text-primary">{goal_info?.contentData?.title}</span> </div>
                <div className="card-body">
                  <div className="card mb-3">
                    <div className="card-header">Goal Content Attchments</div>
                    <div className="card-body">
                      {
                        goal_info?.contentData?.image?.length
                          ?

                          <div className="row">
                            {this.renderimages(goal_info?.contentData?.image)}
                          </div>

                          :
                          <div className="row text-center">
                            <h6>No Attchment Found</h6>
                          </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
              {/* reinformcement process  */}
              <div className="card mt-3">

                <div className="card-header">
                  <h5 className="mb-0"> Add Reinformcement</h5>
                </div>


                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                  <div className="card-body">
                    <div className="row mt-2">
                      <div className="col-sm-6">
                        <label>Select Reinformcement</label>
                        <Field name="reInforcementId" component={reinforcementId => {

                          return (
                            <Select simpleValue
                              value={reinforcementId.input.value}
                              placeholder=" Select Parent "
                              options={reinforcementData}
                              onChange={(e) => {
                                reinforcementId.input.onChange(e);
                              }} />
                          )
                        }} className="form-control" />
                      </div>
                      <div className="col-sm-6">
                        <button action="form_goal" className=" mb-2  mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                          <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Submit '}
                        </button>

                      </div>
                    </div>


                  </div>
                </form>

                {goal_info?.reinforcementData && <div className="card mt-3">
                  <div className="card-header">
                    <h5 className="mb-0"> Reinformcement History </h5>
                  </div>
                  <div className="card-body">

                    <div className=" react-bs-table-container">
                      <table className="table table-responsive">
                        <thead>
                          <tr>
                            <th className="text-left">Image</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Discription</th>
                            <th className="text-center">Status</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left"><Link onClick={() => window.open(goal_info?.reinforcementData?.attachments[0], "_blank")}> <img src={goal_info?.reinforcementData?.attachments[0]} className="img-thumbnail" alt="image" /></Link></td>
                            <td className="text-center">{goal_info?.reinforcementData?.name}</td>
                            <td className="text-center"> {toHtml(goal_info?.reinforcementData?.description)}</td>
                            <td className="text-center">{goal_info?.reinforcementData?.isActive == true ? "Active" : "Deactive"}</td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>





    );
  }
}
GoalInfo = reduxForm({
  form: 'form_goal',
  enableReinitialize: true,
})(GoalInfo);

function mapStateToProps(state) {
  var iniPro = {}
  //console.log("State Teacher Goal Info",state.teacher);
  if (state.teacher.goal_info !== undefined) {
    iniPro.reInforcementId = state.teacher.goal_info.reInforcementId;
  }
  return {

    initialValues: iniPro,
    goal_info: state.teacher.goal_info,
    reinforcement_list: state.teacher.reinforcement_list,
  }
}

export default connect(mapStateToProps, { getGoalInfo, getReinforcementList, updateGoalRenforcement })(GoalInfo);
