import * as config from '../system/config';

import axios from 'axios';
import cookie from 'react-cookie';
import { createAlert } from '../library/function';

export const GET_BATCH_LIST = "GET_BATCH_LIST";
export const GET_All_SUBJECTS = "GET_All_SUBJECTS";

export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const GET_STUDENT_LIST = "GET_STUDENT_LIST";
export const GET_PARENT_TEACHER_CHAT_LIST = "GET_PARENT_TEACHER_CHAT_LIST";
export const GET_USER_RECENT_CHAT_LIST = "GET_USER_RECENT_CHAT_LIST";
export const GET_USER_TEACHER_RECENT_CHAT_LIST = "GET_USER_TEACHER_RECENT_CHAT_LIST";
export const GET_COULAGE_LIST = "GET_COULAGE_LIST";
export const GET_PARENTDASHBOARD_LIST = "GET_PARENTDASHBOARD_LIST";

export const FETCH_STUDENT_INFO = "FETCH_STUDENT_INFO";
export const GET_TEACHER_SUBJECTS = "GET_TEACHER_SUBJECTS";
export const FETCH_PARENT_ACCOUNT_PROFILE = "FETCH_PARENT_ACCOUNT_PROFILE";

export const GET_STUDENT_TASK_LIST = "GET_STUDENT_TASK_LIST";
export const FETCH_TASK_INFO = "FETCH_TASK_INFO";
export const FETCH_GOAL_INFO = "FETCH_GOAL_INFO";
export const GET_GOAL_LIST = "GET_GOAL_LIST";

export function parentDashboardData(callback) {
  //console.log(" Parent Dashboard ==> ",`${config.API_URL + config.API_GET_PARENT_DASHBOARD}?_id=${cookie.load("parent_id")}`,cookie.load('parent'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_PARENT_DASHBOARD}?_id=${cookie.load("parent_id")}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        dispatch({ type: GET_PARENTDASHBOARD_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("Dashboard List Errors",response);
      });
  }
}

// Student Module 

// students list for teacher 

export function getStudents(callback) {
  return function (dispatch) {
    //console.log("Parent URL ===>",`${config.API_URL + config.API_GET_PARENT_STUDENTS}?_id=${cookie.load('parent_id')}`);
    //console.log(cookie.load('parent'));
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_PARENT_STUDENTS}?_id=${cookie.load('parent_id')}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        dispatch({ type: GET_STUDENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        
      });
  }
}

//
export function getRecentChatsWithUsers(userId,callback) {
  return function (dispatch) {
    //console.log(`${config.API_URL + config.API_GET_RECENT_CHAT_LIST}?_id=${cookie.load('parent_id')}&login_type=parent&teacher_id=${userId}`);
    //console.log(cookie.load('parent'));
    //http://localhost:2900/api/v1/parent/chat-users-by-type?_id=61c1d3665b34ae1e360d4cc1&login_type=parent
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_RECENT_CHAT_LIST}?_id=${cookie.load('parent_id')}&login_type=parent&teacher_id=${userId}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        dispatch({ type: GET_USER_RECENT_CHAT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //console.log("Catch Response", response);
      });
  }
}

export function getTeacherRecentChatsWithUsers(userId,callback) {
  return function (dispatch) {
    //console.log(`${config.API_URL + config.API_GET_RECENT_TEACHER_CHAT_LIST}?_id=${cookie.load('teacher_id')}&login_type=teacher&parent_id=${userId}`);
    //console.log(cookie.load('teacher'));
    //http://localhost:2900/api/v1/parent/chat-users-by-type?_id=61c1d3665b34ae1e360d4cc1&login_type=parent
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_RECENT_TEACHER_CHAT_LIST}?_id=${cookie.load('teacher_id')}&login_type=teacher&parent_id=${userId}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_USER_TEACHER_RECENT_CHAT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //console.log("Catch Response", response);
      });
  }
}

export function getParentTeacherCommunication(teacher_id,callback) {
  return function (dispatch) {
    //http://localhost:2900/api/v1/parent/parent-teacher-chat?_id=61c1d3665b34ae1e360d4cc1&teacher_id=61c3071b23974e281f7870b2
    //console.log(cookie.load('parent'));
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_PARENT_TEACHER_CHAT_LIST}?_id=${cookie.load('parent_id')}&teacher_id=${teacher_id}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        dispatch({ type: GET_PARENT_TEACHER_CHAT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        
      });
  }
}

export function saveChatData(formData, callback) {
  //http://localhost:2900/api/v1/parent/save-chat?_id=61c1d3665b34ae1e360d4cc1
  //console.log("API===>",`${config.API_URL + config.API_SAVE_CHAT}?_id=${cookie.load('parent_id')}`,formData,cookie.load('parent'));

  return function (dispatch) {
    axios({
      method: 'post',
      url: `${config.API_URL + config.API_SAVE_CHAT}?_id=${cookie.load('parent_id')}`,
      data: formData,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        //console.log("Chat Save Response", response);
      })
      .catch(response => {
        console.log(response);
      });
  }
}

export function saveTeacherChatData(formData, callback) {
  //console.log("Save Teacher Chat called.....");
  //http://localhost:2900/api/v1/parent/save-chat?_id=61c1d3665b34ae1e360d4cc1
  //console.log("API===>",`${config.API_URL + config.API_TEACHER_SAVE_CHAT}?_id=${cookie.load('teacher_id')}`,cookie.load('teacher'),formData);

  return function (dispatch) {
    axios({
      method: 'post',
      url: `${config.API_URL + config.API_TEACHER_SAVE_CHAT}?_id=${cookie.load('teacher_id')}`,
      data: formData,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        //console.log("Chat Save Response", response);
      })
      .catch(response => {
        console.log(response);
      });
  }
}
// end 

export function updateStudent(formData, callback) {
  //console.log("Parent Update Student Form Data ===> ",formData);
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_INSERT_STUDENT}?_id=${cookie.load('parent_id')}`,
      data: {
        studentId: formData.studentId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        dob: formData.dob,
        username: formData.username,
        address: formData.address,
        parentId: formData.parentId,
        std: formData.std,
        status: formData.status,
        // email: formData.email,
        grade: formData.grade,
        subjectList: formData.subjectList,
        age: formData.age
      },
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.error && response.data) {
          dispatch(getStudents());
          callback(null, response);
          createAlert({ message: 'You Student has been Updated.' });
        } else {
          dispatch(validation(response.data.error));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addStudent(formData, callback) {
  // console.log(formData,"====",cookie.load('parent_id'));
  // return false;
  return function (dispatch) {
        
    // console.log(`${config.API_URL + config.API_UPDATE_STUDENT}?_id=${cookie.load('parent_id')}`,JSON.stringify({
    //   firstName: formData.firstName,
    //   lastName: formData.lastName,
    //   dob: formData.dob,
    //   username: formData.username,
    //   address: formData.address,
    //   parentId: cookie.load('parent_id'),
    //   std: formData.std,
    //   status : false,
    //   grade: formData.grade,
    //   subjectList: formData.subjectList,
    //   age: formData.age,
    // }));
    // return false;

    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_UPDATE_STUDENT}?_id=${cookie.load('parent_id')}`,
      data: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        dob: formData.dob,
        username: formData.username,
        address: formData.address,
        parentId: cookie.load('parent_id'),
        std: formData.std,
        status : true,
        grade: formData.grade,
        subjectList: formData.subjectList,
        age: formData.age,
      },
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getStudents());
          callback(null, response);
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}



export function getProfile() {
  //console.log(`${config.API_URL + config.API_GET_PARENT_PROFILE}?_id=${cookie.load('parent_id')}`,cookie.load('parent'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_PARENT_PROFILE}?_id=${cookie.load('parent_id')}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (response !== undefined && response.data !== undefined) {
          dispatch({ type: FETCH_PARENT_ACCOUNT_PROFILE, payload: response.data });
        }
      })
      .catch(response => {

      });
  }
}


export function updateParentProfile(formData, callback) {
  
  //console.log("FormData ===> ",formData);
  //console.log("First Data ==> ",`${config.API_URL + config.API_UPDATE_PARENT_PROFILE}?_id=${cookie.load('parent_id')}`);
  return function (dispatch) {
    dispatch(validationNull());
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_UPDATE_PARENT_PROFILE}?_id=${cookie.load('parent_id')}`,
      data: formData,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        //console.log(" Response ===> ",response.data);
        if (!response.data.err && response.data.data) {
          createAlert({ message: response.data.msg });
          dispatch(getProfile());
          callback(null, true);
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(response => {
        console.log("Catch Response ===> ",response);
      });
  }
}

export function getTeacherSubjects() {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_TEACHER_SUBJECT_LIST}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {

        dispatch({ type: GET_TEACHER_SUBJECTS, payload: response.data });
      })
      .catch(response => {
        console.log(response);
      });
  }
}
export function getBatches(filter_keyword, callback) {
  console.log("BatchListParentURL ===> ",`${config.API_URL + config.API_GET_BATCH + '?keyword=' + filter_keyword}&_id=${cookie.load('parent_id')}`);
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_BATCH + '?keyword=' + filter_keyword}&_id=${cookie.load('parent_id')}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        dispatch({ type: GET_BATCH_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}


export function updateBatch(formData, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BATCH}`,
      data: {
        batchId: formData.batchId,
        name: formData.name,
        subjectId: formData.subjectId,
        studentList: formData.studentList,
        startTime: formData.startTime,
        endTime: formData.endTime,
        batchDate: formData.batchDate,
        status: formData.status,
        description: formData.description,
      },
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getBatches(undefined, callback));
          callback(null, response);
          createAlert({ message: 'You Batch has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addBatch(formData, callback) {
  return function (dispatch) {

    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BATCH}`,
      data: {
        name: formData.name,
        subjectId: formData.subjectId,
        studentList: formData.studentList,
        startTime: formData.startTime,
        endTime: formData.endTime,
        batchDate: formData.batchDate,
        status: "Pending",
        description: formData.description,
      },
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getBatches(undefined, callback));
          callback(null, response);
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}



export function deleteBatch(batchId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BATCH}`,
      data: {
        batchId: batchId,

      },
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function getAllSubjects() {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_SUBJECT_LIST}`,
      headers: {},
    })
      .then(response => {
        dispatch({ type: GET_All_SUBJECTS, payload: response.data });
      })
      .catch(response => {
        console.log(response);
      });
  }

}
// -----------------Task Releted Work--------------------------------//
export function getTaskList(filter_keyword, callback) {


  //console.log(`${config.API_URL + config.API_GET_PARENT_STUDENT_TASK_LIST + '?_id='+cookie.load('parent_id')+'&status=' + filter_keyword}`,cookie.load('parent'));

  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_PARENT_STUDENT_TASK_LIST + '?_id='+cookie.load('parent_id')+'&status=' + filter_keyword}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        //console.log("Tasks Response", response.data);
        dispatch({ type: GET_STUDENT_TASK_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function updateTask(formData, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      headers: {
        Authorization: cookie.load('parent'),
      },
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_PARENT_STUDENT_TASK}`,
      data: formData
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getTaskList("", callback));
          callback(null, response);
          createAlert({ message: 'Task has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addTask(data, callback) {
  //console.log(data);
  return function (dispatch) {
    // console.log(`${config.API_URL + config.API_INSERT_UPDATE__DETELE_PARENT_STUDENT_TASK}`);
    // console.log(cookie.load('parent'));
    // return false;
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_PARENT_STUDENT_TASK}`,
      data: data,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getTaskList("", callback));
          callback(null, response);
          createAlert({ message: 'Task has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}
export function deleteTask(taskId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_PARENT_STUDENT_TASK}`,
      data: {
        taskId: taskId,

      },
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function getTaskInfo(task_id, callback) {

  return function (dispatch) {
    axios({
      method: 'GET',
      url: `${config.API_URL + config.API_GET_TEACHER_STUDENT_TASK_INFO + '/' + task_id}`,
      headers: {
        Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDE2YTY4MjFjM2I1MzI2NzljOGI1YmIiLCJyb2xlIjoidGVhY2hlciIsIm5hbWUiOiJEaXhpdCBTb2xhbmtpMSIsInBob25lTnVtYmVyIjoiOTkyNDcyNjYzMiIsImVtYWlsIjoiZGl4aXQuZGV2ZWxvcGVyQGdtYWlsLmNvbSIsIl9fdiI6MCwiaWF0IjoxNjE0NDk0MDMwLCJleHAiOjE2MTQ1ODA0MzB9.x0DG8OdRuM69uP2sy3yroBssYlmh63OZ6AuZla-2OrQ',
      },
    })
      .then(response => {
        dispatch({ type: FETCH_TASK_INFO, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function addTaskTimelog(formData, callback) {
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_INSERT_TIME_LOG}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
      data: {
        description: formData.description,
        dateTime: formData.dateTime,
        taskId: formData.taskId
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          createAlert({ message: 'Success: Task Time Log added successfully!' });
          dispatch(getTaskInfo(formData.taskId, callback));

          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
          callback(true, null);
        }
      })
      .catch(response => {
        console.log("task history add error>>>>>>>>", response);

        callback(true, null);
      });
  }
}

export function addTaskComment(formData, task_id, callback) {
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_INSERT_PARENT_TASK_COMMENT}`,
      data: formData,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          createAlert({ message: 'Success: Task comment added successfully!' });
          dispatch(getTaskInfo(task_id, callback));
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
          callback(true, null);
        }
      })
      .catch(response => {
        console.log("Comment add error>>>>>>>>", response);

        callback(true, null);
      });
  }
}

export function addTaskDocuments(data, task_id, callback) {
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_INSERT_PARENT_TASK_DOCUMENTS}`,
      data: data,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          createAlert({ message: 'Success: Task Document added successfully!' });
          dispatch(getTaskInfo(task_id, callback));
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
          callback(true, null);
        }
      })
      .catch(response => {
        console.log("Comment add error>>>>>>>>", response);

        callback(true, null);
      });
  }
}

// -----------------end -Task Releted Work--------------------------------//

export function getTeacherCoulagelist(callback) {
  //console.log("Teacher Coulegue List ===> ",`${config.API_URL + config.API_GET_COULAGE_LIST}`,JSON.stringify({_id : cookie.load('parent_id')}));
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_COULAGE_LIST}`,
      data: {_id : cookie.load('parent_id')},
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        //console.log("Response Teacher List ",response.data);
        dispatch({ type: GET_COULAGE_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
      });
  }
}
export function deleteAttachments(data, callback) {
  return function (dispatch) {

    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_TASK_PARENT_DOCUMENT_DETELE}`,
      data: {
        taskId: data.taskId,
        attachmentLink: data.link
      },
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getGoalInfo(goal_id, callback) {
  //console.log("-----goal_id", goal_id);
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_INFO + '/' + goal_id}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        //console.log("-----response", response);
        dispatch({ type: FETCH_GOAL_INFO, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function getGoalList(student_id, callback) {

  return function (dispatch) {

    axios({
      method: 'post',
      url: `${config.API_URL + config.API_GET_PARENT_GOAL_LIST}`,

      headers: {
        Authorization: cookie.load('parent'),
      },
      data: {
        "studentId": student_id
      }
    })
      .then(response => {

        dispatch({ type: GET_GOAL_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function getStudentInfo(student_id, callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_PARENT_GET_STUDENT_INFO + '/' + student_id}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })


      .then(response => {
        //console.log("Response Data ===> ",response.data.data[0]);
        dispatch({ type: FETCH_STUDENT_INFO, payload: response.data.data[0] });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getAssessmentInfo(goal_id, callback) {
  console.log("-----goal_id", goal_id);
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_INFO + '/' + goal_id}`,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        console.log("-----response", response);
        dispatch({ type: FETCH_GOAL_INFO, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
// Student Module End
export function validation(error) {
  return {
    type: VALIDATION_ERROR,
    payload: error
  }
}
export function validationNull() {
  return {
    type: VALIDATION_ERROR,
    payload: null
  }
}

