import {
  ContentDetailsNull,
  ContentListNull,
  ReinforcementListNull,
  addBatch,
  getCategoryList,
  getContentDetails,
  getContentListbyCategory,
  getReinforcementList,
  getStudents,
  getTeacherSubjects,
  updateBatch,
  validationNull
} from '../teacher_action';
import { Editor, Loading, input } from '../../library/elements';
import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';

import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

var dateFormat = require("dateformat");
class BatchFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      multi: true,
      multiValue: [],
      mloptions: [],
      mlvalue: undefined,
//  for subject
      smulti: true,
      smultiValue: [],
      smloptions: [],
      smlvalue: undefined,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnChangeSubject = this.handleOnChangeSubject.bind(this);
  }

  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });

    this.props.validationNull();
    this.props.ContentListNull();
    this.props.ContentDetailsNull();
    this.props.getTeacherSubjects();
    this.props.ReinforcementListNull();
    this.props.getCategoryList(function (err, result) { });
    this.props.getReinforcementList(function (err, result) { });
    this.props.getStudents(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
    if (this.props.batchData) {
      //console.log("Batch Data",this.props.batchData);
      this.props.getContentDetails(this.props.batchData.contentId, function (err, result) { });
      this.props.getContentListbyCategory(this.props.batchData.categoryId, function (err, result) { });
      this.props.getReinforcementList(this.props.batchData.reInforcementId, function (err, result) { });
      
      //Reinforcement to be define
    }
    if(this.props.batchData != undefined && this.props.batchData.numbers) {
      var contentWords = this.props.batchData.numbers;
      var options = [];
      this.setState({ multiValue: [] });
      this.setState({ mloptions: [] });
      if(contentWords != undefined && contentWords.length > 0) {
        var cwords = [];
        var split_content_tags = contentWords.split(",");
        for(var i in split_content_tags) {
          cwords.push({ value: split_content_tags[i], label:split_content_tags[i], className: "Select-create-option-placeholder" });
          options.push({ value: contentWords[i], label: contentWords[i] });
        }
        _this.setState({ multiValue: cwords });
      }
      _this.setState({ mloptions: options })
    }
  }


  handleFormSubmit(formProps) {
    //console.log("Form Props2 ===>",formProps.numbers);
    //console.log("Form Props ===>",formProps);
    //return false;
    var _this = this;
    this.setState({ isLoading: true });
    this.props.validationNull();

    const data = new FormData();
    // for filter numnbers
    // const temp_numnbers = this.state.multiValue;
    // var new_numnbers = [];
    // if (temp_numnbers.length > 0) {
    //   new_numnbers = _.map(temp_numnbers, 'value');
    // }
   // for filter numnbers
   const temp_sub = this.state.smultiValue;
   var new_sub = [];
   if (temp_sub.length > 0) {
    new_sub = _.map(temp_sub, 'value');
   }

    data.append('name', formProps.name);
    data.append('subjectId', new_sub);
    data.append('startTime', '10:30');
    data.append('endTime', '12:30');
    data.append('createdBy',cookie.load("teacher_id"));
    data.append('studentList', formProps?.child?.length > 0 ? formProps.child.split(',') : []);
    data.append('batchDate', dateFormat(formProps.batchDate ? formProps.batchDate : new Date(), "mm/dd/yyyy"));
    data.append('description', formProps.description);
    data.append('categoryId', formProps.categoryId);
    data.append('reInforcementId', formProps.reInforcementId);
    data.append('status', formProps.status);
    // data.append('numbers', new_numnbers);
    data.append('numbers', formProps.numbers);
    data.append('dateAttachmentIntroduce', dateFormat(formProps.dateAttachmentIntroduce ? formProps.dateAttachmentIntroduce : new Date(), "mm/dd/yyyy"));;
    data.append('dateAttachmentMastered', dateFormat(formProps.dateAttachmentMastered ? formProps.dateAttachmentMastered : new Date(), "mm/dd/yyyy"));;
    data.append('contentId', formProps.contentId);
    data.append('collectionStatus', formProps.collectionStatus);
    
    if (this.props.batchData) {
      data.append('batchId', this.props.batchData._id);
      this.props.updateBatch(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addBatch(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }


  handleContentByCategory(categoryID) {
    this.props.ContentDetailsNull();
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getContentListbyCategory(categoryID, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });

      }
    });
  }
  handleReinforcementList(categoryID) {
    this.props.ReinforcementListNull();
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getReinforcementList(categoryID, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });

      }
    });
  }
  handleAttchmentByContent(contentID) {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getContentDetails(contentID, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  /* close product option */
  handleOnChange(value) {
    const { multi } = this.state;
    if (multi) {
      this.setState({ multiValue: value });
    } else {
      this.setState({ value });
    }
  }
  handleOnChangeSubject(value) {
    const { smulti } = this.state;
    if (smulti) {
      this.setState({ smultiValue: value });
    } else {
      this.setState({ value });
    }
  }
  closeModal() {
    this.props.removeModal();
  }


  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }

  renderimages(Contentimages) {
    if (Contentimages.length) {
      var images_data = Contentimages.map((img, index) => {
        return (
          <div className="col-lg-4 col-sm-6 col-6 mt-2 mb-2 text-center" key={'task-image-' + index}>
            <Link onClick={() => window.open(img, "_blank")}>

              <div className="card alc-card alc-card-fancy alc-hover-fancy mb-4">
                <div className="alc-card-fancy-image">
                  <img src={img.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '100%', height: '12rem' }} />
                </div>
                <div className="card-footer alc-card-fancy-content text-center">
                  <h4 className="card-title alc-card-title"><i className="fa fa-eye"></i></h4>
                </div>
              </div>
            </Link>
            {img.audio_url ? (
                <audio controls controlsList="nodownload">
                  <source src={img.audio_url} type="audio/ogg" />
                  <source src={img.audio_url} type="audio/mpeg" />
                </audio>
              ) : null}
          </div>

        );

      });

      return (
        images_data
      );
    }
  }


  render() {
    const { handleSubmit, content_list, content_details, student_list, subject_list, errorMessage, initialValues, reinforcement_list } = this.props;
      //console.log("Initial Values Subjects ===>",initialValues.subjectName);
    var { isLoading, mloptions, multi, multiValue, mlvalue , smloptions, smulti, smultiValue, smlvalue } = this.state;
    
    if (errorMessage) {
      isLoading = false;
    }

    if (student_list == undefined || subject_list == undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    var subjectListData = [];
    for (var i in subject_list) {
      subjectListData.push({ label: subject_list[i].name, value: subject_list[i]._id });
    }
    {/* student  dropdown*/ }
    var studentListData = [];
    for (var i in student_list) {
      studentListData.push({ label: student_list[i].firstName, value: student_list[i].userId });
    }
    {/* category dropdown*/ }
    if (this.props.category_list != undefined && this.props.category_list.length) {
      var CategoryData = [];
      for (var i in this.props.category_list) {
        CategoryData.push({ label: this.props.category_list[i].category, value: this.props.category_list[i]._id });
      }
    }
    {/* content   dropdown*/ }
    var contentData = [];
    for (var i in content_list) {
      contentData.push({ label: content_list[i].title, value: content_list[i]._id });
    }

    var reinforcementData = [];
    for (var i in reinforcement_list) {
      reinforcementData.push({ label: reinforcement_list[i].name, value: reinforcement_list[i]._id });
    }

    {/* batch status   dropdown*/ }
    var batchStatusData = [];
    batchStatusData.push(
      { label: 'Pending', value: 'Pending' },
      { label: 'Ongoing', value: 'Ongoing' },
      { label: 'Completed', value: 'Completed' },
    );
    return (
      <div className="sk-form">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-12">
              <Field name="name" type="text" component={input} label="Assessment Title" className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4">
              <label className="control-label required">Select Category</label>
              <Field name="categoryId" component={categoryId => {
                return (
                  <>
                    <Select simpleValue
                      value={categoryId.input.value}
                      placeholder="Select Category"
                      options={CategoryData}
                      onChange={(e) => {
                        categoryId.input.onChange(e);
                        this.handleContentByCategory(e);
                      }} />
                    <span>

                      {categoryId.meta.touched && ((categoryId.meta.error && <div className="error text-danger">{categoryId.meta.error}</div>) || (categoryId.meta.warning && <div className="error text-danger">{categoryId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
            <div className="col-sm-4">
              <label className="control-label required">Select Content</label>
              <Field name="contentId" component={contentId => {
                return (
                  <>
                    <Select simpleValue
                      value={contentId.input.value}
                      placeholder="Select Content"
                      options={contentData}
                      onChange={(value) => {
                        contentId.input.onChange(value);
                        this.handleAttchmentByContent(value);
                      }} />
                    <span>

                      {contentId.meta.touched && ((contentId.meta.error && <div className="error text-danger">{contentId.meta.error}</div>) || (contentId.meta.warning && <div className="error text-danger">{contentId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
            {
                !this.props.batchData && <div className="col-sm-4">
                <label className="control-label required">Select Reinforcement</label>
                <Field name="reInforcementId" component={reInforcementId => {
                  return (
                    <>
                      <Select simpleValue
                        value={reInforcementId.input.value}
                        placeholder="Select Reinforcement"
                        options={reinforcementData}
                        onChange={(e) => {
                          reInforcementId.input.onChange(e);
                          //this.handleReinforcementList(e);
                        }} />
                      <span>
  
                        {reInforcementId.meta.touched && ((reInforcementId.meta.error && <div className="error text-danger">{reInforcementId.meta.error}</div>) || (reInforcementId.meta.warning && <div className="error text-danger">{reInforcementId.meta.warning}</div>))}
                      </span>
                    </>
                  )
  
                }
  
                } className="form-control" />
              </div>
              }
            
          </div>
          <div className="form-group row">
            <div className="col-sm-4">
              <label className="control-label required">Enter Array</label>
              <Field name="numbers" type="number" component={input} label="Enter Array Value" className="form-control" />
              <br />
              {/* <Field name="numbers" component={numbers => {
                return (
                  <div>
                    <Select.Creatable
                      allowCreate={true}
                      multi={multi}
                      options={mloptions}
                      onChange={this.handleOnChange}
                      value={multi ? multiValue : mlvalue}
                    />
                    <small>Press Enter or Tab button to set the value</small>
                    <Field name="numbers" type="text" component={input} label="Enter Array Value" className="form-control" />
                  </div>
                )
              }} className="form-control" /> */}
            </div>


          
            <div className="col-sm-4">
              <label className="control-label">Assessment Status</label>
              <Field name="status" component={status => {
                return (
                  <>
                    <Select simpleValue
                      value={status.input.value}
                      placeholder="Select Status"
                      options={batchStatusData}
                      onChange={(e) => {
                        status.input.onChange(e);

                      }} />
                    <span>
                      {status.meta.touched && ((status.meta.error && <div className="error text-danger">{status.meta.error}</div>) || (status.meta.warning && <div className="error text-danger">{status.meta.warning}</div>))}
                    </span>
                  </>
                )

              }} className="form-control" />

            </div>


            {/* {initialValues._id !== undefined ? ''
               : 
            <div className="col-sm-4">
            <label className="control-label required">Select Subject</label>
                    <Field name="subjectId" component={ subjectId => {
                      return (
                        <div>
                          <Select.Creatable
                            allowCreate={true}
                            multi={smulti}
                            options={smloptions}
                            onChange={this.handleOnChangeSubject}
                            value={smulti ? smultiValue : smlvalue}
                          />
                          <small>Press Enter or Tab button to set the value</small>
                        </div>
                      )
                    }} className="form-control"/>
                 </div>
        
            }  */}

            {initialValues._id !== undefined ? <div className="col-sm-6">
              <label className="control-label">Selected Subject</label>
              <div>{initialValues.subjectName}</div>
              </div>
               : 
            <div className="col-sm-6">
            <label className="control-label required">Select Subject</label>
                    <Field name="subjectId" component={ subjectId => {
                      return (
                        <div>
                          <Select.Creatable
                            allowCreate={true}
                            multi={smulti}
                            options={smloptions}
                            onChange={this.handleOnChangeSubject}
                            value={smulti ? smultiValue : smlvalue}
                          />
                          <small>Press Enter or Tab button to set the value</small>
                        </div>
                      )
                    }} className="form-control"/>
                 </div>
            }

            {
            content_details?.image?.length ?
              <div className="card">
                <div className="card-header d-flex vertical-center justify-content-between">
                  <h5 className="mt-4"> View Targets</h5>
                  <div className="pull-right">
                    <div className="form-group row mt-4">
                      <div className="col-sm-6">
                        {/* <label className="control-label"> Date Introduced</label>
                        <Field name={`dateAttachmentIntroduce`} component={dateAttachmentIntroduce => {
                          return (
                            <div className="dt-picker">
                              <DatePicker
                                time={true}
                                name="dateAttachmentIntroduce"
                                format={"MM/dd/y"}
                                value={!dateAttachmentIntroduce.input.value ? new Date() : (dateAttachmentIntroduce.input.value != '0000-00-00' ? new Date(dateAttachmentIntroduce.input.value) : null)}
                                onChange={(date) => {
                                  dateAttachmentIntroduce.input.onChange(date);
                                }}
                              />
                              <span>

                                {dateAttachmentIntroduce.meta.touched && ((dateAttachmentIntroduce.meta.error && <div className="error text-danger">{dateAttachmentIntroduce.meta.error}</div>) || (dateAttachmentIntroduce.meta.warning && <div className="error text-danger">{dateAttachmentIntroduce.meta.warning}</div>))}
                              </span>

                            </div>
                          )
                        }} className="form-control" /> */}
                      </div>

                      <div className="col-sm-6">
                        {/* <label className="control-label"> Date Mastered</label>
                        <Field name={`dateAttachmentMastered`} component={dateAttachmentMastered => {
                          return (
                            <div className="dt-picker">
                              <DatePicker
                                time={true}
                                name="dateAttachmentMastered"
                                format={"MM/dd/y"}
                                value={!dateAttachmentMastered.input.value ? new Date() : (dateAttachmentMastered.input.value != '0000-00-00' ? new Date(dateAttachmentMastered.input.value) : null)}
                                onChange={(date) => {
                                  dateAttachmentMastered.input.onChange(date);
                                }}
                              />
                              <span>

                                {dateAttachmentMastered.meta.touched && ((dateAttachmentMastered.meta.error && <div className="error text-danger">{dateAttachmentMastered.meta.error}</div>) || (dateAttachmentMastered.meta.warning && <div className="error text-danger">{dateAttachmentMastered.meta.warning}</div>))}
                              </span>

                            </div>
                          )
                        }} className="form-control" /> */}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    {this.renderimages(content_details?.image)}
                  </div>
                </div>
              </div>
              :
              null
          }
          </div>
            
          <div className="form-group row">
              { !this.props.batchData && <div className="col-sm-6 ">
              <label className="control-label required">Students</label>
              <Field name="child" component={child => {
                return (
                  <>
                    <Select simpleValue
                      value={child.input.value}
                      multi={true}
                      placeholder="Select Students"
                      options={studentListData}
                      onChange={(e) => {
                        child.input.onChange(e);

                      }} />
                    <span>
                      {child.meta.touched && ((child.meta.error && <div className="error text-danger">{child.meta.error}</div>) || (child.meta.warning && <div className="error text-danger">{child.meta.warning}</div>))}
                    </span>
                  </>
                )

              }} className="form-control" />
            </div>
            }

            <div className="col-sm-6">
              <label className="control-label required"> Date Assigned</label>
              <Field name={`batchDate`} component={batchDate => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      time={true}
                      name="batchDate"
                      format={"MM/dd/y"}
                      value={!batchDate.input.value ? new Date() : (batchDate.input.value != '0000-00-00' ? new Date(batchDate.input.value) : null)}
                      onChange={(date) => {
                        batchDate.input.onChange(date);
                      }}
                    />
                    <span>

                      {batchDate.meta.touched && ((batchDate.meta.error && <div className="error text-danger">{batchDate.meta.error}</div>) || (batchDate.meta.warning && <div className="error text-danger">{batchDate.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
            </div>
          </div>

          <div className="form-group  row">
            <div className="col-sm-12">
              <label> Description</label>
              <Field name="description" type="text" component={Editor} className="form-control" />
            </div>
          </div>
          
          {/* <div className="form-group row">
            <div className="col-sm-6">
              <label className="form-lable required">Data Collection</label>
              <Field

                name="collectionStatus"
                component={collectionStatus =>
                  <div>
                    <select
                      name="collectionStatus"
                      className="form-control"
                      value={collectionStatus.input.value}
                      onChange={(event) => {
                        collectionStatus.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="manual">Manual</option>
                      <option value="automatic">Automatic</option>
                    </select>
                    <span>
                      {collectionStatus.meta.touched && ((collectionStatus.meta.error && <div className="error text-danger">{collectionStatus.meta.error}</div>) || (collectionStatus.meta.warning && <div className="error text-danger">{collectionStatus.meta.warning}</div>))}
                    </span>
                  </div>

                } />
            </div>
          </div> */}
          <div className="row">

            <div className="col-sm-9">
              {
                this.props.batchData
                  ?
                  <button action="form_batch" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Assessment'}
                  </button>
                  :
                  <button action="form_batch" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Assessment'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.name) {
    errors.name = 'Required Assessment Name';
  }

  if (formProps.categoryId === undefined) {
    errors.categoryId = 'Select Category';
  } else if (formProps.categoryId === '') {
    errors.categoryId = 'Select Category';
  }

  if (formProps.contentId === undefined) {
    errors.contentId = 'Select Content';
  } else if (formProps.contentId === '') {
    errors.contentId = 'Select Content';
  }
  
  // if (formProps.collectionStatus === undefined) {
  //   errors.collectionStatus = 'Required';
  // } else if (formProps.collectionStatus === '') {
  //   errors.collectionStatus = 'Required';
  // }

  if (formProps.status === undefined) {
    errors.status = 'Select Status';
  } else if (formProps.status === '') {
    errors.status = 'Select Status';
  }

  if (formProps.child === undefined) {
    errors.child = 'Select Child ';
  } else if (formProps.child === '') {
    errors.child = 'Select Child';
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  //console.log("own props",ownProps.batchData);
  var iniBatch = {};
  if (ownProps.batchData) {
    var iniBatch = ownProps.batchData;
    var studentData = [];
    for (var i in iniBatch.studentList) {
      studentData.push(iniBatch.studentList[i].userId);
    }
    var student_merge = studentData.join(',');
    iniBatch.child = student_merge;
    //console.log("Init",iniBatch.child);
  }
  return {
    errorMessage: state.account.error,
    initialValues: iniBatch,
    student_list: state.teacher.student_list,
    category_list: state.teacher.category_list,
    content_list: state.teacher.content_list,
    subject_list: state.teacher.teacher_subject_list,
    content_details: state.teacher.content_details,
    reinforcement_list: state.teacher.reinforcement_list,
  }
}

BatchFormModal = reduxForm({
  form: 'form_batch',
  validate: validate
})(BatchFormModal);

export default connect(mapStateToProps, { addBatch, updateBatch, getStudents, getTeacherSubjects,getReinforcementList, getCategoryList, validationNull, ContentDetailsNull, ContentListNull,ReinforcementListNull, getContentListbyCategory, getContentDetails })(BatchFormModal);