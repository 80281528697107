import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { Frontload } from 'react-frontload';
import cookie from 'react-cookie';

import createStore from './store';

import CommonApp from '../src/App';

import { AUTHENTICATE_USER,AUTHENTICATE_PARENT,AUTHENTICATE_STUDENT} from '../src/account/account_action';

const { store, history } = createStore();

const user = cookie.load('teacher');
const parent = cookie.load('parent');
const student = cookie.load('student');
if(user) {
  store.dispatch( { type: AUTHENTICATE_USER });
}
if(parent) {
  store.dispatch( { type: AUTHENTICATE_PARENT });
}
if(student) {
  store.dispatch( { type: AUTHENTICATE_STUDENT });
}
history.listen(_ => {
    window.scrollTo(0, 0)
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Frontload noServerRender={true} >
        <CommonApp />
      </Frontload>
    </ConnectedRouter>
  </Provider>
  , document.querySelector('#root'));
