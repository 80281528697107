import React, { Component } from 'react';
import { deleteTaskStudent, getTeacherTaskStudentList } from '../teacher_action';

import { Link } from 'react-router-dom';
import { Loading } from '../../library/elements';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

class BatchStudentFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.applySortBy = this.applySortBy.bind(this);
  }


  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getTeacherTaskStudentList(this.props.task_id ? this.props.task_id : 0, undefined, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  closeModal() {
    this.props.removeModal();
  }


  deleteTaskStudent(task_id, student_id) {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.deleteTaskStudent(task_id, student_id, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  renderStudents(students) {
    if (students != undefined && students != null && students != "") {
      return students.map((student) => {

        return (
          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12" key={"checkout.student." + student.studentDetails.userId}>


            <div className="card card-address mb-4">
                <div className="col-sm-12 col-12">
                  <div className="colored-side" style={{ "backgroundColor": "rgb(18 105 248)" }}></div>
                  <div className=" row  card-address-header overflow-hidden">
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 col-9 text-left name">
                      <p className="m-0">
                        <span className="btn-address-customer text-secondary">{student.studentDetails.firstName + ' ' + student.studentDetails.lastName}21</span>
                        <label className="badge bg-warning ml-2">{student.taskStatus}</label>
                      </p>

                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3 price  text-right diate pull-right pr-2">
                      <div className="btn-group">
                        <Link className="btn btn-outline-danger btn-sm pull-left border-0" onClick={() => this.deleteTaskStudent(this.props.task_id, student.studentDetails.userId)} ><i className="fa fa-trash"></i></Link>
                        <Link className="btn btn-outline-primary btn-sm pull-left border-0" onClick={() => this.closeModal()} to={'/teacher/task_info/' + this.props.task_id + '/' + student.studentDetails.userId}><i className="fa fa-arrow-right"></i></Link>

                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row  card-address-header overflow-hidden">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-left card-address-text">

                      <p className="m-0">
                        {
                          student.studentDetails.grade
                            ?
                            <span className="badge bg-primary rounded-pill">Grade: {student.studentDetails.grade}</span>
                            :
                            null
                        }



                        <br />

                      </p>


                    </div>

                  </div>
                </div>
              </div>
            </div>
 

        );
      });
    } else {
      return (
        <div className="col-sm-12 text-center p-1">
          Currently No Students add in this Task
        </div>
      );
    }
  }

  
  applySortBy(e) {
    this.setState({ isLoading: true });
    var _this = this;
    this.props.getTeacherTaskStudentList(this.props.task_id ? this.props.task_id : 0, e.target.value, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  render() {
    const { students } = this.props;
    var { isLoading } = this.state;

    if (students == undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    return (
      <div className="border rounded card-body  sk-form">
        {isLoading ? <Loading /> : null}
        <div className="row">
         
            <div className="row mb-3 p-0">
            <div className="col-md-4 col-sm-12">
            </div>
            <div className="col-md-4 col-xs-12"></div>
            <div className="col-md-4 col-xs-12 pr-0">
              <div className="text-right">
                <div className="ml-1 select-style pull-right">
                  <select onChange={(e) => this.applySortBy(e)} className="form-select">
                    <option value="">-- Choose --</option>
                    <option value="Pending">Pending</option>
                    <option value="Complete">Complete</option>
                    <option value="Running">Running</option>
                    <option value="Stop">Stop</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          
        
          {this.renderStudents(students)}

        </div>
      </div>
    );
  }
}



function mapStateToProps(state, ownProps) {
  return {
    task_id: ownProps.batchstudentsData._id,
    students: state.teacher.teacher_task_student_list,
  }
}

BatchStudentFormModal = reduxForm({
  form: 'form_batch',
})(BatchStudentFormModal);


export default connect(mapStateToProps, { getTeacherTaskStudentList, deleteTaskStudent })(BatchStudentFormModal);
