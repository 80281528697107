
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form';
import AccountReducer from '../account/account_reducer';
import TeacherReducer from '../teacher/teacher_reducer';
import ParentReducer from '../parent/parent_reducer';
import BlogReducer from '../blog/blog_reducer';
import StudentReducer from '../student/student_reducer';
import { modalReducer } from '../library/modal';
import libraryReducer from '../library/system/reducer';
const rootReducer  = (history) =>  combineReducers({
    router: connectRouter(history),
    routing: routerReducer,
    form: formReducer,
    modals: modalReducer,
    account: AccountReducer,
    teacher:TeacherReducer,
    parent:ParentReducer,
    student:StudentReducer,
    library: libraryReducer,
    blog:BlogReducer,
});

export default rootReducer;
