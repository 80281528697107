import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ModelDelete from './modal/delete/modal_event_delete';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { getAdminEventsList } from './teacher_action';
import { modal } from '../library/modal';

var dateFormat = require("dateformat");
class AdminEventList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };

    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }
  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getAdminEventsList(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
    if(!cookie.load('is_login')){
      window.location.href = '/signin';
    }
  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  
  addDeleteConfirmationModal(filter) {
    modal.add(ModelDelete, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }
  
  actionFormatter(cell, row, props) {
    return (
      <div>
        {/* <Link onClick={() => this.AddEditModel('Edit Record - ' + row.name, row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link> */}
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'subject', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
      </div>
    );
  }


  EventDateFormat(cell, row) {
    return (
      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.eventDate, "mm/dd/yyyy")}</span>
    );
  }

  CreatedDateFormat(cell, row) {
    return (
      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.createdTime, "mm/dd/yyyy")}</span>
    );
  }

  DurationFormat(cell, row) {
    return (
      <span className="">
        {row.startTime+" - "+row.endTime}</span>
    );
  }

  formatCreatedBy(cell, row){
    return(
      <span>
        {row.userData[0].name}
      </span>
    )
  }

  statusFormat(cell, row) {
    if (row.isActive === true) {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else {
      return (
        <div className="badge bg-warning ">Inactive</div>
      );
    }
  }
  
  render() {
    const { full_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!full_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      true: 'Yes',
      false: 'No',
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {/* {isLoading ? <Loading /> : null} */}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  alca-card table-responsive mb-4 alc-table">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font font-weight-bold">
                      <i className="fa fa-bag mr-1"></i>Events List
                    </h5>
                    
                    {/* <button onClick={() => this.AddEditModel('Add New', '')} className="text-primary mt-2 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button> */}
                    
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                      keyField='_id'
                      data={(full_list != undefined && full_list.length ? full_list : [])}
                      bordered={false}
                      responsive={true}
                      striped={false}
                      pagination={true}
                      fetchInfo={{ dataTotalSize: full_list.length }}
                      options={{
                        sizePerPage: this.state.sizePerPage,
                        page: this.state.currentPage,
                        hideSizePerPage: true,
                        paginationShowsTotal: this.renderPaginationShowsTotal,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSortChange: this.onSortChange
                      }}
                    >
                      <TableHeaderColumn
                        dataField='eventName'
                        columnClassName={''}
                        filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataSort={true}>
                        Event Name
                      </TableHeaderColumn>
                      
                      <TableHeaderColumn
                        dataField='eventDate'
                        columnClassName={''}
                        //filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataFormat={this.EventDateFormat}
                        dataSort={false}>
                        Event Date
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='startTime'
                        columnClassName={''}
                        //filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataFormat={this.DurationFormat}
                        dataSort={false}>
                        Event Time
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='startTime'
                        columnClassName={''}
                        //filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataFormat={this.formatCreatedBy}
                        dataSort={false}>
                        Created By
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='createdTime'
                        columnClassName={''}
                        width='15%'
                        dataAlign="left"
                        //filter={{ type: 'TextFilter' }}
                        dataFormat={this.CreatedDateFormat}
                        dataSort={true}>
                        Created Date
                      </TableHeaderColumn>

                      <TableHeaderColumn width='150px' dataField='isReminder' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort >Status</TableHeaderColumn>
                      
                      <TableHeaderColumn width='10%' dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                      
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );

  }
}

function mapStateToProps(state) {
  console.log("==========>",state.teacher.admin_events_list);
  return {
    full_list: state.teacher.admin_events_list,

  }
}
export default connect(mapStateToProps, { getAdminEventsList })(AdminEventList);
