import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

class DeshHeader extends Component {

  constructor(props) {
    super(props);

  }
  render() {
    const { } = this.props;

    const currentLink = window.location.pathname;

    return (
     <div className="top-bar d-none d-xl-block" >
        <div className="sidebar">
          <div className="wrapper ">
            <div className="sidebar" data-color="purple" data-background-color="white" >
              <div className="logo">
              </div>
              <div className="sidebar-wrapper dashboard">
                <ul className="nav">
                  <li className={"nav-item " + (currentLink == "/dashboard" ? "active" : null)}>
                    <Link to="/dashboard" className={"nav-link"} >
                      <i className="fa fa-desktop"></i>
                      Dashboard
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/student_mgt" ? "active" : null)}>
                    <Link to="/student_mgt" className={"nav-link"} >
                      <i className="fa fa-graduation-cap"></i>
                      Students
                    </Link>
                  </li>

                  <li className={"nav-item " + (currentLink == "/student_progress" ? "active" : null)}>
                    <Link to="/student_progress" className={"nav-link"} >
                      <i className="fa fa-file-text"></i>
                      Student Progress
                    </Link>
                  </li>

                  

                  {/* <li className={"nav-item " + (currentLink == "/blog_mgt" ? "active" : null)}>
                  <Link to="/blog_mgt" className={"nav-link"} >
                    <i className="fa fa-user"></i>
                    <p>Resource List</p>
                  </Link>
                </li> */}

                  <li className={"nav-item " + (currentLink == "/batch_view" ? "active" : null)}>
                    <Link to="/batch_view" className={"nav-link"} >
                      <i className="fa fa-calendar-o"></i>
                      Calender
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/event_mgt" ? "active" : null)}>
                    <Link to="/event_mgt" className={"nav-link"} >
                      <i className="fa fa-calendar"></i>
                      Event
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/batch_mgt" ? "active" : null)}>
                    <Link to="/batch_mgt" className={"nav-link"} >
                      <i className="fa fa-briefcase"></i>
                      Assessment
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/reinforcement" ? "active" : null)}>
                    <Link to="/reinforcement" className={"nav-link"} >
                      <i className="fa fa-certificate"></i>
                      Reinforcement
                    </Link>
                  </li>
                  {/* <li className={"nav-item " + (currentLink == "/task_mgt" ? "active" : null)}>
                  <Link to="/task_mgt" className={"nav-link"} >
                    <i className="fa fa-user"></i>
                    <p>Task Management</p>
                  </Link>
                </li> */}
                  {/* <li className={"nav-item " + (currentLink == "/categories" ? "active" : null)}>
                  <Link to="/categories" className={"nav-link"} >
                    <i className="fa fa-user"></i>
                    <p>Category</p>
                  </Link>
                </li> */}
                  <li className={"nav-item " + (currentLink == "/content_mgt" ? "active" : null)}>
                    <Link to="/content_mgt" className={"nav-link"} >
                      <i className="fa fa-file-text"></i>
                      Content
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/coulege_list" ? "active" : null)}>
                    <Link to="/coulege_list" className={"nav-link"} >
                      <i className="fa fa-comments-o"></i>
                      Communication
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/blog_mgt" ? "active" : null)}>
                    <Link to="/blog_mgt" className={"nav-link"} >
                      <i className="fa fa-user"></i>
                      Resource Library 
                    </Link>
                  </li>

                  {/* https://fontawesome.com/v4/icons/
                  Upcoming Events
                  Recent Package Expires */}
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/admingoals_mgt" ? "active" : null)}>
                      <Link to="/admingoals_mgt" className={"nav-link"} >
                        <i className="fa fa-futbol-o"></i>
                        Goals
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/adminbatch_mgt" ? "active" : null)}>
                      <Link to="/adminbatch_mgt" className={"nav-link"} >
                        <i className="fa fa-history"></i>
                        Batch
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/teacherstudentprogress_mgt" ? "active" : null)}>
                      <Link to="/teacherstudentprogress_mgt" className={"nav-link"} >
                        <i className="fa fa-level-up"></i>
                        Teacher Students
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/subject_mgt" ? "active" : null)}>
                      <Link to="/subject_mgt" className={"nav-link"} >
                        <i className="fa fa-pencil"></i>
                        Subjects
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/subscriber_mgt" ? "active" : null)}>
                      <Link to="/subscriber_mgt" className={"nav-link"} >
                        <i className="fa fa-inbox"></i>
                        Subscribers
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/admincontact_mgt" ? "active" : null)}>
                      <Link to="/admincontact_mgt" className={"nav-link"} >
                        <i className="fa fa-user"></i>
                        Contact Us
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/admincategories_mgt" ? "active" : null)}>
                      <Link to="/admincategories_mgt" className={"nav-link"} >
                        <i className="fa fa-ship"></i>
                        Categories
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/admincontent_mgt" ? "active" : null)}>
                      <Link to="/admincontent_mgt" className={"nav-link"} >
                        <i className="fa fa-plus"></i>
                        Contents
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/adminevent_mgt" ? "active" : null)}>
                      <Link to="/adminevent_mgt" className={"nav-link"} >
                        <i className="fa fa-television"></i>
                        Events
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/adminreinforcement_mgt" ? "active" : null)}>
                      <Link to="/adminreinforcement_mgt" className={"nav-link"} >
                        <i className="fa fa-trophy"></i>
                        Reinforcements
                      </Link>
                    </li>
                  }

                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/adminpackagepurchased_mgt" ? "active" : null)}>
                      <Link to="/adminpackagepurchased_mgt" className={"nav-link"} >
                        <i className="fa fa-university"></i>
                        Package Purchased
                      </Link>
                    </li>
                  }
                  
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/adminstudent_mgt" ? "active" : null)}>
                      <Link to="/adminstudent_mgt" className={"nav-link"} >
                        <i className="fa fa-male"></i>
                        Students
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/adminparent_mgt" ? "active" : null)}>
                      <Link to="/adminparent_mgt" className={"nav-link"} >
                        <i className="fa fa-users"></i>
                        Parents
                      </Link>
                    </li>
                  }
                  {
                    (cookie.load('login_email') == 'teacher1@yopmail.com' || cookie.load('login_email') == 'learningthreely@gmail.com') && 
                    <li className={"nav-admin nav-item " + (currentLink == "/adminteacher_mgt" ? "active" : null)}>
                      <Link to="/adminteacher_mgt" className={"nav-link"} >
                        <i className="fa fa-book"></i>
                        Teachers
                      </Link>
                    </li>
                  }

                  
                  
                  
                  
                  
                  




                  {/* <li>
                    <Link to="/dashboard" className={"nav-link"} >
                        {cookie.load('teacher_id')}
                      </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {


  }
}
export default connect(mapStateToProps, {})(DeshHeader);


