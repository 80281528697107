import { Field, reduxForm } from 'redux-form'
import React, { Component, PropTypes } from 'react';
import { input, textarea } from '../../library/elements';

import { connect } from 'react-redux';
import { updateParentProfile } from '../parent_action';

class UpdateProfile extends Component {

  constructor(props) {
    super(props);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.removeModal = this.handleProfileSubmit.bind(this);
    this.state = {
      isLoading: false,
      isPasswordText: false,
      isPasswordConfirmText: false
    };
  }

  handleProfileSubmit(formProps) {
    this.state = { isLoading: true };
    var _this = this;
 
    const data = new FormData();
    var image = formProps.image;
    //console.log("image ===> ",image[0]);
    delete formProps.image;
    data.append('name', formProps.name);
    data.append('phoneNumber', formProps.phoneNumber);
    data.append('address', formProps.address);
    data.append('age', formProps.age);
    if (image) {
      data.append('media', image[0]);
    }
    //console.log("Update Data",formProps);
    //console.log("Update Data2",data['media']);
    this.props.updateParentProfile(data, function (err, result) {
      if (result) {
        _this.props.removeModal();
        this.state = { isLoading: false };
      }
    });
  }
  closeModal() {
    this.props.removeModal();
  }

  addDefaultSrc(ev) {
    return ev.target.src = 'profile/User-default-image-boy.png';
  }

  renderPasswordMask(getState) {
    if (this.state[getState] == false) {
      this.setState({ [getState]: true });
    } else {
      this.setState({ [getState]: false });
    }
  }
  
  renderConfirmPasswordMask(getState) {
    if (this.state[getState] == false) {
      this.setState({ [getState]: true });
    } else {
      this.setState({ [getState]: false });
    }
  }

  render() {
    const { handleSubmit, errorMessage } = this.props;
    var { isLoading } = this.state;
    if (errorMessage) {
      isLoading = false;
    }

    return (
      <div className="profile-modal model-card cm-frm">
        <form onSubmit={handleSubmit(this.handleProfileSubmit.bind(this))} className="df-form">
          {
            errorMessage
              ?
              <fieldset className="form-group">
                <div className="col-sm-12">
                  <div className="alert alert-danger mb-0">
                    {errorMessage}
                  </div>
                </div>
              </fieldset>
              :
              null
          }

          <div className="card-block p-1">
            <div className="form-group row">
              <div className="col-sm-12 text-left">
                <Field name="image" component={image => {
                  var showImage = null;
                  if (image.input.value && image.input.value !== '') {
                    showImage = (
                      <div className="pro-image">
                        <img onError={this.addDefaultSrc} src={image.input.value.dataUrl ? image.input.value.dataUrl : image.input.value} className='m-l-10' width='100' height='100' />
                        <div className="fileUpload md-btn btn btn-primary">
                          <span>Upload Profile Image</span>
                          <input type="file"
                            name="image"
                            className="ml-2"
                            onChange={(e) => {
                              var files = e.target.files;
                              let reader = new FileReader();
                              let file = e.target.files[0];
                              reader.onload = function (e) {
                                var picFile = e.target;
                                files.dataUrl = picFile.result;
                                image.input.onChange(files);
                              }
                              reader.readAsDataURL(file);
                            }} />
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div>
                      {
                        showImage !== null
                          ?
                          showImage
                          :
                          <div className="fileUpload md-btn btn btn-primary">
                            <span>Upload Profile Image</span>
                            <input type="file"
                              name="image"
                              className="m-l-20"
                              onChange={(e) => {
                                var files = e.target.files;
                                let reader = new FileReader();
                                let file = e.target.files[0];
                                reader.onload = function (e) {
                                  var picFile = e.target;
                                  files.dataUrl = picFile.result;
                                  image.input.onChange(files);
                                }
                                reader.readAsDataURL(file);
                              }} /> </div>}

                    </div>)
                }
                } />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Field name="name" type="text" component={input} label="Name" className="form-control" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-6 ">
                <Field name="age" type="text" component={input} label="Age" className="form-control" />
              </div>
              <div className="col-sm-6">
                <Field name="phoneNumber" type="text" component={input} label="Contact" className="form-control" />
              </div>
            </div>
            <div className="form-group row">

              <div className="col-sm-12">
                <Field name="address" row="" component={textarea} label="Address"  is_label={true} className="form-control" />
              </div>
            </div>

  
            <div className="form-group row">
              <div className="col-sm-9">
                <button action="updateprofile" type="submit" disabled={isLoading} className="md-btn btn btn btn-primary btn-block mb-1">
                  <i className="fa fa-pencil-circle"></i> {isLoading ? 'Loading...' : 'Update'}
                </button>
              </div>
              <div className="col-sm-3">
                <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
              </div>

            </div>

          </div>
        </form>
      </div>
    );
  }
}

function validate(formProps) {
  const errors = {}
  if (!formProps.name) {
    errors.name = 'Required Name';
  }

  if (!formProps.age) {
    errors.age = 'Required Age';
  } else {
    if (isNaN(formProps.age)) {
      errors.age = 'Age must be numeric only!';
    }
  }

  if (!formProps.phoneNumber) {
    errors.phoneNumber = 'Required Phone Number';
  } else {
    if (isNaN(formProps.phoneNumber)) {
      errors.phoneNumber = 'Phone Number number must be numeric only!';
    } else if (formProps.phoneNumber.length > 10) {
      errors.phoneNumber = 'Phone Number number must be up to 10 digit!';
    }
  }


  return errors;
}

function mapStateToProps(state) {
console.log("Parent Profile Data Edit Mode ===>",state.parent.parent_profile);
  var iniPar = {};
  if (state.parent.parent_profile) {
    iniPar = state.parent.parent_profile._id;
    //iniPar.image = state.parent.parent_profile?._id.photo;
  }

  return {
    initialValues: iniPar,
    errorMessage: state.parent.error,
  }
}

UpdateProfile = reduxForm({
  form: 'updateprofile',
  validate: validate
})(UpdateProfile);

export default connect(mapStateToProps, { updateParentProfile })(UpdateProfile);
