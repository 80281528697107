import { MenuParent, MenuStudent, MenuTeacher } from './menuData';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import cookie from 'react-cookie';
import logo from './images/logo.png';

class HeaderMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      istop: false,
      issMenuOpen: false,
    };
  }
  signOut() {
    this.props.logout();
  }
  topMenuOpen() {
    this.setState({ isMenuOpen: true });
    this.setState({ istop: !this.state.istop });
  }
  topMenuClose() {
    this.setState({ isMenuOpen: false });
    this.setState({ istop: '' });
  }
  mtoggleSearch(status) {
    this.props.setTopSearch(status);
  }

  renderCustomerAccountLinks() {
    //console.log("User Photo ===> ",cookie.load('login_photo'));
    if (cookie.load('teacher')) {
      return [
        <li className="dropdown haccount-dropdown" key="header.customer.li">
          <Link title="My Account" className="drp-profile" data-toggle="dropdown">
            <img src={cookie.load('login_photo')  != undefined? cookie.load('login_photo') : 'static/media/avatar.23a5f093.webp'}className="img-thumbnail" alt="image" />
          </Link>
          <ul className="dropdown-menu">
            <li className="dropdown-item"><Link to="/dashboard">Dashboard</Link></li>
            <li className="dropdown-item"><Link to="/teacher_profile"> Profile</Link></li>
            {/* <li className="dropdown-item"><Link to="/student_mgt"> Students</Link></li>
            <li className="dropdown-item"><Link to="/batch_view"> Batch</Link></li>
            <li className="dropdown-item"><Link to="/event_mgt"> Event</Link></li>
            <li className="dropdown-item"><Link to="/batch_mgt"> Assessment</Link></li>
            <li className="dropdown-item"><Link to="/reinforcement"> ReInforcement</Link></li>
            <li className="dropdown-item"><Link to="/content_mgt"> Content</Link></li> */}
            <li className="dropdown-item"><Link to="/signin" onClick={() => this.signOut()}> Logout</Link></li>
          </ul>
        </li>
      ]
    } else if (cookie.load('parent')) {
      return [
        <li className="dropdown haccount-dropdown" key="header.customer.li">
          <Link title="My Account" className="drp-profile" data-toggle="dropdown">
            <img src={cookie.load('login_photo')  != undefined? cookie.load('login_photo') : 'static/media/avatar.23a5f093.webp'} className="img-thumbnail" alt="image" />
          </Link>
          <ul className="dropdown-menu">
            <li className="dropdown-item"><Link to="/parent_dashboard"> Dashboard</Link></li>
            <li className="dropdown-item"><Link to="/parent_profile">Profile</Link></li>
            {/* <li className="dropdown-item"><Link to="/parent/student_mgt"> Students</Link></li>
            <li className="dropdown-item"><Link to="/parent/batch_view"> WorkSpace</Link></li>
            <li className="dropdown-item"><Link to="/parent/coulege_list">Teachers</Link></li> */}
            <li className="dropdown-item"><Link to="/signin" onClick={() => this.signOut()}> Logout</Link></li>
          </ul>
        </li>
      ]
    } else if (cookie.load('student')) {
      return [
        <li className="dropdown haccount-dropdown" key="header.customer.li">
          <Link title="My Account" className="drp-profile" data-toggle="dropdown">
            <img src={cookie.load('login_photo')  != undefined? cookie.load('login_photo') : 'static/media/avatar.23a5f093.webp'} className="img-thumbnail" alt="image" />
          </Link>
          <ul className="dropdown-menu">
            <li className="dropdown-item"><Link to="/student_dashboard"> Dashboard</Link></li>
            <li className="dropdown-item"><Link to="/student_profile"> Profile</Link></li>
            {/* <li className="dropdown-item"><Link to="/student/assessment_mgt"> Batch</Link></li>
            <li className="dropdown-item"><Link to="/student/goal_mgt"> Goals</Link></li> */}
            <li className="dropdown-item"><Link to="/signin" onClick={() => this.signOut()}> Logout</Link></li>
          </ul>
        </li>
      ]
    }
    else {
      return (
        <li className="dropdown" key="header.customer.lil">
          <Link title="My Account" data-toggle="dropdown">
            <i className="fa fa-user fa-account  "></i><span className="d-none d-xl-block  "> My Account <span className="caret"></span> </span>
          </Link>

          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/signup">{'Register'}</Link></li>
            <li><Link className="dropdown-item" to="/signin">{'Login'}</Link></li>
          </ul>

        </li>
      )
    }
  }
  render() {
    const { authenticated, authenticated_student, authenticated_parent } = this.props;
    const { isMenuOpen, istop } = this.state;
    var is_active_drp = "";
    if (istop) {
      is_active_drp = "dropdown-is-active";
    }
    var is_move_out = "";
    if (isMenuOpen) {
      is_move_out = "move-out";
    }
    var menuStyle = {};
    var menuOverlystyle = {};
    if (is_active_drp == 'dropdown-is-active') {
      menuStyle = {
        transition: "all 0.5s",
        transform: "translate3d(0px, 0px, 0px)",
      }

      menuOverlystyle = {
        display: "block",
        opacity: "inherit",
        animation: "overlay-fadein .5s"
      }
    }


    return (

      <nav className="header-mobile navbar navbar-expand-lg navbar-light d-xl-none shadow-sm">
        <div className="container-xl pr-0">

          <Link className={'mobile-btn-open-navigation-link ' + is_active_drp} onClick={() => this.topMenuOpen()} >
            <i className="fa fa-bars  fa-2x text-dark"></i>
          </Link>


          <div className="mobile-logo-text">
            <Link className="navbar-brand" to="/"> <img src={logo} className="mobile-logo" /></Link>
          </div>


          <ul className="list-unstyled list-group list-group-horizontal">
            <li className="nav-item px-3 border-0">
              {this.renderCustomerAccountLinks()}
            </li>
          </ul>

          <nav className={'border-0 card card-dropdown ' + is_active_drp}>

            <div className="bg-secondary card-header py-2">
              <h5 className="pull-left text-dark mb-0  ml-2 font-weight-bold">Categories</h5>

              <Link className=" pull-right px-2" onClick={() => this.topMenuClose()}><i className="fa fa-times text-white"></i></Link>
              {/*  teacher menu */}

              <ul className={'list-unstyled card-dropdown-content ' + is_move_out}>

                {
                  (authenticated || authenticated_student || authenticated_parent)
                    ?
                    <>
                      {

                        authenticated && MenuTeacher.length && MenuTeacher.map((manuItem, index) => {
                          return (
                            <li key={'menu_' + index}><Link to={manuItem.link} onClick={() => this.topMenuClose()}>{manuItem.name}</Link></li>
                          )
                        })
                      }
                      {/* student */}
                      {

                        authenticated_student && MenuStudent.length && MenuStudent.map((manuItem, index) => {
                          return (
                            <li key={'menu_' + index}><Link to={manuItem.link} onClick={() => this.topMenuClose()}>{manuItem.name}</Link></li>
                          )
                        })
                      }
                      {/* parent */}
                      {

                        authenticated_parent && MenuParent.length && MenuParent.map((manuItem, index) => {
                          return (
                            <li key={'menu_' + index}><Link to={manuItem.link} onClick={() => this.topMenuClose()}>{manuItem.name}</Link></li>
                          )
                        })
                      }
                    </>
                    :
                    <>
                      <li key={'menu_1'}><Link to={"/about"} onClick={() => this.topMenuClose()}>About</Link></li>
                      <li key={'menu_2'}><Link to={"/blogs"} onClick={() => this.topMenuClose()}>Blogs</Link></li>
                      <li key={'menu_3'}><Link to={"/pricing"} onClick={() => this.topMenuClose()}>Pricing</Link></li>
                      <li key={'menu_3'}><Link to={"/contact"} onClick={() => this.topMenuClose()}>Contact</Link></li>
                    </>
                }

              </ul>
            </div>


          </nav>
        </div>
        <div className="overlay" style={menuOverlystyle} onClick={() => this.topMenuClose()}></div>
      </nav>


    );
  }
}


export default HeaderMobile;
