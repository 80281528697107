import * as config from '../../src/system/config';

import { Breadcrumbs, Loading } from '../library/elements';
import { getAllTeacherBlogList, getBlogInfo } from '../blog/blog_action';
import { htmlDecode, toHtml } from '../library/function';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import logo from '../../src/images/logo.png';

var dateFormat = require("dateformat");

class BlogInfo extends React.Component {

    constructor() {
        super();
        this.state = { isLoading: false };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        var _this = this;
        this.props.getAllTeacherBlogList(undefined, function (err, result) {
            if (result) {
                _this.setState({ isLoading: false });
            }
        });
        this.props.getBlogInfo(this.props.match.params.blog_id, function (err, result) {
            if (result) {
                _this.setState({ isLoading: false });
            }
        });
    }

    handleDocumentClick = (url) => {
        window.open(url);
    };


    componentWillReceiveProps(nextProps) {
        if ((nextProps.match.params != undefined && this.props.match.params != undefined && nextProps.match.params.blog_id !== this.props.match.params.blog_id)) {
          this.setState({ isLoading: true });
          var _this = this;
          this.props.getBlogInfo(nextProps.match.params.blog_id, function(err, result) {
            if(result) {
              _this.setState({ isLoading: false });
            }
          });
        }
      }
    renderRecentArticles(articles) {
        return articles.map((article) => {
            var article_image = "http://trystack.mediumra.re/img/knowledge-1.jpg";
            if(article.media){
              article_image =article.media;
            }
            return (
                <div className="card mb-3 card-popular-post border-0 p-2">
                    <div className="row">
                        <div className="col-sm-4 col-4 vertical-center ">
                        <Link to={"/blog/" + article._id}>

                            <img className="img-fluid" src={article_image} alt={article.title ? article.title : "article"} className="img-fluid" title={article.title} />
                               
                            </Link>
                        </div>
                        <div className="col-sm-8 col-8">
                            <div className="text-dark text-truncate d-grid">
                                <Link className="text-dark" to={"/blog/" + article._id}>
                                    {article.title}</Link>
                                <small className="feature-post-date">{dateFormat(article.createdAt, "mm/dd/yyyy")}</small>
                            </div>
                    </div>
                   </div>
                </div>

            );
        });
    }

    render() {
        const { article_info, teacher_blogs_list } = this.props;
        const { isLoading } = this.state;

        if (!article_info || !teacher_blogs_list) {
            return (
                <div className="wallet-undefined"><Loading /></div>
            );
        }
        var shareUrl;
        if (article_info._id) {
            shareUrl = config.APP_URL + "/blog/" + article_info._id;
        }
        var meta = {

            title: htmlDecode(article_info.title ? article_info.title : 'Article'),
            link: [
                { "rel": "apple-touch-icon", "href": logo }
            ],
            meta: [
                { "name": "description", "content": htmlDecode(article_info.description ? article_info.description : article_info.description) },
                { "property": "og:title", "content": htmlDecode(article_info.name ? article_info.name : '') },
                { "property": "og:description", "content": htmlDecode(article_info.description ? article_info.description : '') },
                { "property": "og:url", "content": shareUrl },
                { "property": "og:site_name", "content": config.APP_URL },
                { "property": "og:type", "content": 'website' },
                { "property": "og:image", "content": 'http://trystack.mediumra.re/img/knowledge-1.jpg' },
                { "property": "og:image:type", "content": "image/jpeg" },
                { "property": "og:image:width", "content": 400 },
                { "property": "og:image:height", "content": 400 },
                { "property": "twitter:title", "content": htmlDecode(article_info.title ? article_info.title : '') },
                { "property": "twitter:description", "content": htmlDecode(article_info.description ? article_info.description : '') },
                { "property": "twitter:site", "content": config.APP_URL },
                { "property": "twitter:creator", "content": 'Dixit Solanki' },
                { "property": "twitter:image", "content": 'http://trystack.mediumra.re/img/knowledge-1.jpg' },
                { "property": "twitter:card", "content": 'summary' }
            ],
            script: [
                {
                    "type": "application/ld+json",
                    "innerHTML": `{
                  "@context": "http://schema.org",
                  "@type": "Blog",
                  "url": "${config.APP_URL}",
                  "logo": "${logo}",
                  "description": "${htmlDecode(article_info.description ? article_info.description : article_info.description)}",
                  "name": "${article_info.title ? article_info.title : ''}",
                  "image": "http://trystack.mediumra.re/img/knowledge-1.jpg",
                  "contactPoint": [{
                    "@type": "ContactPoint",
                    "contactType": "customer service"
                  }]
                }`
                }
            ]
        };

        
        var breadcrumbs = [];
        breadcrumbs.push({ text: 'Resource', value: '/blogs' });

        var article_image = "http://trystack.mediumra.re/img/knowledge-1.jpg";
        if(article_info.media){
          article_image =article_info.media;
        }
        return (
            <div id="blogs" className="page  mb-5">
                <Helmet {...meta} />
                {isLoading ? <Loading /> : null}
                <div className="container">
                    <div className="page-heading mt-6 mb-3">
                        <div className="d-flex justify-content-between pt-3 breadcrumb-container">
                            <div className="p-2  mt-2 mb-2 breadcrumb-title font-weight-bold">
                                {article_info.title}
                            </div>
                            <div className="page-heading-breadcrumb">
                                <Breadcrumbs breadcrumbs={breadcrumbs} />
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-9 article-list-container bg-white pt-3">
                            <img src={article_image} alt={article_info.title ? article_info.title : "article"} className="img-fluid" title={article_info.title} />

                            {
                                article_info.document && article_info.document != "" && <button className="btn btn-primary mt-2" onClick={() => {this.handleDocumentClick(article_info.document)}}>View Document</button>
                            }
                            

                            <div className="card-body pl-0">
                                <h2 className="card-title text-primary">{article_info.title}</h2>
                                <div className="article-info-widget">
                                    <span className="widget article-info-author-name text-primary">{article_info.teacherDetails.name} / </span>
                                    <span className="widget-sep"></span>
                                    <span className="widget article-info-publish-date">{dateFormat(article_info.createdAt, "mm/dd/yyyy")}</span>
                                </div>
                                <ul className=" p-0 list-unstyled list-group list-group-horizontal social-links social-share-icons-list p-0">
                                    <li className="list-group-item border-0 pl-0 -2" style={{ "display": "inline-block" }}>
                                        <a target="_blank" className="btn-twitter product-social-share-icon social-icon rounded-circle " href={"https://twitter.com/share?url=" + shareUrl + "&text=" + article_info.title + " "} ><i className="fa fa-twitter"></i></a>
                                    </li>
                                    <li className="list-group-item border-0  pl-0 -2" style={{ "display": "inline-block" }}>
                                        <a target="_blank" className="btn-facebook product-social-share-icon social-icon rounded-circle " href={"https://facebook.com/sharer.php?u=" + shareUrl + "/&quote=" + article_info.title}><i className="fa fa-facebook"></i></a>
                                    </li>
                                    <li className="list-group-item border-0  pl-0 -2" style={{ "display": "inline-block" }}>
                                        <a target="_blank" className="btn-whatsapp product-social-share-icon social-icon rounded-circle " href={"https://api.whatsapp.com/send?text=" + shareUrl + " "}><i className="fa fa-whatsapp"></i></a>
                                    </li>
                                </ul>

                            </div>
                            <div className="card-body bg-white">
                            <p>{toHtml(article_info.description)}</p>
                            </div>

                            
                        </div>

                        <div className="col-sm-3 article-navigation-container">

                            {
                                (teacher_blogs_list.length)
                                    ?
                                    <div className="card border-0">
                                        <div className="card-header">
                                            <h5 className="mb-0">Recent Articles</h5>
                                        </div>
                                        <div className="card-body pl-0">
                                            {this.renderRecentArticles(teacher_blogs_list)}
                                        </div>


                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}

function mapStateToProps(state) {
    return {
        article_info: state.blog.blog_info,
        teacher_blogs_list: state.blog.teacher_blogs_list,
    };
}

export default connect(mapStateToProps, { getBlogInfo,getAllTeacherBlogList })(BlogInfo);
