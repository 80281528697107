import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import BatchFormModal from './modal/model_batch';
import BatchStudentFormModal from './modal/model_batch_student';
import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { getBatches } from '../teacher/teacher_action';
import { modal } from '../library/modal';
import modalDeleteConfirmation from './modal/delete/modal_delete_confirmation';
import moment from "moment";

var dateFormat = require("dateformat");
class Batchlist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };
    this.actionChildList = this.actionChildList.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.URLFormatter = this.URLFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  componentDidMount() {
    let accessData = 'Granted';
    var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
    if(endDate == 'Invalid date'){
      accessData = 'NoAccess';
    }
    if(moment().isAfter(endDate, 'days')) {
      accessData = 'NoAccess';
    }
    if(accessData == 'NoAccess'){
      this.props.history.push('/pricing');
    }

    var _this = this;
    this.setState({ isLoading: true });
    this.props.getBatches(undefined, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }
  statusFormat(cell, row) {
    if (row.studentGoalStatus == 'completed') {
      return (
        <div className="badge bg-success"> Completed</div>
      );
    } else {
      return (
        <div className="badge bg-primary">Pending</div>
      )
    }
  }
  statusTeacherFormat(cell, row) {
    if (row.teacherApproved) {
      return (
        <div className="badge bg-success"> Approved</div>
      );
    } else {
      return (
        <div className="badge bg-danger">Pending</div>
      )
    }
  }
  BatchModel(title, batchData) {
    modal.add(BatchFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      batchData: batchData,
    });
  }
  BatchStudentModel(title, batchData) {
    modal.add(BatchStudentFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      batchstudentsData: batchData,
    });
  }
  addDeleteConfirmationModal(filter) {
    modal.add(modalDeleteConfirmation, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }
  actionFormatter(cell, row, props) {
    let link_info = '/assessment_info/'+row._id
    return (
      <div>
        {/* {
          row.studentGoalStatus != 'completed' &&  <Link onClick={() => this.BatchModel('Edit Assessment - ' + row.name+' - '+ row.subjectName , row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        } */}
        <Link to={link_info} className="btn btn-warning mr-2 btn-sm"><i className="fa fa-eye"></i></Link>
        

        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'batch', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link> 

        {/* <Link onClick={() => this.BatchStudentModel('Total Students - ' + '[ ' + row?.studentList?.length + ' ]', row)} className="btn btn-sm btn-outline-info  alc-btn-shadow mr-1"><i className='fa fa-user'></i></Link> */}
        
      </div>
    );
  }
  actionChildList(cell, row) {
    return (

      (row.studentList.length <= 0)
        ?
        <span className="btn btn-sm disabled  btn-outline-warning  alc-btn-shadow mr-1">No Childs</span>
        :
        <Link onClick={() => this.BatchStudentModel('Total Students - ' + '[ ' + row?.studentList?.length + ' ]', row)} className="btn btn-sm btn-outline-info  alc-btn-shadow mr-1"><i className='fa fa-eye'></i></Link>



    );

  }
  URLFormatter(cell, row) {
    //console.log("==========>",row.StudentData[0].name);
    if(row.studentList.length > 0){
      let url = "/assessment_info/" + row._id;
      return (
        <div>
          <Link to={url}>{row.name} - <br /><small>{row.StudentData[0].name}</small></Link>
        </div>
      );
    } else {
      let url = "/assessment_info/" + row._id;
      return (
        <div>
          <Link to={url}>{row.name} - <br /><small>No Name</small></Link>
        </div>
      );
    }
  }

  locationFormat(cell, row) {
    return (
      <div className="btn btn-sm bg-light text-dark">{row.address}</div>
    );
  }
  subjectFormat(cell, row) {
    return (
      <div className="btn btn-sm bg-light text-dark">{row.subjectName}</div>
    );
  }
  StarttimeFormat(cell, row) {
    return (
      <span className="">   <i className="fa fa-clock-o mr-1"></i>{row.startTime}</span>

    );
  }
  EndtimeFormat(cell, row) {
    return (
      <span className="">   <i className="fa fa-clock-o mr-1"></i>{row.endTime}</span>

    );
  }
  BatchDateFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.batchDate, "mm/dd/yyyy")}</span>


    );
  }

  render() {
    const { batch_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!batch_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      Pending: 'Pending',
      Ongoing: 'Ongoing',
      Completed: 'Completed'
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Assessment List
                    </h5>
                    <button onClick={() => this.BatchModel('Add New', '')} className="text-primary mt-2 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button>
                    {/* <button onClick={() => this.BatchModel('Add Assessment', '')} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New</b></button> */}
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                      keyField='_id'
                      data={(batch_list != undefined && batch_list.length ? batch_list : [])}
                      bordered={false}
                      striped={false}
                      pagination={true}
                      fetchInfo={{ dataTotalSize: batch_list.length }}
                      options={{
                        sizePerPage: this.state.sizePerPage,
                        page: this.state.currentPage,
                        hideSizePerPage: true,
                        paginationShowsTotal: this.renderPaginationShowsTotal,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSortChange: this.onSortChange
                      }}
                    >
                      <TableHeaderColumn
                        dataField='name'
                        columnClassName={''}
                        filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataFormat={this.URLFormatter}
                        width="20%"
                        dataSort={true}>
                        Assessment Name
                      </TableHeaderColumn>
                      {/* <TableHeaderColumn
                        dataField='subjectName'
                        filter={{ type: 'TextFilter' }}
                        columnClassName={''}
                        dataAlign="center"
                        dataFormat={this.subjectFormat} dataSort={true}>
                        Subject
                      </TableHeaderColumn> */}
                      <TableHeaderColumn
                        dataField='batchDate'
                        columnClassName={''}
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        dataFormat={this.BatchDateFormat}
                        dataSort={true}>
                        On Date
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='startTime'
                        columnClassName={''}
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        dataFormat={this.StarttimeFormat}
                        dataSort={true}>
                        Start Time
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='endTime'
                        columnClassName={''}
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        dataFormat={this.EndtimeFormat}
                        dataSort={true}>
                        End Time
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='status' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Student Status</TableHeaderColumn>
                      <TableHeaderColumn dataField='teacherApproved' dataAlign="center" dataFormat={this.statusTeacherFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Teacher Status</TableHeaderColumn>
                      {/* <TableHeaderColumn
                        dataField='studentList'
                        columnClassName={''}
                        dataAlign="left"
                        dataFormat={this.actionChildList}>
                        Childs
                      </TableHeaderColumn> */}
                      <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );

  }
}

function mapStateToProps(state) {
  return {
    batch_list: state.teacher.batch_list,

  }
}
export default connect(mapStateToProps, { getBatches })(Batchlist);
