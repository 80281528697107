import * as config from '../system/config';

import { Breadcrumbs, Loading, input } from '../library/elements';
import { Field, reduxForm } from 'redux-form'
import { pricingFormSubmit, validationNull } from '../account/account_action';

import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { loadStripe } from '@stripe/stripe-js';
import moment from "moment";

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

class Pricing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            planLabel: "",
            displayBuyNow: false,
            paymentCompleted: "",
            displayPaymentForm: false,
            payableAmount: 20,
            paymentStartDate: '',
            paymentEndDate: '',
            paymentPackageName: '',
            paymentUserId: '',
            paymentLoginUser: '',
            paymentRole: '',
            selectedPlanLabel : ''
        };
    }

    componentWillMount() {
        this.props.validationNull();
    }

    componentDidMount() {
        if (cookie.load("packageEndDate") != 'null' && cookie.load("teacher_id") !== undefined) {
            var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
            
            //var currentDate = moment(new Date()).format("MM/DD/yyyy");
            //console.log("End Date ==>",endDate,"===> Current Date ===>",currentDate);
            //console.log(moment().isAfter(endDate, 'days'));
            //if (currentDate > endDate) {
            if(moment().isAfter(endDate, 'days')) {
                this.setState({ planLabel: "Plan is expired since "+ endDate });
                this.setState({ displayBuyNow: true });
            }
            // else if (cookie.load("packageEndDate") !== undefined) {
            //     this.setState({ planLabel: "" });
            //     this.setState({ displayBuyNow: true });
            // } 
            else {
                this.setState({ planLabel: "Your Plan is in Progress till " + endDate });
                this.setState({ displayBuyNow: false });
            }
        } else {
            if (cookie.load("loginType") == 'teacher') {
                this.setState({ displayBuyNow: true });
            }
        }
    }

    setPurchase(startDate, packageDuration, totalAmount, packageName,is_login) {
        if(is_login !== undefined){
            let date = new Date(startDate);
            let endDate = new Date(date.setMonth(date.getMonth() + packageDuration));
    
            this.setState({
                payableAmount: totalAmount,
                paymentStartDate: startDate,
                paymentEndDate: endDate,
                paymentPackageName: packageName,
                paymentUserId: cookie.load("teacher_id"),
                paymentLoginUser: cookie.load("login_user"),
                paymentRole: 'teacher',
                displayPaymentForm: true,
                selectedPlanLabel: "Your Selected Plan "+packageName+' $'+totalAmount
            });
        } else {
            window.location.href = '/';
        }
    }
    
    renderAlert() {
        if (this.props.errorMessage) {
            return (
                <div className="alert alert-warning">
                    <strong>Oops! </strong> {this.props.errorMessage}
                </div>
            )
        }
    }

    render() {
        const { handleSubmit, errorMessage } = this.props;
        var isLoading = this.state.isLoading;

        if (errorMessage) {
            isLoading = false;
        }

        return (
            <div id="blogs" className="page  mb-5">
                {isLoading ? <Loading /> : null}
                <div className="container">
                    <div className="page-heading mt-6">
                        <div className="d-flex justify-content-between pt-3 breadcrumb-container">
                            <div className="p-2  mt-2 mb-2 breadcrumb-title text-primary font-weight-bold">
                                Learning3ly  Pricing
                            </div>
                        </div>
                    </div>

                    {
                        this.state.displayPaymentForm && <div className="row">
                            <div className='col-md-3'></div>
                            <div className='col-md-6'>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                        payableAmount={this.state.payableAmount}
                                        paymentStartDate={this.state.paymentStartDate}
                                        paymentStartDate={this.state.paymentStartDate}
                                        paymentEndDate={this.state.paymentEndDate}
                                        paymentPackageName={this.state.paymentPackageName}
                                        paymentUserId={this.state.paymentUserId}
                                        paymentLoginUser={this.state.paymentLoginUser}
                                        paymentRole={this.state.paymentRole}
                                        setPaymentCompleted={this.state.paymentCompleted}
                                        selectedPlanLabel={this.state.selectedPlanLabel}
                                    />
                                </Elements>
                            </div>
                            <div className='col-md-3'></div>
                        </div>
                    }


                    { !this.state.displayPaymentForm && 
                    <div>
                        <div className="row">
                            <form>
                                <div className="col-sm-12 article-list-container">
                                    <div className="row">
                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">Montly</li>
                                                <li className="grey">$19.99 / 1 Month</li>
                                                <li>Teacher Dashboard Access for month</li>
                                                <li className="grey">
                                                    <small className='text-danger'>{this.state.planLabel}</small>
                                                    <br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 1, 19.99, '1 Month',cookie.load('is_login'))} className="button">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">3 Months</li>
                                                <li className="grey">$49.99 for 3 Months</li>
                                                <li>Teacher Dashboard Access for 3 Months</li>
                                                <li className="grey">
                                                    <small className='text-danger'>{this.state.planLabel}</small>
                                                    <br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 3, 49.99, '3 Month',cookie.load('is_login'))} className="button">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">6 Months</li>
                                                <li className="grey">$169.99 for 6 Months</li>
                                                <li>Teacher Dashboard Access for 6 Months</li>
                                                <li className="grey">
                                                    <small className='text-danger'>{this.state.planLabel}</small>
                                                    <br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 6, 169.99, '6 Month',cookie.load('is_login'))} className="button">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header">12 Months</li>
                                                <li className="grey">$219.99 for 12 Months</li>
                                                <li>Teacher Dashboard Access for 12 Months</li>
                                                <li className="grey">
                                                    <small className='text-danger'>{this.state.planLabel}</small>
                                                    <br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 12, 219.99, '12 Month',cookie.load('is_login'))} className="button">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut lacus et risus rhoncus vulputate a sed mi. Cras eu ipsum id purus finibus rutrum in nec justo. Morbi non risus at diam dignissim porta. Nunc interdum erat vel justo molestie, eget tincidunt odio malesuada. Donec iaculis egestas porttitor. Donec aliquam vulputate justo, non viverra odio cursus ut. Vestibulum eget iaculis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at ultricies urna.
                                </p>
                                <p>Praesent nec tellus tristique, tempus urna sed, euismod dolor. Quisque orci ante, dignissim vel ante id, consequat scelerisque lectus. Nunc odio erat, finibus id erat in, commodo ultrices nunc. Aenean lobortis lorem tempus, feugiat urna placerat, elementum leo. Duis eget semper quam. Phasellus ut magna sit amet nulla tincidunt tincidunt. Integer accumsan posuere aliquam. Cras vehicula ante nec nisi malesuada, quis rutrum enim faucibus. In sit amet nunc ac ligula congue consequat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                                <p>Proin ornare nisi ut est sodales, at tristique est tristique. Quisque dictum felis mollis tortor congue placerat. Praesent a commodo neque. Quisque euismod, nibh sed congue lobortis, nulla ante dignissim sem, tincidunt laoreet nisl ex eget neque. Ut congue odio eget diam bibendum, eget tincidunt erat efficitur. Quisque rutrum nulla ut mauris aliquet pellentesque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed aliquam quam non nibh malesuada pulvinar.</p>
                                <p>Praesent velit urna, placerat a gravida nec, dignissim vel nulla. Nulla id facilisis dolor. Cras pellentesque lacinia metus. Vivamus vel bibendum lectus, quis tempus lacus. Integer ac sapien vel neque tristique scelerisque quis vel neque. Vestibulum finibus sapien in quam rutrum, vel laoreet lacus venenatis. Duis non pulvinar purus, at fringilla lacus. Nulla id est porta, faucibus erat vel, ornare ex. Duis sed pellentesque enim.</p>
                            </div>
                        </div>
                    </div>
                    }


                </div>
            </div>

        )
    }
}

function validate(formProps) {
    const errors = {}

    if (!formProps.email) {
        errors.email = 'Required Email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
        errors.email = 'Invalid Email Address'
    }

    return errors
}

Pricing = reduxForm({
    form: 'pricing',
    validate: validate
})(Pricing)

function mapStateToProps(state) {

    return {
        errorMessage: state.account.error,
        //teacher_blogs_list: state.blog.teacher_blogs_list,
        //subject_list: state.teacher.all_subject_list,

    }
}
export default connect(mapStateToProps, { pricingFormSubmit, validationNull })(Pricing);
