import { Field, reduxForm } from 'redux-form';
import { Loading, input } from '../library/elements';
import React, { Component } from 'react';
import { createAlert, normalizeField } from '../../src/library/function';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { modal } from '../library/modal';
import ModalAccountFrogotPassword from './forgotten';
import {authResetPassword, resetPassword} from '../account/account_action';
import cookie from 'react-cookie';

var ReactRotatingText = require('react-rotating-text');

class Resetpass extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, isError: '' };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        
        if (this.props.authenticated === true) {
            this.props.history.push('/deshboard');
        }
        
        const queryParams = new URLSearchParams(window.location.search);
        const mail = queryParams.get('email');
        const token = queryParams.get('token');
        console.log("Current Email ===>",mail);
        console.log("Current Token ===>",token);
        
        //var mail = this.props.location.pathname.split('/email=')[1];
        //var token = this.props.location.pathname.split('/email=')[0].replace("/resetpassword/token=", '');
        if (token !== undefined && token && mail !== undefined && mail) {
            this.props.authResetPassword(token, mail, function (err, result) { });
        } else {
            this.setState({ isError: true });
        }
    }

    handleFormSubmit(formProps) {
        
        this.setState({ isError: '' });
        this.setState({ isLoading: true });
        var _this = this;
    
        //formProps.email = this.props.location.pathname.split('/email=')[1];
        //formProps.token = this.props.location.pathname.split('/email=')[0].replace("/resetpassword/token=", '');

        let queryParams2 = new URLSearchParams(window.location.search);
        formProps.email = queryParams2.get('email');
        formProps.token = queryParams2.get('token');

        console.log(formProps);
        this.props.resetPassword(formProps, function (err, result) {
          if (!result) {
            _this.setState({ isLoading: false });
          } else {
            _this.props.history.push('/signin');
            _this.setState({ isLoading: false });
          }
        });
      }

      addForpgotPasswordModal(title) {
        modal.add(ModalAccountFrogotPassword, {
          title: title,
          size: 'large',
          closeOnOutsideClick: false,
          hideCloseButton: false,
          modalId: 'forgot-password',
        });
      }

    componentWillMount() {
        if (this.props.authenticated) {
            this.props.history.push('/dashboard');
        }
    }

    // handleFormSubmit(formProps) {
    //     var _this = this;
    //     this.setState({ isLoading: true });
    //     if (formProps !== "" && formProps !== undefined) {
    //         this.props.register(formProps, function (err, result) {
    //             if (result) {
    //                 _this.props.history.push('/signin');
    //                 _this.setState({ isLoading: false });
    //             } else {
    //                 _this.setState({ isLoading: false });
    //             }
    //         });
    //     } else {
    //         createAlert({ message: 'Please Fill requered Details' });
    //         this.setState({ isLoading: false });
    //     }
    // }


    renderPasswordMask(getState) {
        if (this.state[getState] === false) {
            this.setState({ [getState]: true });
        } else {
            this.setState({ [getState]: false });
        }
    }

    renderConfirmPasswordMask(getState) {
        if (this.state[getState] === false) {
            this.setState({ [getState]: true });
        } else {
            this.setState({ [getState]: false });
        }
    }

    onchangeRole(role_value) {
        this.setState({ isRole: role_value });
    }

    renderAlert() {
        if (this.props.errorMessage) {
            return (
                <div className="col-sm-12 pt-3">
                    <div className="alert alert-warning">
                        <strong>Oops! </strong> {this.props.errorMessage}
                    </div>
                </div>

            )
        }
    }

    render() {
        const { handleSubmit, errorMessage } = this.props;
        var { isLoading } = this.state;
        console.log(" Error ====>", errorMessage);

        if (errorMessage) {
            isLoading = false;
        }
        return (
            <div className="account-login ui-form mt-5 mb-5">
                {isLoading ? <Loading /> : null}
                <div className="container">
                    <br /><br /><br /><br />
                    <div className="row">

                        <div className="col-sm-2">
                            <br />
                        </div>

                        <div className="col-sm-8">
                            <div className="card ">
                                <div className="card-header ">
                                    <h5 className="text-primary">Reset Password</h5>
                                </div>
                                <div className="card-body">
                                    {
                                        errorMessage

                                            ?
                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    <div className="alert alert-warning">Oops... Looks like your link has expired. <Link className="link-primary" onClick={() => this.addForpgotPasswordModal('Forgot Password')}>Click here</Link> to get a new link.</div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                                        <div className="form-group">
                                            <div className="col-sm-12 position-relative has-icon-left mb-3">
                                                <Field name="password" type="password" component={input} label="* Password" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-12 position-relative has-icon-left mb-3">
                                                <Field name="confirm_password" type="password" component={input} label="* Confirm  Password" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-sm-12">
                                                <button
                                                    type="submit"
                                                    action="changepassword"
                                                    disabled={isLoading}
                                                    className="btn  w-100  btn-primary btn-submit">
                                                    {isLoading ? 'Loading...' : 'Reset'}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12 text-center">
                                                New to Learning3ly ? <Link className="text-primary" to="/signup" >Signup Now</Link>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function validate(formProps) {
    const errors = {}
    if (!formProps.password) {
        errors.password = 'Required Password';
    } else if (formProps.password.length < 6) {
        errors.password = 'Password must more than 6 characters';
    }

    if (formProps.password !== formProps.confirm_password) {
        errors.confirm_password = 'Confirm must match to Password';
    }

    return errors
}


function mapStateToProps(state) {
    return {
        authenticated: state.account.authenticated,
        errorMessage: state.account.error,
        teacher_profile: state.account.teacher_profile,
    }
}

Resetpass = reduxForm({
    form: 'changepassword',
    validate: validate
})(Resetpass);

export default connect(mapStateToProps, { authResetPassword, resetPassword })(Resetpass);
