import React, { Component, PropTypes } from 'react';

import { Link } from 'react-router-dom';
import { Loading } from '../../library/elements';
import { connect } from 'react-redux';

class ModalViewGoalFiles extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: ''
    };

  }

  closeModal() {
    this.props.removeModal();
  }

  componentDidMount() {

  }
  renderImage(images) {
    return images.map((img, index) => {


      return (
        <div className="col-lg-4 col-sm-6 col-6 mt-2 mb-2 alc-overflow" key={'task-image-' + index}>
          <Link onClick={() => window.open(img.img_url, "_blank")}>

            <div className="card alc-card alc-card-fancy alc-hover-fancy mb-4">
              <div className="alc-card-fancy-image">


                <img src={img.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '100%', height: '12rem' }} />

              </div>
              <div className="card-footer alc-card-fancy-content text-center">
                <h4 className="card-title alc-card-title"><i className="fa fa-eye"></i></h4>
              </div>
            </div>
          </Link>
        </div>

      );
    });
  }
  renderWords(words) {
    return words.map((word, index) => {


      return (
        <div className="col-lg-2 col-sm-2 col-2 mt-2 mb-2" key={'task-image-' + index}>
          <a href={word} target="_blank"><img src={word} className='w-100' /></a>
        </div>
      );
    });
  }

  render() {
    const { documents, type } = this.props;
    const { isLoading } = this.state;
    if (documents == undefined) {
      return (
        <div><Loading /></div>
      );
    }
    return (
      <div>
        <div className="row task-images">
          <div className="col-sm-12">
            {
              documents.length > 0
                ?
                <div className="row">
                  {
                    type == "image"
                      ?
                      this.renderImage(documents)
                      :
                      this.renderWords(documents)
                  }

                </div>
                :
                <h6 className="text-center">No Data Found!</h6>
            }
          </div>
        </div>
      </div>
    );

  }
}
function mapStateToProps(state) {
  return {
  }
}
export default connect(mapStateToProps, {})(ModalViewGoalFiles);
