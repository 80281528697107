import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';
import { getContentList, updateConversation } from './teacher_action';

import ContentFormModal from './modal/model_content';
import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ModalContentDelete from './modal/delete/modal_content_delete';
import ModalViewCommentFiles from './modal/modal_task_comment_file';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { modal } from '../library/modal';
import moment from "moment";

class ContentList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };
    this.actionViewList = this.actionViewList.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }
  componentDidMount() {
    let accessData = 'Granted';
    var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
    if(endDate == 'Invalid date'){
      accessData = 'NoAccess';
    }
    if(moment().isAfter(endDate, 'days')) {
      accessData = 'NoAccess';
    }
    if(accessData == 'NoAccess'){
      this.props.history.push('/pricing');
    }
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getContentList(function (err, result) {
      if (result) {
      //console.log("Result ===>",result.data.data);

      let content_data = result.data.data;
      let arrPendingConvert = [];
      if(content_data && content_data !== undefined){
        let item_image = "";
        let item_image_length = 0;
        if(content_data.length > 0){
          content_data.forEach(item => {
            item_image = item.image;
            item_image_length = item.image.length;
            if(item_image_length > 0){
              item_image.forEach(images => {
                let audio_url = images.audio_url;
                let last_5_chars = audio_url.substr(audio_url.length - 5);
                if(last_5_chars == '.webm'){
                  arrPendingConvert.push({
                    content_id: item._id,
                    _id: images._id,
                    audio_url: images.audio_url
                  });
                }
              });
            }
          });    
        }
        //console.log("Mayank Length >>>",arrPendingConvert);
        if(arrPendingConvert.length > 0){
          updateConversation(arrPendingConvert,function (err, result) {
            if (result) {
              console.log(result);
            }
          });
        }
      }











        _this.setState({ isLoading: false });
      }
    });

  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }
  statusFormat(cell, row) {
    if (row.isActive === true) {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else {
      return (
        <div className="badge bg-danger ">Inactive</div>
      );
    }
  }
  ContentFormModal(title, contentData) {
    modal.add(ContentFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      contentData: contentData,
    });
  }

  addDeleteConfirmationModal(filter) {
    modal.add(ModalContentDelete, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }

  ViewContentAttachment(title, documents) {
    modal.add(ModalViewCommentFiles, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      documents: documents,
    });
  }
  actionViewList(cell, row) {
    return (

      row.image !== undefined && row.image.length > 0 ?
        <Link onClick={() => this.ViewContentAttachment('View Content Attachment', row.image)} className="badge bg-light text-dark mr-2 btn-sm"><i className="fa fa-eye mr-1"></i></Link>
        :
        <span className="text-center"> - </span>
    )

  }
  actionFormatter(cell, row, props) {
    return (
      <div>
        <Link onClick={() => this.ContentFormModal('Edit Content - ' + row.title, row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'content', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
        {
          row.image !== undefined && row.image.length > 0 ?
          <Link onClick={() => this.ViewContentAttachment('View Content Attachment', row.image)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-eye mr-1"></i></Link>
          :
          <span className="text-center"> - </span>
        }
      </div>
    );
  }
  render() {
    const { content_list ,updateConversation} = this.props;
    
    //console.log("Constant list",content_list);

    const { isLoading, sortName, sortOrder } = this.state;

    if (!content_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      true: 'Active',
      false: 'InActive',
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font font-weight-bold text-primary">
                      <i className="fa fa-bag mr-1"></i>Content List
                    </h5>

                    <button onClick={() => this.ContentFormModal('Add New', '')} className="text-primary mt-2 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button>

                    {/* <button onClick={() => this.ContentFormModal('Add Content', '')} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New Content</b></button> */}
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                      keyField='_id'
                      data={(content_list != undefined && content_list.length ? content_list : [])}
                      bordered={false}
                      striped={false}
                      pagination={true}
                      fetchInfo={{ dataTotalSize: content_list.length }}
                      options={{
                        sizePerPage: this.state.sizePerPage,
                        page: this.state.currentPage,
                        hideSizePerPage: true,
                        paginationShowsTotal: this.renderPaginationShowsTotal,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSortChange: this.onSortChange
                      }}
                    >
                      <TableHeaderColumn
                        dataField='title'
                        columnClassName={''}
                        filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        width='60%'
                        dataSort={true}>
                        Content Title
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='category'
                        columnClassName={''}
                        filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataSort={true}>
                        Content Category
                      </TableHeaderColumn>

                      {/* <TableHeaderColumn
                        dataField='studentName'
                        columnClassName={''}
                        dataAlign="left"
                        dataFormat={this.actionViewList}>
                        Content Attchment
                      </TableHeaderColumn> */}
                      <TableHeaderColumn dataField='isActive' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort>Status</TableHeaderColumn>
                      <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );

  }
}

function mapStateToProps(state) {

  return {
    content_list: state.teacher.content_list,

  }
}
export default connect(mapStateToProps, { getContentList, updateConversation })(ContentList);
