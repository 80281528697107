import React, { Component, PropTypes } from 'react';

import { connect } from 'react-redux';
import { createAlert } from '../../library/function';
import { stopbyStudentGoalAssessment } from '../student_action';

class ModalGoalSubmitAssessment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: ''
    };
    this.submitConfim = this.submitConfim.bind(this);
  }

  closeModal() {
    this.props.removeModal();
  }

  submitConfim(data) {
    this.setState({ isLoading: true });
    var _this = this;
    this.props.stopbyStudentGoalAssessment(data.attchment, data.assessment_id, function (err, result) {
      if (result != undefined) {
        if (result.data.err != undefined) {
          _this.setState({ isLoading: false });
          _this.setState({ error: result.data.err });
        } else {

          createAlert({ message: 'Goal has been Submit!' })
          _this.props.removeModal();
          _this.setState({ isLoading: false });
        }
      }
    });
  }

  render() {
    const { data } = this.props;
    const { isLoading, error } = this.state;
    if (data != undefined && data) {
      return (
        <div className="common-modal p-1">
          {
            error
              ?
              <div className="row">
                <div className="col-sm-12 alert alert-warning">
                  {error}
                </div>
              </div>
              :
              <div className="row">
                <div className="col-sm-6">
                  <button onClick={() => this.submitConfim(data)} className="btn btn-danger mr-2 btn-sm btn-block">Yes</button>
                </div>
                <div className="col-sm-6">
                  <button onClick={() => this.closeModal()} className="btn btn-secondary mr-2 btn-sm btn-block">No</button>
                </div>
              </div>
          }
        </div>
      );
    } else {
      return (
        <div className="common-modal p-1">
          <div className="row">
            <div className="col-sm-12">
              Something wrong. please try again.
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <button onClick={() => this.closeModal()} className="btn btn-secondary mr-2 btn-sm btn-block">No</button>
            </div>
          </div>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
  }
}
export default connect(mapStateToProps, { stopbyStudentGoalAssessment })(ModalGoalSubmitAssessment);
