import {
    FETCH_CONTENT_INFO,
    FETCH_GOAL_INFO,
    FETCH_REINFORCEMENT_INFO,
    FETCH_STUDENT_CHART_DATA,
    FETCH_STUDENT_INFO,
    FETCH_TEACHER_ACCOUNT_PROFILE,
    FETCH_TEACHER_STUDENT_LIST,
    GET_ADMIN_BATCH_LIST,
    GET_ADMIN_CATEGORY_LIST,
    GET_ADMIN_CONTACTUS_LIST,
    GET_ADMIN_CONTENT_INFO,
    GET_ADMIN_CONTENT_LIST,
    GET_ADMIN_EVENTS_LIST,
    GET_ADMIN_GOALS_LIST,
    GET_ADMIN_PACKAGE_PURCHASED_LIST,
    GET_ADMIN_PARENT_LIST,
    GET_ADMIN_REINFORCEMENT_LIST,
    GET_ADMIN_STUDENT_LIST,
    GET_ADMIN_SUBJECT_LIST,
    GET_ADMIN_SUBSCRIBER_LIST,
    GET_ADMIN_TEACHER_LIST,
    GET_All_SUBJECTS,
    GET_BATCHES_LIST,
    GET_BATCH_LIST,
    GET_CATEGORY_LIST,
    GET_CONTENT_LIST,
    GET_COULAGE_LIST,
    GET_DASHBOARD_LIST,
    GET_EVENT_LIST,
    GET_GOAL_LIST,
    GET_NEWSLETTER_STATUS,
    GET_REINFORCEMENT_LIST,
    GET_STUDENT_LIST,
    GET_STUDENT_TASK_LIST,
    GET_TEACHER_STUDENT_TASK_INFO,
    GET_TEACHER_SUBJECTS,
    GET_TEACHER_TASK_STUDENTS,
    VALIDATION_ERROR,
} from './teacher_action';

export default function TeacherReducer(state = {}, action) {
    //console.log("Teacher Reducer Action Type ",action.type,'<<---===>>',"Teacher Reducer Action Payload", action.payload);
    switch (action.type) {
        
        case VALIDATION_ERROR:
            return { ...state, error: action.payload }
        case FETCH_TEACHER_ACCOUNT_PROFILE:
            return { ...state, teacher_profile: action.payload.data }
        case GET_BATCH_LIST:
            return { ...state, batch_list: action.payload.data }
            case GET_BATCHES_LIST:
                return { ...state, batches_list: action.payload.data }            
        case GET_All_SUBJECTS:
            return { ...state, all_subject_list: action.payload.data }
        case GET_STUDENT_LIST:
            return { ...state, student_list: action.payload.data }
        case GET_NEWSLETTER_STATUS:
            return {...state, newsletter_response: action.payload}    
        case FETCH_STUDENT_INFO:
            return { ...state, student_info: action.payload }
        case FETCH_TEACHER_STUDENT_LIST:
            return { ...state, teacher_student_list: action.payload }
        case FETCH_STUDENT_CHART_DATA:
            return { ...state, student_chart_data: action.payload.data } 
        case GET_TEACHER_SUBJECTS:
            return { ...state, teacher_subject_list: action.payload.data }
        case GET_STUDENT_TASK_LIST:
            return { ...state, student_task_list: action.payload.data }
        case GET_TEACHER_STUDENT_TASK_INFO:
            return { ...state, teacher_student_task_info: action.payload }
        case GET_COULAGE_LIST:
            return { ...state, coulage_list: action.payload.data }
        case GET_DASHBOARD_LIST:
            return { ...state, teacher_dashboard_list: action.payload.data }
        case GET_TEACHER_TASK_STUDENTS:
            return { ...state, teacher_task_student_list: action.payload.data }
        case GET_CONTENT_LIST:
            return { ...state, content_list: action.payload.data }
        case FETCH_CONTENT_INFO:
            return { ...state, content_details: action.payload }
        case FETCH_REINFORCEMENT_INFO:
            return { ...state, content_details: action.payload }
        case GET_GOAL_LIST:
            return { ...state, goal_list: action.payload.data }
        case GET_CATEGORY_LIST:
            return { ...state, category_list: action.payload.data }
        case FETCH_GOAL_INFO:
            return { ...state, goal_info: action.payload }
        case GET_REINFORCEMENT_LIST:
            return { ...state, reinforcement_list: action.payload.data }
        case GET_EVENT_LIST:
            return { ...state, event_list: action.payload.data }
        case GET_ADMIN_SUBJECT_LIST:
            return { ...state, admin_subject_list: action.payload.data }
        case GET_ADMIN_SUBSCRIBER_LIST:
            return { ...state, admin_subscriber_list: action.payload.data}
        case GET_ADMIN_CONTACTUS_LIST:
            return { ...state, admin_contactus_list: action.payload.data}
        case GET_ADMIN_CATEGORY_LIST:
            return { ...state, admin_category_list: action.payload.data}
        case GET_ADMIN_CONTENT_LIST: 
            return { ...state, admin_content_list: action.payload.data}
        case GET_ADMIN_CONTENT_INFO:
            return { ...state, admin_content_info: action.payload.data}
        case GET_ADMIN_EVENTS_LIST:
            return { ...state, admin_events_list: action.payload.data}
        case GET_ADMIN_REINFORCEMENT_LIST:
            return { ...state, admin_reinforcement_list: action.payload.data}
        case GET_ADMIN_PACKAGE_PURCHASED_LIST:
            return { ...state, admin_package_purchased_list : action.payload.data}
        case GET_ADMIN_STUDENT_LIST:
            return { ...state, admin_student_list: action.payload.data}
        case GET_ADMIN_PARENT_LIST:
            return { ...state, admin_parent_list: action.payload.data}
        case GET_ADMIN_TEACHER_LIST:
            return { ...state, admin_teacher_list: action.payload.data}
        case GET_ADMIN_GOALS_LIST:
            return { ...state, admin_goals_list: action.payload.data}
        case GET_ADMIN_BATCH_LIST:
            return {...state, admin_batch_list: action.payload.data}
        default:
            return state;
    }
}