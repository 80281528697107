import Carousel, { ModalGateway, Modal as PopupModel } from 'react-images'
import { Field, FieldArray, reduxForm } from 'redux-form';
import { InputImage, Loading } from '../library/elements';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import ModalTaskComment from './modal/modal_task_comment';
import ModalTaskDocuments from './modal/modal_task_document';
import ModalTaskTimeLog from './modal/modal_task_time_log';
import ModalViewCommentFiles from './modal/modal_task_comment_file';
import { connect } from 'react-redux';
import { getTeacherStudentTaskInfo } from './teacher_action';
import { modal } from '../library/modal';
import modalTaskDeleteConfirmation from './modal/delete/modal_task_document_delete';
import modalTeacherTaskDeleteCommentConfirmation from './modal/delete/modal_teacher_task_comment_delete';
import { toHtml } from '../library/function';

var dateFormat = require('dateformat');

class TaskInfoForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setTab: 'nav-comment',
      modalIsOpen: false
    };


  }

  componentDidMount() {
    var _this = this;
    var task_id = this.props.match.params.task_id;
    var student_id = this.props.match.params.student_id;
    this.setState({ isLoading: true });
    this.props.getTeacherStudentTaskInfo(task_id, student_id, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    var _this = this;
    if (nextProps.match.params.task_id !== this.props.match.params.task_id && nextProps.match.params.student_id !== this.props.match.params.student_id) {
      this.props.getTeacherStudentTaskInfo(nextProps.params.task_id, nextProps.params.student_id, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    }
  }
  loadProductTab(tab) {
    this.setState({ setTab: tab });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }



  renderAddmoreImageCard() {
    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <ul className="card-stripe-ul  m-0">
        {fields.map((item, index) =>
          <li key={"add_image_" + index} className="card-stripe-ul-li">
            <span className="badge badge-danger remove-image"
              onClick={() => fields.remove(index)}
            >
              <i className="fa fa-close"></i>
            </span>
            <Field name={`${item}.image`} type="text" component={InputImage} size="sm" label="Image" className="form-control" />
          </li>
        )}
        <li className="card-stripe-ul-li add_image card" onClick={() => fields.push({})}><i className="fa fa-plus-circle fa-3x"></i></li>
      </ul>
    )
    return (
      <div className="card-stripe">
        <FieldArray name={"task_images"} component={addMoreImage} />
      </div>
    );
  }

  ViewCommentDocs(title, documents) {
    modal.add(ModalViewCommentFiles, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      documents: documents,
    });
  }
  renderTaskHistory(comments) {
    if (comments !== null && comments.length > 0) {
      return comments.map((comment) => {

        comment.task_id = this.props.task_info.taskDetails._id
        comment.student_id = this.props.task_info.studentDetails.userId
        var type = "";
        if (comment.isByTeacher) {
          type = "Teacher"
        } else if (comment.isByParent) {
          type = "Parent"
        } else {
          type = "Student"
        }
        return (
          <tr key={'history_' + comment._id}>
            <td className="text-center"><span>  <i className="fa fa-calendar mr-1"></i>{dateFormat(comment.at, "dd-mm-yyyy h:MM TT")}</span></td>
            <td className="text-center">{toHtml(comment.comment !== "undefined" && comment.comment !== "" ? comment.comment : '-')}</td>
            <td className="text-center"><span className="btn btn-sm bg-light text-dark">{comment.name ? comment.name : '-'}</span></td>
            <td className="text-center">

              {comment.files.length > 0 ?
                <Link onClick={() => this.ViewCommentDocs('View Comment Attachment', comment.files)} className="btn btn-primary mr-2 btn-sm">View Documents</Link>
                :
                <span className="text-center"> - </span>
              }
            </td>


            <td className="text-center"><span className="btn btn-sm bg-success text-white">{type}</span></td>
            <td className="text-center"><Link onClick={() => this.addCommentDeleteConfirmationModal({ commentId: comment._id, taskId: this.props.task_info.taskDetails._id, studentId: this.props.task_info.studentDetails.userId })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link></td>
            <td className="text-center"><Link onClick={() => this.ModalTaskComment('Update Task Comment', comment, "update")} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-pencil"></i></Link></td>

          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No  Comment Found!</td>
        </tr>
      );
    }
  }

  renderTaskTimeLogs(logs) {
    if (logs !== null && logs.length > 0) {
      return logs.map((log) => {
        var type = "";
        if (log.isByTeacher) {
          type = "Student"
        } else if (log.isByParent) {
          type = "Student"
        } else {
          type = "Student"
        }
        return (
          <tr key={'history_' + log._id}>
            <td className="text-center"><span>  <i className="fa fa-calendar mr-1"></i>{dateFormat(log.dateTime, "dd-mm-yyyy h:MM TT")}</span></td>
            <td className="text-center">{toHtml(log.description !== "undefined" ? log.description : '')}</td>
            <td className="text-center"><span className="btn btn-sm bg-light text-dark">{log.name}</span></td>
            <td className="text-center"><span className="btn btn-sm bg-success text-white">{type}</span></td>
            {/* <td className="text-left"><Link onClick={() => this.ModalTaskTimeLog('View Details',log)} className="btn btn-primary btn-sm"><i className="fa fa-eye"></i> View</Link></td> */}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No  Log Data Found!</td>
        </tr>
      );
    }
  }
  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
  }
  LightBoxLargeImage(thumb, modalIsOpen) {

    var image = [];

    image.push({ source: thumb });

    return (
      <div className="card shadow-none product-main-image-container border-0">
        <ModalGateway>
          {modalIsOpen ? (
            <PopupModel onClose={this.toggleModal}>
              <Carousel views={image} />
            </PopupModel>
          ) : null}
        </ModalGateway>
        <ul className="product-main-image list-unstyled mb-0  m-auto">
          <li>
            <Link className="" onClick={() => this.setState({ modalIsOpen: true })}>
              <img
                className="img-fluid img-thumbnail"
                src={thumb}
                alt={"image"}

              />
            </Link>
          </li>
        </ul>
      </div>
    );

  }
  renderTaskDodument(documents, modalIsOpen) {
    if (documents !== null && documents.length > 0) {
      return documents.map((document) => {

        var type = "";
        if (document.isByTeacher) {
          type = "Teacher"
        } else if (document.isByParent) {
          type = "Parent"
        } else {
          type = "Teacher"
        }
        return (
          <tr key={'history_' + document._id}>

            <td className="text-center border-0">

              <ul className="product-main-image list-unstyled mb-0  m-auto">
                <li>
                  <Link onClick={() => window.open(document.link, "_blank")}>
                    <img
                      className="img-fluid img-thumbnail"
                      src={document.link}
                      alt={"image"}

                    />
                  </Link>
                </li>
              </ul>
            </td>
            <td className="text-center"><span className="btn btn-sm bg-light text-dark">{document.userName}</span></td>
            <td className="text-center border-0 "><span className="btn btn-sm bg-success text-white">{type}</span></td>
            <td className="text-center border-0"><Link onClick={() => this.addDeleteConfirmationModal({ link: document.link, taskId: this.props.task_info.taskDetails._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No Document Found!</td>
        </tr>
      );
    }
  }


  ModalTaskComment(title, commentData, opration) {

    modal.add(ModalTaskComment, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      commentData: commentData,
      opration: opration
    });
  }
  ModalTaskDocuments(title, documentData, opration) {
    modal.add(ModalTaskDocuments, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      documentData: documentData,
      opration: opration
    });
  }
  ModalTaskTimeLog(title, logData) {
    modal.add(ModalTaskTimeLog, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      logData: logData
    });
  }
  addDeleteConfirmationModal(filter) {
    modal.add(modalTaskDeleteConfirmation, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }

  addCommentDeleteConfirmationModal(filter) {
    modal.add(modalTeacherTaskDeleteCommentConfirmation, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }

  render() {

    const { task_info } = this.props;
    const { isLoading, setTab, modalIsOpen } = this.state;
    if (task_info === undefined) {
      return (
        <div className="task-undefined"><Loading /></div>
      );
    }
    var task_data = {};
    task_data.task_id = task_info.taskDetails._id;
    task_data.student_id = task_info.studentDetails.userId;

    return (
      <div className="page material-ui" id="page-batch-info">




        { isLoading ? <Loading /> : null}
        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content p-3">
              <form>
                <div className="card card-form">
                  <div className="card-header">
                    <h5>{task_info?.taskDetails?.name}</h5>
                  </div>

                  <div className='card-body'>
                    <div className='card mt-2 border-0 card-info-block'>
                      <div className="card-header font-weignt-bold h5 bg-white text-primary">Student Information</div>
                      <div className='card-body'>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>

                              <tr>
                                <td className="first  font-weignt-bold h6 ">Profile Image</td>
                                <td className="second "> <span className="">
                                  :

                                <div className="avatar avatar-xxl avatar-circle mb-3">
                                    <img className="img-thumbnail rounded" src="https://image.shutterstock.com/image-vector/man-icon-vector-260nw-1040084344.jpg" alt="Image Description" />
                                  </div>
                                </span></td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold  h6"> Name</td>
                                <td className="second border-top-0 col-6">: {task_info?.studentDetails?.firstName + ' ' + task_info?.studentDetails?.lastName}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold h6 ">Address</td>
                                <td className="second ">:  {task_info?.studentDetails?.address}</td>
                              </tr>
                              <tr>

                                <td className="first  font-weignt-bold h6 ">Std</td>
                                <td className=""> :  <span className="second badge bg-success"> {task_info?.studentDetails?.std}</span></td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold h6 ">Grade</td>
                                <td className="second "> <span className="">
                                  :

                                {task_info?.studentDetails?.grade}
                                </span></td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold h6 ">BirthDate</td>
                                <td className="second "> <span className="">
                                  : <i className="fa fa-calendar mr-1"></i>

                                  {dateFormat(task_info?.studentDetails?.dob, "mm-dd-yyyy")}
                                </span></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='card mt-2 border-0 card-info-block'>
                      <div className="card-header font-weignt-bold h5 bg-white text-primary">Task Information</div>
                      <div className='card-body'>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>

                              <tr>
                                <td className="first  font-weignt-bold  h6">Task Name</td>
                                <td className="second border-top-0 col-6">: {task_info?.taskDetails?.name}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold  h6">Student Name</td>
                                <td className="second border-top-0 col-6">: {task_info?.studentDetails?.firstName + ' ' + task_info?.studentDetails?.lastName}</td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold h6 ">Task Subject</td>
                                <td className="second ">:  {task_info?.subjectName}</td>
                              </tr>
                              <tr>

                                <td className="first  font-weignt-bold h6 ">Task Current Status</td>
                                <td className=""> :  <span className="second badge bg-success">{task_info?.taskStatus}</span></td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold h6 ">Task Start Date & Time</td>
                                <td className="second "> <span className="">
                                  : <i className="fa fa-calendar mr-1"></i>

                                  {dateFormat(task_info?.taskDetails?.taskDateTime, "mm-dd-yyyy h:MM:ss TT")}
                                </span></td>
                              </tr>
                              <tr>
                                <td className="first  font-weignt-bold h6 ">Task End Date & Time</td>
                                <td className="second "> <span className="">
                                  : <i className="fa fa-calendar mr-1"></i>

                                  {dateFormat(task_info?.taskDetails?.completionDateTime, "mm-dd-yyyy h:MM:ss TT")}
                                </span></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-form mt-3">
                  <div className="card-body">
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className={setTab === "nav-comment" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-comment')} href='#nav-comment' role="tab">Comments ({task_info?.commentList?.length ? task_info.commentList.length : 0})</a>
                        <a className={setTab === "nav-logtime" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-logtime')} href='#nav-logtime' role="tab">Time Logs ({task_info?.timeList?.length ? task_info.timeList.length : 0})</a>
                        <a className={setTab === "nav-upload" ? 'nav-link active' : " nav-link"} data-toggle="tab" onClick={() => this.loadProductTab('nav-upload')} href='#nav-upload' role="tab">Documents ({task_info?.taskDetails?.attachment?.length ? task_info.taskDetails.attachment.length : 0})</a>

                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div className={setTab === "nav-comment" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-comment" role="tabpanel" aria-labelledby="nav-comment-tab">
                        <div className="card-body mt-3">
                          <div className="text-right">
                            <Link className="btn btn-primary btn-float waves-effect" onClick={() => this.ModalTaskComment('Add Task Comment', task_data, "insert")}>
                              <i className="fa fa-plus"></i> Add Comment
                            </Link>
                          </div>
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th className="text-center">Date Added</th>
                                <th className="text-center">Comment</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Documents</th>
                                <th className="text-center">Added by</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderTaskHistory(task_info?.commentList)}
                            </tbody>
                          </table>
                        </div>

                      </div>
                      <div className={setTab === "nav-logtime" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-logtime" role="tabpanel" aria-labelledby="nav-logtime-tab">


                        <div className="card mt-3">
                          <div className="card-body mt-3">
                            <div className="text-right">
                              {/* <Link className="btn btn-primary btn-float waves-effect" onClick={() => this.ModalTaskTimeLog('Add Time Log', task_info._id)}>
                                <i className="fa fa-plus"></i> Add Time Log
                            </Link> */}
                            </div>
                            <table className="table table-responsive">
                              <thead>
                                <tr>
                                  <th className="text-center">Date Added</th>
                                  <th className="text-center">Note</th>
                                  <th className="text-center">User</th>
                                  <th className="text-center">Added by</th>
                                  {/* <th className="text-left">Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.renderTaskTimeLogs(task_info.timeList)}
                              </tbody>
                            </table>
                          </div>

                        </div>

                      </div>
                      <div className={setTab === "nav-upload" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-upload" role="tabpanel" aria-labelledby="nav-upload-tab">
                        <div className="card mt-3">
                          <div className="card-body mt-3">
                            <div className="text-right">
                              <Link className="btn btn-primary btn-float waves-effect" onClick={() => this.ModalTaskDocuments('Add Task Documents', task_data, "insert")}>
                                <i className="fa fa-plus"></i> Add Documents
                            </Link>
                            </div>
                            <table className="table table-responsive">
                              <thead>
                                <tr>

                                  <th className="text-center">Image</th>
                                  <th className="text-center">Name</th>
                                  <th className="text-center">Added by</th>
                                  <th className="text-center">Action</th>

                                </tr>
                              </thead>
                              <tbody>

                                {this.renderTaskDodument(task_info.taskDetails.attachment, modalIsOpen)}


                              </tbody>
                            </table>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>




              </form>
            </div>
          </div>
        </div>
      </div >

    );
  }
}


TaskInfoForm = reduxForm({
  form: 'addTaskForm',
  enableReinitialize: true,
})(TaskInfoForm);

function mapStateToProps(state) {

  return {

    task_info: state.teacher.teacher_student_task_info
  }
}

export default connect(mapStateToProps, { getTeacherStudentTaskInfo })(TaskInfoForm);
