import { Field, reduxForm } from 'redux-form';
import { Loading, input } from '../library/elements';
import React, { Component } from 'react';
import { createAlert, normalizeField } from '../../src/library/function';
import { register, validationNull } from '../account/account_action';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

var ReactRotatingText = require('react-rotating-text');

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPasswordText: false,
      isPasswordConfirmText: false,
      isRole: '',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillMount(){
    if(this.props.authenticated){
      this.props.history.push('/dashboard');
    }
  }
  handleFormSubmit(formProps) {
    var _this = this;
    this.setState({ isLoading: true });
    if(formProps !== "" && formProps !== undefined){
      this.props.register(formProps, function (err, result) {
        if (result) {
          _this.props.history.push('/signin');
          _this.setState({ isLoading: false });
        }else{
          _this.setState({ isLoading: false });
        }
      });
    }else{
      createAlert({ message: 'Please Fill requered Details' });
      this.setState({ isLoading: false });
    }
   
   
  }


  renderPasswordMask(getState) {
    if (this.state[getState] === false) {
      this.setState({ [getState]: true });
    } else {
      this.setState({ [getState]: false });
    }
  }
  renderConfirmPasswordMask(getState) {
    if (this.state[getState] === false) {
      this.setState({ [getState]: true });
    } else {
      this.setState({ [getState]: false });
    }
  }
  onchangeRole(role_value) {
    this.setState({ isRole: role_value });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="col-sm-12 pt-3">
          <div className="alert alert-warning">
            <strong>Oops! </strong> {this.props.errorMessage}
          </div>
        </div>

      )
    }
  }
  render() {
    const { handleSubmit } = this.props;
    var { isLoading, isPasswordText, isPasswordConfirmText, isRole } = this.state;

    return (
      <div id="content-container" className="signup">
        {isLoading ? <Loading /> : null}


        <div id="page-login" className="alc-page page mt-5">

          <div className="alc-bg-login">
            <div className="mt-4">
              <div className="row">
                <div className="col-sm-6 hidden-md-down d-none d-md-block">
                  <div className="headings">
                    <h1 className="alc-heading-xxl mt-3-minus alc-c-white">Register Now  <br />  <ReactRotatingText
                      items={['Student', 'Parent', 'Teacher']}
                      pause={1500}
                      emptyPause={1000}
                      typingInterval={100}
                      deletingInterval={100}
                    /></h1>
                    <ul className="list-unstyled mt-4 alc-c-white">
                      <li className="mb-4">
                        <i className="alc-fa alc-icon-box fa fa-check alc-bg-primary mr-2"></i>
                        <span className="alc-font-1-3 ms-2">Next Generation Education System</span>
                      </li>
                      <li className="mb-4">
                        <i className="alc-fa alc-icon-box fa fa-check alc-bg-primary mr-2"></i>
                        <span className="alc-font-1-3 ms-2">Smart Education Management System</span>
                      </li>
                      <li className="mb-4">
                        <i className="alc-fa alc-icon-box fa fa-check alc-bg-primary mr-2"></i>
                        <span className="alc-font-1-3 ms-2">Ready to Change</span>
                      </li>
                      {/* <li className="mb-4">
                        <i className="alc-fa alc-icon-box fa fa-check alc-bg-primary mr-2"></i>
                        <span className="alc-font-1-3 ms-2">FREE Sign Up, Get Service</span>
                      </li> */}

                    </ul>
                    <Link to="/signin" className={"btn alc-btn  btn-primary alc-btn-shadow alc-btn-large"}>
                      Login Now
                  </Link>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card alc-card-form">
                    <div className="card-header"> <h3 className="alc-card-form-title m-0">Sign Up</h3></div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className=" signup-form df-form">
                        {this.renderAlert()}


                        <div className="row form-group mb-3">
                          <div className="col-sm-12">
                            <Field name="role" component={role =>
                              <div>
                                <select className="form-select" aria-label="Default select example"
                                  name="role"
                                  value={role.input.value}
                                  onChange={(event) => {
                                    role.input.onChange(event.target.value);
                                    this.onchangeRole(event.target.value);
                                  }}
                                >
                                  <option value="">-- Select Role --</option>
                                  <option value="parent">Parent</option>
                                  <option value="teacher">Teacher</option>
                                </select>
                                {role.meta.touched && ((role.meta.error && <div className="error text-danger">{role.meta.error}</div>) || (role.meta.warning && <div className="error text-danger">{role.meta.warning}</div>))}
                              </div>
                            } />
                          </div>

                        </div>




                        <div className="form-group row mb-3">
                          <div className="col-sm-12">
                            <Field name="name" type="text" normalize={normalizeField(['trimStart'])} component={input} label="* Name" isBorderError={true} className="form-control" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 form-group mb-3">
                            <Field name="phoneNumber" type="text"normalize={normalizeField(['trim'])} component={input} label="* Phone Number" isBorderError={true} className="form-control" />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <Field name="email" type="text" normalize={normalizeField(['trim'])} component={input} label="* Email" isBorderError={true} className="form-control" />
                          </div>
                          </div>
                          {
                            isRole === 'teacher'
                              ?
                              <div className="row">
                                <div className="col-sm-6  form-group">
                                  <Field name="age" type="text" normalize={normalizeField(['trimStart'])} component={input} label="* Age" isBorderError={true} className="form-control" />
                                </div>
                                <div className="col-sm-6 form-group">
                                  <Field name="school" type="text" normalize={normalizeField(['trimStart'])} component={input} label="* School" isBorderError={true} className="form-control" />
                                </div>
                              </div>
                              :
                              null
                          }

                         
                         



                        <div className=" row mb-3">
                          <div className="col-sm-6 form-group">
                            <div className="input-group">
                              {
                                isPasswordText
                                  ?
                                  <Field name="password" type="text" normalize={normalizeField(['trim'])} component={input} label="* Password" className="form-control" />
                                  :
                                  <Field name="password" type="password" normalize={normalizeField(['trim'])} component={input} label="* Password" className="form-control" />
                              }
                              <button
                                type="button"
                                className="btn btn-sm btn-light password-mask shadow-none"
                                onClick={() => this.renderPasswordMask('isPasswordText')}
                              >
                                {isPasswordText ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}

                              </button>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="input-group">
                              {
                                isPasswordConfirmText
                                  ?
                                  <Field name="confirm_password" normalize={normalizeField(['trim'])} type="text" component={input} label="* Confirm Password" className="form-control" />
                                  :
                                  <Field name="confirm_password" normalize={normalizeField(['trim'])} type="password" component={input} label="* Confirm Password" className="form-control" />
                              }
                              <button
                                type="button"
                                className="btn btn-sm btn-light password-mask shadow-none"
                                onClick={() => this.renderConfirmPasswordMask('isPasswordConfirmText')}
                              >
                                {isPasswordText ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                              </button>
                            </div>
                          </div>
                        </div>



                        <div className="card-footer bg-white p-0 pt-3 ">


                          <div className="col-sm-12 text-center">
                            <button action="register" type="submit" className="btn alc-btn w-100 alc-btn-action alc-btn-large">Sign Up</button>
                          </div>
                          
                          <div className="col-sm-12 text-center mt-2">
                            <Link className="" to="/signin"><label>Already Sign Up? Login</label></Link>
                          </div>
                          <div className="col-sm-12 text-center mt-2">
                            <label> Education is Nation`s Strength,We Stand By it  </label>
                          </div>



                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

function validate(formProps) {
  const errors = {}
  if (!formProps.name) {
    errors.name = 'Required Name';
  }
  if (!formProps.age) {
    errors.age = 'Required Age';
  } else {
    if (isNaN(formProps.age) || formProps.age.indexOf(" ") !== -1) {
      errors.age = 'Age must be numeric only!';
    }
  }
  if (!formProps.school) {
    errors.school = 'Required School';
  }
  if (!formProps.child_name) {
    errors.child_name = 'Required Child Name';
  }
  if (!formProps.role) {
    errors.role = 'Required Role';
  }
  if (!formProps.email) {
    errors.email = 'Required Email'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
    errors.email = 'Invalid Email Address'
  }
  if (!formProps.phoneNumber) {
    errors.phoneNumber = 'Required Phone Number';
  } else {
    if (isNaN(formProps.phoneNumber) || formProps.phoneNumber.indexOf(" ") !== -1) {
      errors.phoneNumber = 'Phone Number number must be numeric only!';
    } else if (formProps.phoneNumber.length > 10) {
      errors.phoneNumber = 'Phone Number number must be up to 10 digit!';
    }
  }
  if (!formProps.password) {
    errors.password = 'Required Password'
  } else if (formProps.password.length < 6) {
    errors.password = 'Password must more than 6 characters'
  }
  if (!formProps.confirm_password) {
    errors.confirm_password = 'Required Confirm Password'
  }
  if (formProps.password !== formProps.confirm_password) {
    errors.confirm_password = 'Confirm must match to Password'
  }

  return errors
}

function mapStateToProps(state) {
  return {
    authenticated: state.account.authenticated,
    errorMessage: state.account.error,
    teacher_profile: state.account.teacher_profile,
  }
}

Signup = reduxForm({
  form: 'signup',
  validate: validate
})(Signup);

export default connect(mapStateToProps, { register, validationNull })(Signup);
