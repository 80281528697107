import React, { Component } from 'react';

import $ from 'jquery';
import HeaderMobile from './mobile_header';
import { Link } from 'react-router-dom';
import UpdateProfile from '../src/teacher/modal/modal_edit_profile';
import avatar from './images/avatar.webp';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import logo from './images/logo.png';
import { logout } from '../src/account/account_action';
import { modal } from '../src/library/modal';

class CommonHeader extends Component {
  toggleSideMenu() {
    $('.navbar-collapse').removeClass("show");
  }
  signOut() {
    cookie.remove("is_login");
    this.props.logout();
  }
  addEditProfileModal() {
    modal.add(UpdateProfile, {
      title: "Edit Profile",
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      modalId: 'edit-profile',
    });
  }
  renderCustomerAccountLinks() {
    if (cookie.load('teacher')) {
      return [
        <li className="dropdown haccount-dropdown" key="header.customer.li">
          <Link title="My Account" className="drp-profile" data-toggle="dropdown">
            <img src={cookie.load('login_photo') != undefined ? cookie.load('login_photo') : 'static/media/avatar.23a5f093.webp'} className="img-thumbnail" alt="image" />
          </Link>
          <ul className="dropdown-menu">
            <li className="dropdown-item"><Link to="/dashboard"> Dashboard</Link></li>
            <li className="dropdown-item"><Link to="/teacher_profile"> Profile</Link></li>
            {/* <li className="dropdown-item"><Link to="/student_mgt">  Students</Link></li>
            <li className="dropdown-item"><Link to="/batch_view"> Batch</Link></li>
            <li className="dropdown-item"><Link to="/event_mgt"> Event</Link></li>
            <li className="dropdown-item"><Link to="/batch_mgt"> Assessment</Link></li>
            <li className="dropdown-item"><Link to="/reinforcement"> ReInforcement</Link></li>
            <li className="dropdown-item"><Link to="/content_mgt"> Content</Link></li> */}
            <li className="dropdown-item"><Link to="/signin" onClick={() => this.signOut()}> Logout</Link></li>
          </ul>
        </li>
      ]
    } else if (cookie.load('parent')) {
      //console.log("===>",cookie.load('login_photo'));
      return [
        <li className="dropdown haccount-dropdown" key="header.customer.li">
          <Link title="My Account" className="drp-profile" data-toggle="dropdown">
            <img src={cookie.load('login_photo') != undefined? cookie.load('login_photo') : 'static/media/avatar.23a5f093.webp'} className="img-thumbnail" alt="image" />
          </Link>
          <ul className="dropdown-menu">
            <li className="dropdown-item"><Link to="/parent_dashboard"> Dashboard</Link></li>
            <li className="dropdown-item"><Link to="/parent_profile">Profile</Link></li>
            {/* <li className="dropdown-item"><Link to="/parent/student_mgt"> Students</Link></li>
            <li className="dropdown-item"><Link to="/parent/batch_view"> WorkSpace</Link></li>
            <li className="dropdown-item"><Link to="/parent/coulege_list">Teachers</Link></li> */}
            <li className="dropdown-item"><Link to="/signin" onClick={() => this.signOut()}> Logout</Link></li>
          </ul>
        </li>
      ]
    } else if (cookie.load('student')) {
      return [
        <li className="dropdown haccount-dropdown" key="header.customer.li">
          <Link title="My Account" className="drp-profile" data-toggle="dropdown">
            <img src={cookie.load('login_photo') != undefined ? cookie.load('login_photo') : 'static/media/avatar.23a5f093.webp'} className="img-thumbnail" alt="image" />
          </Link>
          <ul className="dropdown-menu">
            <li className="dropdown-item"><Link to="/student_dashboard"> Dashboard</Link></li>
            <li className="dropdown-item"><Link to="/student_profile"> Profile</Link></li>
            {/* <li className="dropdown-item"><Link to="/student/assessment_mgt"> Batch</Link></li>
            <li className="dropdown-item"><Link to="/student/goal_mgt"> Goals</Link></li> */}
            {/* <li className="dropdown-item"><Link to="/student/goal_mgt"> Goal</Link></li>
            <li className="dropdown-item"><Link to="/student/task_mgt">Task</Link></li>
            <li className="dropdown-item"><Link to="/student/batch_mgt"> Batch</Link></li>*/}
            <li className="dropdown-item"><Link to="/signin" onClick={() => this.signOut()}>Logout</Link></li>
          </ul>
        </li>
      ]
    }
    else {
      return (
        <li className="alc-nav-item ml-4"><Link to="/signin" className="btn alc-btn btn-primary alc-btn-action alc-btn-medium">Login</Link></li>
      )
    }
  }
  render() {
    const { authenticated, authenticated_student, authenticated_parent } = this.props;
    
    const queryParams = new URLSearchParams(window.location.search);
    const audioUrl = queryParams.get('audioUrl');
    let audio_params = "edu-header alc-bg-light";
    if(audioUrl && audioUrl != "" && audioUrl !== undefined){
      audio_params = "edu-header alc-bg-light audioscreen";
    }
    
    return (

      <header className={audio_params} id="header-main">
        <nav className="navbar navbar-expand-lg d-none d-xl-block">
          <div className="container-fluid">

            <Link to={authenticated ? "/dashboard" : "/"} className="navbar-brand logo-image">
              <img src={logo} /> {/* <span className="logo-text"> Learning3ly</span> */}
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="mobile-navigation">
              <ul className="navbar-nav">
                {
                  (!authenticated && !authenticated_parent && !authenticated_student)
                    ?
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/">Home</Link>
                    </li>
                    :
                    null
                }
                {
                  (authenticated)
                    ?
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/">Dashboard</Link>
                    </li>
                    :
                    null
                }
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/about">About</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/blogs">Resource library</Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/pricing">Pricing</Link>
                </li>
                
                {
                  (!authenticated && !authenticated_parent && !authenticated_student)
                    ?
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/contact">Contact Us</Link>
                    </li>
                    :
                    null
                }

                


              </ul>
            </div>
            <div className="collapse navbar-collapse justify-content-end" id="mobile-navigation">
              <ul className="navbar-nav ml-4">

                <div className="float-right">
                  <div className="top-links nav pull-right">
                    <ul>
                    {
                  cookie.load('teacher') && <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="/dashboard">Welcome {cookie.load('login_user')}</Link>
                  </li>
                }
                {
                  cookie.load('parent') && <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="/parent_dashboard">Welcome {cookie.load('login_user')} </Link>
                  </li>
                }
                {
                  cookie.load('student') && <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="/student_dashboard">Welcome {cookie.load('login_user')}</Link>
                  </li>
                }
                    </ul>
                    <ul className="list-inline pull-right mb-0 mt-2">
                      {this.renderCustomerAccountLinks()}
                    </ul>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </nav>
        <HeaderMobile {...this.props} />
      </header>


    );
  }

}

function mapStateToProps(state) {

  return {
    authenticated: state.account.authenticated,
    authenticated_parent: state.account.authenticated_parent,
    authenticated_student: state.account.authenticated_student,
  }
}

export default connect(mapStateToProps, { logout })(CommonHeader);