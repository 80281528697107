import { Editor, InputImage, Loading, input } from '../../library/elements';
import { Field, FieldArray, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { addReinforcement, deleteReinforcementImage, updateReinforcement, validationNull } from '../teacher_action';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class ReinforcementFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  componentDidMount() {
    this.props.validationNull();
  }
  handleFormSubmit(formProps) {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.validationNull();


    const data = new FormData();
    var attachment = formProps.attachment;
    delete formProps.attachment;

    data.append('name', formProps.name);
    data.append('rewards', formProps.rewards);
    data.append('description', formProps.description);
    data.append('isActive', formProps.isActive);


    for (var mi in attachment) {

      if (attachment[mi]['image'] !== undefined) {
        data.append('attachment', attachment[mi]['image'][0]);
      }
    }
    if (this.props.reinforcementData) {
      data.append('reinforcementId', this.props.reinforcementData._id);
      this.props.updateReinforcement(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addReinforcement(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }

  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }

  deleteImage(image_url) {
    this.setState({ isLoading: true });
    var _this = this;
    this.props.deleteReinforcementImage(image_url, this.props.reinforcementData._id, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
        _this.props.removeModal();
      }
    });
  }
  renderimages(reinforcementImages) {
    if (reinforcementImages.length) {
      var images_data = reinforcementImages.map((img, index) => {
        return (
          <div className="col-lg-4 col-sm-6 col-6 mt-2 mb-2 text-center" key={'task-image-' + index}>
            <Link onClick={() => window.open(img, "_blank")}>
              <div className="card alc-card alc-card-fancy alc-hover-fancy mb-4">
                <div className="alc-card-fancy-image">
                  <img src={img} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '100%', height: '12rem' }} />
                </div>
                <div className="card-footer alc-card-fancy-content text-center">
                  <h4 className="card-title alc-card-title"><i className="fa fa-eye"></i></h4>
                </div>
              </div>
            </Link>
            <Link className="text-center btn btn-sm btn-danger btn-delete-action" onClick={() => this.deleteImage(img)}>
              <i className="fa fa-close fa-1x text-white"></i>
            </Link>
          </div>

        );

      });

      return (
        images_data
      );
    }
  }
  render() {
    const { handleSubmit, errorMessage } = this.props;
    var { isLoading } = this.state;

    if (errorMessage) {
      isLoading = false;
    }

    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <ul className="card-stripe-ul">
        {fields.map((item, index) =>

          <li key={"add_image_" + index} className="card-stripe-ul-li">
            <span className="badge badge-danger remove-image"
              onClick={() => fields.remove(index)}
            >
              <i className="fa fa-close"></i>
            </span>
            <div className=""></div>
            <Field name={`${item}.image`} type="text" component={InputImage} label="Image" className="form-control" />
          </li>
        )}
        <li className="fileUpload btn btn-outline-default p-0 add-image" onClick={() => fields.push({})}><i className="fa fa-plus-circle fa-3x"></i></li>
      </ul>
    )
    return (
      <div className="sk-form">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-12">
              <Field name="name" type="text" component={input} label="* Reinforcement Name" className="form-control" />
            </div>
          </div>
          <div className="form-group row d-none">
            <div className="col-sm-12">
              <Field name="rewards" type="text" component={input} label="* Total Rewards" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12">
              <label>Description</label>
              <Field name="description" type="text" component={Editor} className="form-control" />
            </div>
          </div>
          {
            this.props?.reinforcementData?.attachments?.length
              ?
              <div className="card mb-3">
                <div className="card-header">Past Upload  Attchment</div>
                <div className="card-body">
                  <div className="row">
                    {this.renderimages(this.props?.reinforcementData?.attachments)}
                  </div>
                </div>
              </div>
              :
              null
          }
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header  bg-light">
                  <h6 className="mb-0">Upload Attchment</h6>
                </div>
                <div className="card-body card-stripe">
                  <FieldArray name="attachment" component={addMoreImage} />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <Field

                name="isActive"
                component={isActive =>
                  <div>
                    <select className="form-select" aria-label="Default select example"
                      name="isActive"
                      className="form-control"
                      value={isActive.input.value}
                      onChange={(event) => {
                        isActive.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select Status--</option>
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </select>
                    <span>
                      {isActive.meta.touched && ((isActive.meta.error && <div className="error text-danger">{isActive.meta.error}</div>) || (isActive.meta.warning && <div className="error text-danger">{isActive.meta.warning}</div>))}
                    </span>
                  </div>

                } />
            </div>
          </div>


          <div className="row">

            <div className="col-sm-9">
              {
                this.props.reinforcementData
                  ?
                  <button action="form_reinforcement" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Reinforcement'}
                  </button>
                  :
                  <button action="form_reinforcement" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Reinforcement'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.name) {
    errors.name = 'Required Name';
  }
  if (formProps.isActive === undefined) {
    errors.isActive = 'Select Status ';
  } else if (formProps.isActive === '') {
    errors.isActive = 'Select Status';
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  var iniReinforcement = {
    attachment: [0]
  };

  if (ownProps.reinforcementData) {
    iniReinforcement = ownProps.reinforcementData;
    iniReinforcement.attachment = [0];
  }
  return {
    errorMessage: state.teacher.error,
    initialValues: iniReinforcement,

  }
}

ReinforcementFormModal = reduxForm({
  form: 'form_reinforcement',
  validate: validate
})(ReinforcementFormModal);


export default connect(mapStateToProps, { addReinforcement, updateReinforcement, deleteReinforcementImage, validationNull })(ReinforcementFormModal);
