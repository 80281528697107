import {
    CHECK_GOAL_STATUS,
    FETCH_GOAL_STUDENT_INFO,
    FETCH_GOAL_STUDENT_INFO_ASSESSMENT,
    FETCH_STUDENT_ACCOUNT_PROFILE,
    GET_BATCH_LIST,
    GET_GOAL_LIST,
    GET_STUDENTDASHBOARD_LIST,
    GET_TASK_LIST,
    SET_ATTACHMENT,
    SET_ATTACHMENT_ASSESSMENT,
    SET_CURRENT_AUDIO,
    SET_CURRENT_AUDIO_ASSESSMENT,
    GET_STUDENT_USER_RECENT_CHAT_LIST,
    GET_STUDENT_TEACHER_CHAT_LIST,
    VALIDATION_ERROR
} from './student_action';

export default function StudentReducer(state = {}, action) {

    //console.log("Student Reducer Action Type ",action.type,'<<--->>',"Student Reducer Action Payload", action.payload);
    switch (action.type) {
        case VALIDATION_ERROR:
            return { ...state, error: action.payload }
        case FETCH_STUDENT_ACCOUNT_PROFILE:
            return { ...state, student_profile: action.payload.data }
        case GET_TASK_LIST:
            return { ...state, task_list: action.payload.data }
        case GET_GOAL_LIST:
            return { ...state, goal_list: action.payload.data }
        case FETCH_GOAL_STUDENT_INFO:
            return { ...state, goal_student_info: action.payload.data }
        case FETCH_GOAL_STUDENT_INFO_ASSESSMENT:
            return { ...state, goal_student_info: action.payload.data }  
        case GET_BATCH_LIST:
            return { ...state, batch_list: action.payload.data }
        case CHECK_GOAL_STATUS:
            return { ...state, goal_ans_status: action.payload }
        case SET_CURRENT_AUDIO:
            return { ...state, current_audio: action.payload }
        case SET_CURRENT_AUDIO_ASSESSMENT:
            return { ...state, current_audio: action.payload }
        case GET_STUDENT_TEACHER_CHAT_LIST:
            return { ...state, get_student_teacher_chat_list: action.payload }
        case SET_ATTACHMENT:
            return { ...state, set_attchment: action.payload }
        case SET_ATTACHMENT_ASSESSMENT:
            return { ...state, set_attchment: action.payload } 
        case GET_STUDENT_USER_RECENT_CHAT_LIST:
            return { ...state, get_student_recent_chat_list: action.payload.data }         
        case GET_STUDENTDASHBOARD_LIST:
            return { ...state, student_dashboard_list: action.payload.data }        
        default:
            return state;

    }
}