import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Editor, Loading, input } from '../../library/elements';
import { Field, reduxForm, } from 'redux-form';
import React, { Component } from 'react';
import { addContent, deleteContentAttachment, updateContent, validationNull } from '../teacher_action';

import ContentAttachmentModal from './model_content_attachment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { modal } from '../../library/modal';

class ContentFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };

    this.actionViewAudioList = this.actionViewAudioList.bind(this);
    this.actionViewList = this.actionViewList.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
  }
  componentDidMount() {
    var _this = this;
    this.props.validationNull();
  }
  handleFormSubmit(formProps) {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.validationNull();
    if (this.props.contentData) {
      formProps.contentId = this.props.contentData._id;
      this.props.updateContent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addContent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }

  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing { start} to { to} of { total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }
  ContentAttachmentModal(title, contentId, contentAttachmentData) {

    modal.add(ContentAttachmentModal, {
      title: title,
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      contentId: contentId,
      contentAttachmentData: contentAttachmentData,
    });
  }
  deleteAttachment(indexId) {
    this.setState({ isLoading: true });
    var _this = this;
    this.props.deleteContentAttachment(this.props.contentData._id,indexId, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
        _this.props.removeModal();
      }
    });
  }
  actionViewList(cell, row) {
    return (

      <Link onClick={() => window.open(row.img_url, "_blank")}>

        <div className="card alc-card alc-card-fancy alc-hover-fancy">
          <div className="alc-card-fancy-image">


            <img src={row.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '100%', height: '12rem' }} />

          </div>
          <div className="card-footer alc-card-fancy-content text-center">
            <h4 className="card-title alc-card-title"><i className="fa fa-eye"></i></h4>
          </div>
        </div>
      </Link>

    )

  }
  actionViewAudioList(cell, row) {
    return (


      <div className="audio-player-section" style={{width:'130%',overflow:'hidden'}}>
        <div className="audio-player">
          {row.audio_url !== undefined ? (
            <audio controls controlsList="nodownload">
              <source src={row.audio_url} type="audio/ogg" />
              <source src={row.audio_url} type="audio/mpeg" />
            </audio>
          ) : '-'}
        </div>
        </div>
    )

  }

  actionFormatter(cell, row, props) {
    return (
        <div>
          {/* <Link onClick={() => {this.ContentAttachmentModal('Edit Attachment', this.props.contentData._id, row);this.closeModal()}} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link> */}
          <Link onClick={() => this.deleteAttachment(row._id )} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
        </div>
    );
  }

  render() {
    var { handleSubmit, errorMessage} = this.props;
    var { isLoading, sortName, sortOrder} = this.state;

    if (errorMessage) {
          isLoading = false;
    }
    return (
        <div className="sk-form">
          {isLoading ? <Loading /> : null}
          <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
            {this.renderAlert()}
            <div className="form-group row">
              <div className="col-sm-12">
                <Field name="title" type="text" component={input} label="* Title" className="form-control" />
              </div>
            </div>
            <div className="form-group  row">
              <div className="col-sm-12">
                <label> Description</label>
                <Field name="description" type="text" component={Editor} className="form-control" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-6">
                <Field name="category" type="text" component={input} label="* Category" className="form-control" />
              </div>
              <div className="col-sm-6">
                <Field

                  name="isActive"
                  component={isActive =>
                    <div>
                      <select
                        name="isActive"
                        className="form-control"
                        value={isActive.input.value}
                        onChange={(event) => {
                          isActive.input.onChange(event.target.value);
                        }}
                      >
                        <option value="">--Select--</option>
                        <option value="true">Active</option>
                        <option value="false">InActive</option>
                      </select>
                      <span>
                        {isActive.meta.touched && ((isActive.meta.error && <div className="error text-danger">{isActive.meta.error}</div>) || (isActive.meta.warning && <div className="error text-danger">{isActive.meta.warning}</div>))}
                      </span>
                    </div>

                  } />
              </div>
            </div>
            <div className="row">

              <div className="col-sm-9">
                {
                  this.props.contentData
                    ?
                    <button action="form_content" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                      <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Content'}
                    </button>
                    :
                    <button action="form_content" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                      <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Content'}
                    </button>
                }
              </div>
              <div className="col-sm-3">
                <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
              </div>
            </div>
          </form >
          <hr />
          {
            this.props.contentData
              ?
              <div className="page" id="model-attachment">
                <div className="card  table-responsive mb-4 mt-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title"><i className="fa fa-bag mr-1"></i>Content Attachment List</h5>
                    <button onClick={() => { this.ContentAttachmentModal('Add Content Attachment', this.props.contentData._id, '');this.closeModal()}} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New Attachment</b></button>
                  </div>
                  <BootstrapTable
                    keyField='_id'
                    data={(this.props.contentData.attachment != undefined && this.props.contentData.attachment.length ? this.props.contentData.attachment : [])}
                    bordered={false}
                    striped={false}
                    pagination={false}
                    fetchInfo={{ dataTotalSize: this.props.contentData.attachment.length }}
                    options={{
                      sizePerPage: 50,
                      page: this.state.currentPage,
                      hideSizePerPage: true,
                      sortName: sortName,
                      sortOrder: sortOrder,
                      onSortChange: this.onSortChange
                    }}
                  >
                    <TableHeaderColumn
                      dataField='title'
                      columnClassName={''}
                      dataAlign="left">
                      Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='studentName'
                      columnClassName={''}
                      dataAlign="left"
                      dataFormat={this.actionViewList}>
                      Image
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='studentName'
                      columnClassName={''}
                      dataAlign="left"
                      dataFormat={this.actionViewAudioList}
                    >
                      Audio
                    </TableHeaderColumn>

                    <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                  </BootstrapTable>
                </div>


              </div>

              :
              null
          }


        </div >
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.title) {
          errors.title = 'Required Content Title';
  }
  if (!formProps.category) {
          errors.category = 'Required  Category';
  }
  if (formProps.isActive === undefined) {
          errors.isActive = 'Select Status ';
  } else if (formProps.isActive === '') {
          errors.isActive = 'Select Status';
  }
  return errors
}

function mapStateToProps(state, ownProps) {

  if (ownProps.contentData) {
    var iniContent = ownProps.contentData;
    iniContent.attachment = ownProps.contentData.image;
  }
  return {
          errorMessage: state.account.error,
    initialValues: iniContent,
  }
}

ContentFormModal = reduxForm({
          form: 'form_content',
  validate: validate
})(ContentFormModal);


export default connect(mapStateToProps, { addContent, updateContent,deleteContentAttachment, validationNull})(ContentFormModal);
