import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DeshHeader from './desh_header';

class GoalPauseInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  loadProductTab(tab) {
    this.setState({ setTab: tab });
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }


  render() {

    const { } = this.props;
    const { } = this.state;

    return (
      <div className="page material-ui" id="page-batch-info">

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content p-3">
              <div className="card">

                <div className="card-header h5 bg-white">
                  <span className="text-primary">Goal Detail Info : Color Picker</span>
                  <div className="pull-right"><Link to={"/student/play/1"} className="btn  btn-primary ml-2">Timer : 0:00:00</Link>

                  </div>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="nav-tabContent">

                    <div className="card bg-light">
                      <div className="card-body mt-3">
                        <div className="table-responsive">

                        </div>

                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {

  return {

  }
}

export default connect(mapStateToProps, {})(GoalPauseInfo);
