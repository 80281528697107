import { Editor, Loading, input } from '../../library/elements';
import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { addEvent, getStudents, updateEvent, validationNull } from '../teacher_action';

import DatePicker from 'react-date-picker';
import Select from 'react-select';
import _ from 'lodash';
import { connect } from 'react-redux';

class EventFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPasswordText: false,
      isPasswordConfirmText: false,
      multi: true,
      multiValue: [],
      mloptions: [],
      mlvalue: undefined,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    var _this = this;
    this.props.validationNull();
    this.props.getStudents(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }


  handleFormSubmit(formProps) {
    
    this.setState({ isLoading: true });
    var _this = this;

    if (this.props.eventData) {
      formProps.eventId = this.props.eventData._id;
      this.props.updateEvent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addEvent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }
  closeModal() {
    this.props.removeModal();
  }


  /* close product option */
  handleOnChange(value) {
    const { multi } = this.state;
    if (multi) {
      this.setState({ multiValue: value });
    } else {
      this.setState({ value });
    }
  }


  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }
  render() {
    const { handleSubmit, errorMessage, student_list } = this.props;

    var { isLoading, isPasswordText, isPasswordConfirmText, mloptions, multi, multiValue, mlvalue } = this.state;

    if (errorMessage) {
      isLoading = false;
    }
    if (student_list == undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }


         {/* student  dropdown*/ }
    var studentListData = [];
    for (var i in student_list) {
      studentListData.push({ label: student_list[i].firstName, value: student_list[i].userId });
    }                                                  

    return (
      <div className="sk-form">
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-6">
              <Field name="eventName" type="text" component={input} label="Event Name" is_label={true} isRequired={true} className="form-control" />
            </div>
            <div className="col-sm-6">
              <label className="control-label required"> Event Date </label>
              <Field name={`eventDate`} component={eventDate => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      time={true}
                      name="eventDate"
                      format={"MM/dd/y"}
                      value={!eventDate.input.value ? new Date() : (eventDate.input.value != '0000-00-00' ? new Date(eventDate.input.value) : null)}
                      onChange={(date) => {
                        eventDate.input.onChange(date);
                      }}
                    />
                    <span>

                      {eventDate.meta.touched && ((eventDate.meta.error && <div className="error text-danger">{eventDate.meta.error}</div>) || (eventDate.meta.warning && <div className="error text-danger">{eventDate.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
        
          </div>

          </div>
         
          <div className="form-group row">
            <div className="col-sm-6">
              <Field name="startTime" type="text" component={input} label="Start Time" is_label={true} isRequired={true} className="form-control" />
            </div>
            <div className="col-sm-6">
              <Field name="endTime" type="text" component={input} label="End Time" is_label={true} isRequired={true} className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label className="control-label required">Participants</label>
              <Field name="students" component={students => {
                return (
                  <>
                    <Select multi simpleValue
                      value={students.input.value}
                      placeholder="Select Students"
                      options={studentListData}
                      onChange={(e) => {
                        students.input.onChange(e);

                      }} />
                    <span>
                      {students.meta.touched && ((students.meta.error && <div className="error text-danger">{students.meta.error}</div>) || (students.meta.warning && <div className="error text-danger">{students.meta.warning}</div>))}
                    </span>
                  </>
                )

              }} className="form-control" />
            </div>
            </div>
          <div className="form-group  row">
            <div className="col-sm-12">
              <label> Description</label>
              <Field name="description" type="text" component={Editor} className="form-control" />
            </div>
          </div>
          <div className="form-group row">

            <div className="col-sm-6 d-none">
              <label className="form-lable required">isReminder</label>
              <Field

                name="isReminder"
                component={isReminder =>
                  <div>
                    <select
                      name="isReminder"
                      className="form-control"
                      value={isReminder.input.value}
                      onChange={(event) => {
                        isReminder.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                    {isReminder.meta.touched && ((isReminder.meta.error && <div className="error text-danger">{isReminder.meta.error}</div>) || (isReminder.meta.warning && <div className="error text-danger">{isReminder.meta.warning}</div>))}
                  </div>
                } />
            </div>
          </div>

          <div className="row">

            <div className="col-sm-9">
              {
                this.props.eventData
                  ?
                  <button action="form_student" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Event'}
                  </button>
                  :
                  <button action="form_student" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Event'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {
  const errors = {}

  if (!formProps.eventName) {
    errors.eventName = 'Required Event Name';
  }
  if (!formProps.startTime) {
    errors.startTime = 'Required Start Time';
  }
  if (!formProps.endTime) {
    errors.endTime = 'Required End Time';
  }
  if (!formProps.eventDate) {
    errors.eventDate = 'Required Event Date';
  }
  if (formProps.students === undefined) {
    errors.students = 'Select ';
  } else if (formProps.students === '') {
    errors.students = 'Select';
  }
  // if (formProps.isReminder === undefined) {
  //   errors.isReminder = 'Select';
  // } else if (formProps.isReminder === '') {
  //   errors.isReminder = 'Select';
  // }
  return errors
}

function mapStateToProps(state, ownProps) {


  var iniEvent = {};
  if (ownProps.eventData) {
    var iniEvent = ownProps.eventData;
  }
  return {
    errorMessage: state.account.error,
    initialValues: iniEvent,
    student_list: state.teacher.student_list,
  }
}

EventFormModal = reduxForm({
  form: 'form_student',
  validate: validate
})(EventFormModal);


export default connect(mapStateToProps, { addEvent, updateEvent, getStudents, validationNull })(EventFormModal);
