import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import GoalFormModal from './modal/model_goal';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ModalGoalDelete from './modal/delete/modal_goal_delete';
import ModalViewCommentFiles from './modal/modal_goal_attachment';
import { connect } from 'react-redux';
import { getBatchList } from './teacher_action';
import { modal } from '../library/modal';
import moment from "moment";

class AssassessmentList extends Component {

  constructor(props) {

    super(props);

    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };

    this.actionViewList = this.actionViewList.bind(this);
    this.actionWordsViewList = this.actionWordsViewList.bind(this);

    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }

  componentDidMount() {
    // var _this = this;
    // this.setState({ isLoading: true });
    // this.props.getBatchList(null, function (err, result) {
    //   if (result) {
    //     console.log("Goallss Results",result);
    //     _this.setState({ isLoading: false });
    //   }
    // });
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  GoalFormModal(title, goalData) {
    modal.add(GoalFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      goalData: goalData,
      studentId: this.props.student_id,
    });
  }

  addDeleteConfirmationModal(filter) {
    modal.add(ModalGoalDelete, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
    });
  }

  ViewContentAttachment(title, documents, type) {
    modal.add(ModalViewCommentFiles, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      documents: documents,
      type: type,
    });
  }

  actionViewList(cell, row) {
    return (
      <div>
        {
          row.image !== undefined && row.image.length > 0
            ?
            <Link onClick={() => this.ViewContentAttachment('View Goal Attachment', row.image, 'image')} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-eye mr-1"></i>Goal Attchment</Link>
            :
            <span className="text-center"> - </span>
        }
      </div>
    )
  }
  actionWordsViewList(cell, row) {
    return (
      row.words !== undefined && row.words.length > 0 ?
        <Link onClick={() => this.ViewContentAttachment('View Words', row.words, 'word')} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-eye mr-1"></i>Words Attchment</Link>
        :
        <span className="text-center"> - </span>
    )
  }

  actionFormatter(cell, row, props) {
    return (
      <div>
        <Link onClick={() => this.GoalFormModal('Edit Goal - ' + row.title, row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'goal', id: row._id, student_id: this.props.student_id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
      </div>
    );
  }
  statusFormat(cell, row) {
    if (row.isActive === "active") {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else if (row.isActive === "mastered") {
      return (
        <div className="badge bg-warning ">Mastered</div>
      );
    } else if (row.isActive === "discontinued") {
      return (
        <div className="badge bg-danger ">Discontinued</div>
      );
    }
  }

  // submit status 
  SubmitStatusFormat(cell, row) {
    if (row.studentGoalStatus !== undefined) {
      return (
        <Link className="badge bg-success">{row.studentGoalStatus}</Link>
      )
    }
    else {
      return (
        <div className="badge bg-warning ">Not Completed</div>
      )
    }
  }

  
  timeFormatter(cell, row) {
    return (
        <span className="text-secondary">
          {row.startTime} - {row.endTime}
        </span>     
    );
  }

  dateFormat(cell, row){
    return (
      <span className="text-secondary">
        {moment(row.batchDate).format('MM/DD/yyyy')}
      </span>
    )
  }

  URLFormatter(cell, row) {
    return (
        <span className="text-secondary">
          {row.name}
        </span>
    );
  }
  render() {
    const { batch_list } = this.props;
    //console.log("Assessment Lists ===>",batch_list);
    const { isLoading, sortName, sortOrder } = this.state;

    if (!batch_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }


    const Status = {
      active: 'Active',

    };

    const SubmitStatus = {
      "completed": 'completed',
    };

    var ass_list = [
      { 'title': "Grammer test", 'subject': "English", 'isActive': "Active" },
    ]

    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="card  table-responsive mb-4">
          <div className="card-header d-flex justify-content-between">
            <h5 className="mt-2 font border-bottom font-weight-bold list-title">
              <i className="fa fa-bag mr-1"></i>Assessment List
            </h5>
          </div>
          <div className="card-body">
            <BootstrapTable className="alca-data-table"
              keyField='_id'
              data={batch_list}
              bordered={false}
              striped={false}
              pagination={true}
              fetchInfo={{ dataTotalSize: batch_list.length }}
              options={{
                sizePerPage: this.state.sizePerPage,
                page: this.state.currentPage,
                hideSizePerPage: true,
                paginationShowsTotal: this.renderPaginationShowsTotal,
                sortName: sortName,
                sortOrder: sortOrder,
                onSortChange: this.onSortChange
              }}
            >
              <TableHeaderColumn
                dataField='name'
                columnClassName={''}
                filter={{ type: 'TextFilter' }}
                dataAlign="left"
                dataFormat={this.URLFormatter}
                dataSort={true}>
                Assessment Name
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField='subjectId'
                columnClassName={''}
                filter={{ type: 'TextFilter' }}
                dataAlign="left"
                dataSort={true}>
                Subject
              </TableHeaderColumn>

              <TableHeaderColumn dataField='status' dataAlign="center" dataSort>Status</TableHeaderColumn>

              <TableHeaderColumn dataField='batchDate' dataAlign="center" dataFormat={this.dateFormat} dataSort>Date</TableHeaderColumn>
              
              <TableHeaderColumn
                dataField='studentName'
                columnClassName={''}
                dataAlign="left"
                dataFormat={this.timeFormatter}
                >
                Timing
              </TableHeaderColumn>

            </BootstrapTable>
          </div>
        </div>

      </div>


    );

  }
}

function mapStateToProps(state) {
  //console.log("State Teacher ===> ",state.teacher);
  return {
    batch_list: state.teacher.batch_list,

  }
}
export default connect(mapStateToProps, {getBatchList})(AssassessmentList);
