import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Editor, InputImage } from '../../library/elements';
import { addTaskComment, validationNull } from '../parent_action';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Carousel, { Modal as PopupModel, ModalGateway } from 'react-images'

class ModalTaskComment extends Component {

  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);

    this.state = { isLoading: false,    currentImage: false, modalIsOpen: false };
  }

  componentWillMount() {
    var _this = this;
    this.props.validationNull();
  }

  handleFormSubmit(formProps) {

    var _this = this;
    this.setState({ isLoading: true });
    const data = new FormData();
    var attachment = formProps.attachment;
    delete formProps.attachment;

    data.append('taskId', this.props.commentData._id);
    data.append('comment', formProps.comment);

    for (var mi in attachment) {
      if (attachment[mi]['image'] !== undefined) {
        data.append('attachment', attachment[mi]['image'][0]);
      }
    }

    this.props.addTaskComment(data, this.props.commentData._id, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
        _this.props.removeModal();
      }
    });
  }

  closeModal() {
    this.props.removeModal();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }

  renderChangeImage(popupImg){
    this.setState({ currentImage: popupImg })
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
  }
  LightBoxLargeImage(thumb,modalIsOpen) {


    return (
      <div className="card shadow-none product-main-image-container border-0">
        <ModalGateway>
          {modalIsOpen ? (
            <PopupModel onClose={this.toggleModal}>
              <Carousel views={thumb} />
            </PopupModel>
          ) : null}
        </ModalGateway>
        <ul className="product-main-image list-unstyled mb-0  m-auto">
          <li>
            <Link className=""  onClick={() => this.setState({ modalIsOpen: true })}>
              <img
                className="img-fluid img-thumbnail"
                src={thumb}
      
              />
            </Link>
          </li>
        </ul>
      </div>
    );

  }
  renderimages(productimages,modalIsOpen){
    const images = [];
    if(productimages.length) {
      var images_data = productimages.map((image, index) => {
              console.log("----image",image);
          return(

            <li className={"image-additional-card list-group-item border-0 pl-0 col-2"} key={"additional.image."+index}>
                  <Link onClick={() => this.LightBoxLargeImage((image,modalIsOpen )) }  >
                  <img className="img-fluid img-thumbnail"  style={{"height":"80px"}}  src={image}  />
                  <div className="text-center">{image.name}</div>
                </Link>
            </li>
          );
         
      });

      return (
      
             
                images_data


      );
    }
    } 
  renderAddmoreImageCard() {
    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <ul className="card-stripe-ul  m-0">
        {fields.map((item, index) =>
          <li key={"add_image_" + index} className="card-stripe-ul-li">
            <span className="badge badge-danger remove-image"
              onClick={() => fields.remove(index)}
            >
              <i className="fa fa-close"></i>
            </span>
            <Field name={`${item}.image`} type="text" component={InputImage} size="sm" label="Image" className="form-control" />
          </li>
        )}
        <li className="card-stripe-ul-li add_image card" onClick={() => fields.push({})}><i className="fa fa-plus-circle fa-3x"></i></li>
      </ul>
    )
    return (
      <div className="card-stripe">
        <FieldArray name={"attachment"} component={addMoreImage} />
      </div>
    );
  }
  addDefaultSrc(ev) {
    return ev.target.src = 'https://dummyimage.com/169x102/ebebeb/000.png&text=Learning3ly';
  }
  render() {

    const { handleSubmit, errorMessage } = this.props;
    var { isLoading ,modalIsOpen } = this.state;

    if (errorMessage) {
      isLoading = false;
    }
    return (
      <div className='card'>
        <div className='card-body'>
          <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
            {this.renderAlert()}

            <div className="row">
              <div className="form-group col-sm-12">
                <label> Comment</label>
                <Field name="comment" type="text" component={Editor} className="form-control" />
              </div>
            </div>
           

           
              
              {
                this.props?.commentData?.files?.length
                  ?
                  <div className="card mb-3">
                    <div className="card-header">Past Upload Documents Image</div>
                    <div className="card-body">
                      <div className="row">
                          {this.renderimages(this.props?.commentData?.files,modalIsOpen)}
                      </div>
                    </div>
                    </div>
                  :
                  null
              }

            <div className="card">

              <div className="card-header">Add Image</div>

              <div className="card-body">
                <div className="row">
                  <div className="form-group col-sm-12">
                    {this.renderAddmoreImageCard()}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-10">
                {
                  this.props.opration === 'update'
                    ?
                    <button action="taskHistoryForm" className="btn btn-primary btn-block" disabled={isLoading}>
                      <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : ' Comment Update'}
                    </button>
                    :
                    <button action="taskHistoryForm" className="btn btn-primary btn-block" disabled={isLoading}>
                      <i className="fa fa-plus"></i> {isLoading ? 'Loading...' : ' Comment Add'}
                    </button>
                }

              </div>
              <div className="col-sm-2">
                <button onClick={() => this.closeModal()} type="button" className="btn btn-danger btn-block">Cancel</button>
              </div>
            </div>


          </form>
        </div>
      </div>
    );
  }
}

function validate(formProps) {
  const errors = {}

  if (!formProps.task_status_id) {
    errors.task_status_id = 'Required Task Status';
  }

  return errors;
}

ModalTaskComment = reduxForm({
  form: 'taskHistoryForm',
  enableReinitialize: true,
  validate
})(ModalTaskComment);

function mapStateToProps(state, ownProps) {
  var initCommentData = {};

  if (ownProps.commentData !== undefined) {
    initCommentData = ownProps.commentData;
    initCommentData.attachment = ownProps.commentData.files;
  } else {
    initCommentData.comment = "";
  }
  return {
    initialValues: initCommentData,
    errorMessage: state.teacher.error,
  }
}

export default connect(mapStateToProps, { addTaskComment, validationNull })(ModalTaskComment);
