import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { input, textarea } from '../library/elements';
import { normalizeField } from '../library/function';
import { sendContactMail } from './support_action';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isError: ''
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    componentDidMount() {
        if (this.props.authenticated === true || this.props.authenticated_student === true || this.props.authenticated_parent === true) {
            this.props.history.push('/dashboard');
        }
    }
    handleFormSubmit(formProps) {

        this.setState({ isLoading: true });
        this.setState({ isError: '' });
        var _this = this;
        this.props.sendContactMail(formProps, function (err, result) {
            if (!err && result.err) {
                _this.setState({ isLoading: false });
                _this.setState({ isError: result.err });
            } else if (err && !result) {

                _this.setState({ isLoading: false });
                _this.setState({ isError: err });
            } else {
                _this.setState({ isLoading: false });
                _this.setState({ isError: '' });
                _this.props.reset('contact');
            }
        });
    }

    render() {
        const { handleSubmit, authenticated, authenticated_student, authenticated_parent, teacher_profile } = this.props;
        let { isLoading, isError } = this.state;
        var google_map_address = "Pennsylvania";
        var map_address = google_map_address.replace(/\s+/g, "%20");

        return (
            <div className="page" id="page-contact">
                <div className="page-contact-container">
                    <div className="container">
                        <div className="page-heading mt-7">
                            <div className="col-sm-12">
                                <h1 className="">Contacts</h1>
                                <p className="">Get in touch with by completing the below form or call us now</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="card mb-3  input-form">
                                    <div className="card-body">
                                        {isError
                                            ?
                                            <div className="alert alert-danger">
                                                <strong>Error!</strong> {isError}
                                            </div>
                                            :
                                            ''
                                        }
                                        <form className="m-t-1" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                            <fieldset className="form-group">
                                                <Field name="name" type="text" normalize={normalizeField(['trimStart'])} component={input} label="Name" isRequired={true} is_label={true} />
                                            </fieldset>
                                            <fieldset className="form-group">
                                                <Field name="email" type="text" normalize={normalizeField(['trim'])} component={input} label="E-Mail" isRequired={true} is_label={true} />
                                            </fieldset>
                                            <fieldset className="form-group">
                                                <Field name="phoneNumber" type="text" normalize={normalizeField(['trim'])} component={input} label="Mobile / Phone" isRequired={true} is_label={true} />
                                            </fieldset>
                                            <fieldset className="form-group">
                                                <Field name="description" row="" normalize={normalizeField(['trimStart'])} component={textarea} label="Write Your Message Here" isRequired={true} is_label={true} />
                                            </fieldset>
                                            <div className="card-footerr">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <button
                                                            className="btn btn-primary btn-block mt-3"
                                                            type="submit"
                                                            action="contact"
                                                            disabled={isLoading}>
                                                            {isLoading ? 'Loading...' : 'Send'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <h2 className="h4 pb-3">Contact details</h2>
                                <h3 className="h6 pb-2 font-weight-bold">Pennsylvania, US</h3>
                                <ul className="list-unstyled fs-sm">
                                    <li className="d-flex align-items-top mb-3">
                                        <i className="fa fa-building-o fs-xl text-muted mt-1 mr-2 pe-1"></i>
                                        <div>
                                        Pennsylvania, US

                                        </div>
                                        <br />
                                        <a className="fancy-link ml-2" href="#map" data-scroll="">See on the map</a>
                                    </li>
                                    <li className="d-flex align-items-center mb-3"><i className="fa fa-envelope fs-xl text-muted mr-2 pe-1"></i>
                                        <Link className="text-muted" to={"mailto:" + 'info@Learning3ly.com' + "?subject=Still Have Question?"} target="_blank">{'info@learning3ly.com'}</Link>
                                    </li>
                                    <li className="d-flex align-items-center"><i className="fa fa-phone fs-xl text-muted mr-2 pe-1"></i>
                                        <div><a className="text-muted" href={"tel:" + '+1 724 553 4517'}>{'+1 724 553 4517'}</a></div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="google-map-container mt-3 container-fluid p-0" id="map">
                    <div className="google-amp-inner">
                        {/*https://developers.google.com/maps/documentation/embed/start*/}
                        <iframe id="gmap_canvas" src={"https://maps.google.com/maps?q=" + map_address + "&t=&z=11&ie=UTF8&iwloc=&output=embed"} allowFullScreen frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                        </iframe>
                    </div>
                </div>

            </div>
        );
    }
}

function validate(formProps) {
    const errors = {}
    if (!formProps.name) {
        errors.name = 'Required Name!'
    }

    if (!formProps.email) {
        errors.email = 'Required Email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
        errors.email = 'Invalid Email Address'
    }

    if (!formProps.phoneNumber) {
        errors.phoneNumber = 'Required Phone number';
    } else {
        if (isNaN(formProps.phoneNumber)) {
            errors.phoneNumber = ' Phone number must be numeric only!';
        }
    }

    if (!formProps.description) {
        errors.description = 'Required Description'
    }

    return errors;
}

Contact = reduxForm({
    form: 'contact',
    validate: validate
})(Contact);

function mapStateToProps(state) {
    return {
        authenticated: state.account.authenticated,
        authenticated_parent: state.account.authenticated_parent,
        authenticated_student: state.account.authenticated_student,
        teacher_profile: state.account.teacher_profile,
    }
}

export default connect(mapStateToProps, { sendContactMail })(Contact);
