import React, { Component } from 'react';

import Footer from './footer';
import Header from './header';
import ReduxModal from '../src/library/modal';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { getProfile as getParentProfile } from '../src/parent/parent_action';
import { getProfile as getStudentProfile } from '../src/student/student_action';
import { getProfile as getTeacherProfile } from '../src/teacher/teacher_action';
import rootRoute from './system/route';
import { withRouter } from "react-router";

class CommonApp extends Component {

  constructor(props, context) {
    super(props, context);
  }



  componentDidMount(){
    
  }


  render() {
    const { authenticated,authenticated_parent,authenticated_student } = this.props;
  

      return (
        <div>
          <div id="pageMessages"></div>
          <Header/>
            { rootRoute }
          <Footer/>
          <ReduxModal />
        </div>
      );
    
  }

}

function mapStateToProps(state) {
  return {
    authenticated: state.account.authenticated,
    authenticated_parent: state.account.authenticated_parent,
    authenticated_student: state.account.authenticated_student,
    
  }
}
export default withRouter(connect(mapStateToProps, { getTeacherProfile,getParentProfile,getStudentProfile })(
  frontloadConnect(async (props) => {
    if(props.authenticated) {
      await props.getTeacherProfile()
    }
    if(props.authenticated_parent) {
      await props.getParentProfile()
    }
    if(props.authenticated_student) {
      await props.getStudentProfile()
    }
  })(CommonApp)
));
