import React, { Component } from 'react';
import { getStudents, getTeacherSubjects } from '../teacher_action';

import { Loading } from '../../library/elements';
import { connect } from 'react-redux';
import moment from "moment";
import { reduxForm } from 'redux-form';
import { toHtml } from '../../library/function';
import { validationNull } from '../../account/account_action';
var dateFormat = require("dateformat");
class BatchInformationModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.validationNull();
    this.props.getTeacherSubjects();
    this.props.getStudents(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  handleFormSubmit(formProps) {

  }

 
  closeModal() {
    this.props.removeModal();
  }


  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }
  handleChange(e) {
  }
  statusFormat(status) {
    if (status == 'Pending') {
      return (
        <div className="badge bg-primary">Pending</div>
      );
    } else if (status == 'Ongoing') {
      return (
        <div className="badge bg-warning"> Ongoing</div>
      );
    } else if (status == 'Completed') {
      return (
        <div className="badge bg-success"> Completed</div>
      );
    }
  }
  renderStudents(students) {
    if (students != undefined && students != null && students != "") {
      return students.map((student) => {
        return (
          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12" key={"checkout.student." + student.username}>

            <li className="list-group-item border-bottom rounded  mb-2 d-flex bg-light justify-content-between align-items-center">
              {student.firstName} {student.lastName}
              <span className="badge bg-primary rounded-pill">{student.grade}</span>
            </li>
          </div>
        );
      });
    } else {
      return (
        <div className="col-sm-12 text-center p-1">
          Currently No Students add in this Work Workspace
        </div>
      );
    }
  }
  render() {
    const { handleSubmit, subject_list, student_list, errorMessage, batchInfo } = this.props;
    var { isLoading } = this.state;

    if (errorMessage) {
      isLoading = false;
    }

    if (student_list == undefined || subject_list == undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }


    var subject_name = "";
    for (var i in subject_list) {
      if (subject_list[i]._id == batchInfo.subjectId) {
        subject_name = subject_list[i].name
      }
    }
    var batchStatusData = [];
    batchStatusData.push(
      { label: 'Pending', value: 'Pending' },
      { label: 'Ongoing', value: 'Ongoing' },
      { label: 'Completed', value: 'Completed' },
    );
    return (
      <div className="sk-form">
        {isLoading ? <Loading /> : null}
        {this.renderAlert()}
        <div className='card  mb-3'>
          <div className="card-header bg-light">Workspace Basic Information</div>

          <div className="table-responsive">
            <table className="table mb-0">
              <tbody>

                <tr>
                  <td className="first border-top-0 col-6">Workspace Name</td>
                  <td className="second border-top-0 col-6">: {batchInfo?.name}</td>
                </tr>
                <tr>
                  <td className="first">Subject</td>
                  <td className="second ">: {subject_name}</td>
                </tr>
                <tr>
                  <td className="first">Workspace Status</td>
                  <td className="second ">: {this.statusFormat(batchInfo?.status)}</td>
                </tr>
                <tr>
                  <td className="first">Workspace Date</td>
                  <td className="second "> <span className="">
                    : <i className="fa fa-calendar mr-1"></i>
                    
                      {moment(batchInfo?.batchDate).format('MM/DD/yyyy')}
                  </span></td>
                </tr>
                <tr>
                  <td className="first">Workspace Start time - End Time</td>
                  <td className="second "> <span className="">
                    : <i className="fa fa-clock-o mr-1"></i>
                    {batchInfo?.startTime}      -      <i className="fa fa-clock-o  "></i> {batchInfo?.endTime}
                  </span></td>
                </tr>

              </tbody>
            </table>

            <div className='mb-3'>
              <div className="card-header bg-light">Description</div>
              <div className=" p-3">{toHtml(batchInfo?.description)}</div>
            </div>
            <div className="card-header bg-light">Student List</div>
            <div className="card-body">
              <div className="row">
                {this.renderStudents(batchInfo?.studentList)}
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}


function mapStateToProps(state, ownProps) {
  var iniBatch = {};
  if (ownProps.batchData) {
    var iniBatch = ownProps.batchData;
    var studentData = [];
    for (var i in iniBatch.studentList) {
      studentData.push(iniBatch.studentList[i].userId);
    }
    var student_merge = studentData.join(',');
    iniBatch.child = student_merge;
  }
  return {
    errorMessage: state.account.error,
    batchInfo: iniBatch,
    subject_list: state.teacher.teacher_subject_list,
    student_list: state.teacher.student_list,

  }
}

BatchInformationModal = reduxForm({
  form: 'form_batch',
})(BatchInformationModal);


export default connect(mapStateToProps, { getTeacherSubjects, getStudents, validationNull })(BatchInformationModal);
