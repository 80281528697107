import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Editor } from '../../library/elements';
import DateTimePicker from 'react-datetime-picker';
import { Field, reduxForm } from 'redux-form';
import { addTaskTimelog, validationNull } from '../parent_action';

class ModalTaskTimeLog extends Component {

  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);

    this.state = { isLoading: false, modify_permission: false };
  }

  componentWillMount() {
    var _this = this;
    this.props.validationNull();
  }

  handleFormSubmit(formProps) {
    var _this = this;
    formProps.taskId = this.props.logData._id;
    this.setState({ isLoading: true });
  
    this.props.addTaskTimelog(formProps, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
        _this.props.removeModal();
      }
    });
  }

  closeModal() {
    this.props.removeModal();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }


  render() {

    const { handleSubmit ,errorMessage} = this.props;

    var { isLoading } = this.state;


    if (errorMessage) {
      isLoading = false;
    }
    return (
      <div className='card'>
        <div className='card-body'>
          <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
            {this.renderAlert()}

            <div className="form-group row">
              <div className="col-sm-6">
                <label className="control-label">Log Date and Time</label>
                <Field name={`dateTime`} component={dateTime => {
                  return (
                    <div className="dt-picker">
                      <DateTimePicker
                        name="dateTime"
                        value={!dateTime.input.value ? new Date() : (dateTime.input.value != '0000-00-00' ? new Date(dateTime.input.value) : null)}
                        onChange={(date) => {
                          dateTime.input.onChange(date);
                        }}
                      />
                      <span>

                        {dateTime.meta.touched && ((dateTime.meta.error && <div className="error text-danger">{dateTime.meta.error}</div>) || (dateTime.meta.warning && <div className="error text-danger">{dateTime.meta.warning}</div>))}
                      </span>

                    </div>
                  )
                }} className="form-control" />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <label> Description</label>
                <Field name="description" type="text" component={Editor} className="form-control" />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10">
                <button action="taskTimeForm" className="btn btn-primary btn-block" disabled={isLoading}>
                  <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Add Task Time'}
                </button>
              </div>
              <div className="col-sm-2">
                <button onClick={() => this.closeModal()} type="button" className="btn btn-danger btn-block">Cancel</button>
              </div>
            </div>


          </form>
        </div>
      </div>
    );
  }
}

function validate(formProps) {
  const errors = {}

  if (formProps.dateTime === undefined) {
    errors.dateTime = 'Select Log Date & Time';
  } else if (formProps.dateTime === '') {
    errors.dateTime = 'Select Log Date & Time';
  }

  return errors;
}

ModalTaskTimeLog = reduxForm({
  form: 'taskTimeForm',
  enableReinitialize: true,
  validate
})(ModalTaskTimeLog);

function mapStateToProps(state, ownProps) {
  var initTimeLog = {};
 
  if (ownProps.logData !== undefined) {
    initTimeLog = ownProps.logData;
  }else{
    initTimeLog.description = "";
  }
  return {
    initialValues: initTimeLog,
    errorMessage: state.teacher.error,
  }
}

export default connect(mapStateToProps, { addTaskTimelog, validationNull })(ModalTaskTimeLog);
