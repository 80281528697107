import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import EventFormModal from './modal/model_event';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ModalEventDelete from './modal/delete/modal_event_delete';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { getEventList } from './teacher_action';
import { modal } from '../library/modal';

var dateFormat = require("dateformat");
class Eventlist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };

    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }
  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getEventList(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
    if(!cookie.load('is_login')){
      window.location.href = '/signin';
    }
  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  EventModel(title, eventData) {
    modal.add(EventFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      eventData: eventData,
    });
  }

  addDeleteConfirmationModal(filter) {
    modal.add(ModalEventDelete, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }
  actionFormatter(cell, row, props) {
    return (
      <div>
        <Link onClick={() => this.EventModel('Edit Event - ' + row.eventName, row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'event', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
      </div>
    );
  }


  BatchDateFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.eventDate, "mm/dd/yyyy")}</span>


    );
  }

  durationFormat(cell, row) {
      return (
        <div className="badge bg-success ">{row.startTime} : {row.endTime}</div>
      );
  }

  statusFormat(cell, row) {
    if (row.isReminder === true) {
      return (
        <div className="badge bg-success ">Yes</div>
      );
    } else {
      return (
        <div className="badge bg-warning ">No</div>
      );
    }
  }
  render() {
    const { event_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!event_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      true: 'Yes',
      false: 'No',
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {/* {isLoading ? <Loading /> : null} */}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  alca-card table-responsive mb-4 alc-table">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font font-weight-bold">
                      <i className="fa fa-bag mr-1"></i>Event List
                    </h5>
                    <button onClick={() => this.EventModel('Add New', '')} className="text-primary mt-2 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button>
                    {/* <button onClick={() => this.EventModel('Add Event', '')} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New Event</b></button> */}
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                      keyField='_id'
                      data={(event_list != undefined && event_list.length ? event_list : [])}
                      bordered={false}
                      responsive={true}
                      striped={false}
                      pagination={true}
                      fetchInfo={{ dataTotalSize: event_list.length }}
                      options={{
                        sizePerPage: this.state.sizePerPage,
                        page: this.state.currentPage,
                        hideSizePerPage: true,
                        paginationShowsTotal: this.renderPaginationShowsTotal,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSortChange: this.onSortChange
                      }}
                    >
                      <TableHeaderColumn
                        dataField='eventName'
                        columnClassName={''}
                        filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataSort
                        width='40%'
                        dataSort={true}>
                        Event Name
                      </TableHeaderColumn>



                      <TableHeaderColumn
                        dataField='date'
                        columnClassName={''}
                        dataSort width='15%'
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}

                        dataFormat={this.BatchDateFormat}
                        dataSort={true}>
                        Event Date
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='startTime'
                        columnClassName={''}
                        filter={{ type: 'TextFilter' }}
                        dataAlign="left"
                        dataSort
                        width='100px'
                        dataFormat={this.durationFormat}
                        dataSort={true}>
                        Duration
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField='createdBy'
                        columnClassName={''}
                        dataSort width='100px'
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        dataFormat={this.BatchDateFormat}
                        dataSort={true}>
                        Created On
                      </TableHeaderColumn>

                      {/* <TableHeaderColumn width='150px' dataField='isReminder' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Status</TableHeaderColumn> */}
                      
                      <TableHeaderColumn width='10%' dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                      
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );

  }
}

function mapStateToProps(state) {
  return {
    event_list: state.teacher.event_list,

  }
}
export default connect(mapStateToProps, { getEventList })(Eventlist);
