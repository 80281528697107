import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

class DeshHeader extends Component {

  constructor(props) {
    super(props);

  }
  render() {
    const { } = this.props;

    const currentLink = window.location.pathname;

    return (
      <div className="top-bar d-none d-xl-block" >
        <div className="sidebar">
          <div className="wrapper ">
            <div className="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">
              <div className="logo">
              </div>
              <div className="sidebar-wrapper dashboard">
                <ul className="nav">
                  <li className={"nav-item " + (currentLink == "/student_dashboard" ? "active" : null)}>
                    <Link to="/student_dashboard" className={"nav-link"} >
                      <i className="fa fa-desktop"></i>
                      Dashboard
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/student/assessment_mgt" ? "active" : null)}>
                    <Link to="/student/assessment_mgt" className={"nav-link"} >
                      <i className="fa fa-briefcase"></i>
                      My Assessments
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/student/goal_mgt" ? "active" : null)}>
                    <Link to="/student/goal_mgt" className={"nav-link"} >
                      <i className="fa fa-certificate"></i>
                      My Goal
                    </Link>
                  </li>
                  <li className={"nav-item " + (currentLink == "/student/communication" ? "active" : null)}>
                    <Link to="/student/communication" className={"nav-link"} >
                      <i className="fa fa-comments-o"></i>
                      Communication
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/student_dashboard" className={"nav-link"} >
                        {cookie.load('student_id')}
                      </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

function mapStateToProps(state) {
  return {


  }
}
export default connect(mapStateToProps, {})(DeshHeader);


