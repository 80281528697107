import React, { Component } from 'react';

class textarea extends Component {


  render() {
    //console.log(this.props);
    const { input, label, is_label,isRequired, meta } = this.props;

    var reqClassName = '';
    if(isRequired){
      reqClassName = 'required';
    }
    
    var inputLabel = '';
    if(is_label) {
      inputLabel = <label className={"control-label "+reqClassName}>{label}</label>;
    }
    return (
      <div>
        {inputLabel}
        <textarea {...input} placeholder={!is_label ? label : null } className="form-control" rows="5" />
        {meta.touched && ((meta.error && <span className="error text-danger">{meta.error}</span>) || (meta.warning && <span className="error text-danger">{meta.warning}</span>))}
      </div>
    )
  }
}

export default textarea;
