import React, { Component } from 'react';

import { Loading } from '../library/elements';
import UpdateProfile from './modal/modal_edit_profile';
import { connect } from 'react-redux';
import { modal } from '../library/modal';

class TeacherProfile extends Component {

  addEditProfileModal() {
    modal.add(UpdateProfile, {
      title: "Edit Teacher Profile",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      modalId: 'edit-profile',
    });
  }

  render() {
    const { teacher_profile } = this.props;
    //console.log("Tearcher Profile ===>",teacher_profile);
    if (!teacher_profile) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-1">
              <Loading />
            </div>
            <div className="col-sm-5"></div>
          </div>
        </div>
      );
    }


    return (
      <div className="top-profile-container">
        <div className="container mt-3">
          <div className="row">
            <div className="col-sm-3">
            </div>
            <div className="col-sm-6">
              <div className="card card-parent-card">

                <div className="card-body pt-4">
                  <div className="title-block text-center">
                    {/* <img className="img-thumbnail rounded-circle profile-image" src="https://learning3ly.com/static/media/logo.edade388.png" width="100px" /> */}
                    <img className="img-thumbnail rounded-circle profile-image" src={teacher_profile?.photo != '' ? teacher_profile?.photo : "https://learning3ly.com/static/media/logo.edade388.png"} width="100px" />
                    <h4 className="mt-2">
                      {teacher_profile?.name}
                      <button onClick={() => this.addEditProfileModal()} className="btn btn-outline-primary round btn-outline-edit-profile ml-2 rounded-circle"><i className="fa fa-pencil"></i></button>
                    </h4>
                  </div>
                  <hr />
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <tbody>

                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6 border-top-0">Teacher Name</td>
                          <td className="second border-top-0 col-6">:  {teacher_profile?.name}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Location</td>
                          <td className="second border-top-0 col-6">:  {teacher_profile?.address}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Email</td>
                          <td className="second border-top-0 col-6">:{teacher_profile?.email}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Contact Phone</td>
                          <td className="second border-top-0 col-6">:   {teacher_profile?.phoneNumber}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary h6 "> Subject list</td>
                          <td className="second "> :
                          {
                              teacher_profile.subjectList && teacher_profile.subjectList.map((subject) => {
                                return (
                                  <span className="ml-1 badge bg-primary mr-1">{subject.name}</span>
                                )
                              })
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">

            </div>
          </div>

        </div>
      </div>

    )
  }
}

function mapStateToProps(state) {
  return {
    teacher_profile:  state.teacher.teacher_profile,
  };
}

export default connect(mapStateToProps)(TeacherProfile);
