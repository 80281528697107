import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import moment from "moment";
import { parentDashboardData } from './parent_action';

class AccountDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {

    // let accessData = 'Granted';
    // var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
    // if(endDate == 'Invalid date'){
    //   accessData = 'NoAccess';
    // }
    // if(moment().isAfter(endDate, 'days')) {
    //   accessData = 'NoAccess';
    // }
    // if(accessData == 'NoAccess'){
    //   this.props.history.push('/pricing');
    // } else {
      var _this = this;
      this.setState({ isLoading: true });
      this.props.parentDashboardData(function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    //}
    
  }

  renderStudentsList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        return (
          <tr key={'event_' + data._id}>
            <td className="text-left"><Link to={'/parent/student_info/'+data.userId}>{data.firstName}</Link></td>
            <td className="text-left">{data.lastName}</td>
            <td className="text-left">{data.username}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderUpcomingEvents(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        return (
          <tr key={'event_' + data._id}>
            <td className="text-left"><Link to={'/parent/batch_view/'}>{data.eventName}</Link></td>
            <td className="text-left">{moment(data.eventDate).format("MM/DD/yyyy")}</td>
            <td className="text-left">{data.startTime} - {data.endTime}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderAssessmentList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        let pendingClassName = "text-left badge bg-warning text-white p-1";
        if (data.status == 'Pending') {
          pendingClassName = "text-left badge bg-success text-white p-1";
        }
        return (
          <tr key={'assessment_' + data._id}>
            <td className="text-left"><Link to={'/parent/batch_view/'}>{data.name}</Link></td>
            <td className="text-left"><label className={pendingClassName}>{data.status}</label></td>
            <td className="text-left">{moment(data.dateAttachmentIntroduce).format('MM/DD/yyyy')}</td>
            <td className="text-left">{moment(data.batchDate).format('MM/DD/yyyy')}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  render() {

    const { parent_dashboard_list } = this.props;
    if (parent_dashboard_list && parent_dashboard_list.length) {
      this.setState({ isLoading: false });
    }
    if (!parent_dashboard_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    
    return (
      <div className="account-bottom-container">
        <div className="page" id="page-teacher-dashboard">
          <div className="main-panel container-fluid">
            <div className="content mt-4">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <h4 class="text-primary">Parent Dashboard</h4>
                  <hr class="text-primary" />
                </div>
              </div>
              <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <h6 class="text-left text-warning">Upcoming Events </h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left text-primary">Event</th>
                              <th className="text-left text-primary">Date</th>
                              <th className="text-left text-primary">Duration</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isLoading == false && this.renderUpcomingEvents(parent_dashboard_list.parentsEvents)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <h6 class="text-left text-warning">Recent Registered Students </h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left text-primary">First Name</th>
                              <th className="text-left text-primary">Last Name</th>
                              <th className="text-left text-primary">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isLoading == false && this.renderStudentsList(parent_dashboard_list.recentStudents)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <h6 class="text-left text-warning">Assessments </h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left text-primary">Name</th>
                              <th className="text-left text-primary">Status</th>
                              <th className="text-left text-primary">Assigned</th>
                              <th className="text-left text-primary">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isLoading == false && this.renderAssessmentList(parent_dashboard_list.parentAssessments)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } f
}



function mapStateToProps(state) {
  return {
    parent_dashboard_list: state.parent.parent_dashboard_list
  }
}
export default connect(mapStateToProps, { parentDashboardData })(AccountDashboard);