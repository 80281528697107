import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import DeshHeader from './desh_header';
import cookie from 'react-cookie';
const RouteAccount = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      cookie.load('parent') ? (
        <div id="account-home">
          <DeshHeader {...props} />
          <Component {...props} />
        </div>
      ) : (
        <Redirect to={`/signin`} />
      )
    }
  />
);

function mapStateToProps(state) {
  return { authenticated_parent: state.account.authenticated_parent };
}

export default connect(mapStateToProps)(RouteAccount);
