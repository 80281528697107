import React, { Component } from 'react';

import { Loading } from '../library/elements';
import UpdateProfile from '../parent/modal/modal_edit_profile';
import { connect } from 'react-redux';
import { modal } from '../library/modal';
import moment from "moment";

class ParentProfile extends Component {

  addEditProfileModal() {
    modal.add(UpdateProfile, {
      title: "Edit Parent  Profile",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      modalId: 'edit-profile',
    });
  }

  render() {
    const { parent_profile } = this.props;
    //console.log("=======>",parent_profile);

    if (!parent_profile) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-1">
              <Loading />
            </div>
            <div className="col-sm-5"></div>
          </div>
        </div>
      );
    }
    return (
      <div className="top-profile-container">
        <div className="container mt-3">
          <div className="row">
            <div className="col-sm-3">
            </div>
            <div className="col-sm-6">
              <div className="card card-parent-card">

                <div className="card-body pt-4">
                  <div className="title-block text-center">
                    {parent_profile.photo}
                    
                    <img className="img-thumbnail rounded-circle profile-image" src={parent_profile?._id && parent_profile?._id.photo ? parent_profile?._id.photo : "https://learning3ly.com/static/media/logo.edade388.png"} width="100px" />
                    <h4 className="mt-2">
                      {parent_profile?._id?.name}
                      
                      <button onClick={() => this.addEditProfileModal()} className="btn btn-outline-primary round btn-outline-edit-profile ml-2 rounded-circle"><i className="fa fa-pencil"></i></button>
                    </h4>
                    <br />
                      {
                        parent_profile?._id?.packageName != null && parent_profile?._id?.packageName != "" && <span className='text-danger'>Your Current Plan {parent_profile?._id?.packageName} expires on {moment(parent_profile?._id.packageEndDate).format('MM/DD/yyyy')}</span>
                      }
                  </div>
                  <hr />
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <tbody>

                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6 border-top-0">Parent Name</td>
                          <td className="second border-top-0 col-6">:  {parent_profile?._id?.name}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Age</td>
                          <td className="second border-top-0 col-6">: {parent_profile?._id?.age}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Email</td>
                          <td className="second border-top-0 col-6">: {parent_profile?._id?.email}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Location</td>
                          <td className="second border-top-0 col-6">: {parent_profile?._id?.address}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Role</td>
                          <td className="second border-top-0 col-6">: {parent_profile?._id?.role}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Contact Phone</td>
                          <td className="second border-top-0 col-6">:   {parent_profile?._id?.phoneNumber}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary h6 ">Student list</td>
                          <td className="second "> :
                            {
                              parent_profile?.studentList?.map((student) => {
                                return (
                                  <span className="ml-1 badge bg-primary mr-1">{student.firstName}</span>
                                )
                              })
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  //console.log("Parent Profile",state.parent);
  return {
    parent_profile: state.parent.parent_profile,
  };
}

export default connect(mapStateToProps)(ParentProfile);
