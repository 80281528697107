import { Loading, textarea } from '../library/elements';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAdminContentInfo } from './teacher_action';
import { toHtml } from '../library/function';

var dateFormat = require('dateformat');
class AdminContentInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setTab: 'nav-upload',
    };
  }

  componentDidMount() {
    var _this = this;
    var content_id = this.props.match.params.content_id;
    if (content_id !== undefined && content_id !== 'add') {
      this.setState({ isLoading: true });
      this.props.getAdminContentInfo(content_id, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    }
  }

  renderimages(Contentimages) {
    if (Contentimages.length) {
      var images_data = Contentimages.map((img, index) => {
        return (
          <tr>
            <td>
              {img.title}
            </td>
            <td>
            <Link onClick={() => window.open(img.img_url, "_blank")}>
            <img src={img.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '30%', height: '30%' }} />
            </Link>
            </td>
            <td>
            <div className="audio-player-section" style={{width:'130%',overflow:'hidden'}}>
              <div className="audio-player">
                {img.audio_url !== undefined ? (
                  <audio controls controlsList="nodownload">
                    <source src={img.audio_url} type="audio/ogg" />
                    <source src={img.audio_url} type="audio/mpeg" />
                  </audio>
                ) : '-'}
              </div>
              </div>
            </td>
          </tr>
        );
      });
      return (images_data);
    }
  }

  render() {

    const { handleSubmit, full_list } = this.props;
    const { isLoading } = this.state;

    if (full_list === undefined) {
      return (
        <div className="task-undefined"><Loading /></div>
      );
    }
    
    return (
      <div className="page material-ui mt-5 mb-5" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content p-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0"> <i className="fa fa-info mr-1" aria-hidden="true"></i> Content Info </h4>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="nav-tabContent">
                    <div className="card bg-light">
                      <div className="card-body mt-3">
                        <div className="table-responsive">
                        <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td className="first  font-weight-bold  h6">Title :</td>
                                <td className="second border-top-0 col-6">{full_list?.title}</td>
                              </tr>
                              {
                                full_list.description != "" && 
                                <tr>
                                  <td className="first  font-weight-bold  h6">Description :</td>
                                  <td className="second border-top-0 col-6">{toHtml(full_list?.description)}</td>
                                </tr>
                              }
                              <tr>
                                <td className="first  font-weight-bold  h6">Created At :</td>
                                <td className="second border-top-0 col-6">{dateFormat(full_list?.createdAt)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="card mt-3">
                        <div className="card-header">
                          <h5 className="mb-0"> Contents </h5>
                        </div>
                        <div className="card-body">
                          <div className=" react-bs-table-container">
                            <table className="table table-responsive">
                              <thead>
                                <tr>
                                  <th className="text-left">Title</th>
                                  <th className="text-center">Image</th>
                                  <th className="text-center">Audio</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.renderimages(full_list.image)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{width:'10%', marginLeft:'5%', marginBottom:'5%',alignItems:'flex-end'}}>
                  <button action="form_goal" onClick={() => {window.location.href='/admincontent_mgt'}} className=" mb-2  mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg">Go Back</button>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    full_list: state.teacher.admin_content_info,
  }
}
export default connect(mapStateToProps, { getAdminContentInfo })(AdminContentInfo);