import React,  { Component } from 'react';


class SignOut extends Component {
  render() {
    return (
      <div id="logout">
          <div className="container mb-5 mt-5">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card-block">
                    <div className="well text-muted text-center cart-empty sk-heading">
                      <i className="fa fa-lock fa-5x mb-3 sk-heading"></i>
                        <h1 className="block-title large">Account SignOut</h1>
                        <div className="description">You have been logged off your account.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
  }
}


export default SignOut
