import { AudioRecorder, InputImage, Loading, input } from '../../library/elements';
import { Field, FieldArray, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { addContentAttachment, updateontentAttachment, validationNull } from '../teacher_action';

import { connect } from 'react-redux';

class ContentAttachmentModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
  }
  componentDidMount() {
   
    var _this = this;
    this.props.validationNull();
  }
  handleFormSubmit(formProps) {

    var _this = this;
    this.setState({ isLoading: true });
    this.props.validationNull();
    const data = new FormData();
    var attachment = formProps.attachment;
    delete formProps.attachment;
    data.append('title', formProps.title ? formProps.title : "");
    data.append('audio', formProps.audio ? formProps.audio : "");
    data.append("is_desktop",1);
    for (var mi in attachment) {
      if (attachment[mi]['image'] !== undefined) {
        data.append('attachment', attachment[mi]['image'][0]);
      }
    }
    data.append('contentId', this.props.contentId);
    if (this.props.contentAttachmentData) {
      data.append('indexId', this.props.contentAttachmentData._id);
      data.append('isAudioUpdate', formProps.isAudioUpdate ? formProps.isAudioUpdate : "");
      data.append('isImageUpdate', formProps.isImageUpdate ? formProps.isImageUpdate : "");
      this.props.updateontentAttachment(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addContentAttachment(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }


  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }
  render() {
    var { handleSubmit, errorMessage } = this.props;
    var { isLoading } = this.state;
    if (errorMessage) {
      isLoading = false;
    }
    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <ul className="card-stripe-ul">
        {fields.map((item, index) =>

          <li key={"add_image_" + index} className="card-stripe-ul-li">
           
            <div className=""></div>
            <Field name={`${item}.image`} type="text" component={InputImage} label="Image" className="form-control" />
          </li>
        )}
      
      </ul>
    )
    return (
      <div className="sk-form card p-2">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-12">
              <Field name="title" type="text" component={input} label="* Title" className="form-control" />
            </div>
          </div>
         
           <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header  bg-light">
                  <h6 className="mb-0">Upload Attchment</h6>
                </div>
                <div className="card-body card-stripe">
                  <FieldArray name="attachment" component={addMoreImage} />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
            <div className="card">
                <div className="card-header  bg-light">
                  <h6 className="mb-0">Upload Recording</h6>
                </div>
                <div className="card-body">
              <Field
                name={'audio'}
                component={audio =>
                  <div>
                    <AudioRecorder
                      showUIAudio
                      audioURL={audio.input.value}
                      value={audio.input.value}
                      handleAudioStop={(event) => {
                        audio.input.onChange(event.audio);
                      }}
                    />
                  </div>
                } />
            </div>
          </div>
          </div>
          </div>
          {
            this.props.contentAttachmentData
              ?
              <div className="form-group row">
                <div className="col-sm-6">
                  <div class="form-check d-flex pl-1">
                    <Field class="form-check-input" name="isImageUpdate" type="checkbox" component={input} id="flexCheckChecked" />
                    <label class="form-check-label" for="flexCheckChecked">
                      Image Update
               </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div class="form-check d-flex pl-1">
                    <Field class="form-check-input" name="isAudioUpdate" type="checkbox" component={input} id="flexCheckChecked" />
                    <label class="form-check-label" for="flexCheckChecked">
                      Audio Update
               </label>
                  </div>
                </div>
              </div>
              :
              null
          }


          <div className="row mt-2">

            <div className="col-sm-9">
              {
                this.props.contentAttachmentData
                  ?
                  <button action="form_content" className="mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Content'}
                  </button>
                  :
                  <button action="form_content" className="mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Content'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form >

      </div >
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.title) {
    errors.title = 'Required Title';
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  var iniContent ={
    attachment: [0]
  }
  
  if (ownProps.contentAttachmentData) {
    var iniContent = ownProps.contentAttachmentData;
    if(ownProps.contentAttachmentData.img_url){
      var array = [];
     array.push(ownProps.contentAttachmentData.img_url);
     iniContent.attachment = array;
    }
   
  }
  return {
    errorMessage: state.account.error,
    initialValues: iniContent,
  }
}

ContentAttachmentModal = reduxForm({
  form: 'form_content_attachment',
  validate: validate
})(ContentAttachmentModal);


export default connect(mapStateToProps, { addContentAttachment, updateontentAttachment, validationNull })(ContentAttachmentModal);
