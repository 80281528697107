import { Field, reduxForm } from 'redux-form';
import { Loading, input } from '../library/elements';
import React, { Component } from 'react';
import { login, validationNull } from '../account/account_action';

import { Link } from 'react-router-dom';
import ModalAccountFrogotPassword from './forgotten';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { modal } from '../library/modal';

var ReactRotatingText = require('react-rotating-text');

class PlanSuccessMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: ''
    };
  }
  componentWillMount() {
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    } else if (this.props.authenticated_parent) {
      this.props.history.push('/parent_dashboard');
    } else if (this.props.authenticated_student) {
      this.props.history.push('/student_dashboard');
    }
  }

  componentDidMount() {
    document.getElementById('footer').style.display = 'none';
    document.getElementById('header-main').style.display="none";
  }
  
  render() {
    //const { handleSubmit, errorMessage, teacher_profile } = this.props;
    var { isLoading } = this.state;
    return (
      <div className="page" id="page-login">
        { isLoading ? <Loading /> : null}
        <div id="page-plan-success" className="alc-page page mt-5">
            <div className='planSuccess'>
                <div className='label-success'>Your plan successfully purchased !!!! Please do Logout and Login to your mobile application for appearing new features.</div>
            </div>
        </div>
      </div>


    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.account.authenticated,
    authenticated_parent: state.account.authenticated_parent,
    authenticated_student: state.account.authenticated_student,
    errorMessage: state.account.error,
    teacher_profile: state.account.teacher_profile,
  }
}

export default connect(mapStateToProps, { })(PlanSuccessMobile);
