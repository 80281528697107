import { Route, Switch } from 'react-router-dom';

import About from '../../src/support/about';
import AdminBatchList from '../../src/teacher/all_batch_list';
import AdminContentInfo from '../../src/teacher/all_content_info';
import AdminContentList from '../../src/teacher/all_content_list';
import AdminEventList from '../../src/teacher/all_events_list';
import AdminGoalsList from '../../src/teacher/all_goals_list';
import AdminParentList from '../../src/teacher/all_parents_list';
import AdminReinforcementList from '../../src/teacher/all_reinforcement_list';
import AdminStudentList from '../../src/teacher/all_students_list';
import AdminTeacherList from '../../src/teacher/all_teachers_list';
import AssessmentInfo from '../../src/teacher/assessment_info';
import AudioPlayerData from '../account/audioplayer';
import BatchCalender from '../../src/teacher/batch_calender';
import Batchlist from '../../src/teacher/batch_list';
import BlogInfo from '../../src/blog/blog_info';
// blog
import BlogList from '../../src/blog/blog_list';
import Blogs from '../../src/blog/blog';
import BooksList from '../../src/teacher/hook';
import CategoriesList from '../../src/teacher/all_categories_list';
import CategoryList from '../../src/teacher/category_list';
import Communication from '../../src/parent/communication';
import Contact from '../../src/support/contact';
import ContactusList from '../../src/teacher/all_contactus_list';
// Content
import ContentList from '../../src/teacher/content_list';
import Coulegelist from '../../src/teacher/coulage_list';
// event 
import EventList from '../../src/teacher/event_list';
import GoalForm from '../teacher/goal_form';
import GoalInfo from '../../src/teacher/goal_info'
import GoalList from '../../src/teacher/goal_list';
import Home from '../home';
import Mpricing from '../account/mpricing';
import PackagePurchasedList from '../../src/teacher/all_packagepurchased_list';
import Page404 from '../../src/page_404';
import ParentAccountDashboard from '../parent/dashboard';
import ParentAssessmentInfo from '../../src/parent/assessment_info';
import ParentBatchCalender from '../../src/parent/batch_calender';
import ParentCoulegelist from '../../src/parent/coulage_list';
import ParentGoalInfo from '../../src/parent/goal_info';
import ParentProfile from '../../src/parent/parent_profile';
// Parent 
import ParentRouteAccount from '../parent/authentication';
import ParentStudentInfo from '../../src/parent/student_info';
import ParentStudentList from '../../src/parent/students';
import ParentStudentTasklist from '../../src/parent/student_task_list';
import ParentTaskInfoForm from '../../src/parent/task_info_form';
import PlanSuccessMobile from '../account/plan_success_mobile';
import Pricing from '../../src/blog/pricing';
import Privacy from '../account/privacy';
import React from 'react';
import ReinforcementList from '../teacher/reinforcement_list';
import ResetPassword from '../account/reset_password';
import Resetpass from '../account/resetpass';
import RouteAccount from '../teacher/authentication';
import SignIn from '../account/signin';
import SignOut from '../account/signout';
import Signup from '../account/signup';
import Student from '../../src/teacher/students';
import StudentAccountDashboard from '../student/dashboard';
import StudentAssessmentInfo from '../../src/student/assessment_info';
import StudentAssessmentList from '../../src/student/assessment_list';
import StudentCommunication from '../../src/student/communication';
import StudentGoalInfo from '../../src/student/goal_info';
import StudentGoalList from '../../src/student/goal_list';
import StudentGoalPlayInfo from '../../src/student/goal_play_info';
import StudentInfo from '../../src/teacher/student_info';
// student
import StudentProfile from '../../src/student/student_profile';
import StudentProgress from '../../src/teacher/student_progress';
import StudentRouteAccount from '../student/authentication';
import StudentTaskCalender from '../../src/teacher/student_task_calender';
import StudentTasklist from '../../src/student/task_list';
import SubjectList from '../../src/teacher/all_subjects_list';
import SubscriberList from '../../src/teacher/all_subscribers_list';
import TaskInfoForm from '../../src/teacher/task_info_form';
// teachers
import TeacherAccountDashboard from '../teacher/dashboard';
import TeacherProfile from '../../src/teacher/teacher_profile';
import TeacherStudentProgress from '../../src/teacher/all_teacher_students';
import TeacherStudentTasklist from '../../src/teacher/student_task_list';
import TermsOfService from '../account/termsofservice';

// Goal 

// chat work on 09072021
const rootRoute = (

  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/resetpass" component={Resetpass} />
    <Route exact path="/signin" component={SignIn} />
    <Route path="/audioplayer/" component={AudioPlayerData} />
    <Route exact path="/plan_success_mobile" component={PlanSuccessMobile} />
    <Route exact path="/mpricing" component={Mpricing} />
    <Route exact path="/signout" component={SignOut} />
    <Route exact path="/terms-of-service" component={TermsOfService} />
    <Route exact path="/privacy" component={Privacy} />

    {/* support */}
    <Route exact path="/contact" component={Contact} />
    <Route exact path="/about" component={About} />
    <Route path="/resetpassword/:token" component={ResetPassword} />
    <Route path="/student_info/:student_id" component={StudentInfo} />

    {/* Teacher Module */}
    <RouteAccount exact path="/dashboard" component={TeacherAccountDashboard} />
    <Route exact path="/student_mgt" component={Student} />
    <Route exact path="/student_progress" component={StudentProgress} />
    <Route exact path="/batch_mgt" component={Batchlist} />
    <Route exact path="/batch_view" component={BatchCalender} />
    <Route exact path="/teacher_profile" component={TeacherProfile} />
    <Route exact path="/task_mgt" component={TeacherStudentTasklist} />
    <Route exact path="/task_view" component={StudentTaskCalender} />
    <Route path={"/teacher/task/add"} component={TaskInfoForm} />
    <Route path={'/teacher/task_info/:task_id/:student_id'} component={TaskInfoForm} />
    <Route exact path="/coulege_list" component={Coulegelist} />
    <Route exact path="/content_mgt" component={ContentList} />
    <Route exact path="/goal_mgt" component={GoalList} />
    <Route exact path="/goal_info/:goal_id" component={GoalInfo} />
    <Route exact path="/books" component={BooksList} />
    <Route exact path="/categories" component={CategoryList} />
    <Route exact path="/reinforcement" component={ReinforcementList} />
    <Route exact path="/goal/add" component={GoalForm} />
    <Route exact path="/goal/:goal_id" component={GoalForm} />
    <Route exact path="/assessment_info/:assessment_id" component={AssessmentInfo} />
    {/* event managment */}
    <Route exact path="/event_mgt" component={EventList} />
    <Route exact path="/subject_mgt" component={SubjectList} />
    <Route exact path="/subscriber_mgt" component={SubscriberList} />
    <Route exact path="/admincontact_mgt" component={ContactusList} />
    <Route exact path="/admincategories_mgt" component={CategoriesList} />
    <Route exact path="/admincontent_mgt" component={AdminContentList} />
    <Route path="/all_content_info/:content_id" component={AdminContentInfo} />
    <Route exact path="/adminevent_mgt" component={AdminEventList} />
    <Route exact path="/adminreinforcement_mgt" component={AdminReinforcementList} />
    <Route exact path="/adminpackagepurchased_mgt" component={PackagePurchasedList} />
    <Route exact path="/adminstudent_mgt" component={AdminStudentList} />
    <Route exact path="/adminparent_mgt" component={AdminParentList} />
    <Route exact path="/adminteacher_mgt" component={AdminTeacherList} />
    <Route exact path="/adminbatch_mgt" component={AdminBatchList} />
    <Route exact path="/admingoals_mgt" component={AdminGoalsList} />
    <Route exact path="/teacherstudentprogress_mgt" component={TeacherStudentProgress} />
    
  
    {/* end Teacher Module */}

    {/* Parent Module */}
    <ParentRouteAccount exact path="/parent_dashboard" component={ParentAccountDashboard} />
    <Route exact path="/parent/task_mgt" component={ParentStudentTasklist} />
    <Route path={'/parent/task/:task_id/:student_id'} component={ParentTaskInfoForm} />
    <Route exact path="/parent/student_mgt" component={ParentStudentList} />
    <Route exact path="/parent/batch_view" component={ParentBatchCalender} />
    <Route exact path="/parent_profile" component={ParentProfile} />
    <Route exact path="/parent/coulege_list" component={ParentCoulegelist} />
    <Route exact path="/parent/communication" component={Communication} />
    <Route exact path="/parent/goal_info/:goal_id" component={ParentGoalInfo} />

    <Route path="/parent/student_info/:student_id" component={ParentStudentInfo} />
    <Route exact path="/parent/assessment/assessment_info/:assessment_id" component={ParentAssessmentInfo} />
    {/* end Parent Module */}

    {/* Student list  */}
    <StudentRouteAccount exact path="/student_dashboard" component={StudentAccountDashboard} />
    <Route exact path="/student_profile" component={StudentProfile} />
    <Route exact path="/student/task_mgt" component={StudentTasklist} />
    <Route exact path="/student/goal_mgt" component={StudentGoalList} />
    <Route exact path="/student/communication" component={StudentCommunication} />
    <Route exact path="/student/goal_info/:goal_id" component={StudentGoalInfo} />
    <Route exact path="/student/play/:goal_id" component={StudentGoalPlayInfo} />
    <Route exact path="/student/assessment_mgt" component={StudentAssessmentList} />
    <Route exact path="/student/assessment_info/:assessment_id" component={StudentAssessmentInfo} />
    <Route exact path="/blog_mgt" component={BlogList} />
    <Route exact path="/blogs" component={Blogs} />
    <Route path="/blog/:blog_id" component={BlogInfo} />
    <Route exact path="/pricing" component={Pricing} />
    <Route path="*" component={Page404} />
    
  </Switch>
);

export default rootRoute;