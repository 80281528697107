import { Loading } from '../library/elements';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import DeshHeader from './desh_header';
import { connect } from 'react-redux';
import { Doughnut } from '@reactchartjs/react-chart.js';
import Select from 'react-select';
import {getTeacherStudentList, getStudentChartData} from './teacher_action';
import { input, textarea } from '../library/elements';
import moment from "moment";

class StudentProgress extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
      this.setState({ isLoading: false });
      this.props.getTeacherStudentList(function (err, result) {
        if (result) {
          this.setState({ isLoading: false });
        }
      });
  }
  

  handleFormSubmit(formProps) {
    var _this = this;
    this.props.getStudentChartData(formProps, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  

  render() {
    const { isLoading, student_list, handleSubmit,student_dashboard_list} = this.props;
    //console.log("Student Progress ===>",student_dashboard_list);
    if (student_list == undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    let totalStudentGoals = 0;
    let totalPendingGoals = 0;
    let totalCompletedGoals = 0;
    if(student_dashboard_list && student_dashboard_list.totalStudentGoals && student_dashboard_list.totalStudentGoals.length > 0){
      totalStudentGoals = student_dashboard_list.totalStudentGoals[0].count;
    }
    if(student_dashboard_list && student_dashboard_list.totalPendingGoals && student_dashboard_list.totalPendingGoals.length > 0){
      totalPendingGoals = student_dashboard_list.totalPendingGoals[0].count;
    }
    if(student_dashboard_list && student_dashboard_list.totalCompletedGoals && student_dashboard_list.totalCompletedGoals.length > 0){
      totalCompletedGoals = student_dashboard_list.totalCompletedGoals[0].count;
    }

    const GoalsProgress = {
      labels: ['Total','Completed', 'Pending'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            totalStudentGoals,
            totalCompletedGoals,
            totalPendingGoals
          ],
          backgroundColor: [
            '#0275d8',
            '#5cb85c',
            '#d9534f',
          ],
          borderColor: [
            '#0275d8',
            '#5cb85c',
            '#d9534f',
          ],
          borderWidth: 1,
        },
      ],
    }
    

    let totalCompletedAssessment = 0;
    let totalApprovedAssessment = 0;
    let totalPendingAssessment = 0;
    let totalTeachersPendingApproval = 0;
    
    if(student_dashboard_list && student_dashboard_list.totalCompletedAssessment && student_dashboard_list.totalCompletedAssessment.length > 0){
      totalCompletedAssessment = student_dashboard_list.totalCompletedAssessment[0].count;
    }

    if(student_dashboard_list && student_dashboard_list.totalApprovedAssessment && student_dashboard_list.totalApprovedAssessment.length > 0){
      totalApprovedAssessment = student_dashboard_list.totalApprovedAssessment[0].count;
    }

    if(student_dashboard_list && student_dashboard_list.totalPendingAssessment && student_dashboard_list.totalPendingAssessment.length > 0){
      totalPendingAssessment = student_dashboard_list.totalPendingAssessment[0].count;
    }

    if(student_dashboard_list && student_dashboard_list.totalTeachersPendingApproval && student_dashboard_list.totalTeachersPendingApproval.length > 0){
      totalTeachersPendingApproval = student_dashboard_list.totalTeachersPendingApproval[0].count;
    }

    const AssessmentsProgress = {
      labels: ['Completed','Approved','Pending','Teachers Pending Approval'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            totalCompletedAssessment,
            totalApprovedAssessment,
            totalPendingAssessment,
            totalTeachersPendingApproval
          ],
          backgroundColor: [
            '#5cb85c',
            '#5bc0de',
            '#d9534f',
            '#f0ad4e'
          ],
          borderColor: [
            '#5cb85c',
            '#5bc0de',
            '#d9534f',
            '#f0ad4e'
          ],
          borderWidth: 1,
        },
      ],
    }

    var studentData = [];
    for (var i in student_list.data) {
      studentData.push({ label: student_list.data[i].firstName+''+student_list.data[i].lastName, value: student_list.data[i].userId });
    }
    
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="wrapper">
          <DeshHeader />
          <div className="main-panel">
            <div className="content container mt-4">
              <div className="page" id="page-batch">
                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Student Progress
                     </h5>
                  </div>
                  <div className="card-body" style={{minHeight:300}}>
                    <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                      <div className='row'>
                        <div className='col-md-3'>
                        <Field name="studentId" component={studentId => {
                            return (
                              <Select simpleValue
                                value={studentId.input.value}
                                placeholder=" Select Student "
                                options={studentData}
                                onChange={(e) => {
                                  studentId.input.onChange(e);
                                }} />
                            )
                          }} className="form-control" />
                        </div>
                        {/* <div className='col-md-3'>
                          <Field name="start_date" type="date" component={input} is_label={false} className="form-control" />
                        </div>
                        <div className='col-md-3'>
                          <Field name="end_date" type="date" component={input} is_label={false} className="form-control" />
                        </div> */}
                        <div className='col-md-3'>
                        <button action="student_progress" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg">Submit</button>
                        </div>
                      </div>
                          <hr />
                    </form>


                    {
                      student_dashboard_list && student_dashboard_list !== undefined && student_dashboard_list.totalStudentGoals.length > 0 && 
                      <div className='student-results-wrapper mt-5'>
                        <div className='row mb-4'>
                          <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="card card-stats px-3 bg-danger">
                              <div className="card-header card-header-warning card-header-icon">
                                <div className="dashboard-card-icon dashboard-card-icon-danger">
                                  <i className="fa fa-user text-white"></i>
                                </div>
                                <p className="card-category text-white">Pending Goals</p>
                                <p className="card-title text-white">{student_dashboard_list.totalPendingGoals && student_dashboard_list.totalPendingGoals.length > 0 ? student_dashboard_list.totalPendingGoals[0].count : 0}</p>
                              </div>
                              
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="card card-stats px-3 bg-success">
                              <div className="card-header card-header-success card-header-icon">
                                <div className="dashboard-card-icon dashboard-card-icon-success">
                                <i className="fa fa-user text-white"></i>
                                </div>
                                <p className="card-category text-white">Completed Goals</p>
                                <p className="card-title text-white">{student_dashboard_list.totalCompletedGoals && student_dashboard_list.totalCompletedGoals.length > 0 && student_dashboard_list.totalCompletedGoals[0].count > 0 ? student_dashboard_list.totalCompletedGoals[0].count : 0}</p>
                              </div>
                              
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="card card-stats px-3 bg-danger">
                              <div className="card-header card-header-success card-header-icon">
                                <div className="dashboard-card-icon dashboard-card-icon-danger">
                                <i className="fa fa-user text-white"></i>
                                </div>
                                <p className="card-category text-white">Pending Assessments</p>
                                <p className="card-title text-white">{student_dashboard_list.totalPendingAssessment && student_dashboard_list.totalPendingAssessment.length > 0 ? student_dashboard_list.totalPendingAssessment[0].count : 0}</p>
                              </div>
                              
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="card card-stats px-3 bg-info">
                              <div className="card-header card-header-success card-header-icon">
                                <div className="dashboard-card-icon dashboard-card-icon-info">
                                <i className="fa fa-user text-white"></i>
                                </div>
                                <p className="card-category text-white">Done Assessments</p>
                                <p className="card-title text-white">{student_dashboard_list.totalCompletedAssessment && student_dashboard_list.totalCompletedAssessment.length > 0 ? student_dashboard_list.totalCompletedAssessment[0].count : 0}</p>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          

                        {student_dashboard_list && student_dashboard_list.totalCompletedGoals && student_dashboard_list.totalCompletedGoals.length > 0 && 
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="card card-chart">
                                <div className="ct-chart" id="dailySalesChart"><Doughnut data={GoalsProgress} /></div>
                              <div className="card-body">
                                <p className="card-title mb-0 text-warning text-center">Your Goals Progress</p>
                              </div>
                            </div>
                          </div>
                        }
                          
                        {
                          student_dashboard_list && student_dashboard_list.totalCompletedAssessment && student_dashboard_list.totalCompletedAssessment.length > 0 &&
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <div className="card card-chart">
                                  <div className="ct-chart" id="dailySalesChart">
                                    <Doughnut data={AssessmentsProgress} />
                                    </div>
                                <div className="card-body">
                                  <p className="card-title mb-0 text-warning text-center">Your Assessments Progress</p>
                                </div>
                              </div>
                            </div>
                          }  
                        </div>
                    </div>
                    }
                    
                    
              

              









                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("Chart Data",state.teacher.student_chart_data);
  return {
    student_list: state.teacher.teacher_student_list,
    student_dashboard_list: state.teacher.student_chart_data
  }
}

function validate(formProps) {
  const errors = {}

  if (!formProps.studentId) {
    errors.studentId = 'Required Student ID';
  }
  return errors
}

StudentProgress = reduxForm({
  form: 'student_progress',
  validate: validate
})(StudentProgress);


export default connect(mapStateToProps, { getTeacherStudentList, getStudentChartData })(StudentProgress);