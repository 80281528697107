import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Loading, modalDeleteConfirmation } from '../library/elements';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import StudentFormModal from './modal/model_student';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { getStudents } from './teacher_action';
import { modal } from '../library/modal';
import moment from "moment";
import { setFilters } from '../library/system/action';

class StudentList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
      isActivetab: false
    };
    this.actionFormatter = this.actionFormatter.bind(this);
    this.URLFormatter = this.URLFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.refresh = this.refresh.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }

  componentDidMount() {

    let accessData = 'Granted';
    var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
    if(endDate == 'Invalid date'){
      accessData = 'NoAccess';
    }
    if(moment().isAfter(endDate, 'days')) {
      accessData = 'NoAccess';
    }
    if(accessData == 'NoAccess'){
      this.props.history.push('/pricing');
    }

    this.setState({
      currentPage: 1,
      sortName: undefined,
      sortOrder: undefined,
    })
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getStudents(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  refresh() {
    this.setState({ isLoading: true });
    var _this = this;
    var page = this.state.currentPage;

    this.props.setFilters(
      'students',
      {
        start: ((page - 1) * this.state.sizePerPage),
        limit: this.state.sizePerPage,
      }
    );
    this.props.getStudents(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }


  StudentModel(title, studentData) {
    modal.add(StudentFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      studentData: studentData,
    });
  }
  addDeleteConfirmationModal(filter) {
    modal.add(modalDeleteConfirmation, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }
  actionFormatter(cell, row, props) {


    return (
      <div>
        <Link onClick={() => this.StudentModel('Edit Student - ' + row.firstName, row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'student', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
      </div>
    );
  }
  URLFormatter(cell, row) {

    let url = "/student_info/" + row.userId;
    return (
      <Link to={url}>
        <span className="text-primary">
          {row.firstName + " " + row.lastName}
        </span>
      </Link>

    );
  }
  statusFormat(cell, row) {
    if (row.status === true) {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else {
      return (
        <div className="badge bg-danger ">Inactive</div>
      );
    }
  }
  locationFormat(cell, row) {

    if (row.address !== "undefined") {
      return (
        <div className="btn btn-sm bg-light text-dark">{row.address}</div>
      );
    } else {
      return (
        <span className="=">-</span>
      );
    }

  }
  gradeFormat(cell, row) {
    return (
      <div className="badge bg-danger">{row.grade}</div>
    );
  }

  activetab() {
    this.setState({ isActivetab: !this.state.isActivetab });
  }
  render() {
    const { student_list } = this.props;
    const { isLoading, sortName, sortOrder, isActivetab } = this.state;
    if (student_list === undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    const Status = { true: 'Active', false: 'Inactive' };
    return (

      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content container mt-4">
              <div className="page" id="page-batch">
                <div className="card alca-card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Students
                    </h5>
                    <button onClick={() => this.StudentModel('Add Student', '')} className="text-primary mt-3 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button>
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                      keyField='_id'
                      data={(student_list != undefined && student_list.length ? student_list : [])}
                      bordered={false}
                      striped={false}
                      pagination={true}
                      fetchInfo={{ dataTotalSize: student_list.length }}
                      options={{
                        sizePerPage: this.state.sizePerPage,
                        page: this.state.currentPage,
                        hideSizePerPage: true,
                        paginationShowsTotal: this.renderPaginationShowsTotal,
                        sortName: sortName,
                        sortOrder: sortOrder,
                        onSortChange: this.onSortChange
                      }}
                    >
                      <TableHeaderColumn
                        dataField='firstName'
                        columnClassName={''}
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        dataFormat={this.URLFormatter}
                        dataSort={true}>
                        Student Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='age'
                        columnClassName={''}
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        dataSort={true}>
                        Age
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='std'
                        columnClassName={''}
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        dataFormat={this.gradeFormat}
                        dataSort={true}>
                        Grade
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='address'
                        columnClassName={''}
                        dataAlign="left"
                        filter={{ type: 'TextFilter' }}
                        //dataFormat={this.locationFormat}
                        dataSort={true}>
                        Location
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='status' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Status</TableHeaderColumn>
                      <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    student_list: state.teacher.student_list,
  }
}

export default connect(mapStateToProps, { setFilters, getStudents })(StudentList);