import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import StudentTaskFormModal from './modal/model_student_task';
import { connect } from 'react-redux';
import { getTaskList } from './parent_action';
import { modal } from '../library/modal';
import modalTaskDeleteConfirmation from './modal/modal_delete_confirmation';

var dateFormat = require("dateformat");
class ParentStudentTasklist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };
    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }
  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getTaskList("",function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing { start} to { to} of { total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }
  statusFormat(cell, row) {
    if (row.isActive === true) {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else {
      return (
        <div className="badge bg-danger ">Inactive</div>
      );
    }
  }
  StudentTaskFormModal(title, taskData) {
    modal.add(StudentTaskFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      taskData: taskData,
    });
  }

  addDeleteConfirmationModal(filter) {
    modal.add(modalTaskDeleteConfirmation, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }


  subjectFormat(cell, row) {
    return (
      <div className="btn btn-sm bg-light text-dark">{row.subjectName}</div>
    );
  }
  StartDateFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.taskDateTime, "mm-dd-yyyy h:MM:ss TT")}</span>


    );
  }
  EndDateFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.completionDateTime, "mm-dd-yyyy h:MM:ss TT")}</span>
    );
  }
  actionFormatter(cell, row, props) {
    return (
      <div>
        <Link onClick={() => this.StudentTaskFormModal('Edit Task - ' + row.name, row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        {/* <Link   to={"/parent/task/" + row._id}  className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-eye"></i></Link> */}
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'task', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
      </div>
    );
  }
  render() {
    const { student_task_list } = this.props;
    //console.log("===>",student_task_list);
    const { isLoading, sortName, sortOrder } = this.state;
 
    if (!student_task_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      true: 'Active',
      false: 'InActive',
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Task List
                     </h5>
                    
                    <button onClick={() => this.StudentTaskFormModal('Add Task', '')} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New Task</b></button>
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                    keyField='id'
                    data={(student_task_list != undefined && student_task_list.length ? student_task_list : [])}
                    bordered={false}
                    striped={false}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: student_task_list.length }}
                    options={{
                      sizePerPage: this.state.sizePerPage,
                      page: this.state.currentPage,
                      hideSizePerPage: true,
                      paginationShowsTotal: this.renderPaginationShowsTotal,
                      sortName: sortName,
                      sortOrder: sortOrder,
                      onSortChange: this.onSortChange
                    }}
                  >
                    <TableHeaderColumn
                      dataField='name'
                      columnClassName={''}
                      filter={{ type: 'TextFilter' }}
                      dataAlign="left"
                      width="10%"
                      dataSort={true}>
                      Task Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='subjectName'
                      filter={{ type: 'TextFilter' }}
                      columnClassName={''}
                      dataAlign="center"
                      dataFormat={this.subjectFormat} dataSort={true}>
                      Subject
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='studentListString'
                      columnClassName={''}
                      dataAlign="left"
                      width="20%"
                      filter={{ type: 'TextFilter' }}>
                      Student
                      </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField='taskDateTime'
                      columnClassName={''}
                      dataAlign="left"
                      dataFormat={this.StartDateFormat}
                      dataSort={true}>
                      Start Date & Time
                       </TableHeaderColumn>
                       <TableHeaderColumn
                      dataField='batchDacompletionDateTimete'
                      columnClassName={''}
                      dataAlign="left"
                      dataFormat={this.EndDateFormat}
                      dataSort={true}>
                      End Date & Time
                       </TableHeaderColumn>
                    
                    <TableHeaderColumn dataField='isActive' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Status</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

                    </div>

    );

  }
}

function mapStateToProps(state) {
  return {
    student_task_list: state.parent.student_task_list,

  }
}
export default connect(mapStateToProps, { getTaskList })(ParentStudentTasklist);
