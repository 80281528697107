import React, { Component, PropTypes } from 'react';
import { getBatchList, getGoalList, getStudentInfo } from './teacher_action';

import AssessmentList from './assessment_list';
import DeshHeader from './desh_header';
import GoalList from './goal_list';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import moment from "moment";

var dateFormat = require('dateformat');

class ParentStudentInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setTab: 'nav-upload',
    };
  }

  componentDidMount() {
    var _this = this;
    var student_id = this.props.match.params.student_id;
    if (student_id !== undefined && student_id !== 'add') {
      this.setState({ isLoading: true });
      this.props.getStudentInfo(student_id, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
      this.props.getGoalList(student_id, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
      this.props.getBatchList(student_id,'', function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    }

  }
  componentWillReceiveProps(nextProps) {
    var _this = this;
    if (nextProps.match.params.student_id !== this.props.match.params.student_id) {
      this.props.getStudentInfo(nextProps.params.student_id, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    }
  }
  loadProductTab(tab) {
    this.setState({ setTab: tab });
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }

  renderTeacherTask(tasks) {
    if (tasks !== null && tasks.length > 0) {
      return tasks.map((task) => {
        return (
          <tr key={'history_' + task._id}>

            <td className="text-left">{task.taskDetails.name}</td>
            <td className="text-center"> {moment(task.taskDetails.createdTime).format('MM/DD/yyyy')}</td>
            <td className="text-center"> {moment(task.taskDetails.taskDateTime).format('MM/DD/yyyy')}</td>
            <td className="text-center">{moment(task.taskDetails.completionDateTime).format('MM/DD/yyyy')}</td>
            <td className="text-center"><span className="btn btn-sm bg-success text-white">{task.taskStatus}</span></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No  Log Data Found!</td>
        </tr>
      );
    }
  }


  renderStudentBatch(batchs) {
    if (batchs !== null && batchs.length > 0) {
      return batchs.map((batch) => {
        return (
          <tr key={'history_' + batch._id}>

            <td className="text-left">{batch.name}</td>
            <td className="text-left"><span className="btn btn-sm bg-light text-dark">{batch.subjectName}</span></td>
            <td className="text-center"> <span className="">   <i className="fa fa-clock-o mr-1"></i>{batch.startTime}</span></td>
            <td className="text-center"> <span className="">   <i className="fa fa-clock-o mr-1"></i>{batch.endTime}</span></td>
            <td className="text-center"><span className="btn btn-sm bg-success text-white">{batch.status}</span></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No  Log Data Found!</td>
        </tr>
      );
    }
  }
  render() {

    const { student_info } = this.props;
    const { isLoading, setTab } = this.state;

    if (student_info === undefined) {
      return (
        <div className="task-undefined"><Loading /></div>
      );
    }

    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content p-3">
              <div className="card">
                <div className="card-header">
                  <h3> Student Details of <span className="text-primary">{student_info?.firstName + '' + student_info?.lastName} </span> </h3>
                </div>
                <div className="card-body">
                  <div className="card  mt-3">
                    <div className="card-body">
                      <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          <a className={setTab === "nav-upload" ? 'nav-link active' : " nav-link"} data-toggle="tab" onClick={() => this.loadProductTab('nav-upload')} href='#nav-upload' role="tab">Student Information</a>
                          <a className={setTab === "nav-comment" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-comment')} href='#nav-comment' role="tab">Goals </a>
                          {/* <a className={setTab === "nav-parent" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-parent')} href='#nav-parent' role="tab">Parent Tasks(5)</a> */}
                          <a className={setTab === "nav-logtime" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-logtime')} href='#nav-logtime' role="tab">Calender</a>
                          {/* <a className={setTab === "nav-data" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-data')} href='#nav-data' role="tab">Data</a>
                          <a className={setTab === "nav-reinforcement" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-reinforcement')} href='#nav-reinforcement' role="tab">Reinforcement</a> 
                          <a className={setTab === "nav-assessment" ? 'nav-link active' : "nav-link "} data-toggle="tab" onClick={() => this.loadProductTab('nav-assessment')} href='#nav-assessment' role="tab">Assessment</a>*/}

                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        <div className={setTab === "nav-comment" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-comment" role="tabpanel" aria-labelledby="nav-comment-tab">
                          <div className="mt-3">

                            <GoalList student_id={student_info.userId} />
                          </div>

                        </div>
                        <div className={setTab === "nav-logtime" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-logtime" role="tabpanel" aria-labelledby="nav-logtime-tab">


                          <div className="card mt-3">
                            <div className="card-body mt-3">
                              <div className="text-right">

                              </div>
                              <table className="table table-responsive">
                                <thead>
                                  <tr>
                                    <th className="text-center">Workspace Name</th>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Start Date</th>
                                    <th className="text-left">End Date</th>
                                    <th className="text-left">Added by</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {this.renderStudentBatch(student_info.batchList)}
                                </tbody>
                              </table>
                            </div>

                          </div>

                        </div>

                        <div className={setTab === "nav-upload" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-upload" role="tabpanel" aria-labelledby="nav-upload-tab">
                          <div className="card mt-3 bg-light">
                            <div className="card-body mt-3">
                              <div className="table-responsive">
                                <table className="table mb-0">
                                  <tbody>
                                    <tr>
                                      <img src={student_info?.photo != '' ? student_info?.photo : "https://learning3ly.com/logo.png"} className="img-thumbnail w-25" alt="image" />
                                    </tr>
                                    <tr>
                                      <td className="first  border-top-0 font-weight-bold  h6">Student Name</td>
                                      <td className="second border-top-0 col-6">: {student_info?.firstName + '' + student_info?.lastName}</td>
                                    </tr>
                                    {/* <tr>
                                      <td className="first  font-weight-bold  h6">Student STD</td>
                                      <td className="second border-top-0 col-6">: {student_info?.std}</td>
                                    </tr> */}
                                    <tr>
                                      <td className="first  font-weight-bold  h6">Grade</td>
                                      <td className="second border-top-0 col-6">: <span className="second badge bg-success">{student_info?.grade} </span></td>
                                    </tr>
                                    <tr>
                                      <td className="first  font-weight-bold h6 ">Date OF Birth</td>
                                      <td className="second "> <span className="">
                                        : <i className="fa fa-calendar mr-1"></i>

                                        {dateFormat(student_info?.dob, "mm-dd-yyyy")}
                                      </span></td>
                                    </tr>
                                    <tr>
                                      <td className="first  font-weight-bold  h6">Age</td>
                                      <td className="second border-top-0 col-6">: {student_info?.age}</td>
                                    </tr>
                                    <tr>
                                      <td className="first  font-weight-bold  h6">Parent Name</td>
                                      <td className="second border-top-0 col-6">: {student_info?.parentName}</td>
                                    </tr>
                                    <tr>
                                      <td className="first  font-weight-bold h6 ">Address</td>
                                      <td className="second ">:  {student_info?.address}</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>

                            </div>

                          </div>

                        </div>
                        <div className={setTab == "nav-parent" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-parent" role="tabpanel" aria-labelledby="nav-parent-tab">


                          <div className="card mt-3">
                            <div className="card-body mt-3">
                              <div className="text-right">

                              </div>
                              <table className="table table-responsive">
                                <thead>
                                  <tr>
                                    <th className="text-center">Date Added</th>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Start Date</th>
                                    <th className="text-left">End Date</th>
                                    <th className="text-left">Added by</th>

                                  </tr>
                                </thead>
                                <tbody>

                                </tbody>
                              </table>
                            </div>

                          </div>

                        </div>
                      </div>

                      <div className={setTab === "nav-reinforcement" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-reinforcement" role="tabpanel" aria-labelledby="nav-reinforcement-tab">
                        <div className="mt-3">
                          Listing of Reinforcement
                        </div>
                      </div>

                      <div className={setTab === "nav-assessment" ? '"tab-pane fade show active' : " tab-pane fade"} id="nav-assessment" role="tabpanel" aria-labelledby="nav-assessment-tab">
                        <div className="mt-3">
                          <AssessmentList student_id={student_info.userId} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

    );
  }
}


function mapStateToProps(state) {
  //console.log("State.Parent ===>",state.parent);
  return {
    student_info: state.parent.student_info
  }
}

export default connect(mapStateToProps, { getStudentInfo, getGoalList, getBatchList })(ParentStudentInfo);
