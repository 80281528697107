import { Editor, Loading, input } from '../../library/elements';
import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { addBlog, updateBlog, validationNull } from '../blog_action';

import Select from 'react-select';
import { connect } from 'react-redux';
import { getTeacherSubjects } from '../../teacher/teacher_action';

class BlogFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    var _this = this;

    this.props.validationNull();
    this.props.getTeacherSubjects();
  }

  handleFormSubmit(formProps) {
    //console.log("Props",formProps);
    if(formProps.subject == null){
      formProps.subject = "others";
    }
    var _this = this;
    this.setState({ isLoading: true });
    const data = new FormData();
    var image = formProps.image;
    delete formProps.image;

    var imagedoc = formProps.document;
    delete formProps.document;

    data.append('title', formProps.title);
    data.append('brif', "");
    data.append('description', formProps.description);
    data.append('isFeatured', 'true');
    data.append('isPopular', 'true');
    data.append('sortOrder', formProps.sortOrder);
    data.append('status', formProps.status);
    if (image) {
      data.append('media', image[0]);
    }
    if (imagedoc) {
      data.append('document', imagedoc[0]);
    }
    data.append('subject', formProps.subject);


    if (this.props.blogData) {
      console.log(data);
      data.append('blogId', this.props.blogData._id);
      this.props.updateBlog(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addBlog(data, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }
  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }
  handleChange(e) {
    console.log('handle change called',)
  }
  addDefaultSrc(ev) {
    return ev.target.src = 'https://dummyimage.com/169x102/ebebeb/000.png&text=Learning3ly';
  }
  render() {
    const { handleSubmit, subject_list, errorMessage } = this.props;
    var { isLoading } = this.state;

    if (errorMessage) {
      isLoading = false;
    }

    if (subject_list == undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    var subjectData = [{ label: 'Others', value: 'others' }];
    for (var i in subject_list) {
      subjectData.push(
        { label: subject_list[i].name, value: subject_list[i]._id });
    }

    return (
      <div className="sk-form">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-12">
              <Field name="title" type="text" component={input} label="Blog Title" className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12">
              <label>Blog Description</label>
              <Field name="description" type="text" component={Editor} className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-9">
              <label>Document</label>
              <div className="form-group row">
                <div className="col-sm-6">
                  <Field name="document" component={imagedoc => {
                    var showImageDoc = null;
                    if (imagedoc.input.value && imagedoc.input.value !== '') {
                      showImageDoc = (
                        <div className="pro-image">
                          <img onError={this.addDefaultSrc} src={imagedoc.input.value.dataUrl ? imagedoc.input.value.dataUrl : imagedoc.input.value} className='img-thumbnail' width='100' height='100' />
                          <div className="fileUpload md-btn btn btn-outline-primary position-absolute mt-1">
                            <span>Change Document</span>
                            <input type="file"
                              name="imagedoc"
                              className="ml-2 position-absolute"
                              onChange={(e) => {
                                var files = e.target.files;
                                let reader = new FileReader();
                                let file = e.target.files[0];
                                reader.onload = function (e) {
                                  var picFile = e.target;
                                  files.dataUrl = picFile.result;
                                  imagedoc.input.onChange(files);
                                }
                                reader.readAsDataURL(file);
                              }} />
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div>
                        {
                          showImageDoc !== null
                            ?
                            showImageDoc
                            :
                            <>
                              <img onError={this.addDefaultSrc} src={imagedoc.input.value.dataUrl ? imagedoc.input.value.dataUrl : imagedoc.input.value} className='img-thumbnail' width='100' height='100' />
                              <div className="fileUpload md-btn btn btn-outline-primary position-absolute">
                                <span>Document Upload</span>

                                <input type="file"
                                  name="imagedoc"
                                  className="ml-2 position-absolute"
                                  onChange={(e) => {
                                    var files = e.target.files;
                                    let reader = new FileReader();
                                    let file = e.target.files[0];
                                    reader.onload = function (e) {
                                      var picFile = e.target;
                                      files.dataUrl = picFile.result;
                                      imagedoc.input.onChange(files);
                                    }
                                    reader.readAsDataURL(file);
                                  }} /> </div></>}

                      </div>)
                  }
                  } />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-9">
              <label>Image</label>
              <div className="form-group row">
                <div className="col-sm-6">
                  <Field name="image" component={image => {
                    var showImage = null;
                    if (image.input.value && image.input.value !== '') {
                      showImage = (
                        <div className="pro-image">
                          <img onError={this.addDefaultSrc} src={image.input.value.dataUrl ? image.input.value.dataUrl : image.input.value} className='img-thumbnail' width='100' height='100' />
                          <div className="fileUpload md-btn btn btn-outline-primary position-absolute mt-1">
                            <span>Change Banner</span>
                            <input type="file"
                              name="image"
                              className="ml-2 position-absolute"
                              onChange={(e) => {
                                var files = e.target.files;
                                let reader = new FileReader();
                                let file = e.target.files[0];
                                reader.onload = function (e) {
                                  var picFile = e.target;
                                  files.dataUrl = picFile.result;
                                  image.input.onChange(files);
                                }
                                reader.readAsDataURL(file);
                              }} />
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div>
                        {
                          showImage !== null
                            ?
                            showImage
                            :
                            <>
                              <img onError={this.addDefaultSrc} src={image.input.value.dataUrl ? image.input.value.dataUrl : image.input.value} className='img-thumbnail' width='100' height='100' />
                              <div className="fileUpload md-btn btn btn-outline-primary position-absolute">
                                <span>Banner Upload</span>

                                <input type="file"
                                  name="image"
                                  className="ml-2 position-absolute"
                                  onChange={(e) => {
                                    var files = e.target.files;
                                    let reader = new FileReader();
                                    let file = e.target.files[0];
                                    reader.onload = function (e) {
                                      var picFile = e.target;
                                      files.dataUrl = picFile.result;
                                      image.input.onChange(files);
                                    }
                                    reader.readAsDataURL(file);
                                  }} /> </div></>}

                      </div>)
                  }
                  } />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <label className="control-label">Subject</label>
              <Field name="subject" component={subject => {
                return (
                  <>
                    <Select simpleValue
                      value={subject.input.value}
                      placeholder="Select Subject"
                      options={subjectData}
                      onChange={(e) => {
                        subject.input.onChange(e);

                      }} />
                    <span>

                      {subject.meta.touched && ((subject.meta.error && <div className="error text-danger">{subject.meta.error}</div>) || (subject.meta.warning && <div className="error text-danger">{subject.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-6">
              <label>Sort Order</label>
              <Field name="sortOrder" type="text" component={input} className="form-control" />
            </div>

            <div className="col-sm-6">
              <label className="form-lable required">Status</label>
              <Field

                name="status"
                component={status =>
                  <div>
                    <select
                      name="status"
                      className="form-control"
                      value={status.input.value}
                      onChange={(event) => {
                        status.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="Enable">Enable</option>
                      <option value="Disable">Disable</option>
                    </select>
                    <span>
                      {status.meta.touched && ((status.meta.error && <div className="error text-danger">{status.meta.error}</div>) || (status.meta.warning && <div className="error text-danger">{status.meta.warning}</div>))}
                    </span>
                  </div>

                } />
            </div>
          </div>

          <div className="row">

            <div className="col-sm-9">
              {
                this.props.blogData
                  ?
                  <button action="form_blog" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Blog'}
                  </button>
                  :
                  <button action="form_blog" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Blog'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.title) {
    errors.title = 'Required Blog Name';
  }
  if (!formProps.sortOrder) {
    errors.sortOrder = 'Required';
  }
  if (formProps.status === undefined) {
    errors.status = 'Required';
  } else if (formProps.status === '') {
    errors.status = 'Required';
  }
  if (formProps.subject === undefined) {
    errors.subject = 'Required';
  } else if (formProps.subject === '') {
    errors.subject = 'Required';
  }
  return errors
}

function mapStateToProps(state, ownProps) {

  var iniBlog = {};
  if (ownProps.blogData) {
    var iniBlog = ownProps.blogData;
    iniBlog.image = ownProps.blogData.media;
  }
  return {
    errorMessage: state.account.error,
    initialValues: iniBlog,
    subject_list: state.teacher.teacher_subject_list,

  }
}

BlogFormModal = reduxForm({
  form: 'form_blog',
  validate: validate
})(BlogFormModal);


export default connect(mapStateToProps, { addBlog, updateBlog, getTeacherSubjects, validationNull })(BlogFormModal);
