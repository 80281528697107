import axios from 'axios';
import cookie from 'react-cookie';
import * as config from '../../../src/system/config';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const GET_LIST_FILTERS = 'GET_LIST_FILTERS';
export const MERGE_LIST_FILTERS = 'MERGE_LIST_FILTERS';
export const UPDATE_LIST_FILTERS = 'UPDATE_LIST_FILTERS';
export const GET_ALERT_TOP = 'GET_ALERT_TOP';

export function setFilters(group, group_data, type) {
  return function(dispatch, getState) {
    const state = getState();
    var filters_state = state.library.list_filters;
    if(filters_state != undefined && filters_state[group] != undefined){
      if(type != undefined && type == 'merge'){
        dispatch( { type: MERGE_LIST_FILTERS, payload: group_data, group:group });
      } else {
        dispatch( { type: UPDATE_LIST_FILTERS, payload: group_data, group:group });
      }

    } else {
      filters_state = {[group]: group_data}
      dispatch( { type: GET_LIST_FILTERS, payload: {data:filters_state}} );
    }
  };
}

export function validation(error) {
  return {
    type: VALIDATION_ERROR,
    payload: error
  }
}
export function validationNull() {
  return {
    type: VALIDATION_ERROR,
    payload: null
  }
}

export function setAlertTop(data) {
  return {
    type: GET_ALERT_TOP,
    payload: {data}
  };
}
export function removeAlertTop() {
  var data = {};
  return {
    type: GET_ALERT_TOP,
    payload: {data}
  };
}

export function commonDelete(filter, callback) {
  console.log("---------filter",filter);
  return function(dispatch) {
    
    axios({
      method: 'delete',
      url: `${config.API_URL + '/teacher/entity/'+filter.type+'/'+filter.id}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
    .then(response => {
      callback(null, response);
    })
    .catch(error => {
      if (error.response) {
        callback(null, false);
      }
    });
  }
}