import React,{ useReducer } from 'react';

export default function BooksList() {
    const [books, updateBooks] = React.useState([]);

    React.useEffect(function effectFunction() {
        fetch('http://dummy.restapiexample.com/api/v1/employees')
            .then(response => response.json())
            .then(({ data: books }) => {
                updateBooks(books);
            });
    }, []);

    return (
        <ul>
         
            {

                books.map(book => (
                    <li key={book.id}>{book.employee_name}</li>
                ))
            }


        </ul>
    );
}