import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ModalReinforcementDelete from './modal/delete/modal_reinforcement_delete';
import ModalViewCommentFiles from './modal/modal_goal_attachment';
import ReinforcementFormModal from './modal/model_reinforcement';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { getReinforcementList } from './teacher_action';
import { modal } from '../library/modal';
import moment from "moment";

class ReinforcementList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };

    this.actionViewList = this.actionViewList.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }
  
  componentDidMount() {

    let accessData = 'Granted';
    var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
    if(endDate == 'Invalid date'){
      accessData = 'NoAccess';
    }
    if(moment().isAfter(endDate, 'days')) {
      accessData = 'NoAccess';
    }
    if(accessData == 'NoAccess'){
      this.props.history.push('/pricing');
    }

    var _this = this;
    this.setState({ isLoading: true });
    this.props.getReinforcementList(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing { start} to { to} of { total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }
  statusFormat(cell, row) {
    if (row.isActive === true) {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else {
      return (
        <div className="badge bg-danger ">Inactive</div>
      );
    }
  }
  ReinforcementFormModal(title, reinforcementData) {
    modal.add(ReinforcementFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      reinforcementData: reinforcementData,
    });
  }

  addDeleteConfirmationModal(filter) {
    modal.add(ModalReinforcementDelete, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }



  actionFormatter(cell, row, props) {
    console.log("Att",row.attachments)
    return (
      <div>
        <Link onClick={() => this.ReinforcementFormModal('Edit Reinforcement - ' + row.name, row)} className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'contCategoryent', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>

        { row.attachments !== undefined && row.attachments.length > 0 ? 
        <Link onClick={() => this.ViewAttachment('View Attachment', row.attachments)} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-paperclip mr-1"></i></Link> : <i className="fa fa-paperclip mr-1 text-danger"></i> }

      </div>
    );
  }
  
  ViewAttachment(title, documents) {
    modal.add(ModalViewCommentFiles, {
      title: title,
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      documents: documents,
    });
  }

  actionViewList(cell, row) {
    return (
      row.attachments !== undefined && row.attachments.length > 0 ?
        <Link onClick={() => this.ViewAttachment('View Attachment', row.attachments)} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-eye mr-1"></i>Attchment</Link> : <span className="text-center"> - </span>
    )
  }

  render() {
    const { reinforcement_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!reinforcement_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      true: 'Active',
      false: 'InActive',
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content container mt-4">
              <div className="page" id="page-batch">
                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Reinforcement List
                     </h5>
                    {/* <button onClick={() => this.ReinforcementFormModal('Add Reinforcement', '')} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New Reinforcement</b></button> */}
                    <button onClick={() => this.ReinforcementFormModal('Add New', '')} className="text-primary mt-2 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button>
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                    keyField='_id'
                    data={(reinforcement_list != undefined && reinforcement_list.length ? reinforcement_list : [])}
                    bordered={false}
                    striped={false}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: reinforcement_list.length }}
                    options={{
                      sizePerPage: this.state.sizePerPage,
                      page: this.state.currentPage,
                      hideSizePerPage: true,
                      paginationShowsTotal: this.renderPaginationShowsTotal,
                      sortName: sortName,
                      sortOrder: sortOrder,
                      onSortChange: this.onSortChange
                    }}
                  >
                    <TableHeaderColumn
                      dataField='name'
                      columnClassName={''}
                      filter={{ type: 'TextFilter' }}
                      dataAlign="left"
                      width='70%'
                      dataSort={true}>
                      Reinforcement Name
                    </TableHeaderColumn>

                    {/* <TableHeaderColumn
                      dataField='studentName'
                      columnClassName={''}
                      dataAlign="left"
                      dataFormat={this.actionViewList}>
                      Attchment
                    </TableHeaderColumn> */}

                    <TableHeaderColumn dataField='isActive' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort>Status</TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
                    </div>
    );

  }
}

function mapStateToProps(state) {
  return {
    reinforcement_list: state.teacher.reinforcement_list,

  }
}
export default connect(mapStateToProps, { getReinforcementList })(ReinforcementList);
