import { ContentDetailsNull, ContentListNull, addGoal, deleteGoalImage, getCategoryList, getContentDetails, getContentList, getContentListbyCategory, getReinforcementList, updateGoal, validationNull } from '../teacher_action';
import { Editor, InputImage, Loading, MultiOptionCheckbox, input } from '../../library/elements';
import { Field, FieldArray, change, reduxForm } from 'redux-form';
import React, { Component } from 'react';

import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import { connect } from 'react-redux';

var dateFormat = require("dateformat");
class GoalFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isCheckValue: '',
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);

  }


  handleFormSubmit(formProps) {

    //console.log("ffff",formProps);
     
    var _this = this;
    this.setState({ isLoading: true });
    validationNull()
    formProps.studentId = this.props.studentId;
    // console.log(formProps);
    //   return false;
    if (this.props.goalData) {

      formProps.goalId = this.props.goalData._id;
      
      this.props.updateGoal(formProps, this.props.studentId, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addGoal(formProps, this.props.studentId, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }

  componentDidMount() {
    var _this = this;
    this.props.validationNull();
    this.props.ContentListNull();
    this.props.ContentDetailsNull();
    this.props.getCategoryList(function (err, result) { });
    this.props.getReinforcementList(function (err, result) { });
    if (this.props.goalData) {
      this.props.getContentDetails(this.props.goalData.contentId, function (err, result) { });
      this.props.getContentListbyCategory(this.props.goalData.categoryId, function (err, result) { });
    }

  }


  handleContentByCategory(categoryID) {
    this.props.ContentDetailsNull();

    var _this = this;
    this.setState({ isLoading: true });
    this.props.getContentListbyCategory(categoryID, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });

      }
    });
  }
  handleAttchmentByContent(contentID) {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getContentDetails(contentID, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }


  renderimages(images) {
    //console.log("------images",images);
    return images.map((image, index) => {
      return (
        <div className="row mb-2">
      
          <div className="col-sm-4">
            <Field name={"imagesArray[" + index + "][data]"} component={datackecobox => {
              //console.log("datackecobox",datackecobox);
              return (
                <div className="dt-picker">
                  <input
                    name={"imagesArray[" + index + "][data]"}
                    type="checkbox"
                    value={image.img_url}
                    onChange={(e) => {
                      datackecobox.input.onChange(e.target.value);
                    }}
                  />
                <Link onClick={() => window.open(image.img_url, "_blank")}>
                    <div className="alc-card">
                      <img src={image.img_url} alt={'image'} className="img-thumbnail" style={{ width: '5rem', height: '5rem' }} />
                    </div>
                </Link>

                </div>
              )
            }} className="form-control" />
          </div>
          <div className="col-sm-4">
            <Field name={"imagesArray[" + index + "][dateIntroduce]"} component={dateAttachmentIntroduce => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"imagesArray[" + index + "][dateIntroduce]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentIntroduce.input.value ? new Date() : (dateAttachmentIntroduce.input.value != '0000-00-00' ? new Date(dateAttachmentIntroduce.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentIntroduce.input.onChange(date);
                    }}
                  />
                </div>
              )
            }} className="form-control" />
          </div>
          <div className="col-sm-4">
            <Field name={"imagesArray[" + index + "][ddateMastered]"} component={dateAttachmentMastered => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"imagesArray[" + index + "][ddateMastered]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentMastered.input.value ? new Date() : (dateAttachmentMastered.input.value != '0000-00-00' ? new Date(dateAttachmentMastered.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentMastered.input.onChange(date);
                    }}
                  />


                </div>
              )
            }} className="form-control" />
          </div>
        </div>
      )
    });
  }


  renderWords(words) {
    return words.map((word, index) => {
      return (
        <div className="row mb-2">
          <div className="col-sm-4">
            <Field name={"wordsArray[" + index + "][data]"} component={datackecobox => {
              return (
                <div className="dt-picker">
                  <input
                    name={"wordsArray[" + index + "][data]"}
                    type="checkbox"
                    value={word}
                    onChange={(e) => {
                      datackecobox.input.onChange(e.target.value);
                    }}
                  />
                  {word}

                </div>
              )
            }} className="form-control" />
          </div>
          <div className="col-sm-4">
            <Field name={"wordsArray[" + index + "][dateIntroduce]"} component={dateAttachmentIntroduce => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"wordsArray[" + index + "][dateIntroduce]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentIntroduce.input.value ? new Date() : (dateAttachmentIntroduce.input.value != '0000-00-00' ? new Date(dateAttachmentIntroduce.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentIntroduce.input.onChange(date);
                    }}
                  />
                </div>
              )
            }} className="form-control" />
          </div>
          <div className="col-sm-4">
            <Field name={"wordsArray[" + index + "][ddateMastered]"} component={dateAttachmentMastered => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"wordsArray[" + index + "][ddateMastered]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentMastered.input.value ? new Date() : (dateAttachmentMastered.input.value != '0000-00-00' ? new Date(dateAttachmentMastered.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentMastered.input.onChange(date);
                    }}
                  />


                </div>
              )
            }} className="form-control" />
          </div>
        </div>
      )
    });
  }

  renderAddmoreImageCard() {
    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <tbody>
        {fields.map((item, index) =>
          <tr key={"add_image_" + index}>

            <td className="text-center">
              <Field name={`${item}.data`} type="checkbox" component={input} className="form-control" />
              <Field name={`${item}.datatext`} type="text" component={input} className="form-control" />
            </td>


          </tr>
        )}
        <tr>
          <td colSpan="1"></td>

        </tr>
      </tbody>
    )
    return (
      <div className="tab-pane" role="tabpanel">
        <table className="table table-bordered">
          <thead>
            <tr>

              <th className="text-center">Title</th>

            </tr>
          </thead>
          <FieldArray name={"words"} component={addMoreImage} />
        </table>
      </div>
    );
  }

  renderWordHistory(histories) {
    if (histories != undefined && histories.length > 0) {
      return histories.map((history, index) => {
        return (
          <tr key={'history_' + history._id + "." + index}>
            <td className="text-left">{dateFormat(history.dateIntroduce, "dd-mm-yyyy")}</td>

            <td className="text-left">{dateFormat(history.dateIntroduce, "dd-mm-yyyy")}</td>
            <td className="text-left">{history.data}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">
            <b>Currently No History Found!</b>
          </td>
        </tr>
      );
    }
  }

  renderImageHistory(histories) {
    if (histories != undefined && histories.length > 0) {
      return histories.map((history, index) => {
        
   if(history !== null){
    return (
      <tr key={'history_' + history._id + "." + index}>
        <td className="text-left">{dateFormat(history.dateIntroduce, "dd-mm-yyyy")}</td>

        <td className="text-left">{dateFormat(history.dateIntroduce, "dd-mm-yyyy")}</td>
        <td className="text-left">
           <Link onClick={() => window.open(history.data, "_blank")}>
                <div className="alc-card">
                  <img src={history.data} alt={'image'} className="img-thumbnail" style={{ width: '5rem', height: '5rem' }} />
                </div>
            </Link></td>
      </tr>
    );
   }  
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">
            <b>Currently No History Found!</b>
          </td>
        </tr>
      );
    }
  }
  
  render() {
    const { handleSubmit, content_list, reinforcement_list, content_details, errorMessage } = this.props;
    var { isLoading } = this.state;



    if (errorMessage) {
      isLoading = false;
    }

    if (this.props.category_list != undefined && this.props.category_list.length) {
      var CategoryData = [];
      for (var i in this.props.category_list) {
        CategoryData.push({ label: this.props.category_list[i].category, value: this.props.category_list[i]._id });

      }
    }
    

    var contentData = [];
    for (var i in content_list) {
      contentData.push({ label: content_list[i].title, value: content_list[i]._id });
    }
    var reinforcementData = [];
    for (var i in reinforcement_list) {
      reinforcementData.push({ label: reinforcement_list[i].name, value: reinforcement_list[i]._id });
    }

    var PromptData = [];

    PromptData.push({ label: 'Point', value: 'point' }, { label: 'Hightlight', value: 'hightlight' },
      { label: 'Shake', value: 'shake' });

//console.log("--------content_details",content_details);
    return (
      <div className="sk-form">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-12">
              <Field name="title" type="text" component={input} label="* Goal Type" className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            
            {/* <div className="col-6">
              <label className="control-label required">Select Prompting</label>
              <Field name="prompting" component={prompting => {
                return (
                  <>
                    <Select simpleValue
                      value={prompting.input.value}
                      placeholder="Select Prompting"
                      options={PromptData}
                      onChange={(e) => {
                        prompting.input.onChange(e);

                      }} />
                    <span>

                      {prompting.meta.touched && ((prompting.meta.error && <div className="error text-danger">{prompting.meta.error}</div>) || (prompting.meta.warning && <div className="error text-danger">{prompting.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div> */}

            <div className="col-md-4">
              <label className="control-label required">Select Reinforcement</label>
              <Field name="reInforcementId" component={reInforcementId => {
                return (
                  <>
                    <Select simpleValue
                      value={reInforcementId.input.value}
                      placeholder="Select Reinforcement"
                      options={reinforcementData}
                      onChange={(e) => {
                        reInforcementId.input.onChange(e);

                      }} />
                    <span>

                      {reInforcementId.meta.touched && ((reInforcementId.meta.error && <div className="error text-danger">{reInforcementId.meta.error}</div>) || (reInforcementId.meta.warning && <div className="error text-danger">{reInforcementId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
            <div className="col-sm-4">
              <label className="control-label"> Date Introduced</label>
              <Field name={`dateIntroduce`} component={dateIntroduce => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      time={true}
                      name="dateIntroduce"
                      format={"MM/dd/y"}
                      value={!dateIntroduce.input.value ? new Date() : (dateIntroduce.input.value != '0000-00-00' ? new Date(dateIntroduce.input.value) : null)}
                      onChange={(date) => {
                        dateIntroduce.input.onChange(date);
                      }}
                    />
                    <span>

                      {dateIntroduce.meta.touched && ((dateIntroduce.meta.error && <div className="error text-danger">{dateIntroduce.meta.error}</div>) || (dateIntroduce.meta.warning && <div className="error text-danger">{dateIntroduce.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
            </div>

            <div className="col-sm-4">
              <label className="control-label"> Date Mastered</label>
              <Field name={`dateMastered`} component={dateMastered => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      time={true}
                      name="dateMastered"
                      format={"MM/dd/y"}
                      value={!dateMastered.input.value ? new Date() : (dateMastered.input.value != '0000-00-00' ? new Date(dateMastered.input.value) : null)}
                      onChange={(date) => {
                        dateMastered.input.onChange(date);
                      }}
                    />
                    <span>

                      {dateMastered.meta.touched && ((dateMastered.meta.error && <div className="error text-danger">{dateMastered.meta.error}</div>) || (dateMastered.meta.warning && <div className="error text-danger">{dateMastered.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4">
              <label className="form-lable required">Status</label>
              <Field

                name="isActive"
                component={isActive =>
                  <div>
                    <select
                      name="isActive"
                      className="form-control"
                      value={isActive.input.value}
                      onChange={(event) => {
                        isActive.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="active">Active</option>
                      <option value="mastered">Mastered</option>
                      <option value="discontinued">Discontinued</option>
                    </select>
                    <span>
                      {isActive.meta.touched && ((isActive.meta.error && <div className="error text-danger">{isActive.meta.error}</div>) || (isActive.meta.warning && <div className="error text-danger">{isActive.meta.warning}</div>))}
                    </span>
                  </div>

                } />
            </div>
            <div className="col-md-4">
              <label className="control-label required">Select Category</label>
              <Field name="categoryId" component={categoryId => {
                return (
                  <>
                    <Select simpleValue
                      value={categoryId.input.value}
                      placeholder="Select Category"
                      options={CategoryData}
                      onChange={(e) => {
                        categoryId.input.onChange(e);
                        this.handleContentByCategory(e);
                      }} />
                    <span>

                      {categoryId.meta.touched && ((categoryId.meta.error && <div className="error text-danger">{categoryId.meta.error}</div>) || (categoryId.meta.warning && <div className="error text-danger">{categoryId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
            <div className="col-md-4">
              <label className="control-label required">Select Content</label>
              <Field name="contentId" component={contentId => {
                return (
                  <>
                    <Select simpleValue
                      value={contentId.input.value}
                      placeholder="Select Content"
                      options={contentData}
                      onChange={(value) => {
                        contentId.input.onChange(value);
                        this.handleAttchmentByContent(value);
                      }} />
                    <span>

                      {contentId.meta.touched && ((contentId.meta.error && <div className="error text-danger">{contentId.meta.error}</div>) || (contentId.meta.warning && <div className="error text-danger">{contentId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
            
          </div>


          {
            content_details !== undefined && !this.props.goalData && content_details?.image?.length
              ?
              <div className="card">
                <div className="card-header d-flex vertical-center justify-content-between">
                  <h5 className=""> View Targets</h5>
                </div>
                <div className="card-body">
           
                  <div className="row mb-2">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">Date Introduced</div>
                    <div className="col-sm-4">Date Mastered</div>
                  </div>
                    {this.renderimages(content_details?.image)}
                  </div>
               
              </div>
              :
              null
          }
        {
            this.props.goalData !== undefined && this.props.goalData?.imagesArray?.length
              ?
              <div className="card mt-3">
                <div className="card-header ch-alt">
                  <i className="fa fa-list"></i>Image History
              </div>
                <div className="card-body data-table">
                  <div className="table-responsive">
                    <table className="table table-hover table-bordered table-striped mb-0">
                      <thead>
                        <tr>
                          <th className="text-left">Date Introduced</th>
                          <th className="text-left">Date Mastered</th>
                          <th className="text-left">Data</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.renderImageHistory(this.props.goalData?.imagesArray)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              :
              null
          }


          {
            content_details !== undefined &&  content_details?.words?.length
              ?
              <div className="card mt-3">
                <div className="card-header">
                  <h5 className="mb-0"> View Words</h5>
                </div>
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">Date Introduced</div>
                    <div className="col-sm-4">Date Mastered</div>
                  </div>
                  {this.renderWords(content_details?.words)}
                </div>
              </div>
              :
              null
          }

          {
           this.props.goalData !== undefined &&  this.props.goalData?.wordsArray?.length
              ?
              <div className="card mt-3">
                <div className="card-header ch-alt">
                  <i className="fa fa-list"></i>Word History
              </div>
                <div className="card-body data-table">
                  <div className="table-responsive">
                    <table className="table table-hover table-bordered table-striped mb-0">
                      <thead>
                        <tr>
                          <th className="text-left">Date Introduced</th>
                          <th className="text-left">Date Mastered</th>
                          <th className="text-left">Data</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.renderWordHistory(this.props.goalData?.wordsArray)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              :
              null
          }


          <div className="row">
            <div className="form-group col-sm-12">
              <label> Goal</label>
              <Field name="description" type="text" component={Editor} className="form-control" />
            </div>
          </div>

          
          { this.props.goalData && <div className='text-danger text-center'>Note: Content and Categories won't get update due to already assigned to students !!!</div>}

          <div className="row">

            <div className="col-sm-9">
              {
                this.props.goalData
                  ?
                  <button action="form_goal" className=" mb-2  mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Goal'}
                  </button>
                  :
                  <button action="form_goal" className=" mb-2  mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Goal'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.title) {
    errors.title = 'Required Goal Title';
  }
  if (formProps.isActive === undefined) {
    errors.isActive = 'Select Status ';
  } else if (formProps.isActive === '') {
    errors.isActive = 'Select Status';
  }
  if (formProps.contentId === undefined) {
    errors.contentId = 'Select Content';
  } else if (formProps.contentId === '') {
    errors.contentId = 'Select Content';
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  var iniGoal = {
    attachment: [0],
  };

  if (ownProps.goalData) {
    iniGoal = ownProps.goalData;
    iniGoal.attachment = [0];
  }

  return {

    errorMessage: state.account.error,
    initialValues: iniGoal,
    content_list: state.teacher.content_list,
    content_details: state.teacher.content_details,
    category_list: state.teacher.category_list,
    reinforcement_list: state.teacher.reinforcement_list,
  }
}

GoalFormModal = reduxForm({
  form: 'form_goal',
  validate
})(GoalFormModal);

export default connect(mapStateToProps, { addGoal, updateGoal, deleteGoalImage, getReinforcementList, getContentList, getContentListbyCategory, getContentDetails, getCategoryList, validationNull, ContentListNull, ContentDetailsNull })(GoalFormModal);
