import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookie';

class DeshHeader extends Component {

  constructor(props) {
    super(props);

  }
  render() {
    const {  } = this.props;
 
    const currentLink =  window.location.pathname; 

    return (
      <div className="top-bar d-none d-xl-block" >
      <div className="sidebar">
        <div className="wrapper ">
          <div className="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">
            <div className="logo">
         </div>
            <div className="sidebar-wrapper dashboard">
              <ul className="nav">
                <li className={"nav-item " + (currentLink == "/dashboard" ? "active" : null)}>
                  <Link to="/dashboard" className={"nav-link"} >
                    <i className="fa fa-desktop"></i>
                    Dashboard
                  </Link>
                </li>
                <li className={"nav-item " + (currentLink == "/parent/student_mgt" ? "active" : null)}>
                  <Link to="/parent/student_mgt" className={"nav-link"} >
                    <i className="fa fa-user"></i>
                    Parent Students
                  </Link>
                </li>
                <li className={"nav-item " + (currentLink == "/parent/batch_view" ? "active" : null)}>
                  <Link to="/parent/batch_view" className={"nav-link"} >
                    <i className="fa fa-briefcase"></i>
                    Student Workspace
                  </Link>
                </li>

                {/* <li className={"nav-item " + (currentLink == "/parent/task_mgt" ? "active" : null)}>
                  <Link to="/parent/task_mgt" className={"nav-link"} >
                    <i className="fa fa-tasks"></i>
                    Task Management
                  </Link>
                </li> */}
                <li className={"nav-item " + (currentLink == "/parent/coulege_list" ? "active" : null)}>
                  <Link to="/parent/coulege_list" className={"nav-link"} >
                    <i className="fa fa-user"></i>
                    Teachers
                  </Link>
                </li>
               {/* 16-06-2021  */}
                <li className={"nav-item " + (currentLink == "/parent/communication" ? "active" : null)}>
                  <Link to="/parent/communication" className={"nav-link"} >
                  <i className="fa fa-comments-o"></i>
                    Communication 
                  </Link>
                </li>
                {/* <li>
                <Link to="/parent_dashboard" className={"nav-link"} >
                    {cookie.load('parent_id')}
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>
    
    )
  }
}

function mapStateToProps(state) {
  return {


  }
}
export default connect(mapStateToProps, {  })(DeshHeader);


