import { Bar, Doughnut, Pie } from '@reactchartjs/react-chart.js';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import moment from "moment";
import { studentDashboardData } from './student_action';

class StudentAccountDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.studentDashboardData(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  renderAssessmentList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        let pendingClassName = "text-left badge bg-warning text-white p-1";
        if (data.status == 'Pending') {
          pendingClassName = "text-left badge bg-success text-white p-1";
        }
        return (
          <tr key={'assessment_' + data._id}>
            <td className="text-left"><Link to={'/student/assessment_info/'+data._id}>{data.name}</Link></td>
            <td className="text-left">{moment(data.batchDate).format('MM/DD/yyyy')}</td>
            <td className="text-left"><label className={pendingClassName}>{data.startTime} : {data.endTime}</label></td>
            <td className="text-left">{data.status}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Batches Found!</td>
        </tr>
      );
    }
  }

  renderUpcomingEvents(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        return (
          <tr key={'event_' + data._id}>
            <td className="text-left">{data.eventName}</td>
            <td className="text-left">{moment(data.eventDate).format("MM/DD/yyyy")}</td>
            <td className="text-left">{data.startTime} - {data.endTime}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderStudentGoal(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        let pendingClassName = "text-left badge bg-success text-white p-1";
        if (data.status == 'Pending') {
          pendingClassName = "text-left badge bg-warning text-white p-1";
        }
        return (
          <tr key={'goal_' + data._id}>
            <td className="text-left"><Link to={'/student/goal_info/'+data._id}>{data.title}</Link></td>
            <td className="text-left"><label className={pendingClassName}>{data.studentGoalStatus}</label></td>
            <td className="text-left">
              {moment(data.goalStartTime).format('MM/DD/yyyy')} 
              
            </td>
            <td className="text-left">{moment(data.goalStartTime).format('hh:mm A')} </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Goals Found!</td>
        </tr>
      );
    }
  }

  render() {
    
    const { student_dashboard_list } = this.props;
    //console.log("Mikeee....",student_dashboard_list);

    let totalStudentGoals = '';
    let totalPendingGoals = '';
    let totalCompletedGoals = '';

    if(student_dashboard_list && student_dashboard_list.totalStudentGoals && student_dashboard_list.totalStudentGoals.length > 0 && student_dashboard_list.totalStudentGoals[0].count){
      totalStudentGoals = student_dashboard_list.totalStudentGoals[0].count;
    }
    if(student_dashboard_list && student_dashboard_list.totalPendingGoals && student_dashboard_list.totalPendingGoals.length > 0 && student_dashboard_list.totalPendingGoals[0].count){
      totalPendingGoals = student_dashboard_list.totalPendingGoals[0].count;
    }
    if(student_dashboard_list && student_dashboard_list.totalCompletedGoals && student_dashboard_list.totalCompletedGoals.length > 0){
      totalCompletedGoals = student_dashboard_list.totalCompletedGoals[0].count;
    }

    let totalCompletedAssessment = '';
    let totalApprovedAssessment = '';
    let totalPendingAssessment = '';
    let totalTeachersPendingApproval = '';

    if(student_dashboard_list && student_dashboard_list.totalCompletedAssessment && student_dashboard_list.totalCompletedAssessment.length > 0){
      totalCompletedAssessment = student_dashboard_list.totalCompletedAssessment[0].count;
    }
    if(student_dashboard_list && student_dashboard_list.totalApprovedAssessment && student_dashboard_list.totalApprovedAssessment.length > 0){
      totalApprovedAssessment = student_dashboard_list.totalApprovedAssessment[0].count;
    }
    if(student_dashboard_list && student_dashboard_list.totalPendingAssessment && student_dashboard_list.length > 0){
      totalPendingAssessment = student_dashboard_list.totalPendingAssessment[0].count;
    }
    if(student_dashboard_list && student_dashboard_list.totalTeachersPendingApproval && student_dashboard_list.totalTeachersPendingApproval.length > 0){
      totalTeachersPendingApproval = student_dashboard_list.totalTeachersPendingApproval[0].count;
    }

    const GoalsProgress = {
      labels: ['Total','Completed', 'Pending'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            totalStudentGoals,
            totalPendingGoals,
            totalCompletedGoals
          ],
          backgroundColor: [
            '#0275d8',
            '#5cb85c',
            '#d9534f',
          ],
          borderColor: [
            '#0275d8',
            '#5cb85c',
            '#d9534f',
          ],
          borderWidth: 1,
        },
      ],
    }
    const AssessmentsProgress = {
      labels: ['Completed','Approved','Pending','Teachers Pending Approval'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            totalCompletedAssessment,
            totalApprovedAssessment,
            totalPendingAssessment,
            totalTeachersPendingApproval
          ],
          backgroundColor: [
            '#5cb85c',
            '#0275d8',
            '#d9534f',
            '#f0ad4e'
          ],
          borderColor: [
            '#5cb85c',
            '#0275d8',
            '#d9534f',
            '#f0ad4e'
          ],
          borderWidth: 1,
        },
      ],
    }

    // Upcoming Events Chart Preparation Start //
    let uEventLabels = [];
    let uEventData = [];
    let uEventListChartData = [];
    let applyBGuEvent = [];
    let bgcolorsuEvent = ['#ff4d4d','#bfff00','#40ff00','#00ff80','#00bfff','#0040ff','#bf00ff','#ff00bf'];

    if(student_dashboard_list && student_dashboard_list.studentsEvents && student_dashboard_list.studentsEvents != false){  

      let j = 0;
      student_dashboard_list.studentsEvents.map((data,index) => {
        uEventData.push(data.eventName+ ' ' + moment(data.eventDate).format('MM/DD/yyyy') + ' '+ data.startTime + ' to '+ data.endTime+ '                  ');
        uEventLabels.push(Math.random() * 2);
        applyBGuEvent.push(bgcolorsuEvent[j]);
        if(index % 7 == 0){
          j = 0;
        }
        j++;
      });
      
      uEventListChartData = {
        labels: uEventData,
        datasets: [
          {
            label: '# EventList',
            data: uEventLabels,
            backgroundColor: applyBGuEvent,
            borderColor: '#EDEDED',
            borderWidth: 1,
          },
        ],
      }
    }
    // Upcoming Events Chart Preparation Over //

    // Pending Goal List Chart Preparation Start //
    let goalListLabels = [];
    let goalListData = [];
    let goalListListChartData = [];
    let applyBGgoalList = [];
    let bgcolorsgoalList = ['#ff4d4d','#bfff00','#40ff00','#00ff80','#00bfff','#0040ff','#bf00ff','#ff00bf'];

    if(student_dashboard_list && student_dashboard_list.studentGoal && student_dashboard_list.studentGoal != false){
      let j = 0;
      student_dashboard_list.studentGoal.map((data,index) => {
        if(data.studentGoalStatus == 'pending' || data.studentGoalStatus == 'started'){
          goalListData.push(data.title);
          goalListLabels.push(Math.random() * 2);
          applyBGgoalList.push(bgcolorsgoalList[j]);
          if(index % 7 == 0){
            j = 0;
          }
          j++;
        }
      });
      
      goalListListChartData = {
        labels: goalListData,
        datasets: [
          {
            label: '# Pending Goals',
            data: goalListLabels,
            backgroundColor: applyBGgoalList,
            borderColor: '#EDEDED',
            borderWidth: 1,
          },
        ],
      }
    }
    // Pending Goal List Chart Preparation Over //


    // Pending Assessment List Chart Preparation Start //
    let assessmentListLabels = [];
    let assessmentListData = [];
    let assessmentListListChartData = [];
    let applyBGassessmentList = [];
    let bgcolorsassessmentList = ['#ff4d4d','#bfff00','#40ff00','#00ff80','#00bfff','#0040ff','#bf00ff','#ff00bf'];

    if(student_dashboard_list && student_dashboard_list.studentAssessments && student_dashboard_list.studentAssessments != false){
      let j = 0;
      student_dashboard_list.studentAssessments.map((data,index) => {
        if(data.status == 'Pending'){
          assessmentListData.push(data.name);
          assessmentListLabels.push(Math.random() * 2);
          applyBGassessmentList.push(bgcolorsassessmentList[j]);
          if(index % 7 == 0){
            j = 0;
          }
          j++;
        }
      });
      
      assessmentListListChartData = {
        labels: assessmentListData,
        datasets: [
          {
            label: '# Pending Assessment',
            data: assessmentListLabels,
            backgroundColor: applyBGassessmentList,
            borderColor: '#EDEDED',
            borderWidth: 1,
          },
        ],
      }
    }
    // Pending Assessment List Chart Preparation Over //


    if (student_dashboard_list && student_dashboard_list.length) {
      //console.log('=======================================>');
      this.setState({ 
        isLoading: false
      });
    }
    if (!student_dashboard_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    
    return (
      <div className="account-bottom-container">
        <div className="page" id="page-teacher-dashboard">
          <div className="main-panel container-fluid">
            <div className="content mt-4">
              
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <h4 class="text-primary">Student Dashboard</h4>
                  <hr class="text-primary" />
                </div>
              </div>

              <div className='row mb-4'>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-warning card-header-icon">
                      <div className="dashboard-card-icon">
                        <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">Pending Goals</p>
                      <p className="card-title text-black">{student_dashboard_list.totalPendingGoals && student_dashboard_list.totalPendingGoals.length > 0 ? student_dashboard_list.totalPendingGoals[0].count : 0}</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/student/goal_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="dashboard-card-icon">
                      <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">Completed Goals</p>
                      <p className="card-title text-black">{student_dashboard_list.totalCompletedGoals && student_dashboard_list.totalCompletedGoals.length > 0 ? student_dashboard_list.totalCompletedGoals[0].count : 0}</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/student/goal_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="dashboard-card-icon">
                      <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">Pending Assessments</p>
                      <p className="card-title text-black">{student_dashboard_list.totalPendingAssessment && student_dashboard_list.totalPendingAssessment.length > 0 ? student_dashboard_list.totalPendingAssessment[0].count : 0}</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/student/assessment_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="dashboard-card-icon">
                      <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">Done Assessments</p>
                      <p className="card-title text-black">{student_dashboard_list.totalCompletedAssessment && student_dashboard_list.totalCompletedAssessment.length > 0 ? student_dashboard_list.totalCompletedAssessment[0].count : 0}</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/student/assessment_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                {
                  this.state.isLoading == false && student_dashboard_list.totalStudentGoals && student_dashboard_list.totalStudentGoals !== undefined &&
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    {
                      totalStudentGoals  && <div className="card card-chart">
                      <div className="ct-chart" id="dailySalesChart"><Doughnut data={GoalsProgress} /></div>
                        <div className="card-body">
                          <p className="card-title mb-0 text-warning text-center">Your Goals Progress</p>
                        </div>
                      </div>
                    }
                  </div>
                }
                
                {
                  this.state.isLoading == false && student_dashboard_list.totalPendingAssessment &&
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    {
                      (totalCompletedAssessment || totalApprovedAssessment || totalPendingAssessment || totalTeachersPendingApproval) && <div className="card card-chart">
                      <div className="ct-chart" id="dailySalesChart"><Doughnut data={AssessmentsProgress} /></div>
                        <div className="card-body">
                          <p className="card-title mb-0 text-warning text-center">Your Assessments Progress</p>
                        </div>
                      </div>
                    }
                  </div>
              }
                
              </div>
              <div className="row">
                
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  {
                    student_dashboard_list && student_dashboard_list.studentsEvents && student_dashboard_list.studentsEvents.length > 0 && 
                    <div className="card card-stats px-3">
                      <div className="card-header card-header-success card-header-icon">
                        <h6 class="text-left text-warning">Upcoming Events </h6>
                        <div className=" react-bs-table-container">
                          <table className="table table-responsive">
                            <thead>
                              {/* <tr>
                                <th className="text-left text-primary">Event</th>
                                <th className="text-left text-primary">Date</th>
                                <th className="text-left text-primary">Duration</th>
                              </tr> */}
                            </thead>
                            <tbody>
                              {/* {this.state.isLoading == false && this.renderUpcomingEvents(student_dashboard_list.studentsEvents)} */}

                              {
                                this.state.isLoading == false && 
                                student_dashboard_list &&
                                student_dashboard_list.studentsEvents &&
                                student_dashboard_list.studentsEvents.length > 0 &&
                                <Bar data={uEventListChartData}/>
                              }

                            </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
                  }
                  
                </div>
                
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  {
                    student_dashboard_list && student_dashboard_list.studentGoal && student_dashboard_list.studentGoal.length > 0 && 
                    <div className="card card-stats px-3">
                      <div className="card-header card-header-success card-header-icon">
                        <h6 class="text-left text-warning">Your Pending Goals To Be Complete</h6>
                        <div className=" react-bs-table-container">
                          <table className="table table-responsive">
                            {
                              this.state.isLoading == false && 
                              student_dashboard_list &&
                              student_dashboard_list.studentGoal &&
                              student_dashboard_list.studentGoal.length > 0 &&
                              <Pie data={goalListListChartData}/>
                            }
                          </table>
                        </div>
                      </div>
                    </div>
                  }
                  
                </div>
                
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  {
                    student_dashboard_list && student_dashboard_list.studentAssessments && student_dashboard_list.studentAssessments.length > 0 && 
                    <div className="card card-stats px-3">
                      <div className="card-header card-header-success card-header-icon">
                        <h6 class="text-left text-warning">Your Pending Assessments To Be Complete</h6>
                        <div className=" react-bs-table-container">
                          <table className="table table-responsive">
                            {
                              this.state.isLoading == false && 
                              student_dashboard_list &&
                              student_dashboard_list.studentAssessments &&
                              student_dashboard_list.studentAssessments.length > 0 &&
                              <Pie data={assessmentListListChartData}/>
                            }
                          </table>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2"></div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <h6 class="text-left text-warning">Recent Goals </h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left text-primary">Title</th>
                              <th className="text-left text-primary">Status</th>
                              <th className="text-left text-primary">Start Date</th>
                              <th className="text-left text-primary">Start Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isLoading == false && this.renderStudentGoal(student_dashboard_list.studentGoal)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <h6 class="text-left text-warning">Assessments </h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left text-primary">Name</th>
                              <th className="text-left text-primary">Date</th>
                              <th className="text-left text-primary">Duration</th>
                              <th className="text-left text-primary">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isLoading == false && this.renderAssessmentList(student_dashboard_list.studentAssessments)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    )
  }
}


function mapStateToProps(state) {
  //console.log("Students ===>",state.student);
  return {
    student_dashboard_list: state.student.student_dashboard_list
  }
}
export default connect(mapStateToProps, { studentDashboardData })(StudentAccountDashboard);