import { Field, reduxForm } from 'redux-form'
import { Loading, input } from '../library/elements';
import React,  { Component } from 'react';
import { forgotPassword, validationNull } from './account_action';

import { connect } from 'react-redux';
import { createAlert } from '../../src/library/function';

class ModalAccountFrogotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading:false };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
    this.goSignup = this.goSignup.bind(this);
  }

  componentWillMount() {
    this.props.validationNull();

  }
  handleFormSubmit(formProps) {
    var _this = this;
    this.setState({ isLoading: true });
    if(formProps !== "" && formProps !== undefined){
      this.props.forgotPassword(formProps, function(err, result) {
        if(result) {
          _this.props.removeModal();
          _this.setState({ isLoading: false });
        }
      });
    }else{
      createAlert({ message: 'Please Fill requered Details' });
      this.setState({ isLoading: false });
    }

   
  }
  renderAlert() {
    if(this.props.errorMessage) {
      return (
        <div className="alert alert-warning">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }

  goSignup() {
    this.props.removeModal();
    this.props.history.push('/signup');
  }

  render() {
    const { handleSubmit ,errorMessage } = this.props;
    var isLoading = this.state.isLoading;

    if(errorMessage){
      isLoading = false;
    }
    return (
        <div className="account-forgotten m-form">
           {isLoading ? <Loading /> : null}
            <div className="row">
              <div className="col-sm-12">
                  <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    {this.renderAlert()}
                    <div className="form-group row">
                      <div className="col-sm-12 mb-1">
                        <h6>Enter your email address to receive  a new password</h6>
                      </div>
                      <div className="col-sm-12 position-relative has-icon-left">
                        <Field name="email" type="text" component={input} label="Email"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <button action="forgotpass" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                          <i className="fa fa-paper-plane"></i> {isLoading ? 'Loading...' : 'Send'}
                        </button>
                      </div>
                    </div>
                   
                  </form>
              </div>
            </div>
     </div>
  );
  }
}

function validate(formProps) {
  const errors = {}

  if (!formProps.email) {
    errors.email = 'Required Email'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
    errors.email = 'Invalid Email Address'
  }

  return errors
}

ModalAccountFrogotPassword = reduxForm({
  form: 'forgotpass',
   validate: validate
})(ModalAccountFrogotPassword);

function mapStateToProps(state) {
  return {
    errorMessage: state.account.error,
  }
}

export default(connect(mapStateToProps, {forgotPassword, validationNull })(ModalAccountFrogotPassword));
