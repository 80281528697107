import { Breadcrumbs, Loading } from '../library/elements';

import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { getAllSubjects } from '../teacher/teacher_action';
import { getAllTeacherBlogList } from '../blog/blog_action';
import { toHtml } from '../library/function';
var dateFormat = require("dateformat");
class Blogs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
      
        this.applySortBy = this.applySortBy.bind(this);
    }
    componentWillMount() {
        this.setState({ isLoading: true });
        var _this = this;
        this.props.getAllSubjects();

        this.props.getAllTeacherBlogList(undefined, function (err, result) {
            if (result) {
                _this.setState({ isLoading: false });
            }
        });
    }

    applySortBy(e) {
        this.setState({ isLoading: true });
        var _this = this;

        this.props.getAllTeacherBlogList(e.target.value, function (err, result) {
            if (result) {
                _this.setState({ isLoading: false });
            }
        });
    }
    renderTeacherAllBlog(teacher_blogs_list) {
        return teacher_blogs_list.map((article) => {
     

            var article_image = "http://trystack.mediumra.re/img/knowledge-1.jpg";
            if(article.media){
              article_image =article.media;
            }

            return (
                <div className="col-sm-4">
                    <div className="card alc-card alc-shadow-dark alc-hover-transform mb-4" key={"article." + article._id}>
                        <div className="alc-overflow-hidden">
                            <Link to={"/blog/" + article._id}>
                                <img src={article_image} className="img-fluid alc-h-18" alt={article.title} /></Link>
                        </div>
                        <div className="card-body">
                            <div className="badge bg-light text-dark p-2 h5 mb-0">{dateFormat(article.createdAt, "mm/dd/yyyy")}</div>
                            <div className="alc-card-title m-0">
                                <Link to={"/blog/" + article._id} >{article.title}</Link>
                            </div>
                            <div className="card-text alc-card-brief">{toHtml(article.description)}</div>
                        </div>
                        <div className="card-footer pb-1">
                            <div className="float-left">
                                <Link className="link-secondary"to={"/blog/" + article._id}>  <i className="fa fa-user mr-2"></i>{article.teacherDetails.name ? article.teacherDetails.name : ""}</Link>
                            </div>
                            <div className="float-right">
                                <Link to={"/blog/" + article._id}  className="btn btn-outline-primary mb-2">Read More</Link>
                            </div>
                        </div>
                    </div>




                </div>
            );
        });
    }

    render() {

        const { teacher_blogs_list, subject_list } = this.props;
        const { isLoading } = this.state;

        if (!teacher_blogs_list || subject_list === undefined) {
            return (
                <div className="wallet-undefined"><Loading /></div>
            );
        }
        var breadcrumbs = [];
        breadcrumbs.push({ text: 'Resources', value: '/blogs' });
        return (
            <div id="blogs" className="page  mb-5">
                {isLoading ? <Loading /> : null}
                <div className="container">
                    <div className="page-heading mt-6">

                        <div className="d-flex justify-content-between pt-3 breadcrumb-container">
                            <div className="p-2  mt-2 mb-2 breadcrumb-title font-weight-bold">
                                Learning3ly  Resources
                               </div>
                            <div className="page-heading-breadcrumb">
                                <Breadcrumbs breadcrumbs={breadcrumbs} />
                            </div>
                        </div>

                    </div>
                    {
                                    teacher_blogs_list?.length
                                        ?
                                        <div className="row mb-3">
                                        <div className="col-md-4 col-sm-12">
                                        </div>
                                        <div className="col-md-4 col-xs-12"></div>
                                        <div className="col-md-4 col-xs-12">
                                            <div className="text-right">
                                                <div className="ml-1 select-style pull-right">
                                                    <select onChange={(e) => this.applySortBy(e)} className="form-select">
                                                        <option value="">-- All Resources --</option>
                                                        <option value="others">Others</option>
                                                        {subject_list &&
                                                            subject_list?.map((subject, index) => (
                                                                <option key={index} value={subject._id}>
                                                                    {subject.name}
                                                                </option>
                
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        :
                                       null
                                }

                    
                    <div className="row">
                        <div className="col-sm-12 article-list-container">
                            <div className="row">
                                {
                                    teacher_blogs_list?.length
                                        ?
                                        this.renderTeacherAllBlog(teacher_blogs_list)
                                        :
                                        <div className="col-sm-12">
                                            <div className="no-result-card">
                                                <div className="text-center">
                                                    <div className="card-body">
                                                        <h2 className="no-result-title large">{'oops! No Blogs Found!'}</h2>
                                                        <div className="no-result-text no-search-result"><h6>{'There is no record available.'}</h6></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {

    return {
        teacher_blogs_list: state.blog.teacher_blogs_list,
        subject_list: state.teacher.all_subject_list,

    }
}
export default connect(mapStateToProps, { getAllTeacherBlogList, getAllSubjects })(Blogs);
