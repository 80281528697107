import { ContentDetailsNull, ContentListNull, addGoal, deleteGoalImage, getCategoryList, getContentDetails, getContentList, getContentListbyCategory, getReinforcementList, updateGoal, validationNull } from './teacher_action';
import { Editor, InputImage, Loading, MultiOptionCheckbox, input } from '../library/elements'
import { Field, FieldArray, change, reduxForm } from 'redux-form';
import React, { Component } from 'react';

import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import { connect } from 'react-redux';

var dateFormat = require("dateformat");
class GoalForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isCheckValue: '',
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }


  handleFormSubmit(formProps) {

    var _this = this;
    this.setState({ isLoading: true });
    validationNull()
    const data = new FormData();


    data.append('title', formProps.title);
    data.append('description', formProps.description);
    data.append('isActive', formProps.isActive);
    data.append('contentId', formProps.contentId);
    data.append('studentId', this.props.studentId);
    data.append('categoryId', formProps.categoryId);
    data.append('dateIntroduce', dateFormat(formProps.dateIntroduce ? formProps.dateIntroduce : new Date(), "mm/dd/yyyy"));
    data.append('dateMastered', dateFormat(formProps.dateMastered ? formProps.dateMastered : new Date(), "mm/dd/yyyy"));;
    data.append('contentData', formProps.contentData);
    data.append('prompting', formProps.prompting);
    data.append('reInforcementId', formProps.reInforcementId);

    if (this.props.goalData) {

      data.append('goalId', this.props.goalData._id);
      this.props.updateGoal(data, this.props.studentId, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addGoal(data, this.props.studentId, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }

  componentDidMount() {



    var _this = this;
    this.props.validationNull();
    this.props.ContentListNull();
    this.props.ContentDetailsNull();
    this.props.getCategoryList(function (err, result) { });
    this.props.getReinforcementList(function (err, result) { });

  }


  handleContentByCategory(categoryID) {
    this.props.ContentDetailsNull();

    var _this = this;
    this.setState({ isLoading: true });
    this.props.getContentListbyCategory(categoryID, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });

      }
    });
  }
  handleAttchmentByContent(contentID) {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getContentDetails(contentID, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  closeModal() {
    this.props.removeModal();
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }




  renderimages(images) {
    return images.map((image, index) => {
      return (
        <div className="row mb-2">
          <div className="col-sm-3">
            <Link onClick={() => window.open(image, "_blank")}>

              <div className="card alc-card alc-card-fancy alc-hover-fancy mb-4">
                <div className="alc-card-fancy-image">


                  <img src={image} alt={'image'} className="img-fluid alc-img-fluid" style={{ width: '100%', height: '12rem' }} />

                </div>
                <div className="card-footer alc-card-fancy-content text-center">
                  <h4 className="card-title alc-card-title"><i className="fa fa-eye"></i></h4>
                </div>
              </div>
            </Link>

          </div>
          <div className="col-sm-3">
            <Field
              name={"imagesArray[" + index + "][" + index + "]"}
              type="checkbox"
              label={image}

              component={input} className="form-control"
            />
          </div>
          <div className="col-sm-3">
            <Field name={"imagesArray[" + index + "][dateIntroduce_" + index + "]"} component={dateAttachmentIntroduce => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"imagesArray[" + index + "][dateIntroduce_" + index + "]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentIntroduce.input.value ? new Date() : (dateAttachmentIntroduce.input.value != '0000-00-00' ? new Date(dateAttachmentIntroduce.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentIntroduce.input.onChange(date);
                    }}
                  />
                </div>
              )
            }} className="form-control" />
          </div>
          <div className="col-sm-3">
            <Field name={"imagesArray[" + index + "][ddateMastered_" + image + "]"} component={dateAttachmentMastered => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"imagesArray[" + index + "][ddateMastered_" + image + "]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentMastered.input.value ? new Date() : (dateAttachmentMastered.input.value != '0000-00-00' ? new Date(dateAttachmentMastered.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentMastered.input.onChange(date);
                    }}
                  />


                </div>
              )
            }} className="form-control" />
          </div>
        </div>
      )
    });
  }
  handleChange(event) {

  }

  renderWords(words) {
    return words.map((word, index) => {
      return (
        <div className="row mb-2">
          <div className="col-sm-4">
            <Field name={"contentData[" + index + "][data]"} component={datackecobox => {
              return (
                <div className="dt-picker">
                  <input
                    name={"contentData[" + index + "][data]"}
                    type="checkbox"
                    value={word}
                    onChange={(e) => {
                      datackecobox.input.onChange(e.target.value);
                    }}
                  />
                   {word}

                </div>
              )
            }} className="form-control" />

          

          


          </div>
          <div className="col-sm-4">
            <Field name={"contentData[" + index + "][dateIntroduce]"} component={dateAttachmentIntroduce => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"contentData[" + index + "][dateIntroduce]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentIntroduce.input.value ? new Date() : (dateAttachmentIntroduce.input.value != '0000-00-00' ? new Date(dateAttachmentIntroduce.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentIntroduce.input.onChange(date);
                    }}
                  />
                </div>
              )
            }} className="form-control" />
          </div>
          <div className="col-sm-4">
            <Field name={"contentData[" + index + "][ddateMastered]"} component={dateAttachmentMastered => {
              return (
                <div className="dt-picker">
                  <DatePicker
                    time={true}
                    name={"contentData[" + index + "][ddateMastered]"}
                    format={"MM/dd/y"}
                    value={!dateAttachmentMastered.input.value ? new Date() : (dateAttachmentMastered.input.value != '0000-00-00' ? new Date(dateAttachmentMastered.input.value) : null)}
                    onChange={(date) => {
                      dateAttachmentMastered.input.onChange(date);
                    }}
                  />


                </div>
              )
            }} className="form-control" />
          </div>
        </div>
      )
    });
  }

  renderAddmoreImageCard() {
    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <tbody>
        {fields.map((item, index) =>
          <tr key={"add_image_" + index}>

            <td className="text-center">
              <Field name={`${item}.data`} type="checkbox" component={input} className="form-control" />
              <Field name={`${item}.datatext`} type="text" component={input} className="form-control" />
            </td>


          </tr>
        )}
        <tr>
          <td colSpan="1"></td>

        </tr>
      </tbody>
    )
    return (
      <div className="tab-pane" role="tabpanel">
        <table className="table table-bordered">
          <thead>
            <tr>

              <th className="text-center">Title</th>

            </tr>
          </thead>
          <FieldArray name={"words"} component={addMoreImage} />
        </table>
      </div>
    );
  }

  render() {
    const { handleSubmit, content_list, reinforcement_list, content_details, errorMessage } = this.props;
    var { isLoading } = this.state;



    if (errorMessage) {
      isLoading = false;
    }

    if (this.props.category_list != undefined && this.props.category_list.length) {
      var CategoryData = [];
      for (var i in this.props.category_list) {
        CategoryData.push({ label: this.props.category_list[i].category, value: this.props.category_list[i]._id });

      }
    }

    var contentData = [];
    for (var i in content_list) {
      contentData.push({ label: content_list[i].title, value: content_list[i]._id });
    }
    var reinforcementData = [];
    for (var i in reinforcement_list) {
      reinforcementData.push({ label: reinforcement_list[i].name, value: reinforcement_list[i]._id });
    }

    var PromptData = [];

    PromptData.push({ label: 'Point', value: 'point' }, { label: 'Hightlight', value: 'hightlight' },
      { label: 'Shake', value: 'shake' });






    return (
      <div className="container mt-5">
        {isLoading ? <Loading /> : null}
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-12">
              <Field name="title" type="text" component={input} label="* Goal Type" className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <label className="control-label required">Select Category</label>
              <Field name="categoryId" component={categoryId => {
                return (
                  <>
                    <Select simpleValue
                      value={categoryId.input.value}
                      placeholder="Select Category"
                      options={CategoryData}
                      onChange={(e) => {
                        categoryId.input.onChange(e);
                        this.handleContentByCategory(e);
                      }} />
                    <span>

                      {categoryId.meta.touched && ((categoryId.meta.error && <div className="error text-danger">{categoryId.meta.error}</div>) || (categoryId.meta.warning && <div className="error text-danger">{categoryId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
          </div>


          <div className="form-group row">
            <div className="col-12">
              <label className="control-label required">Select Content</label>
              <Field name="contentId" component={contentId => {
                return (
                  <>
                    <Select simpleValue
                      value={contentId.input.value}
                      placeholder="Select Content"
                      options={contentData}
                      onChange={(value) => {
                        contentId.input.onChange(value);
                        this.handleAttchmentByContent(value);
                      }} />
                    <span>

                      {contentId.meta.touched && ((contentId.meta.error && <div className="error text-danger">{contentId.meta.error}</div>) || (contentId.meta.warning && <div className="error text-danger">{contentId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
          </div>


          {/* {
            content_details?.image?.length
              ?
              <div className="card">
                <div className="card-header d-flex vertical-center justify-content-between">
                  <h5 className=""> View Targets</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    {this.renderimages(content_details?.image)}
                  </div>
                </div>
              </div>
              :
              null
          } */}


           {/* {
             content_details?.words?.length
              ?
              <div className="card mt-3">
                <div className="card-header">
                  <h5 className="mb-0"> View Words</h5>
                </div>
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-sm-4">Object</div>
                    <div className="col-sm-4">DateIntroduce</div>
                    <div className="col-sm-4">Date Mastered</div>
                  </div>
                  {this.renderWords(content_details?.words)}
                </div>
              </div>
              :
              null
          }  */}

          <div className="row mt-3">
            <div className="form-group col-sm-12">
              {this.renderAddmoreImageCard()}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-12">
              <label> Goal</label>
              <Field name="description" type="text" component={Editor} className="form-control" />
            </div>
          </div>

          <div className="form-group row">
            
            <div className="col-6">
              <label className="control-label required">Select Prompting</label>
              <Field name="prompting" component={prompting => {
                return (
                  <>
                    <Select simpleValue
                      value={prompting.input.value}
                      placeholder="Select Prompting"
                      options={PromptData}
                      onChange={(e) => {
                        prompting.input.onChange(e);

                      }} />
                    <span>

                      {prompting.meta.touched && ((prompting.meta.error && <div className="error text-danger">{prompting.meta.error}</div>) || (prompting.meta.warning && <div className="error text-danger">{prompting.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>

            <div className="col-6">
              <label className="control-label required">Select Reinforcement</label>
              <Field name="reInforcementId" component={reInforcementId => {
                return (
                  <>
                    <Select simpleValue
                      value={reInforcementId.input.value}
                      placeholder="Select Reinforcement"
                      options={reinforcementData}
                      onChange={(e) => {
                        reInforcementId.input.onChange(e);

                      }} />
                    <span>

                      {reInforcementId.meta.touched && ((reInforcementId.meta.error && <div className="error text-danger">{reInforcementId.meta.error}</div>) || (reInforcementId.meta.warning && <div className="error text-danger">{reInforcementId.meta.warning}</div>))}
                    </span>
                  </>
                )

              }

              } className="form-control" />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-6">
              <label className="control-label"> Date Introduced</label>
              <Field name={`dateIntroduced`} component={dateIntroduced => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      time={true}
                      name="dateIntroduced"
                      format={"MM/dd/y"}
                      value={!dateIntroduced.input.value ? new Date() : (dateIntroduced.input.value != '0000-00-00' ? new Date(dateIntroduced.input.value) : null)}
                      onChange={(date) => {
                        dateIntroduced.input.onChange(date);
                      }}
                    />
                    <span>

                      {dateIntroduced.meta.touched && ((dateIntroduced.meta.error && <div className="error text-danger">{dateIntroduced.meta.error}</div>) || (dateIntroduced.meta.warning && <div className="error text-danger">{dateIntroduced.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
            </div>

            <div className="col-sm-6">
              <label className="control-label"> Date Mastered</label>
              <Field name={`dateMastered`} component={dateMastered => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      time={true}
                      name="dateMastered"
                      format={"MM/dd/y"}
                      value={!dateMastered.input.value ? new Date() : (dateMastered.input.value != '0000-00-00' ? new Date(dateMastered.input.value) : null)}
                      onChange={(date) => {
                        dateMastered.input.onChange(date);
                      }}
                    />
                    <span>

                      {dateMastered.meta.touched && ((dateMastered.meta.error && <div className="error text-danger">{dateMastered.meta.error}</div>) || (dateMastered.meta.warning && <div className="error text-danger">{dateMastered.meta.warning}</div>))}
                    </span>

                  </div>
                )
              }} className="form-control" />
            </div>
          </div>





          <div className="form-group row">
            <div className="col-sm-6">
              <label className="form-lable required">Status</label>
              <Field

                name="isActive"
                component={isActive =>
                  <div>
                    <select
                      name="isActive"
                      className="form-control"
                      value={isActive.input.value}
                      onChange={(event) => {
                        isActive.input.onChange(event.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      <option value="active">Active</option>
                      <option value="mastered">Mastered</option>
                      <option value="discontinued">Discontinued</option>
                    </select>
                    <span>
                      {isActive.meta.touched && ((isActive.meta.error && <div className="error text-danger">{isActive.meta.error}</div>) || (isActive.meta.warning && <div className="error text-danger">{isActive.meta.warning}</div>))}
                    </span>
                  </div>

                } />
            </div>
          </div>

          <div className="row">

            <div className="col-sm-9">
              {
                this.props.goalData
                  ?
                  <button action="form_goal" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Goal'}
                  </button>
                  :
                  <button action="form_goal" className="btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Goal'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {

  const errors = {}
  if (!formProps.title) {
    errors.title = 'Required Goal Title';
  }
  if (formProps.isActive === undefined) {
    errors.isActive = 'Select Status ';
  } else if (formProps.isActive === '') {
    errors.isActive = 'Select Status';
  }
  if (formProps.contentId === undefined) {
    errors.contentId = 'Select Content';
  } else if (formProps.contentId === '') {
    errors.contentId = 'Select Content';
  }
  return errors
}
GoalForm = reduxForm({
  form: 'form_goal',
  validate,
  
})(GoalForm);

function mapStateToProps(state, ownProps) {
  var iniPro = {}
  if (state.teacher.content_details !== undefined) {
    var tempArr = state.teacher.content_details.words;
    var wordsArr = [];
    for (var i in tempArr) {
      wordsArr.push({ 'data': false, 'datatext': tempArr[i], 'dateIntroduced': new Date() });
    }
    iniPro.words = wordsArr;
  }
  return {

    initialValues: iniPro,
    errorMessage: state.account.error,
    content_list: state.teacher.content_list,
    content_details: state.teacher.content_details,
    category_list: state.teacher.category_list,
    reinforcement_list: state.teacher.reinforcement_list,
  }
}




export default connect(mapStateToProps, { addGoal, updateGoal, deleteGoalImage, getReinforcementList, getContentList, getContentListbyCategory, getContentDetails, getCategoryList, validationNull, ContentListNull, ContentDetailsNull })(GoalForm);
