import './audioplayer.css';

import { Field, reduxForm } from 'redux-form';
import { Loading, input } from '../library/elements';
import React, { Component } from 'react';
import { login, validationNull } from '../account/account_action';

import AudioPlayer from 'react-h5-audio-player';
import { connect } from 'react-redux';

class AudioPlayerData extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      recordingUrl: '',
      isError: ''
    };
  }
  
  componentDidMount(){
    const queryParams = new URLSearchParams(window.location.search);
    const audioUrl = queryParams.get('audioUrl');
    this.setState({recordingUrl: audioUrl})
  }

  render() {
    var { isLoading } = this.state;
    return (
      <div>
        {
                      this.state.recordingUrl && this.state.recordingUrl != "" && 
                        <AudioPlayer
                            src={this.state.recordingUrl}
                            style={{width:'100%'}}
                            onPlay={e => console.log("onPlay")}
                        />
                  }
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
      
  }
}

export default connect(mapStateToProps, { login, validationNull })(AudioPlayerData);