import $ from 'jquery';

//title, summary, details, severity, dismissible, autoDismiss, appendToId
export function createAlert(alert_data) {
  //https://codepen.io/codysechelski/pen/dYVwjb?q=alert&limit=all&type=type-pens
  if(alert_data.type == undefined || !alert_data.type){
    alert_data.class = 'success';
  } else {
    alert_data.class = alert_data.type;
  }
  if(alert_data.dismissible == undefined){
    alert_data.dismissible = true;
  }
  if(alert_data.autoDismiss == undefined){
    alert_data.autoDismiss = true;
  }


  var iconMap = {
    info: "fa fa-info-circle",
    success: "fa fa-thumbs-up",
    warning: "fa fa-exclamation-triangle",
    danger: "fa ffa fa-exclamation-circle"
  };

  var iconAdded = false;

  var alertClasses = ["alert", "animated", "flipInX"];
  alertClasses.push("alert-" + alert_data.class.toLowerCase());


  if (alert_data.dismissible != undefined && alert_data.dismissible) {
    alertClasses.push("alert-dismissible");
  }

  var msgIcon = $("<i />", {
    "class": iconMap[alert_data.class] // you need to quote "class" since it's a reserved keyword
  });

  var msg = $("<div />", {
    "class": alertClasses.join(" ") // you need to quote "class" since it's a reserved keyword
  });
  if (alert_data.title) {
    var msgTitle = $("<h4 />", {
      html: alert_data.title
    }).appendTo(msg);

    if(!iconAdded){
      msgTitle.prepend(msgIcon);
      iconAdded = true;
    }
  } else {
    var msgTitle = $("<h4 />", {
      html: 'Success'
    }).appendTo(msg);

    if(!iconAdded){
      msgTitle.prepend(msgIcon);
      iconAdded = true;
    }
  }

  if (alert_data.sub_title != undefined && alert_data.sub_title) {
    var msgSummary = $("<strong />", {
      html: alert_data.sub_title
    }).appendTo(msg);

    if(!iconAdded){
      msgSummary.prepend(msgIcon);
      iconAdded = true;
    }
  }

  if (alert_data.message) {
    var msgDetails = $("<p />", {
      html: alert_data.message
    }).appendTo(msg);

    if(!iconAdded){
      msgDetails.prepend(msgIcon);
      iconAdded = true;
    }
  }


  if (alert_data.dismissible) {
    var msgClose = $("<span />", {
      "class": "close", // you need to quote "class" since it's a reserved keyword
      "data-dismiss": "alert",
      html: "<i class='fa fa-times-circle'></i>"
    }).appendTo(msg);
  }

  //$('#' + appendToId).prepend(msg);
  $('#pageMessages').prepend(msg);
  if (alert_data.play_sound != undefined && alert_data.play_sound == true) {
    document.getElementById('sound').play();
  }
  if(alert_data.autoDismiss){
    setTimeout(function(){
      msg.addClass("flipOutX");
      setTimeout(function(){
        msg.remove();
      },1000);
    }, 5000);
  }
}
export function normalizeField(normalizers){
  return function(value, previousValue, allValues, previousAllValues){
    var i = 0;
    var normalizersLength = normalizers.length;
    var currentValue = value;
    while(i < normalizersLength)
    {
        var currentNormalizer = normalizers[i];
        if(typeof currentNormalizer == "string")
        {
          currentValue = eval(currentNormalizer)(currentValue ,previousValue , allValues , previousAllValues);
        }
        i++;
    }
    return currentValue;
  }
}
var trimStart = function(value , previousValue , allValues , previousAllValues){
  return value.trimStart();
}
var trimEnd = function(value , previousValue , allValues , previousAllValues){
  return value.trimEnd();
}
var trim = function(value , previousValue , allValues , previousAllValues){
  return value.trim();
}
var upperCase = function(value , previousValue , allValues , previousAllValues){
  return value.toUpperCase();
}
var lowerCase = function(value , previousValue , allValues , previousAllValues){
  return value.toLowerCase();
}
var onlyNums = function(value , previousValue , allValues , previousAllValues){
  if (!value) {
    return value
  }

  return value.replace(/[^\d]/g, '');
}

export function toHtml(str_html){
  return <div dangerouslySetInnerHTML = {{__html : str_html }}/>;
}

export function htmlDecode(str){
  if(str && typeof str === 'string') {
    str = str.replace(/\&amp;/g,'&');
    str = str.replace(/\&nbsp;/g,' ');
		str = str.replace(/\&lt;/g,'<');
    str =  str.replace( /(<([^>]+)>)/g, '');
    str = str.replace(/<\/?[^>]+(>|$)/g, '');
  }
  return str;
}

export function blobToBase64(blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
      reader.onloadend = () => {
          resolve(reader.result);
      };
  });
};