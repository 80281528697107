export const MenuStudent = [
   {
      'id': 1,
      'name': 'Dashboard',
      'link': '/dashboard',
      'icon': 'desktop'
   },
   {
      'id': 2,
      'name': ' Student Assessment',
      'link': '/student/batch_mgt',
      'icon': 'briefcase'
   },
   {
      'id': 3,
      'name': ' My Goal',
      'link': '/student/goal_mgt',
      'icon': 'certificate'
   },
]
export const MenuTeacher = [
   {
      'id': 1,
      'name': 'Dashboard',
      'link': '/dashboard',
      'icon': 'user'
   },
   {
      'id': 2,
      'name': 'Students',
      'link': '/student_mgt',
      'icon': 'user'
   },
   {
      'id': 3,
      'name': 'Calender',
      'link': '/batch_view',
      'icon': 'user'
   },
   {
      'id': 4,
      'name': 'Event',
      'link': '/event_mgt',
      'icon': 'user'
   },
   {
      'id': 5,
      'name': 'Assessment',
      'link': '/batch_mgt',
      'icon': 'user'
   },
    {
      'id': 8,
      'name': 'Reinforcement',
      'link': '/reinforcement',
      'icon': 'user'
   }, {
      'id': 6,
      'name': 'Content',
      'link': '/content_mgt',
      'icon': 'user'
   },
   {
      'id': 7,
      'name': 'Communication',
      'link': '/coulege_list',
      'icon': 'user'
   },
   {
      'id': 9,
      'name': 'Resource Library',
      'link': '/blog_mgt',
      'icon': 'user'
   }
]
export const MenuParent = [
   {
      'id': 1,
      'name': 'link1',
      'link': '/dashboard',
      'icon': 'user'
   },
   {
      'id': 2,
      'name': 'link2',
      'link': '/dashboard',
      'icon': 'user'
   },
   {
      'id': 3,
      'name': 'link4',
      'link': '/dashboard',
      'icon': 'user'
   },
   {
      'id': 4,
      'name': 'link5',
      'link': '/dashboard',
      'icon': 'user'
   }
]