import * as config from '../system/config';

import { Breadcrumbs, Loading, input } from '../library/elements';
import { Field, reduxForm } from 'redux-form'
import { getUserData, pricingFormSubmit, validationNull } from '../account/account_action';

import CheckoutFormMobile from '../../src/blog/CheckoutFormMobile';
import { Elements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { loadStripe } from '@stripe/stripe-js';
import moment from "moment";

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

class Mpricing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            planLabel: "",
            displayBuyNow: true,
            paymentCompleted: "",
            displayPaymentForm: false,
            payableAmount: 20,
            paymentStartDate: '',
            paymentEndDate: '',
            paymentPackageName: '',
            paymentUserId: '',
            paymentLoginUser: '',
            paymentRole: '',
            selectedPlanLabel : ''
        };
    }

    componentWillMount() {
        this.props.validationNull();
        //this.fnReload();
    }

    componentDidMount() {
        document.getElementById('footer').style.display = 'none';
        document.getElementById('header-main').style.display="none";

        if (cookie.load("packageEndDate") != 'null' && cookie.load("teacher_id") !== undefined) {
            console.log("iff1");
            var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
            if(moment().isAfter(endDate, 'days')) {
                console.log("iff11111111");
                this.setState({ planLabel: "Plan is expired since "+ endDate });
                this.setState({ displayBuyNow: true });
            }
            else {
                console.log("iff2");
                this.setState({ planLabel: "Your Plan is in Progress till " + endDate });
                this.setState({ displayBuyNow: false });
            }
        } else {
            console.log("iff3");
            if (cookie.load("loginType") == 'teacher') {
                this.setState({ displayBuyNow: true });
            }
        }

        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get('email');
        if(email != ''){
            var _this = this;
            _this.setState({ isLoading: true });
            _this.props.getUserData(email, function (err, result) {
                _this.setState({ isLoading: false });
            });
            //window.location.reload();
            //setTimeout(function(){ console.log("After 3 seconds !!!!");window.location.reload() },2000);
            console.log("this.state.displayBuyNow",this.state.displayBuyNow);
        }

    }

    fnReload = () => {
        window.location.reload();
    }

    setPurchase(startDate, packageDuration, totalAmount, packageName,is_login) {
        if(is_login !== undefined){
            let date = new Date(startDate);
            let endDate = new Date(date.setMonth(date.getMonth() + packageDuration));
    
            this.setState({
                payableAmount: totalAmount,
                paymentStartDate: startDate,
                paymentEndDate: endDate,
                paymentPackageName: packageName,
                paymentUserId: cookie.load("teacher_id"),
                paymentLoginUser: cookie.load("login_user"),
                paymentRole: 'teacher',
                displayPaymentForm: true,
                selectedPlanLabel: "Your Selected Plan "+packageName+' $'+totalAmount
            });
        } else {
            window.location.href = '/';
        }
    }
    
    renderAlert() {
        if (this.props.errorMessage) {
            return (
                <div className="alert alert-warning">
                    <strong>Oops! </strong> {this.props.errorMessage}
                </div>
            )
        }
    }

    render() {
        const { handleSubmit, errorMessage } = this.props;
        var isLoading = this.state.isLoading;

        if (errorMessage) {
            isLoading = false;
        }

        return (
            <div id="blogs" className="page mobile mb-5">
                {/* {isLoading ? <Loading /> : null} */}
                <div className="container">
                    <div className="page-heading">
                        <div className="d-flex justify-content-between breadcrumb-container">
                            <div className="p-2 mt-1 mb-2 breadcrumb-title text-primary font-weight-bold">
                            </div>
                        </div>
                    </div>
                    {
                        this.state.displayPaymentForm && <div className="row">
                            <div className='col-md-3'></div>
                            <div className='col-md-6'>
                                <Elements stripe={stripePromise}>
                                    <CheckoutFormMobile
                                        payableAmount={this.state.payableAmount}
                                        paymentStartDate={this.state.paymentStartDate}
                                        paymentEndDate={this.state.paymentEndDate}
                                        paymentPackageName={this.state.paymentPackageName}
                                        paymentUserId={this.state.paymentUserId}
                                        paymentLoginUser={this.state.paymentLoginUser}
                                        paymentRole={this.state.paymentRole}
                                        setPaymentCompleted={this.state.paymentCompleted}
                                        selectedPlanLabel={this.state.selectedPlanLabel}
                                    />
                                </Elements>
                            </div>
                            <div className='col-md-3'></div>
                        </div>
                    }
                    { !this.state.displayPaymentForm && 
                    <div>
                        <div className="row">
                            <form>
                                <div className="col-sm-12 article-list-container">
                                    <div className="row">
                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header p-1">Montly</li>
                                                <li className="grey">$19.99 / 1 Month</li>
                                                <li className="grey">
                                                    <small className='text-danger planlabel'>{this.state.planLabel}</small><br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 1, 19.99, '1 Month',cookie.load('is_login'))} className="button p-1">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header p-1">3 Months</li>
                                                <li className="grey">$49.99 for 3 Months</li>
                                                <li className="grey">
                                                    <small className='text-danger planlabel'>{this.state.planLabel}</small><br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 3, 49.99, '3 Month',cookie.load('is_login'))} className="button p-1">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header p-1">6 Months</li>
                                                <li className="grey">$169.99 for 6 Months</li>
                                                <li className="grey">
                                                    <small className='text-danger planlabel'>{this.state.planLabel}</small><br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 6, 169.99, '6 Month',cookie.load('is_login'))} className="button p-1">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns">
                                            <ul className="price">
                                                <li className="header p-1">12 Months</li>
                                                <li className="grey">$219.99 for 12 Months</li>
                                                <li className="grey">
                                                    <small className='text-danger planlabel'>{this.state.planLabel}</small><br /><br />
                                                    {
                                                        this.state.displayBuyNow && <a href="#" onClick={() => this.setPurchase(new Date(), 12, 219.99, '12 Month',cookie.load('is_login'))} className="button p-1">Buy Now</a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="columns mb-5">
                                            <ul className="price mb-5">
                                                <li className="header1">
                                                    <a className="" onClick={() => this.fnReload()}><i className="fa fa-refresh"></i></a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    }
                </div>
            </div>

        )
    }
}

function validate(formProps) {
    const errors = {}

    if (!formProps.email) {
        errors.email = 'Required Email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
        errors.email = 'Invalid Email Address'
    }

    return errors
}

Mpricing = reduxForm({
    form: 'mpricing',
    validate: validate
})(Mpricing)

function mapStateToProps(state) {
    //console.log("state.account ===>",state.account);
    return {
        errorMessage: state.account.error,
        //teacher_blogs_list: state.blog.teacher_blogs_list,
        //subject_list: state.teacher.all_subject_list,

    }
}
export default connect(mapStateToProps, { pricingFormSubmit,getUserData, validationNull })(Mpricing);
