import {
    FETCH_GOAL_INFO,
    FETCH_PARENT_ACCOUNT_PROFILE,
    FETCH_STUDENT_INFO,
    FETCH_TASK_INFO,
    GET_All_SUBJECTS,
    GET_BATCH_LIST,
    GET_COULAGE_LIST,
    GET_GOAL_LIST,
    GET_PARENTDASHBOARD_LIST,
    GET_PARENT_TEACHER_CHAT_LIST,
    GET_STUDENT_LIST,
    GET_STUDENT_TASK_LIST,
    GET_TEACHER_SUBJECTS,
    GET_USER_RECENT_CHAT_LIST,
    GET_USER_TEACHER_RECENT_CHAT_LIST,
    VALIDATION_ERROR
} from './parent_action';

export default function ParentReducer(state = {}, action) {

    //console.log("Parent Reducer Action Type ",action.type,"<<==>>Parent Reducer Action Payload", action.payload);
    switch (action.type) {

        case VALIDATION_ERROR:
            return { ...state, error: action.payload }
        case FETCH_PARENT_ACCOUNT_PROFILE:
            return { ...state, parent_profile: action.payload.data }
        case GET_BATCH_LIST:
            return { ...state, batch_list: action.payload.data }
        case GET_All_SUBJECTS:
            return { ...state, all_subject_list: action.payload.data }
        case GET_STUDENT_LIST:
            return { ...state, student_list: action.payload.data }
        case GET_PARENT_TEACHER_CHAT_LIST:
            return { ...state, parent_teacher_list: action.payload.data }  
        case GET_USER_RECENT_CHAT_LIST:
            return { ...state, get_recent_chat_list: action.payload.data }      
        case GET_USER_TEACHER_RECENT_CHAT_LIST:
            return { ...state, get_teacher_recent_chat_list: action.payload.data}
        case FETCH_STUDENT_INFO:
            return { ...state, student_info: action.payload }
        case GET_TEACHER_SUBJECTS:
            return { ...state, teacher_subject_list: action.payload.data }
        case GET_STUDENT_TASK_LIST:
            return { ...state, student_task_list: action.payload.data }
        case FETCH_TASK_INFO:
            return { ...state, parent_student_task_info: action.payload.data }
        case GET_COULAGE_LIST:
            return { ...state, coulage_list: action.payload.data }
        case FETCH_GOAL_INFO:
            return { ...state, goal_student_info: action.payload.data }
        case GET_GOAL_LIST:
            return { ...state, goal_list: action.payload.data }
        case GET_PARENTDASHBOARD_LIST:
            return { ...state, parent_dashboard_list: action.payload.data }    
        default:
            return state;

    }
}