import React,  { Component, PropTypes } from 'react';
import { connect } from 'react-redux';

import { createAlert } from '../../library/function';
import { deleteBatch } from '../parent_action';
class modalDeleteConfirmation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading:false,
      error: ''
    };
    this.deleteConfim = this.deleteConfim.bind(this);
  }

  closeModal(){
    this.props.removeModal();
  }

  deleteConfim(filter){
    this.setState({isLoading: true});
    var _this = this;
    this.props.deleteBatch(filter.id, function(err, result) {
      if(result != undefined){
        if(result.data.err != undefined){
          _this.setState({isLoading: false});
          _this.setState({error: result.data.err});
        } else {
          _this.props.refresh();
          createAlert({message:'Batch has been Deleted!'})
          _this.props.removeModal();
          _this.setState({isLoading: false});
        }
      }
    });
  }

  render() {
    const { filter } = this.props;
    const { isLoading, error } = this.state;
    if(filter != undefined && filter){
      return (
        <div className="common-modal p-1">


          {
            error
            ?
            <div className="row">
              <div className="col-sm-12 alert alert-warning">
                {error}
              </div>
            </div>
            :
            <div className="row">
              <div className="col-sm-6">
                <button onClick={()=>this.deleteConfim(filter)} className="btn btn-danger mr-2 btn-sm btn-block">Yes</button>
              </div>
              <div className="col-sm-6">
                <button onClick={()=>this.closeModal()} className="btn btn-secondary mr-2 btn-sm btn-block">No</button>
              </div>
            </div>
          }
        </div>
      );
    } else {
      return (
        <div className="common-modal p-1">
          <div className="row">
            <div className="col-sm-12">
              Something wrong. please try again.
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <button onClick={()=>this.closeModal()} className="btn btn-secondary mr-2 btn-sm btn-block">No</button>
            </div>
          </div>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
  }
}
export default connect(mapStateToProps, { deleteBatch })(modalDeleteConfirmation);
