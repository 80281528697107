import React, { Component } from 'react';

class Loading extends Component {

  render() {
    return (
      <div>
        <div className="loader-overlay"></div>
        <div className="load-wrapp">
          <div className="load-3">
            <p>Loading 3</p>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
