import React, { Component } from 'react';
import logo from '../../images/logo.png';
class Loading extends Component {

  render() {
    return (
      <div>
        <div className="loader-overlay"></div>
        <div className="sk-cube-grid">
          <div className="load-3">
          <img src={logo}  className="img-fluid"/>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
