import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
//import { getBatches } from '../teacher/teacher_action';
import { getBatches } from '../student/student_action';
import { modal } from '../library/modal';

var dateFormat = require("dateformat");
class Batchlist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };

    this.URLFormatter = this.URLFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }
  componentDidMount() {
    console.log(cookie.load('student'));
    var _this = this;
    this.setState({ isLoading: false });
    this.props.getBatches(undefined,function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing { start} to { to} of { total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }
  
  statusFormat(cell, row) {
    if (row.studentGoalStatus == 'completed') {
      return (
        <div className="badge bg-success"> Completed</div>
      );
    } else {
      return (
        <div className="badge bg-primary">Pending</div>
      );
    } 
  }

  statusTeacherFormat(cell, row) {
    if (row.teacherApproved) {
      return (
        <div className="badge bg-success"> Approved</div>
      );
    } else {
      return (
        <div className="badge bg-danger">Pending</div>
      )
    }
  }




  URLFormatter(cell, row) {
    console.log("Rowwww===========",row);
    //let url = "/batch_info/" + row._id;
    let url = 'assessment_info/'+row._id;
    return (
      <div>
        <Link to={url}>{row.name}</Link>
      </div>
    );
  }

  URLStartFormatter(cell, row) {
    //let url = "/batch_info/" + row._id;
    if(row.studentGoalStatus == 'completed'){
      return (
        <div>
          <div className="text-secondary">Completed</div>
        </div>
      );
    } else {
      let url = 'assessment_info/'+row._id;
      return (
        <div>
          <Link to={url} className="text-danger">Let's Start</Link>
        </div>
      );
    }
  }

  locationFormat(cell, row) {
    return (
      <div className="btn btn-sm bg-light text-dark">{row.address}</div>
    );
  }
  subjectFormat(cell, row) {
    return (
      <div className="btn btn-sm bg-light text-dark">{row.subjectName}</div>
    );
  }
  StarttimeFormat(cell, row) {
    return (
      <span className="">   <i className="fa fa-clock-o mr-1"></i>{row.startTime}</span>

    );
  }
  EndtimeFormat(cell, row) {
    return (
      <span className="">   <i className="fa fa-clock-o mr-1"></i>{row.endTime}</span>

    );
  }
  BatchDateFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.batchDate, "mm/dd/yyyy")}</span>


    );
  }

  render() {
    const { batch_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!batch_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      Pending: 'Pending',
      Ongoing: 'Ongoing',
      Completed: 'Completed'
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Assessment List
                     </h5>
                  
                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                    keyField='_id'
                    data={(batch_list != undefined && batch_list.length ? batch_list : [])}
                    bordered={false}
                    striped={false}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: batch_list.length }}
                    options={{
                      sizePerPage: this.state.sizePerPage,
                      page: this.state.currentPage,
                      hideSizePerPage: true,
                      paginationShowsTotal: this.renderPaginationShowsTotal,
                      sortName: sortName,
                      sortOrder: sortOrder,
                      onSortChange: this.onSortChange
                    }}
                  >
                    <TableHeaderColumn
                      dataField='name'
                      columnClassName={''}
                      filter={{ type: 'TextFilter' }}
                      dataAlign="left"
                      width="20%"
                      dataFormat={this.URLFormatter}
                      dataSort={true}>
                      Assessment Name
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                      dataField='subjectName'
                      filter={{ type: 'TextFilter' }}
                      columnClassName={''}
                      dataAlign="center"
                      dataFormat={this.subjectFormat} dataSort={true}>
                      Subject
                    </TableHeaderColumn>
                    */}

                    <TableHeaderColumn
                      dataField='batchDate'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataFormat={this.BatchDateFormat}
                      dataSort={true}>
                      On Date
                       </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='startTime'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataFormat={this.StarttimeFormat}
                      dataSort={true}>
                      Start Time
                       </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='endTime'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataFormat={this.EndtimeFormat}
                      dataSort={true}>
                      End Time
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='status' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Status</TableHeaderColumn>
                    <TableHeaderColumn dataField='teacherApproved' dataAlign="center" dataFormat={this.statusTeacherFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Teacher Status</TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='name'
                      columnClassName={''}
                      filter={{ type: 'TextFilter' }}
                      dataAlign="left"
                      width="20%"
                      dataFormat={this.URLStartFormatter}
                      dataSort={true}>
                      Action
                    </TableHeaderColumn>

                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
                    </div>


    );

  }
}

function mapStateToProps(state) {
  return {
    batch_list: state.teacher.batch_list,

  }
}
export default connect(mapStateToProps, { getBatches })(Batchlist);
