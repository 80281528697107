import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import { modal } from '../library/modal';
import { getBlogList } from '../blog/blog_action';


import modalDeleteConfirmation from './modal/modal_delete_confirmation';
import BlogFormModal from './modal/model_blog';
import DeshHeader from '../../src/teacher/desh_header';
var dateFormat = require("dateformat");
class BlogList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };

    this.refresh = this.refresh.bind(this);
    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }
  componentDidMount() {
    this.setState({ isLoading: true });
    var _this = this;
    this.props.getBlogList(function (err, result) {
        if (result) {
            _this.setState({ isLoading: false });
        }
    });
}
  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing { start} to { to} of { total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }

  refresh() {
    this.setState({ isLoading: true });
    var _this = this;
    var page = this.state.currentPage;

    this.props.setFilters(
      'blogs',
      {
        start: ((page - 1) * this.state.sizePerPage),
        limit: this.state.sizePerPage,
      }
    );
    this.props.getBlogList(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }
  addDeleteConfirmationModal(filter) {
    modal.add(modalDeleteConfirmation, {
      title: "Are you sure you want to delete?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      filter: filter,
      refresh: () => this.componentDidMount()
    });
  }
  actionFormatter(cell, row, props) {
    return (
      <div>
        <Link   onClick={() => this.BlogModel('Edit Blog ', row)}  className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-pencil"></i></Link>
        <Link onClick={() => this.addDeleteConfirmationModal({ type: 'batch', id: row._id })} className="btn btn-danger mr-2 btn-sm"><i className="fa fa-trash-o"></i></Link>
      </div>
    );
  }

  BlogDateFormat(cell, row) {
    return (

      <span className="">
        <i className="fa fa-calendar mr-1"></i>
        {dateFormat(row.createdAt, "mm/dd/yyyy")}</span>
    );
  }
  statusFormat(cell, row) {
    if (row.status == 'Enable') {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else {
      return (
        <div className="badge bg-danger ">Inactive</div>
      );
    }
  }
  BlogModel(title, blogData) {
    modal.add(BlogFormModal, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      blogData: blogData,
    });
  }
  render() {
    const { blog_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!blog_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    const Status = {
      Enable: 'Enable',
      Desable: 'Desable',
    };
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">
              <div className="page" id="page-batch">


                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Blog List
                     </h5>
                    {/* <button onClick={() => this.BlogModel('Add New', '')} className="btn alc-btn  alc-btn-primary alc-btn-shadow alc-btn-small"><i className="fa fa-plus mr-2"></i><b>Add New Blog</b></button> */}
                    <button onClick={() => this.BlogModel('Add New', '')} className="text-primary mt-3 btn btn-primary btn-sm mr-2"><i className="fa fa-plus mr-2 text-white"></i><b className='text-white'>Add New</b></button>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    data={(blog_list != undefined && blog_list.length ? blog_list : [])}
                    bordered={false}
                    striped={false}
                    pagination={true}
                    fetchInfo={{ dataTotalSize: blog_list.length }}
                    options={{
                      sizePerPage: this.state.sizePerPage,
                      page: this.state.currentPage,
                      hideSizePerPage: true,
                      paginationShowsTotal: this.renderPaginationShowsTotal,
                      sortName: sortName,
                      sortOrder: sortOrder,
                      onSortChange: this.onSortChange
                    }}
                  >
                    <TableHeaderColumn
                      dataField='title'
                      columnClassName={''}
                      filter={{ type: 'TextFilter' }}
                      dataAlign="left"
                      width="45%"
                      dataSort={true}>
                      Blog Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='subjectName'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataSort={true}>
                      Subject
                      </TableHeaderColumn>

                    
                    <TableHeaderColumn
                      dataField='sortOrder'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataSort={true}>
                      Sort Order
                    </TableHeaderColumn>

                    <TableHeaderColumn dataField='status' dataAlign="center" dataFormat={this.statusFormat} filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort width='150px'>Status</TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='createdAt'
                      columnClassName={''}
                      dataAlign="left"
                      dataFormat={this.BlogDateFormat}>
                      Date Added
                       </TableHeaderColumn>
                    <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    );

  }
}

function mapStateToProps(state) {
  return {
    blog_list: state.blog.blog_list,

  }
}
export default connect(mapStateToProps, { getBlogList })(BlogList);
