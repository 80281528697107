import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { addStudent, getTeacherSubjects, updateStudent, validationNull } from '../teacher_action';
import { getParents, getUniqueUserName } from '../../account/account_action';
import { input, textarea } from '../../library/elements';

import DatePicker from 'react-date-picker';
import Select from 'react-select';
import _ from 'lodash';
import { connect } from 'react-redux';

class StudentFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPasswordText: false,
      isPasswordConfirmText: false,
      multi: true,
      multiValue: [],
      mloptions: [],
      mlvalue: undefined,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {

    var _this = this;
    this.props.validationNull();
    this.props.getTeacherSubjects();
    this.props.getUniqueUserName();
    this.props.getParents();

    if (this.props.studentData != undefined && this.props.studentData.subjectList) {
      var contentSubjects = this.props.studentData.subjectList;
      var options = [];
      this.setState({ multiValue: [] });
      this.setState({ mloptions: [] });
      if (contentSubjects != undefined && contentSubjects.length > 0) {
        var cwords = [];
        var split_content_tags = contentSubjects.split(",");
        for (var i in split_content_tags) {
          cwords.push({ value: split_content_tags[i], label: split_content_tags[i], className: "Select-create-option-placeholder" });
          options.push({ value: contentSubjects[i], label: contentSubjects[i] });
        }
        _this.setState({ multiValue: cwords });
      }
      _this.setState({ mloptions: options })
    }

  }


  handleFormSubmit(formProps) {


    this.setState({ isLoading: true });
    var _this = this;
    // for filter words
    const temp_subjects = this.state.multiValue;
    var new_subjects = [];
    if (temp_subjects.length > 0) {
      new_subjects = _.map(temp_subjects, 'value');
    }
    console.log("New Subjects =======>",new_subjects);
    formProps.subjectList = new_subjects.toString();

    // end  filter words
    if (this.props.studentData) {
      formProps.studentId = formProps._id;
      formProps.user_id = this.props.studentData.userId;
      //console.log(this.props.studentData.userId);
      this.props.updateStudent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addStudent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }
  closeModal() {
    this.props.removeModal();
  }


  /* close product option */
  handleOnChange(value) {
    const { multi } = this.state;
    if (multi) {
      this.setState({ multiValue: value });
    } else {
      this.setState({ value });
    }
  }


  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }
  render() {
    const { handleSubmit, teacher_subject_list, errorMessage, parent_list } = this.props;
    //console.log("Props ====================>",this.props.studentData.userId);
    var { isLoading, isPasswordText, isPasswordConfirmText, mloptions, multi, multiValue, mlvalue } = this.state;

    if (errorMessage) {
      isLoading = false;
    }
    var past18Year = new Date().getFullYear() - 18;
    var past18YearDate = new Date();
    past18YearDate.setFullYear(past18Year);

    var subjectData = [];
    for (var i in teacher_subject_list) {
      subjectData.push({ label: teacher_subject_list[i].name, value: teacher_subject_list[i]._id });
    }
    var parentData = [];
    for (var i in parent_list) {
      parentData.push({ label: parent_list[i].name, value: parent_list[i]._id });
    }

    return (
      <div className="sk-form">
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-4 mb-3">
              <Field name="firstName" type="text" component={input} label="First Name" is_label={true} isRequired={true} className="form-control" />
            </div>
            <div className="col-sm-4">
              <Field name="lastName" type="text" component={input} label="Last Name" is_label={true} isRequired={true} className="form-control" />
            </div>
          {/* <div className="form-group row">
            <div className="col-sm-12">
              <Field name="email" row="" type="text" component={input}  label="Parent Email" isRequired={true} is_label={true} className="form-control" />
            </div>
          </div> */}

            
            <div className="col-sm-4">
              <label className="control-label">Birthday</label>
              <Field name={`dob`} component={dob => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      maxDate={new Date()}
                      name="dob"
                      format={"MM/dd/y"}
                      value={

                        (!dob.input.value || dob.input.value == '0000-00-00')
                          ? past18YearDate
                          :
                          new Date(dob.input.value)
                      }
                      onChange={(date) => {
                        dob.input.onChange(date);
                      }}
                    />
                  </div>
                )
              }} className="form-control" />
            </div>
            <div className="col-sm-4">
              <Field name="age" type="text" component={input} label="Age" is_label={true} isRequired={true} className="form-control" />
            </div>
          
            <div className="col-sm-4">
              <label>Parents</label>
              <Field name="parentId" component={parentId => {
                return (
                  <Select simpleValue
                    value={parentId.input.value}
                    placeholder=" Select Parent "
                    options={parentData}
                    onChange={(e) => {
                      parentId.input.onChange(e);
                    }} />
                )
              }} className="form-control" />
            </div>
            <div className="col-sm-4">
              <Field name="username" type="text" component={input} label="Username / Email" is_label={true} className="form-control" />
            </div>
            <div className="col-sm-4">
              <label className="control-label">Subjects</label>
              <Field name="subjectList" component={subjectList => {
                return (
                  <div>
                    <Select.Creatable
                      allowCreate={true}
                      multi={multi}
                      options={mloptions}
                      onChange={this.handleOnChange}
                      value={multi ? multiValue : mlvalue}
                    />
                    <small>Press Enter or Tab button</small>
                  </div>
                )
              }} className="form-control" />
            </div>
            <div className="col-sm-4">
              <Field name="grade" type="text" component={input} label="Grade" is_label={true} className="form-control" />
            </div>
            <div className="col-sm-12">
              <Field name="address" row="" component={textarea} label="Address" isRequired={true} is_label={true} className="form-control" />
            </div>
          </div>

          {
            this.props.studentData
              ?
              <div className="form-group row">

                <div className="col-sm-6">
                  <label className="form-lable required">Status</label>
                  <Field

                    name="status"
                    component={status =>
                      <div>
                        <select
                          name="status"
                          className="form-select"
                          value={status.input.value}
                          onChange={(event) => {
                            status.input.onChange(event.target.value);
                          }}
                        >
                          <option value="">--Select--</option>
                          <option value="true">Active</option>
                          <option value="false">InActive</option>
                        </select>
                        {status.meta.touched && ((status.meta.error && <div className="error">{status.meta.error}</div>) || (status.meta.warning && <div className="error">{status.meta.warning}</div>))}
                      </div>
                    } />
                </div>
              </div>

              :
              null
          }
          <div className="row">

            <div className="col-sm-3">
              {
                this.props.studentData
                  ?
                  <button action="form_student" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Student'}
                  </button>
                  :
                  <button action="form_student" className=" mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Student'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {
  const errors = {}

  //console.log(" Parent ID ===>",formProps.parentId);
  //return false;

  if (!formProps.firstName) {
    errors.firstName = 'Required First Name';
  }
  if (!formProps.email) {
    errors.email = 'Required Email'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
    errors.email = 'Invalid Email Address'
  }
  if (!formProps.lastName) {
    errors.lastName = 'Required Last Name';
  }
  if (formProps.parentId === undefined) {
    errors.username = 'Required Parent Selection with this student !!!';
  }
  if (!formProps.std) {
    errors.std = 'Required std';
  }
  if (!formProps.age) {
    errors.age = 'Required Age';
  } else {
    if (isNaN(formProps.age)) {
      errors.age = 'Age must be numeric only!';
    }
  }
  if (!formProps.username) {
    errors.username = 'Required Unique User Name';
  }

  if (!formProps.address) {
    errors.address = 'Required Address';
  }
  return errors
}

function mapStateToProps(state, ownProps) {

  var iniStudent = {};
  iniStudent.username = state?.account?.unique_username?.unique
  if (ownProps.studentData) {
    var iniStudent = ownProps.studentData;
  }
  return {
    errorMessage: state.account.error,
    initialValues: iniStudent,

    teacher_subject_list: state.teacher.teacher_subject_list,
    parent_list: state.account.parent_list,

  }
}

StudentFormModal = reduxForm({
  form: 'form_student',
  validate: validate
})(StudentFormModal);


export default connect(mapStateToProps, { addStudent, updateStudent, getTeacherSubjects, getParents, getUniqueUserName, validationNull })(StudentFormModal);
