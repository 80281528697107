import React, { Component } from 'react';

class Image extends Component {

  render() {
    const { input } = this.props;
console.log('--input---',input);
    return (
      <div className="fileUpload btn shadow-none btn-outline-default p-0">
        <div className="">
          {
            input.value
              ?
              <img src={input.value.dataUrl ? input.value.dataUrl : input.value} className="input-image-content" width='100' height='100' />
              :
              <img className="input-image-content" src="https://dummyimage.com/160x160/ebebeb/000.png&text=Learning3ly" />
          }
        </div>
        <input type="file"
          name="image"
          className="m-l-20"
          onChange={(e) => {
          
            var files = e.target.files;
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onload = function (e) {
              var picFile = e.target;
              files.dataUrl = picFile.result;
              input.onChange(files);
            }
            reader.readAsDataURL(file);
          }}
        />
      </div>
    )
  }
}

export default Image;

