import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Loading, input } from '../library/elements';
import React, { Component } from 'react';

import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { getTeacherCoulagelist } from './parent_action';
import { modal } from '../library/modal';
import moment from "moment";

class CoulageList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
      isActivetab: false
    };
    this.actionFormatter = this.actionFormatter.bind(this);
    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }

  componentDidMount() {

    // let accessData = 'Granted';
    // var endDate = moment(cookie.load("packageEndDate")).format("MM/DD/yyyy");
    // if(endDate == 'Invalid date'){
    //   accessData = 'NoAccess';
    // }
    // if(moment().isAfter(endDate, 'days')) {
    //   accessData = 'NoAccess';
    // }
    // if(accessData == 'NoAccess'){
    //   this.props.history.push('/pricing');
    // } else {
      this.setState({
        currentPage: 1,
        sortName: undefined,
        sortOrder: undefined,
      })
      var _this = this;
      this.setState({ isLoading: true });
      this.props.getTeacherCoulagelist(function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
        }
      });
    //}
  }


  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing { start} to { to} of { total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    console.info('onSortChange', arguments);
    this.setState({
      sortName,
      sortOrder
    });
  }

  

  actionFormatter(cell, row, props) {

    return (
      <div>
        <Link className="btn btn-sm btn-primary alc-bg-orange alc-btn-shadow mr-1"><i className="fa fa-comments mr-2"></i>Chat</Link>
      </div>
    );
  }

  PhoneFormat(cell, row) {
    return (
      <span className="">   <i className="fa fa-phone mr-2"></i>{row.phoneNumber?row.phoneNumber:""}</span>

    );
  }
  EmailFormat(cell, row) {
    return (
      <span className="">   <i className="fa fa-envelope mr-2"></i>{row.email}</span>
    );

  }
  activetab() {
    this.setState({ isActivetab: !this.state.isActivetab });
  }
  render() {
    const { coulage_list } = this.props;
    const { isLoading, sortName, sortOrder, isActivetab } = this.state;
    if (coulage_list == undefined) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    var teacher_list = [];
    for (var i in coulage_list) {
      if (coulage_list[i].role === 'teacher') {
        teacher_list.push(coulage_list[i])
      }
    }

    const Status = { true: 'Active', false: 'Inactive' };
    return (

      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}

        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">

            <div className="content container mt-4">

              <div className="page" id="page-batch">


                <div className="card  table-responsive mb-4">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="mt-2 font border-bottom font-weight-bold list-title">
                      <i className="fa fa-bag mr-1"></i>Teacher Colleague List
                     </h5>

                  </div>
                  <div className="card-body">
                    <BootstrapTable className="alca-data-table"
                    keyField='id'
                    data={(teacher_list != undefined && teacher_list.length ? teacher_list : [])}
                    bordered={false}
                    striped={false}
                    pagination={true}

                    fetchInfo={{ dataTotalSize: teacher_list.length }}
                    options={{
                      sizePerPage: this.state.sizePerPage,
                      page: this.state.currentPage,
                      hideSizePerPage: true,
                      paginationShowsTotal: this.renderPaginationShowsTotal,
                      sortName: sortName,
                      sortOrder: sortOrder,
                      onSortChange: this.onSortChange
                    }}
                  >
                    <TableHeaderColumn
                      dataField='name'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataSort={true}>
                      Teacher Name
              </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='phoneNumber'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataFormat={this.PhoneFormat}
                      dataSort={true}>
                      Phone Number
              </TableHeaderColumn>


                    <TableHeaderColumn
                      dataField='email'
                      columnClassName={''}
                      dataAlign="left"
                      filter={{ type: 'TextFilter' }}
                      dataFormat={this.EmailFormat}
                      dataSort={true}>
                      Email
              </TableHeaderColumn>




                    {/* <TableHeaderColumn dataFormat={this.actionFormatter} dataAlign="center" >Action</TableHeaderColumn> */}


                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
                    </div>




    );

  }
}

function mapStateToProps(state) {
  return {
    coulage_list: state.parent.coulage_list,
  }
}
export default connect(mapStateToProps, { getTeacherCoulagelist })(CoulageList);
