import React, { Component } from 'react';

import { Loading } from '../library/elements';
import UpdateProfile from '../student/modal/modal_edit_profile';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { modal } from '../library/modal';

class StudentProfile extends Component {

  addEditProfileModal() {
    modal.add(UpdateProfile, {
      title: "Edit Student  Profile",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      modalId: 'edit-profile',
    });
  }

  render() {
    const { student_profile } = this.props;

    if (!student_profile) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-1">
              <Loading />
            </div>
            <div className="col-sm-5"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="top-profile-container">
        <div className="container mt-3">
          <div className="row">
            <div className="col-sm-3">
            </div>
            <div className="col-sm-6">
              <div className="card card-parent-card">

                <div className="card-body pt-4">
                  <div className="title-block text-center">
                    <img className="img-thumbnail rounded-circle profile-image" src={student_profile.photo != "" ? student_profile.photo : "https://learning3ly.com/static/media/logo.edade388.png"} width="100px" />
                    <h4 className="mt-2">
                      {student_profile?.firstName}
                      <button onClick={() => this.addEditProfileModal()} className="btn btn-outline-primary round btn-outline-edit-profile ml-2 rounded-circle"><i className="fa fa-pencil"></i></button>
                    </h4>
                  </div>
                  <hr />
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <tbody>

                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6 border-top-0">Student Name</td>
                          <td className="second border-top-0 col-6">:  {student_profile?.firstName} {student_profile?.lastName}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Email</td>
                          <td className="second border-top-0 col-6">: {student_profile?.username}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Grade</td>
                          <td className="second border-top-0 col-6">: {student_profile?.grade}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Location</td>
                          <td className="second border-top-0 col-6">:{student_profile?.address}</td>
                        </tr>
                        <tr>
                          <td className="first  font-weignt-bold text-primary  h6">Age</td>
                          <td className="second border-top-0 col-6">: {student_profile?.age}</td>
                        </tr>
                        
                        {
                          student_profile?.subjectList && 
                          <tr>
                          <td className="first  font-weignt-bold text-primary h6 ">Subject list</td>
                          <td className="second "> :
                            {
                              student_profile?.subjectList?.map((subject) => {
                                return (
                                  <span className="ml-1 badge bg-primary mr-1">{subject.subject}</span>
                                )
                              })
                            }

                          </td>
                        </tr>
                        }
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">

            </div>
          </div>

        </div>
      </div>

    )
  }
}

function mapStateToProps(state) {
  return {
    student_profile: state.student.student_profile,
  };
}

export default connect(mapStateToProps)(StudentProfile);
