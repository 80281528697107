import React, { Component } from "react";

import { blobToBase64 } from '../function';

const audioType = "audio/webm";

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {},
      seconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  handleAudioPause(e) {
    e.preventDefault();
    clearInterval(this.timer);
    this.mediaRecorder.pause();
    this.setState({ pauseRecord: true });
  }
  handleAudioStart(e) {
    e.preventDefault();
    this.startTimer();
    this.mediaRecorder.resume();
    this.setState({ pauseRecord: false });
  }

  startTimer() {
    //if (this.timer === 0 && this.state.seconds > 0) {
    this.timer = setInterval(this.countDown, 1000);
    //}
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds + 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  async componentDidMount() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (navigator.mediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (this.props.mimeTypeToUseWhenRecording) {
        this.mediaRecorder = new MediaRecorder(stream, { mimeType: this.props.mimeTypeToUseWhenRecording });
      } else {
        this.mediaRecorder = new MediaRecorder(stream);
      }
      this.chunks = [];
      this.mediaRecorder.ondataavailable = e => {
        if (e.data && e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };
    } else {
      this.setState({ medianotFound: true });
      console.log("Media Decives will work only with SSL....");
    }
  }

  startRecording() {
    // wipe old data chunks
    this.chunks = [];
    // start recorder with 10ms buffer
    this.mediaRecorder.start(15);
    this.startTimer();
    // say that we're recording
    this.setState({ recording: true });
  }

  stopRecording(e) {
    clearInterval(this.timer);
    this.setState({ time: {} });
    e.preventDefault();
    // stop the recorder
    this.mediaRecorder.stop();
    // say that we're not recording
    this.setState({ recording: false, pauseRecord: false, });
    // save the video to memory
    this.saveAudio();
  }

  handleReset(e) {
    if (this.state.recording) {
      this.stopRecording(e);
    }
    this.setState({
      time: {},
      seconds: 0,
      recording: false,
      medianotFound: false,
      audios: [],
      audioBlob: null
    });

  }

  saveAudio() {
    // convert saved chunks to blob
    const blob = new Blob(this.chunks, { type: audioType });


    // generate video url from blob
    const audioURL = window.URL.createObjectURL(blob);
    // append videoURL to list of saved videos for rendering
    const audios = [audioURL];
    this.setState({ audios, audioBlob: blob });
    this.props.handleAudioStop({
      url: audioURL,
      blob: blob,
      chunks: this.chunks,
      duration: this.state.time,
      audio:blobToBase64(blob)
    });
  }

  render() {
    const { recording, audios, time, medianotFound, pauseRecord } = this.state;
    const { showUIAudio, audioURL } = this.props;
    return (
      <div className='card bg-light text-center'>
        <div className="card-body">
          <div className="record-section">
            <button
              onClick={(e) => this.handleReset(e)}
              className={"btn btn-clear btn-info  mb-3"}
            >
              <i className="fa fa-refresh" ></i> Clear
              </button>
            <div className="audio-player-section">
              <div className="audio-player">
                {audios.length && showUIAudio ? (
                  <audio controls controlsList="nodownload">
                    <source src={audios[0]} type="audio/ogg" />
                    <source src={audios[0]} type="audio/mpeg" />
                  </audio>
                ) : null}
              </div>
              <div className={'audio-duration mb-3'}>
                <span className={'h2 duration-minute'}>
                  {time.m !== undefined
                    ? `${time.m <= 9 ? "0" + time.m : time.m}`
                    : "00"}
                </span>
                <span className={'h2 audio-divider'}>:</span>
                <span className={'h2 duration-second'}>
                  {time.s !== undefined
                    ? `${time.s <= 9 ? "0" + time.s : time.s}`
                    : "00"}
                </span>
              </div>

              {!recording ? (
                <p className={'audio-helptext h6 mt-2 mb-3 text-dark'}>Press the microphone to record</p>
              ) : null}
            </div>


            {!recording ? (
              <button
                onClick={e => this.startRecording()}
                className={"btn btn-microphone rounded-circle btn-lg btn-danger mr-1"}
              >
                <i className="fa fa-microphone" ></i>
              </button>


            ) : (
              <div className="record-action">


                <button
                  onClick={e => this.stopRecording(e)}
                  className={"btn btn-clear btn-danger mr-2"}
                >
                  <i className="fa fa-stop" ></i> Stop
                    </button>
                <button
                  onClick={
                    !pauseRecord
                      ? e => this.handleAudioPause(e)
                      : e => this.handleAudioStart(e)
                  }
                  className={"btn btn-clear btn-primary"}
                >



                  {pauseRecord ?
                    <span>   <i className="fa fa-play"></i> Play</span> :
                    <span>   <i className="fa fa-pause"></i> Pause</span>
                  }
                </button>
              </div>
            )}
          </div>

        </div>
      </div>

    );
  }
}

export default Recorder;

Recorder.defaultProps = {
  hideHeader: false,
  mimeTypeToUseWhenRecording: null
}
