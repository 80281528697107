import * as config from '../system/config';

import axios from 'axios';
import cookie from 'react-cookie';
import { createAlert } from '../library/function';

export const VALIDATION_ERROR = "VALIDATION_ERROR";

export const GET_TASK_LIST = "GET_TASK_LIST";
export const GET_GOAL_LIST = "GET_GOAL_LIST";
export const FETCH_GOAL_STUDENT_INFO = "FETCH_GOAL_STUDENT_INFO";
export const FETCH_GOAL_STUDENT_INFO_ASSESSMENT = "FETCH_GOAL_STUDENT_INFO_ASSESSMENT";
export const GET_BATCH_LIST = "GET_BATCH_LIST";
export const FETCH_STUDENT_ACCOUNT_PROFILE = "FETCH_STUDENT_ACCOUNT_PROFILE";
export const CHECK_GOAL_STATUS = "CHECK_GOAL_STATUS";
export const SET_CURRENT_AUDIO = "SET_CURRENT_AUDIO";
export const SET_CURRENT_AUDIO_ASSESSMENT = "SET_CURRENT_AUDIO_ASSESSMENT";
export const GET_STUDENT_TEACHER_CHAT_LIST = "GET_STUDENT_TEACHER_CHAT_LIST";
export const SET_ATTACHMENT = "SET_ATTACHMENT";
export const SET_ATTACHMENT_ASSESSMENT = "SET_ATTACHMENT_ASSESSMENT";
export const GET_STUDENTDASHBOARD_LIST = "GET_STUDENTDASHBOARD_LIST";
export const GET_STUDENT_USER_RECENT_CHAT_LIST = "GET_STUDENT_USER_RECENT_CHAT_LIST";

export function getProfile() {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_PROFILE}?_id=${cookie.load('student_id')}`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        if (response !== undefined && response.data !== undefined) {
          dispatch({ type: FETCH_STUDENT_ACCOUNT_PROFILE, payload: response.data });

        }
      })
      .catch(response => {

      });
  }
}

export function studentDashboardData(callback) {
  //console.log("Student URL ===>",`${config.API_URL + config.API_GET_STUDENT_DASHBOARD}?_id=${cookie.load("student_id")}`);
  //console.log("Student Data",cookie.load('student'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_DASHBOARD}?_id=${cookie.load("student_id")}`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        //console.log("Server Response",response.data);
        dispatch({ type: GET_STUDENTDASHBOARD_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("Dashboard List Errors",response);
      });
  }
}


export function updateStudentProfile(formData, callback) {
  //console.log("Student ===> ",formData);
  return function (dispatch) {
    dispatch(validationNull());
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_UPDATE_STUDENT_PROFILE}?_id=${cookie.load('student_id')}`,
      data: formData,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          createAlert({ message: response.data.msg });
          dispatch(getProfile());
          callback(null, true);
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(response => {
        console.log(response);
      });
  }
}

export function getTaskList(callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_TASK_LIST}?_id=${cookie.load('student_id')}`,

      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        dispatch({ type: GET_TASK_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}
export function getGoalList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_GOAL_LIST}?_id=${cookie.load('student_id')}`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        dispatch({ type: GET_GOAL_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function getStudentGoalInfo(goal_id, callback) {
  console.log("Student goal info ===> ",`${config.API_URL + config.API_GET_STUDENT_GOAL_INFO + '/' + goal_id}?_id=${cookie.load('student_id')}`,cookie.load('student'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_GOAL_INFO + '/' + goal_id}?_id=${cookie.load('student_id')}&screen=goals`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        //console.log("goal_student_info", response.data);
        dispatch({ type: FETCH_GOAL_STUDENT_INFO, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getStudentGoalInfoAssessment(goal_id, callback) {
  //console.log("Student goal info 1==============> ",`${config.API_URL + config.API_GET_STUDENT_GOAL_INFO + '/' + goal_id}?_id=${cookie.load('student_id')}`,cookie.load('student'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_GOAL_INFO + '/' + goal_id}?_id=${cookie.load('student_id')}&screen=assessment`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        //console.log("goal_student_info", response.data);
        dispatch({ type: FETCH_GOAL_STUDENT_INFO_ASSESSMENT, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getBatches(callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_BATCHL_LIST}?_id=${cookie.load('student_id')}`,

      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        dispatch({ type: GET_BATCH_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

//  Student Goal Start


export function addbyStudentGoal(goal_id, callback) {
  //console.log(`${config.API_URL + config.API_START_END_GOAL + '/' + goal_id + '/start'}?_id=${cookie.load('student_id')}&screen=goals`,cookie.load('student'));
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_START_END_GOAL + '/' + goal_id + '/start'}?_id=${cookie.load('student_id')}&screen=goals`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          callback(null, response);
          dispatch(getStudentGoalInfo(goal_id));
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("ERROR in  addbyStudentGoal"));
          callback(null, false);
        }
      });
  }
}


export function addbyStudentRestartGoal(goal_id, revisionCounter, callback) {
  //console.log(`${config.API_URL + config.API_START_END_GOAL + '/' + goal_id + '/restart'}?_id=${cookie.load('student_id')}&screen=goals&revisionCounter=${revisionCounter}`);
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_START_END_GOAL + '/' + goal_id + '/restart'}?_id=${cookie.load('student_id')}&screen=goals&revisionCounter=${revisionCounter}`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          callback(null, response);
          dispatch(getStudentGoalInfo(goal_id));
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("ERROR in  addbyStudentGoal"));
          callback(null, false);
        }
      });
  }
}


export function addbyStudentGoalAssessment(goal_id, callback) {
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_START_END_ASSESSMENT + '/' + goal_id + '/start'}?_id=${cookie.load('student_id')}&screen=assessment`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {

        if (!response.data.err && response.data) {
          callback(null, response);
          dispatch(getStudentGoalInfoAssessment(goal_id));
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("ERROR in  addbyStudentGoalAssessment"));
          callback(null, false);
        }
      });
  }
}

//  Student Goal Start
export function stopbyStudentGoal(answerData, goal_id, callback) {

  return function (dispatch) {

    console.log(`${config.API_URL + config.API_START_END_GOAL + '/' + goal_id + '/end'}?_id=${cookie.load('student_id')}&screen=goals`,answerData,cookie.load('student'));

    axios({
      method: 'put',
      url: `${config.API_URL + config.API_START_END_GOAL + '/' + goal_id + '/end'}?_id=${cookie.load('student_id')}`,
      data: {
        answerData: answerData
      },
      headers: {
        Authorization: cookie.load('student'),
      },
    })

      .then(response => {
        if (!response.data.err && response.data) {

          dispatch(getStudentGoalInfo(goal_id));
          createAlert({ message: response.data.msg });
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("ERROR in  stopbyStudentGoal"));
          callback(null, false);
        }
      });
  }
}

//  Student Goal Start
export function stopbyStudentGoalAssessment(answerData, goal_id, callback) {
  return function (dispatch) {
    //console.log(`${config.API_URL + config.API_START_END_ASSESSMENT + '/' + goal_id + '/end'}?_id=${cookie.load('student_id')}&screen=assessment`,answerData,cookie.load('student'));
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_START_END_ASSESSMENT + '/' + goal_id + '/end'}?_id=${cookie.load('student_id')}&screen=assessment`,
      data: {
        answerData: answerData
      },
      headers: {
        Authorization: cookie.load('student'),
      },
    })

      .then(response => {
        if (!response.data.err && response.data) {

          dispatch(getStudentGoalInfoAssessment(goal_id));
          createAlert({ message: response.data.msg });
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("ERROR in  stopbyStudentGoalAssessment"));
          callback(null, false);
        }
      });
  }
}




export function checkAnswerStatus(callback) {

  return function () {
    callback(null, true);
  }
}

export function SetsuffleAttachments(attchment) {

  return {
    type: SET_ATTACHMENT,
    payload: attchment
  }
}

export function SetsuffleAttachmentsAssessment(attchment) {

  return {
    type: SET_ATTACHMENT_ASSESSMENT,
    payload: attchment
  }
}

export function SetsuffleCurrentAudio(data) {
  return {
    type: SET_CURRENT_AUDIO,
    payload: data
  }
}

export function SetsuffleCurrentAudioAttachment(data) {
  return {
    type: SET_CURRENT_AUDIO_ASSESSMENT,
    payload: data
  }
}

export function validation(error) {
  return {
    type: VALIDATION_ERROR,
    payload: error
  }
}
export function validationNull() {
  return {
    type: VALIDATION_ERROR,
    payload: null
  }
}

export function getStudentTeacherCommunication(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_TEACHER_CHAT_LIST}?_id=${cookie.load('student_id')}&teacher_id=${cookie.load('student_teacher_id')}`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        dispatch({ type: GET_STUDENT_TEACHER_CHAT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        
      });
  }
}

export function saveChatData(formData, callback) {
  // console.log("saveChatData ==>",formData);
  // return false;
  return function (dispatch) {
    axios({
      method: 'post',
      url: `${config.API_URL + config.API_STUDENT_SAVE_CHAT}?_id=${cookie.load('student_id')}`,
      data: formData,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        console.log("Chat Save Response", response);
      })
      .catch(response => {
        console.log(response);
      });
  }
}

export function getRecentChatsWithUsers(callback) {
  //console.log(`${config.API_URL + config.API_GET_STUDENT_RECENT_CHAT_LIST}?_id=${cookie.load('student_id')}&login_type=student&teacher_id=${cookie.load('student_teacher_id')}`,cookie.load('student'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_RECENT_CHAT_LIST}?_id=${cookie.load('student_id')}&login_type=student&teacher_id=${cookie.load('student_teacher_id')}`,
      headers: {
        Authorization: cookie.load('student'),
      },
    })
      .then(response => {
        dispatch({ type: GET_STUDENT_USER_RECENT_CHAT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("getRecentStudentChatsWithUsers", response);
      });
  }
}