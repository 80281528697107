import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import ModalViewCommentFiles from './modal/modal_goal_attachment';
import { connect } from 'react-redux';
import { modal } from '../library/modal';

class AssassessmentList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
      sortName: undefined,
      sortOrder: undefined,
      sizePerPage: 10,
    };
    this.actionViewList = this.actionViewList.bind(this);
    this.actionWordsViewList = this.actionWordsViewList.bind(this);

    this.renderPaginationShowsTotal = this.renderPaginationShowsTotal.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

  }


  renderPaginationShowsTotal(start, to, total) {
    return (
      <span>
        Showing {start} to {to} of {total} ({Math.ceil(total / this.state.sizePerPage)} Pages)
      </span>
    );
  }
  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder
    });
  }




  ViewContentAttachment(title, documents, type) {
    modal.add(ModalViewCommentFiles, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      documents: documents,
      type: type,
    });
  }




  actionViewList(cell, row) {
    return (
      <div>
        {
          row.image !== undefined && row.image.length > 0
            ?
            <Link onClick={() => this.ViewContentAttachment('View Goal Attachment', row.image, 'image')} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-eye mr-1"></i>Attchment</Link>
            :
            <span className="text-center"> - </span>
        }
      </div>
    )
  }
  actionWordsViewList(cell, row) {
    return (
      row.words !== undefined && row.words.length > 0 ?
        <Link onClick={() => this.ViewContentAttachment('View Words', row.words, 'word')} className="btn btn-primary mr-2 btn-sm"><i className="fa fa-eye mr-1"></i>Words Attchment</Link>
        :
        <span className="text-center"> - </span>
    )
  }


  statusFormat(cell, row) {
    if (row.isActive === "active") {
      return (
        <div className="badge bg-success ">Active</div>
      );
    } else if (row.isActive === "mastered") {
      return (
        <div className="badge bg-warning ">Mastered</div>
      );
    } else if (row.isActive === "discontinued") {
      return (
        <div className="badge bg-danger ">Discontinued</div>
      );
    }
  }

  // submit status 
  SubmitStatusFormat(cell, row) {
    if (row.studentGoalStatus !== undefined) {
      return (
        <Link className="badge bg-success">{row.studentGoalStatus}</Link>
      )
    }
    else {
      return (
        <div className="badge bg-warning ">Not Completed</div>
      )
    }
  }

  URLFormatter(cell, row) {
    let url = "/goal_info/" + row._id;
    return (
      <Link to={url}>
        <span className="text-primary">
          {row.title}
        </span>
      </Link>

    );
  }
  render() {
    const { goal_list } = this.props;
    const { isLoading, sortName, sortOrder } = this.state;

    if (!goal_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }


    const Status = {
      active: 'Active',

    };
    const SubmitStatus = {
      "completed": 'completed',
    };
    var ass_list = [
      { 'title': "Grammer test", 'subject': "English", 'isActive': "Active" },

    ]
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="card  table-responsive mb-4">
          <div className="card-header d-flex justify-content-between">
            <h5 className="mt-2 font border-bottom font-weight-bold list-title">
              <i className="fa fa-bag mr-1"></i>Assessment List
            </h5>
          </div>
          <div className="card-body">
            <BootstrapTable className="alca-data-table"
              keyField='_id'
              data={ass_list}
              bordered={false}
              striped={false}
              pagination={true}
              fetchInfo={{ dataTotalSize: goal_list.length }}
              options={{
                sizePerPage: this.state.sizePerPage,
                page: this.state.currentPage,
                hideSizePerPage: true,
                paginationShowsTotal: this.renderPaginationShowsTotal,
                sortName: sortName,
                sortOrder: sortOrder,
                onSortChange: this.onSortChange
              }}
            >
              <TableHeaderColumn
                dataField='title'
                columnClassName={''}
                filter={{ type: 'TextFilter' }}
                dataAlign="left"
                dataFormat={this.URLFormatter}
                dataSort={true}>
                Assessment Name
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField='subject'
                columnClassName={''}
                filter={{ type: 'TextFilter' }}
                dataAlign="left"
                dataSort={true}>
                Subject
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='studentName'
                columnClassName={''}
                dataAlign="left"
                dataFormat={this.actionViewList}>
                Attchment
              </TableHeaderColumn>
              <TableHeaderColumn dataField='isActive' dataAlign="center" filter={{ type: "SelectFilter", options: Status, placeholder: "- Select Status -" }} dataSort>Status</TableHeaderColumn>
              <TableHeaderColumn dataField='studentGoalStatus' dataAlign="center" dataFormat={this.SubmitStatusFormat} filter={{ type: "SelectFilter", options: SubmitStatus, placeholder: "- Select Submit Status -" }} dataSort>Submit Status</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

      </div>


    );

  }
}

function mapStateToProps(state) {
  return {
    goal_list: state.teacher.goal_list,

  }
}
export default connect(mapStateToProps, {})(AssassessmentList);
