import 'react-h5-audio-player/lib/styles.css';

import React, { Component } from 'react';
import { SetsuffleAttachmentsAssessment, SetsuffleCurrentAudioAttachment, addbyStudentGoalAssessment, getStudentGoalInfoAssessment } from './student_action';

import AudioPlayer from 'react-h5-audio-player';
import DeshHeader from './desh_header';
import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import { modal } from '../library/modal';
import submitGoalConfirmation from './modal/modal_confirm_submit_goal_assessment';
import { toHtml } from '../library/function';

var dateFormat = require('dateformat');
class StudentAssessmentInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setTab: 'nav-upload',
      count: 0,
      selectedOption: false,
      start: false
    };
    this.handleCheckAns = this.handleCheckAns.bind(this);
    this.handleSubmitStartGoal = this.handleSubmitStartGoal.bind(this);
  }

  componentDidMount() {
    var _this = this;
    //console.log(cookie.load('student'));
    var assessment_id = this.props.match.params.assessment_id;
    if (assessment_id !== undefined) {
      this.setState({ isLoading: true });
      window.addEventListener('beforeunload', this.keepOnPage);
      this.props.getStudentGoalInfoAssessment(assessment_id, function (err, result) {
        console.log("Get Student Info =====>",result);
        if (result) {
          var attachments = result.data.data.image;
          var suffleCurrentAudio = {
            audio_url: attachments[0].audio_url,
            id: attachments[0]._id,
            title: attachments[0].title
          }
          _this.props.SetsuffleAttachmentsAssessment(attachments);
          _this.props.SetsuffleCurrentAudioAttachment(suffleCurrentAudio);
          _this.setState({ isLoading: false });
        }
      });
    }
  }

  keepOnPage(e) {
    var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
    e.returnValue = message;
    return message;
  }

  // handleCheckAns(e, index, data) {
  //   this.setState({ count: this.state.count + 1 })
  //   this.setState({ start: true });
  //   //correct_ans
  //   console.log(this.props.current_audio);
  //   if (this.props.current_audio.id == data._id) {
  //     this.props.attchment[index].correct_ans = true;
  //   } else {
  //     this.props.attchment[index].correct_ans = false;
  //   }
    
  //   this.props.attchment[index].ans_status = true;
  //   var arrdata = [];
  //   console.log(this.props.attchment);
  //   arrdata = this.props.attchment.filter(item => item.ans_status == undefined && item.ans_status != false);
    
  //   if (arrdata.length) {
  //     var suffleCurrentAudio = {
  //       audio_url: arrdata[0].audio_url,
  //       id: arrdata[0]._id,
  //       title: arrdata[0].title
  //     }
  //     this.props.SetsuffleCurrentAudioAttachment(suffleCurrentAudio);
  //   }
    
  //   let counter_index = this.props.attchment.findIndex(data => data._id == this.props.current_audio.id);
  //   this.props.attchment[counter_index].attempt = this.state.count + 1;
  //   this.props.SetsuffleAttachmentsAssessment(this.props.attchment.sort(() => Math.random() - 0.5));
  // }

  handleCheckAns(e, index, data) {
   
    this.setState({ count: this.state.count + 1 })
    this.setState({ start: true })
    this.props.attchment[index].ans_status = true;
    if (this.props.current_audio.id == data._id) {
      this.props.attchment[index].correct_ans = true;
      var arrdata = [];
      arrdata = this.props.attchment.filter(item => item.ans_status == undefined && item.ans_status != true)
      if (arrdata.length) {
        var suffleCurrentAudio = {
          audio_url: arrdata[0].audio_url,
          id: arrdata[0]._id,
          title: arrdata[0].title
        }
        this.props.SetsuffleCurrentAudioAttachment(suffleCurrentAudio);

        //this.setState({ count: 0 });
      }
    }else {
      var arrdata = [];
      this.props.attchment[index].ans_status = true;
      arrdata = this.props.attchment.filter(item => item._id !== this.props.current_audio.id)
      //console.log(arrdata);
      this.props.attchment[index].correct_ans = false;
      if (arrdata.length) {
        var suffleCurrentAudio = {
          audio_url: arrdata[0].audio_url,
          id: arrdata[0]._id,
          title: arrdata[0].title
        }
        this.props.SetsuffleCurrentAudioAttachment(suffleCurrentAudio);
      }
    }
    // let counter_index = this.props.attchment.findIndex(AudioData => AudioData._id == this.props.current_audio.id);
    // this.props.attchment[counter_index].attempt = this.state.count + 1;
    this.props.SetsuffleAttachmentsAssessment(this.props.attchment.sort(() => Math.random() - 0.5));
  }
  handleSubmitStartGoal(e) {
    e.preventDefault();
    this.props.addbyStudentGoalAssessment(this.props.match.params.assessment_id, function (err, result) { });
  }

  renderReinforcementAttachment(attachments) {
    if (attachments?.length > 0) {
      return attachments.map((attachment) => {
        return (
          <div className="col-md-4">
            <a href={attachment} target="_blank"><img src={attachment} className="w-100" /></a>
          </div>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No  Log Data Found!</td>
        </tr>
      );
    }
  }


  renderGoalimages(images, arrayNumber) {
    if (images !== null && images.length > 0) {
      let WrongClassCount = 0;
      let TotalAllowDisplay = parseInt(arrayNumber) - parseInt(1);
      return images.map((img, index) => {
        let qClass = '';
        if (this.props.current_audio.id == img._id) {
          qClass = 'col-sm-2 mb-3 q-right-ans';
        } else {
          WrongClassCount = WrongClassCount + 1;
          if(TotalAllowDisplay >= WrongClassCount){
            qClass = 'col-sm-2 mb-3 q-wrong-ans '+WrongClassCount+ ' '+TotalAllowDisplay;
          } else {
            qClass = 'col-sm-2 mb-3 q-wrong-ans d-none '+WrongClassCount+ ' '+TotalAllowDisplay;
          }
        }
        return (
          <div className={qClass} key={'attchment_' + index} onClick={(e) => this.handleCheckAns(e, index, img)} style={{ "cursor": "pointer" }}>
            <div className={"quiz-question-variation"}>
              <img src={img.img_url} alt={'image'} className="img-fluid alc-img-fluid" style={{ 'height': '7rem', 'width': '7rem' }} />
            </div>
          </div>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="5">No Data Found!</td>
        </tr>
      );
    }
  }
  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="col-sm-12 mt-3">
          <div className="alert alert-warning">
            <strong>Oops! </strong> {this.props.errorMessage}
          </div>
        </div>

      )
    }
  }
  submitGoalConfirmationModal() {
    modal.add(submitGoalConfirmation, {
      title: "Do you want to submit goal?",
      size: 'medium',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      data: {
        'attchment': this.props.attchment,
        'assessment_id': this.props.match.params.assessment_id
      }
    });
  }

  renderGoalHistory(goal_data) {
    if (goal_data?.length > 0) {
      return goal_data.map((goal_ans) => {
        return (
          <tr key={'history_' + goal_ans._id}>
            <td className="text-left">{goal_ans.title}</td>
            <td className="text-center"><img src={goal_ans.img_url} alt="ans" style={{ "height": "50px", "width": "50px" }} /></td>
            <td className="text-center"> <div className={goal_ans.correct_ans ? "btn btn-sm bg-primary text-white" : "btn bg-danger btn-sm text-white"}>{goal_ans.correct_ans ? "Correct" : "Incorrect"}</div></td>
            {/* <td className="text-center">{this.props.goal_info.student_name}</td> */}

          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No  Log Data Found!</td>
        </tr>
      );
    }
  }

  render() {

    const { goal_info, errorMessage, attchment, current_audio } = this.props;
    var { isLoading, start,count } = this.state;

    if (!goal_info || !current_audio) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }
    if (errorMessage) {
      isLoading = false;
    }
    var total_attemp = 0;
    if (attchment.length) {
      total_attemp = attchment.filter(item => item.ans_status == true).length;
    }
    var divStyle = {};
    if (goal_info?.studentGoalStatus !== "started") {
      divStyle = {
        pointerEvents: 'none',
        opacity: '.2',
      };
    }
    console.log(count);
    return (
      <div className="page material-ui" id="page-batch-info">
        {isLoading ? <Loading /> : null}
        <div className="wrapper ">
          <DeshHeader />
          <div className="main-panel">
            <div className="content p-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0"> <i className="fa fa-info mr-1" aria-hidden="true"></i> Assessment Details Info - <span className="text text-primary">{goal_info.title ? goal_info.title : ""} </span> </h4>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="nav-tabContent">
                    <div className="card bg-light">
                      <div className="card-body mt-3">
                        {
                          goal_info && <div className="table-responsive">
                            <table className="table mb-0">
                              <tbody>
                                {/* <tr>
                                <img src="https://image.shutterstock.com/image-vector/man-icon-vector-260nw-1040084344.jpg" className="img-thumbnail rounded" alt="image" />
                              </tr> */}
                                {/* <tr>
                                <td className="first  border-top-0 font-weight-bold  h6">Teacher Name</td>
                                <td className="second border-top-0 col-6">{goal_info?.createdBy.name} <span className="text-primary">{goal_info.createdBy.email}</span></td>
                              </tr> */}
                                <tr>
                                  <td className="first  font-weight-bold  h6">Assign Assessment :</td>
                                  <td className="second border-top-0 col-6">{goal_info?.name} ({goal_info?.numbers})</td>
                                </tr>
                                <tr>
                                  <td className="first  font-weight-bold  h6">Assessment  Details :</td>
                                  <td className="second border-top-0 col-6">{toHtml(goal_info.description)}</td>
                                </tr>

                                <tr>
                                  <td className="first  font-weight-bold  h6">Assessment  Status :</td>
                                  <td className="second border-top-0 col-6">{goal_info.studentGoalStatus !== undefined ? <span className="second badge bg-success">{goal_info.studentGoalStatus}</span> : <span className="second badge bg-warning">Not Completed</span>}  {goal_info.completionDateTime !== undefined && dateFormat(goal_info.completionDateTime)}</td>
                                </tr>
                                <tr>
                                  <td className="first  font-weight-bold h6 ">Date OF Creation</td>
                                  <td className="second "> <span className="">
                                    : <i className="fa fa-calendar mr-1"></i>

                                    {dateFormat(goal_info?.createdTime, "mm-dd-yyyy")}
                                  </span></td>
                                </tr>
                                <tr>
                                  <td className="first  font-weight-bold h6 ">Date OF Mastered</td>
                                  <td className="second "> <span className="">
                                    : <i className="fa fa-calendar mr-1"></i>

                                    {dateFormat(goal_info?.dateMastered, "mm-dd-yyyy")}
                                  </span></td>
                                </tr>
                                {
                                goal_info.studentGoalStatus == 'completed' && <tr>
                                <td className="first  font-weight-bold  h6">Teacher's Feedback :</td>
                                <td className="second border-top-0 col-6 text-danger">{goal_info.teacherApprovedNotes}</td>
                              </tr>
                              }
                                {/* {
                                  goal_info.studentGoalStatus == 'completed' && <tr>
                                    <td className="first  font-weight-bold h6 ">Assessment Score</td>
                                    <td className="second "> <span className="">
                                      {goal_info?.score}%
                                    </span></td>
                                  </tr>
                                } */}
                              </tbody>
                            </table>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header h5 bg-white">
                  <span className="text-primary">{goal_info.title ? goal_info.title : ""}</span>
                  {
                    goal_info?.studentGoalStatus !== "completed"

                      ?
                      <div className="pull-right">{count} out of {attchment.length ? attchment.length : 0}
                        {
                          (goal_info.studentGoalStatus !== "started")
                            ?
                            <Link to={"/student/play/1"} className="btn  btn-primary ml-2" onClick={(e) => this.handleSubmitStartGoal(e)}>Start</Link>
                            :
                            null
                        }
                        {count == attchment.length && <Link onClick={() => this.submitGoalConfirmationModal()} className="btn btn-danger ml-2">Submit</Link>}
                      </div>
                      :
                      <div className="pull-right"><h5 className=" badge bg-success">Assessment Completed</h5>  </div>
                  }

                </div>

                {
                  count < attchment.length && <div className="card-body p-0 " style={divStyle}>
                  {this.renderAlert()}
                  <div className="mb-3">
                    
                    <div className="card-header d-flex  heading-question">
                      <h6><span className="text-danger">Question :</span>  {current_audio?.title} </h6>
                      <AudioPlayer
                        src={current_audio?.audio_url}
                        onPlay={e => console.log("onPlay")}
                      />
                    </div>

                    <div className="card-body">
                    {
                      attchment?.length > 0
                        ?
                        <div className="row assessment-info">
                          {this.renderGoalimages(attchment,goal_info?.numbers)}
                        </div>
                        :
                        null
                    }
                    </div>
                    
                    <div className=" card-footer bg-transparent">
                      <div className="pull-right pb-2">
                        {goal_info?.studentGoalStatus !== "completed" && total_attemp == attchment.length && <Link onClick={() => this.submitGoalConfirmationModal()} className="btn btn-danger">Submit</Link>}
                      </div>
                    </div>
                  </div>
                </div>
                }
                {
                  count == attchment.length && <div className='completedQuestions'>
                    <div className='text-center submit-assessment'>Assessment Completed. Please Submit!</div>
                  </div> 
                }
                

                {
                  goal_info.answerData && goal_info.teacherApproved && goal_info.answerData.length > 0 && <div className="card mt-3">
                  <div className="card-header">
                    <h5 className="mb-0"> Student Assessment Workspace </h5>
                    </div>
                    <div className="card-body">
    
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left">Hint</th>
                              <th className="text-center">Object</th>
                              <th className="text-center">Status</th>
                              {/* <th className="text-center">Added by</th> */}
    
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderGoalHistory(goal_info.answerData)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                }
                
                {
                goal_info.reinforcementData && goal_info.teacherApproved && goal_info.studentGoalStatus == 'completed' &&
                  <div className="card mt-3">
                    <div className="card-header">
                      <h5 className="mb-0"> Reinforcement Details </h5>
                    </div>
                    <div className="card-body">

                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                            <tr>
                              <td className="text-left">Name : {goal_info?.reinforcementData.name}</td>
                            </tr>
                            <tr>
                              <td className="text-left">Reinforcement : 
                              <span dangerouslySetInnerHTML={{ __html: goal_info?.reinforcementData.description }}></span></td>
                            </tr>
                            <tr>
                              <td className="text-left">Rewards : {goal_info?.reinforcementData.rewards}</td>
                            </tr>
                            <tr>
                              <div className="row">
                                {this.renderReinforcementAttachment(goal_info?.reinforcementData.attachments)}
                              </div>
                            </tr>
                            
                        </table>
                      </div>
                    </div>
                  </div>
              }

                {/* {goal_info?.reinforcementData && <div className="card mt-3">
                  <div className="card-header">
                    <h5 className="mb-0"> Assessment Reinformcement History </h5>
                  </div>
                  <div className="card-body">

                    <div className=" react-bs-table-container">
                      <table className="table table-responsive">
                        <thead>
                          <tr>
                            <th className="text-left">Image</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Discription</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left"><Link onClick={() => window.open(goal_info?.reinforcementData?.attachments[0], "_blank")}> <img src={goal_info?.reinforcementData?.attachments[0]} className="img-thumbnail" alt="image" /></Link></td>
                            <td className="text-center">{goal_info?.reinforcementData?.name}</td>
                            <td className="text-center"> {toHtml(goal_info?.reinforcementData?.description)}</td>
                            <td className="text-center">{goal_info?.reinforcementData?.isActive == true ? "Active" : "Deactive"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  console.log("Assessment Information ",state.student);
  return {
    goal_info: state.student.goal_student_info,
    current_audio: state.student.current_audio,
    attchment: state.student.set_attchment,
    errorMessage: state.student.error
  }
}

export default connect(mapStateToProps, { getStudentGoalInfoAssessment, addbyStudentGoalAssessment, SetsuffleCurrentAudioAttachment, SetsuffleAttachmentsAssessment })(StudentAssessmentInfo);
