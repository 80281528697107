import * as config from '../system/config';

import axios from 'axios';
import cookie from 'react-cookie';
import { createAlert } from '../../src/library/function';

var teacherAction = require('../teacher/teacher_action');
var parentAction = require('../parent/parent_action');
var studentAction = require('../student/student_action');

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_PARENT = "AUTHENTICATE_PARENT";
export const AUTHENTICATE_STUDENT = "AUTHENTICATE_STUDENT";

export const UNAUTHENTICATE_USER = "UNAUTHENTICATE_USER";
export const UNAUTHENTICATE_PARENT = "UNAUTHENTICATE_PARENT";
export const UNAUTHENTICATE_STUDENT = "UNAUTHENTICATE_STUDENT";

export const VALIDATION_ERROR = "VALIDATION_ERROR";

export const GET_PARENTS = "GET_PARENTS";
export const GET_TEACHER_DETAILS_BY_EMAIL = "GET_TEACHER_DETAILS_BY_EMAIL";
export const GET_UNIQUE_USERNAME = "GET_UNIQUE_USERNAME";

export function login(formProps, callback) {
  cookie.remove('teacher', { path: '/' });
  cookie.remove('parent', { path: '/' });
  cookie.remove('student', { path: '/' });
  cookie.remove("is_login");
  cookie.remove("login_user");
  cookie.remove("login_photo");
  cookie.remove("packageEndDate");
  cookie.remove("packageStartDate");
  cookie.remove("loginType");

  return function (dispatch) {
    dispatch(validationNull());
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_LOGIN}`,
      data: {
        email: formProps.email,
        password: formProps.password,
      },
    })
      .then(response => {
        //console.log("Login Data =====>",response.data.data);
        cookie.remove('teacher', { path: '/' });
        cookie.remove('parent', { path: '/' });
        cookie.remove('student', { path: '/' });
        cookie.remove("is_login");
        cookie.remove("login_user");
        cookie.remove("login_photo");
        cookie.remove("packageEndDate");
        cookie.remove("packageStartDate");
        cookie.remove("loginType");
        console.log(response.data.data.accessToken);
        let photo_url = "https://learning3ly.com/static/media/logo.edade388.png";
        if (response.data.data.accessToken !== undefined) {
          if(response.data.data && response.data.data.photo != ''){  
            photo_url = response.data.data.photo; 
          }
          cookie.save("login_photo",photo_url);
          if (response.data.data.role === 'teacher') {
            cookie.save('teacher', response.data.data.accessToken, { path: '/' });
            cookie.save("teacher_id",response.data.data._id);
            cookie.save("is_login","Yes");
            cookie.save("login_user",response.data.data.name);
            cookie.save("login_email",response.data.data.email);
            cookie.save("packageEndDate",response.data.data.packageEndDate);
            cookie.save("packageStartDate",response.data.data.packageStartDate);
            cookie.save("loginType","teacher");
            dispatch({ type: AUTHENTICATE_USER, payload: response.data.data.accessToken });
            dispatch(teacherAction.getProfile());
            createAlert({ message: response.data.msg });
          } else if (response.data.data.role === 'parent') {
            cookie.save('parent', response.data.data.accessToken, { path: '/' });
            cookie.save("parent_id",response.data.data._id);
            cookie.save("is_login","Yes");
            cookie.save("login_user",response.data.data.name);
            cookie.save("login_email",response.data.data.email);
            cookie.save("packageEndDate",response.data.data.packageEndDate);
            cookie.save("packageStartDate",response.data.data.packageStartDate);
            cookie.save("loginType","parent");
            dispatch({ type: AUTHENTICATE_PARENT, payload: response.data.data.accessToken });
            dispatch(parentAction.getProfile());
            createAlert({ message: response.data.msg });
          } if (response.data.data.role === 'student') {
            console.log("Student Data ===========>",response.data);
            //console.log("Student Teacher ID ===>",response.data.data.teacherId);
            cookie.save('student', response.data.data.accessToken, { path: '/' });
            cookie.save("student_id",response.data.data._id);
            cookie.save("student_teacher_id",response.data.data.teacherId);
            cookie.save("student_teacher_email",response.data.data.teacherEmail);
            cookie.save("is_login","Yes");
            cookie.save("login_user",response.data.data.name);
            cookie.save("login_email",response.data.data.email);
            cookie.save("packageEndDate",response.data.data.packageEndDate);
            cookie.save("packageStartDate",response.data.data.packageStartDate);
            cookie.save("loginType","student");
            dispatch({ type: AUTHENTICATE_STUDENT, payload: response.data.data.accessToken });
            dispatch(studentAction.getProfile());
            createAlert({ message: response.data.msg });
          }
          callback(null, true);
        } else {
          if (response.data.err) {
            dispatch(validation(response.data.err));
            callback(null, false);
          }
        }

      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid Email or Password"));
          callback(null, false);
        }
      });
  }
}

export function register(formProps, callback) {
  return function (dispatch) {
    dispatch(validationNull());
    // console.log(`${config.API_URL + config.API_REGISTER}`,JSON.stringify({
    //   role: formProps.role,
    //     name: formProps.name,
    //     email: formProps.email,
    //     password: formProps.password,
    //     phoneNumber: formProps.phoneNumber
    // }));
    // return false;

    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_REGISTER}`,
      data: {
        role: formProps.role,
        name: formProps.name,
        email: formProps.email,
        password: formProps.password,
        phoneNumber: formProps.phoneNumber
      }
    })
      .then(response => {
        if (response.data.data !== undefined && response.data.err === undefined) {
          createAlert({ message: response.data.msg });
          callback(null, true);
        } else {
          if (response.data.err) {
            dispatch(validation(response.data.err));
            callback(null, false);
          }
        }
      })

      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid Email or Password"));
          callback(null, false);
        }
      });
  }
}

export function getUserData(email_address, callback) {
  //console.log("Get User Data ",email_address);
  
  return function (dispatch) {
    dispatch(validationNull());
    axios({
      method: 'get',
      url: `${config.API_URL + "/teacher/teacherDetails?email="+email_address}`,
      data: email_address,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        //console.log("User Details Response ===>",response.data.data);
        cookie.save("teacher_id",response.data.data._id);
        cookie.save("is_login","Yes");
        cookie.save("login_user",response.data.data.email);
        cookie.save("login_email",response.data.data.email);
        cookie.save("loginType","teacher");
        cookie.save("packageEndDate",response.data.data.packageEndDate);
        cookie.save("packageStartDate",response.data.data.packageStartDate);
        dispatch({ type: GET_TEACHER_DETAILS_BY_EMAIL, payload: response.data });
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function pricingFormSubmit(formProps, callback) {
  //console.log(`${config.API_URL + config.API_PACKAGE_PURCHASE_API}`,formProps,cookie.load("parent"));
  return function (dispatch) {
    dispatch(validationNull());
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_PACKAGE_PURCHASE_API}`,
      data: formProps,
      headers: {
        Authorization: cookie.load('parent'),
      },
    })
      .then(response => {
        if (!response.data.err) {
          //createAlert({ message: 'Your password reset link email has been sent to your email address, please check your email.' });
          callback(null, response);
        }
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function forgotPassword(formProps, callback) {
  return function (dispatch) {
    dispatch(validationNull());
    // console.log("Reset Password ===>");
    // console.log(formProps);
    // return false;
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_OTP_REQUEST}`,
      data: {
        email: formProps.email,
        link: config.APP_URL + '/resetpassword',
      },
    })
      .then(response => {

        if (!response.data.err) {
          createAlert({ message: 'Your password reset link email has been sent to your email address, please check your email.' });
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
        }

      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid Email or Password"));
          callback(null, false);
        }
      });
  }
}



export function authResetPassword(token, email, callback) {
  return function (dispatch) {
    dispatch(validationNull());
    // console.log(token,email,`${config.API_URL + config.APICHECK_TOKEN}`);
    // return false;
    axios({
      method: 'POST',
      url: `${config.API_URL + config.APICHECK_TOKEN}`,
      data: {
        token: token,
        email: email,
      },
    })
      .then(response => {

        if (response.data.data !== undefined && response.data.err === undefined) {
          callback(null, true);
        } else {
          if (response.data != undefined && response.data.err) {
            dispatch(validation(response.data.err));
            callback(null, false);
          }
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation(error.response.data.msg));
          callback(null, false);
        }
      });
  }
}

export function resetPassword(formProps, callback) {
  return function (dispatch) {
    dispatch(validationNull());
    //console.log(formProps,`${config.API_URL + config.API_fORGOT_PASSWORD}`);
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_fORGOT_PASSWORD}`,
      data: {
        token: formProps.token,
        email: formProps.email,
        password: formProps.password
      },
    })
      .then(response => {
        if (response.data.data !== undefined && response.data.err === undefined) {
          createAlert({ message: 'Your Password has been update successfully!' });
          callback(null, true);
        } else {
          if (response.data.data != undefined && response.data.err) {
            dispatch(validation(response.data.err));
            callback(null, false);
          }
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation(error.response.data.msg));
          callback(null, false);
        }
      });
  }
}
export function getParents() {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_PARENT_LIST}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_PARENTS, payload: response.data });
      })
      .catch(response => {
        console.log(response);
      });
  }
}
export function getUniqueUserName() {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_UNIQUE_USERNAME}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_UNIQUE_USERNAME, payload: response.data });
      })
      .catch(response => {
        console.log(response);
      });
  }
}
const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

export function logout() {
  clearCacheData();

  return function (dispatch) {
    cookie.remove('teacher', { path: '/' });
    cookie.remove('parent', { path: '/' });
    cookie.remove('student', { path: '/' });
    cookie.remove('student_id', { path: '/' });
    cookie.remove('parent_id', { path: '/' });
    cookie.remove('teacher_id', { path: '/' });
    dispatch({ type: UNAUTHENTICATE_USER });
    dispatch({ type: UNAUTHENTICATE_PARENT });
    dispatch({ type: UNAUTHENTICATE_STUDENT });
  }
}
export function validation(error) {
  return {
    type: VALIDATION_ERROR,
    payload: error
  }
}
export function validationNull() {
  return {
    type: VALIDATION_ERROR,
    payload: null
  }
}