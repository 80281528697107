import * as actions from '../account/account_action';

import { Field, reduxForm } from 'redux-form';
import { Loading, input } from '../library/elements';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import ModalAccountFrogotPassword from './forgotten';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { modal } from '../library/modal';
import { withRouter } from "react-router";

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: false, isError: '' };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.authenticated === true) {
      this.props.history.push('/deshboard');
    }
    var mail = this.props.location.pathname.split('/email=')[1];
    var token = this.props.location.pathname.split('/email=')[0].replace("/resetpassword/token=", '');
    if (token !== undefined && token && mail !== undefined && mail) {
      this.props.authResetPassword(token, mail, function (err, result) {});
    } else {
      this.setState({ isError: true });
    }
  }

  handleFormSubmit(formProps) {
    this.setState({ isError: '' });
    this.setState({ isLoading: true });
    var _this = this;

    formProps.email = this.props.location.pathname.split('/email=')[1];
    formProps.token = this.props.location.pathname.split('/email=')[0].replace("/resetpassword/token=", '');
    this.props.resetPassword(formProps, function (err, result) {
      if (!result) {
        _this.setState({ isLoading: false });
      } else {
        _this.props.history.push('/signin');
        _this.setState({ isLoading: false });
      }
    });
  }


  addForpgotPasswordModal(title) {
    modal.add(ModalAccountFrogotPassword, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      modalId: 'forgot-password',
    });
  }

  render() {
    const { handleSubmit, errorMessage } = this.props;
    var { isLoading } = this.state;
    console.log(" Error ====>",errorMessage);

    if (errorMessage) {
      isLoading = false;
    }
    
    var breadcrumbs = [];
    breadcrumbs.push({ text: 'Login', value: '/' });
    return (
      <div className="account-login ui-form mt-5 mb-5">
        { isLoading ? <Loading /> : null}
        <div className="container">
        <br /><br /><br /><br />
          <div className="row">

            <div className="col-sm-2">
            <br />
            </div>
            
            <div className="col-sm-8">
              <div className="card ">
                <div className="card-header ">
                  <h5 className="text-primary">Reset Password</h5>
                </div>
                <div className="card-body">
                {
                         errorMessage
                   
                      ?
                      <div className="form-group">
                        <div className="col-sm-12">
                          <div className="alert alert-warning">Oops... Looks like your link has expired. <Link className="link-primary" onClick={() => this.addForpgotPasswordModal('Forgot Password')}>Click here</Link> to get a new link.</div>
                        </div>
                      </div>
                      :
                      null
                  }

                  <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    
                    <div className="form-group">
                      <div className="col-sm-12 position-relative has-icon-left mb-3">
                        <Field name="password" type="password" component={input} label="* Password" className="form-control" />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-12 position-relative has-icon-left mb-3">
                        <Field name="confirm_password" type="password" component={input} label="* Confirm  Password" className="form-control" />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-sm-12">
                        <button
                          type="submit"
                          action="changepassword"
                          disabled={isLoading}
                          className="btn  w-100  btn-primary btn-submit">
                          {isLoading ? 'Loading...' : 'Reset'}
                        </button>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 text-center">
                        New to Learning3ly ? <Link className="text-primary" to="/signup" >Signup Now</Link>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
            <div className="col-sm-2">
            </div>
          </div>
        </div>
      </div>

    );
  }
}

function validate(formProps) {
  const errors = {}
  if (!formProps.password) {
    errors.password = 'Required Password';
  } else if (formProps.password.length < 6) {
    errors.password = 'Password must more than 6 characters';
  }

  if (formProps.password !== formProps.confirm_password) {
    errors.confirm_password = 'Confirm must match to Password';
  }

  return errors
}

ResetPassword = reduxForm({
  form: 'changepassword',
  validate: validate
})(ResetPassword);

function mapStateToProps(state) {
  return {
    errorMessage: state.account.error,
    authenticated: state.account.authenticated,
  }
}

export default withRouter(connect(mapStateToProps, actions)(ResetPassword));
