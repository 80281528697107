import { Field, reduxForm } from 'redux-form';
import { Loading, input } from '../library/elements';
import React, { Component } from 'react';
import { login, validationNull } from '../account/account_action';

import { Link } from 'react-router-dom';
import ModalAccountFrogotPassword from './forgotten';
import { Player } from 'video-react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { modal } from '../library/modal';

var ReactRotatingText = require('react-rotating-text');

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: ''
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  componentWillMount() {
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    } else if (this.props.authenticated_parent) {
      this.props.history.push('/parent_dashboard');
    } else if (this.props.authenticated_student) {
      this.props.history.push('/student_dashboard');
    }
  }
  handleFormSubmit(formProps) {
    this.setState({ isLoading: true });
    var _this = this;
    this.props.login(formProps, function (err, result) {
      if (!result) {
        _this.setState({ isLoading: false });
      } else {
        if (_this.props.authenticated) {
          _this.props.history.push('/dashboard');
        } else if (_this.props.authenticated_parent) {
          _this.props.history.push('/parent_dashboard');
        } else if (_this.props.authenticated_student) {
          _this.props.history.push('/student_dashboard');
        }
        _this.setState({ isLoading: false });

      }
    });
  }

  addForpgotPasswordModal(title) {
    modal.add(ModalAccountFrogotPassword, {
      title: title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      modalId: 'forgot-password',
    });
  }






  render() {
    const { handleSubmit, errorMessage, teacher_profile } = this.props;
    var { isLoading } = this.state;


    return (

      <div className="page" id="page-login">
        { isLoading ? <Loading /> : null}

        <div id="page-login" className="alc-page page mt-5">
        <link
          rel="stylesheet"
          href="/video-react.css"
        />
          <div className="alc-bg-login">
            <div className="container">
              <div className="mt-4">
              {/* <div className='row'>
                  <div className='col-md-12'>
                  <div className='bottom-logo'><img src="static/media/logo.edade388.png" className='img-logo' /><span className='logo-span'>Learning3ly</span></div>
                  <h1 className="alc-heading-md text-center mt-3-minus mb-4 alc-c-white">Next Generation Smart Education System. Login With <ReactRotatingText
                        items={['Student', 'Parent', 'Teacher']}
                        pause={1500}
                        emptyPause={1000}
                        typingInterval={100}
                        deletingInterval={100}
                      />Account</h1>
                      
                  </div>
                </div> */}
                {/* <div className='row'>
                  <div className='col-md-12'>
                  <div className='bottom-logo'><img src="static/media/logo.edade388.png" className='img-logo' /><span className='logo-span'>Learning3ly</span></div>
                  </div>
                  </div> */}
                <div className="row">
                  <div className="col-sm-8 hidden-md-down d-none d-md-block">
                    <div className="headings">
                    {/* <Player><source src="https://www.cloudswiftsolutions.com/theme/default/images/learning3ly.mp4" /></Player> */}
                      <h1 className="alc-heading-xl mt-3-minus  alc-c-white">Log In To Your Learning3ly <br />  <ReactRotatingText
                        items={['Student', 'Parent', 'Teacher']}
                        pause={1500}
                        emptyPause={1000}
                        typingInterval={100}
                        deletingInterval={100}
                      />Account</h1>
                      <ul className="list-unstyled mt-4 alc-c-white">
                        <li className="mb-4">
                          <i className="alc-fa alc-icon-box fa fa-check alc-bg-primary mr-2"></i>
                          <span className="alc-font-1-3 mr-2">Next Generation Education System</span>
                        </li>
                        <li className="mb-4">
                          <i className="alc-fa alc-icon-box fa fa-check alc-bg-primary mr-2"></i>
                          <span className="alc-font-1-3 mr-2">Smart Education Management System</span>
                        </li>
                        <li className="mb-4">
                          <i className="alc-fa alc-icon-box fa fa-check alc-bg-primary mr-2"></i>
                          <span className="alc-font-1-3 mr-2">Ready to Change</span>
                        </li>
                      </ul>
                      <Link to="/signup" className={"btn alc-btn  btn-primary alc-btn-shadow alc-btn-large"}>
                        Sign Up Now
              </Link>
                    </div>
                  </div>
                  <div className="col-sm-4" style={{marginTop:'-4%'}}>
                    <div className="card alc-card-form">
                      <div className="card-header"> <h3 className="alc-card-form-title m-0">Log In</h3></div>
                      <div className="card-body">
                        <form id="form" className="m-t-1" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                          {
                            errorMessage
                              ?
                              <div className="form-group">
                                <div className="col-sm-12">
                                  <div className="alert alert-warning">{errorMessage == 'Cannot read property \'role\' of null' ? "Invalid Login Credentials" : errorMessage}</div>
                                </div>
                              </div>
                              :
                              null
                          }
                          <label htmlFor="">Email / User Name</label>
                          <fieldset className="form-group">
                            <Field name="email" type="text" placeholder="Email / User Name" component={input} label="Email" />
                          </fieldset>
                          <label htmlFor="">Password</label>
                          <fieldset className="form-group">
                            <Field name="password" type="password" placeholder="Password" component={input} label="Password" />
                          </fieldset>

                          <button
                            type="submit"
                            action="login"
                            disabled={isLoading}
                            className="btn alc-btn w-100 alc-btn-action alc-btn-large">
                            {isLoading ? 'Loading...' : 'Login'}
                          </button>
                        </form>
                        <div className="col-sm-12 text-center mt-2 mb-2">
                        <Link className="" to="/signup"><label> Don't have an Account? Sign Up Now!  </label></Link>
                          </div>
                        <div className="row text-center mt-3 mb-2"><div className="col-sm-12"><Link className="forgot-link text-primary" onClick={() => this.addForpgotPasswordModal('Forgot Password')}>Forgot Password</Link></div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

function validate(formProps) {
  const errors = {}
  // if (!formProps.email) {
  //   errors.email = 'Required Email'
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formProps.email)) {
  //   errors.email = 'Invalid Email Address'
  // }
  if (!formProps.password) {
    errors.password = 'Required Password'
  }
  return errors
}

Login = reduxForm({
  form: 'login',
  validate: validate
})(Login);

function mapStateToProps(state) {
  return {
    authenticated: state.account.authenticated,
    authenticated_parent: state.account.authenticated_parent,
    authenticated_student: state.account.authenticated_student,
    errorMessage: state.account.error,
    teacher_profile: state.account.teacher_profile,
  }
}

export default connect(mapStateToProps, { login, validationNull })(Login);
