import React, { Component } from 'react';
import { connect } from 'react-redux';
import { input, InputImage } from '../../library/elements';
import { createAlert } from '../../library/function';
import { addTaskDocuments, validationNull } from '../teacher_action';
import { Field, reduxForm, FieldArray } from 'redux-form';


class ModalTaskDocuments extends Component {

  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);

    this.state = { isLoading: false, modify_permission: false };
  }

  componentWillMount() {
    var _this = this;
    this.props.validationNull();
  }


  handleFormSubmit(formProps) {

   
    var _this = this;
    this.setState({ isLoading: true });
    const data = new FormData();
    var attachment = formProps.attachment;
    delete formProps.attachment;

    data.append('taskId', this.props.documentData.task_id);
    for(var mi in attachment){
    if(attachment[mi]['image'] !== undefined ){
        data.append('attachment', attachment[mi]['image'][0]);
      }
    }

  
    this.props.addTaskDocuments(data, this.props.documentData.task_id,this.props.documentData.student_id, function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
        _this.props.removeModal();
      }
    });
  }


  closeModal() {
    this.props.removeModal();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }


  renderAddmoreImageCard(app_url) {
    const addMoreImage = ({ fields, meta: { touched, error } }) => (
      <tbody>
        {fields.map((item, index) =>
          <tr key={"add_image_" + index}>
            <td className="text-center">
              <Field name={`${item}.image`} type="text" component={InputImage} video="true" label="Image" className="form-control" />
            </td>
            <td className="text-center">
              <Field name={`${item}.title`} type="text" component={input} className="form-control" />
            </td>

            <td className="text-center">
              <button
                className="btn btn-danger"
                type="button"
                title="Remove Member"
                onClick={() => fields.remove(index)}
              >
                <i className="fa fa-minus-circle"></i>
              </button>
            </td>
          </tr>
        )}
        <tr>
          <td colSpan="2"></td>
          <td className="text-center">
            <button type="button" className="btn btn-primary"
              onClick={() => fields.push({})}
              title="Add Option"
            >
              <i className="fa fa-plus-circle"></i>
            </button>
          </td>
        </tr>
      </tbody>
    )
    return (
      <div className="tab-pane" role="tabpanel">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">File</th>
              <th className="text-center">Title</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <FieldArray name={"attachment"} component={addMoreImage} />
        </table>
      </div>
    );
  }

  render() {

    const { handleSubmit ,errorMessage } = this.props;
    var { isLoading } = this.state;

    if (errorMessage) {
      isLoading = false;
    }


    return (
  
          <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="frm-document">
            {this.renderAlert()}
            <div className="row">
              <div className="form-group col-sm-12">
                {this.renderAddmoreImageCard()}
              </div>
            </div>


            <div className="row">
              <div className="col-sm-10">
                <button action="taskDocumentForm" className="btn btn-primary btn-block" disabled={isLoading}>
                  <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Add Task Documents'}
                </button>
              </div>
              <div className="col-sm-2">
                <button onClick={() => this.closeModal()} type="button" className="btn btn-danger btn-block">Cancel</button>
              </div>
            </div>


          </form>
  
    );
  }
}



ModalTaskDocuments = reduxForm({
  form: 'taskDocumentForm',
  enableReinitialize: true,
})(ModalTaskDocuments);

function mapStateToProps(state, ownProps) {
  var initDocumentData = {
    attachment: [ {image: '', title: ''}]
  };

  if (ownProps.documentData._id !== undefined) {
    initDocumentData = ownProps.documentData;
  }
  return {
    initialValues: initDocumentData,
    errorMessage: state.teacher.error,
  }
}


export default connect(mapStateToProps, { addTaskDocuments,validationNull })(ModalTaskDocuments);
