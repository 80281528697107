export const API_URL = 'https://learning3ly.com/api/v1';
export const APP_URL = 'https://learning3ly.com';

// export const API_URL = 'http://100.26.218.57:2900/api/v1';
// export const API_SOCKET_URL = 'http://100.26.218.57:2900/api/v1/';
// export const APP_URL = 'http://100.26.218.57:2900';

// export const API_URL = 'http://localhost:3700/api/v1';
// export const APP_URL = 'http://localhost:3700';

// export const API_URL = 'https://www.mike1.ga/api/v1';
// export const APP_URL = 'https://www.mike1.ga/';

// export const API_URL = 'http://18.212.71.214:2900/api/v1';
// export const API_SOCKET_URL = 'http://18.212.71.214:2900/api/v1/';
// export const APP_URL = 'http://18.212.71.214:2900';

// export const API_URL = 'https://keyurdomain.ml/api/v1';
// export const APP_URL = 'https://keyurdomain.ml';

//export const API_SOCKET_URL = 'https://learning3ly.com/api/v1/';

// All Api Config Here.....//
export const API_REGISTER = '/signup';
export const API_LOGIN = '/login';
export const API_OTP_REQUEST = '/otp_request';
export const APICHECK_TOKEN = '/check_token';
export const API_fORGOT_PASSWORD = '/forgotpassword';

// Teacher section
export const API_GET_STUDENTS= '/teacher/student';  // list of students
export const API_GET_STUDENT_INFO= '/teacher/student'; //this is use for studentinfo
export const API_GET_TEACHER_STUDENT_LIST= '/teacher/studentByTeacher';
export const API_GET_STUDENT_CHART_DETAILS= '/teacher/getStudentChartData';

export const API_INSERT_STUDENT= '/teacher/student';
export const API_UPDATE_STUDENT= '/teacher/student';
export const API_UPDATE_TEACHER_PROFILE= '/teacher/';

export const API_GET_TEACHER_PROFILE= '/teacher/';
export const API_GET_UNIQUE_USERNAME= '/unique_username/';
export const API_GET_PARENT_LIST= '/parent_list';
export const API_GET_SUBJECT_LIST= '/subject_list';
export const API_GET_TEACHER_SUBJECT_LIST= '/teacher/subject';
export const API_GET_BATCH= '/teacher/batch-list';
export const API_GET_STUDENT_BATCH= '/teacher/student-batch-list';
export const API_INSERT_UPDATE__DETELE_BATCH= '/teacher/batch';

// Blog API
export const API_GET_BLOG_LIST= '/teacher/blog-list';
export const API_GET_BLOG_INFO= '/teacher/blog';
export const API_GET_ALL_TEACHER_BLOGS_LIST= '/teacher/all-blog-list';
export const API_INSERT_UPDATE__DETELE_BLOG= '/teacher/blog';

// Teacher  Section
export const API_GET_TASK_LIST= '/teacher/task-list';
export const API_INSERT_UPDATE__DETELE_TASK= '/teacher/task';
export const API_GET_TEACHER_STUDENT_TASK_INFO= '/teacher/task-student-details';
export const API_INSERT_TIME_LOG= '/teacher/task-time';
export const API_INSERT_TASK_COMMENT= '/teacher/task-comment';
export const API_INSERT_TASK_DOCUMENTS= '/teacher/task-document';
export const API_GET_COULAGE_LIST= '/teacher/coulege';
export const API_GET_TEACHER_DASHBOARD= '/teacher/dashboard';
export const API_TASK_TEACHER_DOCUMENT_DETELE= '/teacher/task-document-remove';
export const API_TASK_TEACHER_COMMENT_DETELE= '/teacher/task-comment-remove';
export const API_TASK_TEACHER_COMMENT_UPDATE= '/teacher/task-comment-update';
export const API_ADD_GOAL_REINFORCEMENT= '/teacher/goal/add-reinforcement';

// Content API
export const API_GET_CONTENT_LIST='/teacher/content-list';
export const API_GET_CONTENT_LIST_FFMPEG='/teacher/content-ffmpeg';
export const API_GET_CONTENT_LIST_BY_CATEGORY='/teacher/content-search';
export const API_FETCH_CONTENT_INFO='/teacher/content';
export const API_GET_INSERT_UPDATE__DETELE_CONTENT= '/teacher/content';
export const API_CONTENT_IMAGE_DETELE= '/teacher/content/remove-attachment';
export const API_DETELE_CONTENT_ADD_ROW = '/teacher/content/remove-attachment';
// Audio Work API

// Category API
export const API_INSERT_UPDATE__DETELE_LIST_CATEGORY= '/teacher/category';
export const API_GET_GOAL_LIST='/teacher/goal-list';
export const API_GET_INSERT_UPDATE__DETELE_GOAL= '/teacher/goal';
export const API_GOAL_IMAGE_DETELE='/teacher/goal/remove-attachment';

//  Reinforcement 
export const API_INSERT_UPDATE_DETELE_LIST_REINFORCEMENT= '/teacher/reinforcement';
export const API_GET_REINFORCEMENT_LIST='/teacher/reinforcement-list';
export const API_REINFORCEMENT_IMAGE_DETELE= '/teacher/reinforcement/remove-attachment';

// Parent Section
export const API_GET_PARENT_PROFILE ="/parent"
export const API_UPDATE_PARENT_PROFILE= '/parent';
export const API_GET_PARENT_STUDENT_TASK_LIST= '/parent/task-list';
export const API_INSERT_UPDATE__DETELE_PARENT_STUDENT_TASK= '/parent/task';
export const API_GET_PARENT_DASHBOARD= '/parent/dashboard';
export const API_GET_STUDENT_DASHBOARD= '/student/dashboard';
export const API_INSERT_PARENT_TASK_COMMENT= '/parent/task-comment';
export const API_INSERT_PARENT_TASK_DOCUMENTS= '/parent/task-document';
export const API_TASK_PARENT_DOCUMENT_DETELE= '/parent/task-document-remove';
export const API_GET_PARENT_STUDENTS= '/parent/child-list';
export const API_GET_PARENT_TEACHER_CHAT_LIST= '/parent/parent-teacher-chat';
export const API_GET_STUDENT_TEACHER_CHAT_LIST= '/student/student-teacher-chat';
export const API_SAVE_CHAT= '/parent/save-chat';
export const API_TEACHER_SAVE_CHAT= '/teacher/save-chat';
export const API_STUDENT_SAVE_CHAT= '/student/save-chat';
export const API_GET_RECENT_CHAT_LIST= '/parent/chat-users-by-type';
export const API_GET_RECENT_TEACHER_CHAT_LIST= '/teacher/chat-teacher-users-by-type';
export const API_GET_STUDENT_RECENT_CHAT_LIST= '/student/chat-users-by-type';
export const API_GET_TEACHER_TASK_STUDENTS= '/teacher/task-student';
export const API_GET_INFO= '/parent/goal';
export const API_PARENT_GET_STUDENT_INFO= '/parent/student-info';
export const API_GET_PARENT_GOAL_LIST='/parent/goal-list';

// Student API
export const API_GET_STUDENT_PROFILE ="/student"
export const API_UPDATE_STUDENT_PROFILE= '/student';
export const API_GET_STUDENT_TASK_LIST= '/student/task-list';
export const API_GET_STUDENT_GOAL_LIST= '/student/goal-list';
export const API_GET_STUDENT_GOAL_INFO= '/student/goal';
export const API_GET_STUDENT_BATCHL_LIST= '/student/batch-list';
export const API_START_END_GOAL= '/student/goal';
export const API_START_END_ASSESSMENT= '/student/assessment';

export const API_PACKAGE_PURCHASE_API = '/parent/package-purchase';

// Event 
export const AP_EVENT= '/teacher/events';

// Functions for admin //
export const ADMIN_SUBJECTS = '/teacher/adminsubjects';
export const ADMIN_SUBSCRIBERS = '/teacher/adminsubscriberlist';
export const ADMIN_CONTACTUS = '/teacher/admincontactuslist';
export const ADMIN_CATEGORY = '/teacher/admincategorylist';
export const ADMIN_CONTENT = '/teacher/admincontentlist';
export const ADMIN_CONTENT_INFO = '/teacher/admincontentinfo';
export const ADMIN_EVENTS = '/teacher/admineventslist';
export const ADMIN_REINFORCEMENT = '/teacher/adminreinforcementlist';
export const ADMIN_PACKAGE_PURCHASED = '/teacher/adminpackagepurchasedlist';
export const ADMIN_STUDENT_LIST = "/teacher/adminstudentslist";
export const ADMIN_PARENT_LIST = "/teacher/adminparentlist";
export const ADMIN_TEACHER_LIST = "/teacher/adminteacherlist";
export const ADMIN_BATCH_LIST = "/teacher/adminbatchlist";
export const ADMIN_GOALS_LIST = "/teacher/admingoallist";

export const STRIPE_PUBLIC_KEY='pk_live_51JMy2UFy4xLxnDuFUazOUpIwZJPRB5wa25UW0KGlLKxvEllgaLl10GMGGN5jz9lSuHU7RWCMYftz5QHbeLk2QkoM00FxFyyARk';