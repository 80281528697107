import { Bar, Bubble, Doughnut, Line, Pie, PolarArea, Scatter } from '@reactchartjs/react-chart.js';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Loading } from '../library/elements';
import { connect } from 'react-redux';
import moment from "moment";
import { teacherDashboardData } from './teacher_action';

class AccountDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.teacherDashboardData(function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  renderSubjectList(subjectList) {
    if (subjectList?.length > 0) {
      return subjectList.map((data) => {
        return (
          <tr key={'subject_' + data._id}>
            <td className="text-left">{data}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderEventList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        return (
          <tr key={'event_' + data._id}>
            <td className="text-left"><Link to={'/event_mgt/'}>{data.eventName}</Link></td>
            <td className="text-left">{moment(data.eventDate).format('MM/DD/yyyy')}</td>
            <td className="text-left">{data.startTime} to {data.endTime}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderStudentsList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        return (
          <tr key={'event_' + data._id}>
            <td className="text-left"><Link to={'/student_info/'+data.userId}>{data.firstName}</Link></td>
            <td className="text-left">{data.lastName}</td>
            <td className="text-left">{data.username}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderAssessmentList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        let pendingClassName = "text-left badge bg-warning text-white p-1";
        if(data.status == 'Pending'){
          pendingClassName = "text-left badge bg-success text-white p-1";
        }
        return (
          <tr key={'assessment_' + data._id}>
            <td className="text-left"><Link to={'/assessment_info/'+data._id}>{data.name}</Link></td>
            <td className="text-left"><label className={pendingClassName}>{data.status}</label></td>
            <td className="text-left">{data.collectionStatus}</td>
            <td className="text-left">{moment(data.dateAttachmentIntroduce).format('MM/DD/yyyy')}</td>
            <td className="text-left">{moment(data.batchDate).format('MM/DD/yyyy')}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderCategoriesList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        return (
          <tr key={'category_' + data._id}>
            <td className="text-left"><Link to={'/content_mgt/'}>{data.category}</Link></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }

  renderGoalList(list) {
    if (list?.length > 0) {
      return list.map((data) => {
        let pendingClassName = "text-left badge bg-warning text-white p-1";
        if(data.status == 'completed'){
          pendingClassName = "text-left badge bg-success text-white p-1";
        }
        return (
          <tr key={'goal_' + data._id}>
            <td className="text-left">
              <Link to={'/student_info/'+data.studentId}>
              {data.title}
              </Link>
            </td>
            <td className="text-left">{data.prompting}</td>
            <td className="text-left"><label className={pendingClassName}>{data.studentGoalStatus}</label></td>
            <td className="text-left">{moment(data.completionDateTime).format('MM/DD/yyyy')}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan="4">No Data Found!</td>
        </tr>
      );
    }
  }


  render() {
    const { teacher_dashboard_list } = this.props;
    if (teacher_dashboard_list && teacher_dashboard_list.length) {
      this.setState({ isLoading: false });
    }
    if (!teacher_dashboard_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    let assessment_percentage = '';
    let goals_percentage = '';

    if(teacher_dashboard_list){
      
      if(teacher_dashboard_list && teacher_dashboard_list.getCompletedAssessments && teacher_dashboard_list.getCompletedAssessments.length > 0){
        assessment_percentage = (parseFloat(teacher_dashboard_list.getCompletedAssessments[0].count) * 100 / parseFloat(teacher_dashboard_list && teacher_dashboard_list.getTotalAssessment[0].count));
      }

      if(teacher_dashboard_list && teacher_dashboard_list.getCompletedGoals && teacher_dashboard_list.getCompletedGoals.length > 0){
        goals_percentage = (parseFloat(teacher_dashboard_list.getCompletedGoals[0].count) * 100 / parseFloat(teacher_dashboard_list && teacher_dashboard_list.getTotalGoals[0].count));
      }      
    }

    let getCompletedGoals = '';
    let getTotalGoals = '';

    if(teacher_dashboard_list && teacher_dashboard_list.getCompletedGoals && teacher_dashboard_list.getCompletedGoals.length > 0){
      getCompletedGoals = teacher_dashboard_list.getCompletedGoals[0].count;
    }
    if(teacher_dashboard_list && teacher_dashboard_list.getTotalGoals && teacher_dashboard_list.getTotalGoals.length > 0){
      getTotalGoals = teacher_dashboard_list.getTotalGoals[0].count;
    }

    const GoalsPercentage = {
      labels: ['Completed','Total'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            getCompletedGoals,
            getTotalGoals
          ],
          backgroundColor: [
            
            '#5cb85c',
            '#5bc0de'
          ],
          borderColor: [
            '#5cb85c',
            '#5bc0de'
          ],
          borderWidth: 1,
        },
      ],
    }

    let getCompletedAssessments = '';
    let getTotalAssessment = '';

    if(teacher_dashboard_list && teacher_dashboard_list.getCompletedAssessments && teacher_dashboard_list.getCompletedAssessments.length > 0){
      getCompletedAssessments = teacher_dashboard_list.getCompletedAssessments[0].count;
    }
    
    if(teacher_dashboard_list && teacher_dashboard_list.getTotalAssessment && teacher_dashboard_list.getTotalAssessment.length > 0){
      getTotalAssessment = teacher_dashboard_list.getTotalAssessment[0].count;
    }

    const AssessmentPercentage = {
      labels: ['Completed','Total'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            getCompletedAssessments,
            getTotalAssessment
          ],
          backgroundColor: [     
            '#f0ad4e',
            '#d9534f'
          ],
          borderColor: [
            '#f0ad4e',
            '#d9534f'
          ],
          borderWidth: 1,
        },
      ],
    }

    let getTotalGoalsAssessment = '';
    let getPendingGoals = '';
    let getCompletedGoalsAssessments = '';

    if(teacher_dashboard_list && teacher_dashboard_list.getTotalGoals && teacher_dashboard_list.getTotalGoals.length > 0){
      getTotalGoalsAssessment = teacher_dashboard_list.getTotalGoals[0].count;
    }
    if(teacher_dashboard_list && teacher_dashboard_list.getPendingGoals && teacher_dashboard_list.getPendingGoals.length > 0){
      getPendingGoals = teacher_dashboard_list.getPendingGoals[0].count;
    }
    if(teacher_dashboard_list && teacher_dashboard_list.getCompletedGoals && teacher_dashboard_list.getCompletedGoals.length > 0){
      getCompletedGoalsAssessments = teacher_dashboard_list.getCompletedGoals[0].count;
    }

    const GoalsProgress = {
      labels: ['Total','Completed', 'Pending'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            getTotalGoalsAssessment,
            getPendingGoals,
            getCompletedGoalsAssessments
          ],
          backgroundColor: [
            '#5cb85c',
            '#5bc0de',
            '#d9534f',
          ],
          borderColor: [
            '#5cb85c',
            '#5bc0de',
            '#d9534f',
          ],
          borderWidth: 1,
        },
      ],
    }

    let getTotalAssessment1 = '';
    let getCompletedAssessments1 = '';
    let getPendingAssessments1 = '';
    let getPendingApprovalAssessment1 = '';

    if(teacher_dashboard_list && teacher_dashboard_list.getTotalAssessment && teacher_dashboard_list.getTotalAssessment.length > 0){
      getTotalAssessment1 = teacher_dashboard_list.getTotalAssessment[0].count;
    }

    if(teacher_dashboard_list && teacher_dashboard_list.getCompletedAssessments && teacher_dashboard_list.getCompletedAssessments.length > 0){
      getCompletedAssessments1 = teacher_dashboard_list.getCompletedAssessments[0].count;
    }

    if(teacher_dashboard_list && teacher_dashboard_list.getPendingAssessments && teacher_dashboard_list.getPendingAssessments.length > 0){
      getPendingAssessments1 = teacher_dashboard_list.getPendingAssessments[0].count;
    }

    if(teacher_dashboard_list && teacher_dashboard_list.getPendingApprovalAssessment && teacher_dashboard_list.getPendingApprovalAssessment.length > 0){
      getPendingApprovalAssessment1 = teacher_dashboard_list.getPendingApprovalAssessment[0].count;
    }

    const AssessmentsProgress = {
      labels: ['Total','Completed','Pending','Teachers Pending Approval'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            getTotalAssessment1,
            getCompletedAssessments1,
            getPendingAssessments1,
            getPendingApprovalAssessment1
          ],
          backgroundColor: [
            '#5cb85c',
            '#5bc0de',
            '#d9534f',
            '#f0ad4e'
          ],
          borderColor: [
            '#5cb85c',
            '#5bc0de',
            '#d9534f',
            '#f0ad4e'
          ],
          borderWidth: 1,
        },
      ],
    }

    // Subject Chart Preparation Start //
    let subjectLabels = [];
    let subjectData = [];
    let subjectListChartData = [];
    let applyBG = [];
    let bgcolors = ['#1266f1','#b23cfd','#00b74a','#f93154','#ffa900','#39c0ED','#666','#262626'];

    if(teacher_dashboard_list && teacher_dashboard_list.subjectList && teacher_dashboard_list.subjectList != false){  

      let j = 0;
      teacher_dashboard_list.subjectList.map((data,index) => {
        subjectData.push(data);
        subjectLabels.push(1);
        applyBG.push(bgcolors[j]);
        if(index % 7 == 0){
          j = 0;
        }
        j++;
      });
      
      subjectListChartData = {
        labels: subjectData,
        datasets: [
          {
            label: '# Subjectlist',
            data: subjectLabels,
            backgroundColor: applyBG,
            borderColor: '#EAEAEA',
            borderWidth: 1,
          },
        ],
      }
    }
    // Subject Chart Preparation Over //


    // Category Chart Preparation Start //
    let categoryLabels = [];
    let categoryData = [];
    let categoryListChartData = [];
    let applyBGCat = [];
    let bgcolorsCat = ['#ff4d4d','#bfff00','#40ff00','#00ff80','#00bfff','#0040ff','#bf00ff','#ff00bf'];

    if(teacher_dashboard_list && teacher_dashboard_list.teacherCategories && teacher_dashboard_list.teacherCategories != false){  

      let j = 0;
      teacher_dashboard_list.teacherCategories.map((data,index) => {
        categoryData.push(data.category);
        categoryLabels.push(Math.random() * 3);
        applyBGCat.push(bgcolorsCat[j]);
        if(index % 7 == 0){
          j = 0;
        }
        j++;
      });
      
      categoryListChartData = {
        labels: categoryData,
        datasets: [
          {
            label: '# CategoryList',
            data: categoryLabels,
            backgroundColor: applyBGCat,
            borderColor: '#EDEDED',
            borderWidth: 1,
          },
        ],
      }
    }
    // Subject Chart Preparation Over //


    // Upcoming Events Chart Preparation Start //
    let uEventLabels = [];
    let uEventData = [];
    let uEventListChartData = [];
    let applyBGuEvent = [];
    let bgcolorsuEvent = ['#ff4d4d','#bfff00','#40ff00','#00ff80','#00bfff','#0040ff','#bf00ff','#ff00bf'];

    if(teacher_dashboard_list && teacher_dashboard_list.teacherEvents && teacher_dashboard_list.teacherEvents != false){  

      let j = 0;
      teacher_dashboard_list.teacherEvents.map((data,index) => {
        uEventData.push(data.eventName+ ' ' + moment(data.eventDate).format('MM/DD/yyyy') + ' '+ data.startTime + ' to '+ data.endTime+ '                  ');
        uEventLabels.push(Math.random() * 2);
        applyBGuEvent.push(bgcolorsuEvent[j]);
        if(index % 7 == 0){
          j = 0;
        }
        j++;
      });
      
      uEventListChartData = {
        labels: uEventData,
        datasets: [
          {
            label: '# EventList',
            data: uEventLabels,
            backgroundColor: applyBGuEvent,
            borderColor: '#EDEDED',
            borderWidth: 1,
          },
        ],
      }
    }
    // Subject Chart Preparation Over //


    // Recent Registration Chart Preparation Start //
    let recRegLabels = [];
    let recRegData = [];
    let recRegListChartData = [];
    let applyBGrecReg = [];
    let bgcolorsrecReg = ['#ff4d4d','#bfff00','#40ff00','#00ff80','#00bfff','#0040ff','#bf00ff','#ff00bf'];

    if(teacher_dashboard_list && teacher_dashboard_list.recentStudents && teacher_dashboard_list.recentStudents != false){

      let j = 0;
      teacher_dashboard_list.recentStudents.map((data,index) => {
        recRegData.push(data.username);
        recRegLabels.push(1);
        applyBGrecReg.push(bgcolorsrecReg[j]);
        if(index % 7 == 0){
          j = 0;
        }
        j++;
      });
      
      recRegListChartData = {
        labels: recRegData,
        datasets: [
          {
            label: '# Recent Registration',
            data: recRegLabels,
            backgroundColor: applyBGrecReg,
            borderColor: '#EDEDED',
            borderWidth: 1,
          },
        ],
      }
    }
    // Recent Registration Preparation Over //


    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return (
      <div className="account-bottom-container">
        <div className="page" id="page-teacher-dashboard">
          <div className="main-panel container-fluid">
            <div className="content">  

            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <h4 class="text-primary">Teachers Dashboard</h4>
                  <hr class="text-primary" />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="dashboard-card-icon">
                      <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">All Assessments</p>
                      <p className="card-title text-danger">{teacher_dashboard_list && teacher_dashboard_list && teacher_dashboard_list.getCompletedAssessments.length > 0 > 0 ? teacher_dashboard_list && teacher_dashboard_list.getCompletedAssessments[0].count : 0} Completed out of {teacher_dashboard_list && teacher_dashboard_list && teacher_dashboard_list.getTotalAssessment.length > 0 ? teacher_dashboard_list && teacher_dashboard_list.getTotalAssessment[0].count : 0}</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/batch_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="dashboard-card-icon">
                      <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">Goals Overview</p>
                      <p className="card-title text-danger">{teacher_dashboard_list && teacher_dashboard_list.getCompletedGoals.length > 0 > 0 ? teacher_dashboard_list && teacher_dashboard_list.getCompletedGoals[0].count : 0} Completed out of {teacher_dashboard_list && teacher_dashboard_list.getTotalGoals.length > 0 ? teacher_dashboard_list && teacher_dashboard_list.getTotalGoals[0].count : 0}</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/student_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-warning card-header-icon">
                      <div className="dashboard-card-icon">
                        <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">Subjects Overview</p>
                      <p className="card-title text-black">Total {teacher_dashboard_list && teacher_dashboard_list.getSubjectList.length > 0 ? teacher_dashboard_list.getSubjectList.length : 0} Subjects</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/batch_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="dashboard-card-icon">
                      <i className="fa fa-user text-white"></i>
                      </div>
                      <p className="card-category">Students Overview</p>
                      <p className="card-title text-black">{teacher_dashboard_list && teacher_dashboard_list.getTotalStudents && teacher_dashboard_list.getTotalStudents.length > 0 ? teacher_dashboard_list.getTotalStudents[0].count : 0} Students</p>
                    </div>
                    <div className="card-footer bg-transparent border-0">
                      <div className="btn btn-sm btn-primary pull-right">
                        <Link to={'/student_mgt'} className="text-white">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
              {
                    getTotalGoals && getTotalGoals > 0 && 
                <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data">
                  {
                    getTotalGoals && getTotalGoals > 0 && <div className="card card-chart">
                    <div className="ct-chart" id="dailySalesChart"><Pie data={GoalsPercentage} /></div>
                      <div className="card-body">
                        <p className="card-title mb-0 text-danger text-center">Your Overall Goal Progress ({goals_percentage && goals_percentage > 0 ? goals_percentage.toFixed(2) : 0}%)
                        </p>
                      </div>
                    </div>
                  }
                </div>
                }

                {
                    getTotalAssessment && getTotalAssessment > 0 && 
                <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data">
                  {
                    getTotalAssessment && getTotalAssessment > 0 && <div className="card card-chart">
                    <div className="ct-chart" id="dailySalesChart"><Pie data={AssessmentPercentage} /></div>
                      <div className="card-body">
                        <p className="card-title mb-0 text-danger text-center">Your Overall Assessment Progress ({assessment_percentage > 0 ? assessment_percentage.toFixed(2) : 0}%)</p>
                      </div>
                    </div>
                  }
                </div>
                }
                
                <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data">
                  {
                    getTotalGoalsAssessment && getTotalGoalsAssessment > 0 && <div className="card card-chart">
                    <div className="ct-chart" id="dailySalesChart"><Doughnut data={GoalsProgress} /></div>
                      <div className="card-body">
                        <p className="card-title mb-0 text-warning text-center">Your Goal Progress</p>
                      </div>
                    </div>
                  }
                  
                </div>
                {
                    getTotalAssessment && getTotalAssessment > 0 && 
                <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data">
                  {
                    getTotalAssessment && getTotalAssessment > 0 && <div className="card card-chart">
                    <div className="ct-chart" id="dailySalesChart"><Doughnut data={AssessmentsProgress} /></div>
                      <div className="card-body">
                        <p className="card-title mb-0 text-warning text-center">Your Assessments Progress</p>
                      </div>
                    </div>
                  }
                  
                </div>
                }
              </div>


              <div className="row">
                {/* <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data mb-2">
                  {
                    teacher_dashboard_list.recentStudents && teacher_dashboard_list.recentStudents.length > 0 && <div className="card card-stats px-3">
                      <div className="card-header card-header-success card-header-icon">
                        <h6 class="text-left text-warning">Recent Students Registerations</h6>
                        <div className=" react-bs-table-container">
                          <table className="table table-responsive">
                            <thead>
                            </thead>
                            <tbody>
                              {
                                this.state.isLoading == false && 
                                teacher_dashboard_list &&
                                teacher_dashboard_list.recentStudents &&
                                teacher_dashboard_list.recentStudents.length > 0 &&
                                <Bar data={recRegListChartData}/>
                              }
                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  }
                  
                </div> */}

                {/* <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data mb-2">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <h6 class="text-left text-warning">Teachers Recent Assessments</h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left text-primary">Name</th>                              
                              <th className="text-left text-primary">Status</th>
                              <th className="text-left text-primary">Collection</th>
                              <th className="text-left text-primary">Assigned</th>
                              <th className="text-left text-primary">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isLoading == false && this.renderAssessmentList(teacher_dashboard_list.teacherAssessments)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}


                
                {/* <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data mb-2">
                  {
                    teacher_dashboard_list.teacherEvents && teacher_dashboard_list.teacherEvents.length > 0 && <div className="card card-stats px-3">
                      <div className="card-header card-header-success card-header-icon">
                        <h6 class="text-left text-warning">Upcoming Events Placed By Teachers</h6>
                        <div className=" react-bs-table-container">
                          <table className="table table-responsive">
                            <thead>
                             </thead>
                            <tbody>
                            
                              {
                                this.state.isLoading == false && 
                                teacher_dashboard_list &&
                                teacher_dashboard_list.teacherEvents &&
                                teacher_dashboard_list.teacherEvents.length > 0 &&
                                <Bar data={uEventListChartData}/>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  }
                  
                </div> */}

                {/* <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data mb-2">
                  <div className="card card-stats px-3">
                    <div className="card-header card-header-success card-header-icon">
                      <h6 class="text-left text-warning">Recent Goals Created By Teachers</h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left text-primary">Title</th>
                              <th className="text-left text-primary">Prompting</th>
                              <th className="text-left text-primary">Status</th>
                              <th className="text-left text-primary">Complete On</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.isLoading == false && this.renderGoalList(teacher_dashboard_list.teacherGoals)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data mb-2">
                  {
                    teacher_dashboard_list.teacherCategories && teacher_dashboard_list.teacherCategories.length > 0 &&<div className="card card-stats px-3">
                        <div className="card-header card-header-success card-header-icon">
                          <h6 class="text-left text-warning">Recent Categories Added By Teachers</h6>
                          <div className=" react-bs-table-container">
                            <table className="table table-responsive">
                              <thead>
                                {/* <tr>
                                  <th className="text-left text-primary">Name Of Category</th>                             
                                </tr> */}
                              </thead>
                              <tbody>
                                {/* {this.state.isLoading == false && this.renderCategoriesList(teacher_dashboard_list.teacherCategories)} */}
                                {
                                  this.state.isLoading == false && 
                                  teacher_dashboard_list &&
                                  teacher_dashboard_list.teacherCategories &&
                                  teacher_dashboard_list.teacherCategories.length > 0 &&
                                  <Doughnut data={categoryListChartData} />
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  }
                  
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 dashboard-data mb-2">
                  {
                    teacher_dashboard_list.getTotalGoals && teacher_dashboard_list.getTotalGoals.length > 0 && <div className="card card-stats px-3">
                    <div className="card-header card-header-warning card-header-icon">
                    <h6 class="text-left text-warning">Recently Added Subjects Of Students</h6>
                      <div className=" react-bs-table-container">
                        <table className="table table-responsive">
                          <thead>
                            {/* <tr>
                              <th className="text-left text-primary">List Of All Subjects Selected By Teachers</th>
                            </tr> */}
                          </thead>
                          <tbody>
                            {/* {this.state.isLoading == false && this.renderSubjectList(teacher_dashboard_list.subjectList)} */}
                            {
                              this.state.isLoading == false && 
                              teacher_dashboard_list &&
                              teacher_dashboard_list.subjectList &&
                              teacher_dashboard_list.subjectList.length > 0 &&
                            <Doughnut data={subjectListChartData} />}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  }
                  
                </div>              
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    teacher_dashboard_list: state.teacher.teacher_dashboard_list
  }
}

export default connect(mapStateToProps, { teacherDashboardData })(AccountDashboard);
//export default AccountDashboard;