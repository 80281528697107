import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

class BatchStudentFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };


  }




  renderStudents(students) {
    if (students != undefined && students != null && students != "") {
      return students.map((student) => {
        return (
          <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-12" key={"checkout.student." + student.username}>

            <li className="list-group-item border-bottom rounded  mb-2 d-flex bg-light justify-content-between align-items-center">
              {student.firstName} {student.lastName}
              <span className="badge bg-primary rounded-pill">{student.grade}</span>
            </li>
          </div>
        );
      });
    } else {
      return (
        <div className="col-sm-12 text-center p-1">
          Currently No Students add in this Workspace
        </div>
      );
    }
  }

  render() {
    const { students } = this.props;
    var { isLoading } = this.state;
    return (
      <div className="sk-form">
        <div className="row">
              
            {this.renderStudents(students)}
        
        </div>
      </div>
    );
  }
}



function mapStateToProps(state, ownProps) {
  var studentList = {};
  if (ownProps.batchstudentsData) {
    var studentList = ownProps.batchstudentsData.studentList;
  }
  return {
    students: studentList,

  }
}

BatchStudentFormModal = reduxForm({
  form: 'form_batch',
})(BatchStudentFormModal);


export default connect(mapStateToProps, {})(BatchStudentFormModal);
