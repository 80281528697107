import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { addStudent, getAllSubjects, getProfile, updateStudent, validationNull } from '../parent_action';
import { input, textarea } from '../../library/elements';

import DatePicker from 'react-date-picker';
import Select from 'react-select';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { getUniqueUserName } from '../../account/account_action';

class StudentFormModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPasswordText: false,
      isPasswordConfirmText: false,
      parentId : cookie.load('parent_id')
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removeModal = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.validationNull();
    this.props.getAllSubjects();
    this.props.getUniqueUserName();
  }

  handleFormSubmit(formProps) {
    formProps.subjectList = formProps?.subjects?.length > 0 ? formProps.subjects.split(',') : ['6013a9c0be28bb1884356a0b'];

    this.setState({ isLoading: true });
    var _this = this;
    if (this.props.studentData) {
      formProps.studentId = formProps._id;
      this.props.updateStudent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    } else {
      this.props.addStudent(formProps, function (err, result) {
        if (result) {
          _this.setState({ isLoading: false });
          _this.props.removeModal();
        }
      });
    };
  }
  closeModal() {
    this.props.removeModal();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops! </strong> {this.props.errorMessage}
        </div>
      )
    }
  }
  render() {
    const { handleSubmit, teacher_subject_list, errorMessage,initialValues } = this.props;

    var { isLoading, isPasswordText, isPasswordConfirmText } = this.state;
    //console.log("Props ====>",cookie.load('parent_id'));
    if (errorMessage) {
      isLoading = false;
    }
    var past18Year = new Date().getFullYear() - 18;
    var past18YearDate = new Date();
    past18YearDate.setFullYear(past18Year);

    var subjectData = [];
    for (var i in teacher_subject_list) {
      subjectData.push({ label: teacher_subject_list[i].name, value: teacher_subject_list[i]._id });
    }
   
 
    return (
      <div className="sk-form">
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <div className="col-sm-6">
              <Field name="firstName" type="text" component={input} label="First Name" is_label={true} isRequired={true} className="form-control" />
            </div>
            <div className="col-sm-6">
              <Field name="lastName" type="text" component={input} label="Last Name" is_label={true} isRequired={true} className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6">
              <Field name="age" type="text" component={input} label="Age" is_label={true} isRequired={true} className="form-control" />
            </div>
            <div className="col-sm-6">
              <label className="control-label">Birthday</label>
              <Field name={`dob`} component={dob => {
                return (
                  <div className="dt-picker">
                    <DatePicker
                      maxDate={new Date()}
                      name="dob"
                      format={"MM/dd/y"}
                      value={

                        (!dob.input.value || dob.input.value == '0000-00-00')
                          ? past18YearDate
                          :
                          new Date(dob.input.value)
                      }
                      onChange={(date) => {
                        dob.input.onChange(date);
                      }}
                    />
                  </div>
                )
              }} className="form-control" />
            </div>
          </div>

          <div className="form-group row">

            <div className="col-sm-12">
              <Field name="username" type="text" component={input} label="Username" is_label={true} className="form-control" />

            </div>
          </div>

          <div className="form-group row">
            <div className="col-6">
              <label className="control-label">Subject</label>
              <Field name="subjects" component={subjects => {
                return (
                  // <Select multi simpleValue
                  //   value={subjects.input.value}
                  //   placeholder="Select Subject"
                  //   options={subjectData}
                  //   onChange={(e) => {
                  //     subjects.input.onChange(e);

                  //   }} />
                  <Select.Creatable
                      allowCreate={true}
                      //multi={multi}
                      options={subjectData}
                      onChange={(e) => {subjects.input.onChange(e)}}
                      value={subjects.input.value}
                    />
                )

              }} className="form-control" />
            </div>
            <div className="col-sm-6">
              <Field name="grade" type="text" component={input} label="Grade" is_label={true} className="form-control" />
            </div>
          </div>

          <div className="form-group row">

            <div className="col-sm-12">
              <Field name="address" row="" component={textarea} label="Address" isRequired={true} is_label={true} className="form-control" />
            </div>
          </div>
          
          {
                this.props.studentData
                  ?
                  <div className="form-group row">

                  <div className="col-sm-6">
                  <label className="form-lable required">Status</label>
                  <Field
  
                    name="status"
                    component={ status =>
                     <div>
                       <select
                         name="status"
                         className="form-control"
                         value={status.input.value}
                         onChange={(event) => {
                           status.input.onChange(event.target.value);
                         }}
                       >
                       <option value="">--Select--</option>
                       <option value="true">Active</option>
                       <option value="false">InActive</option>
                       </select>
                       {status.meta.touched && ((status.meta.error && <div className="error">{status.meta.error}</div>) || (status.meta.warning && <div className="error">{status.meta.warning}</div>))}
                     </div>
                  } />
                </div>
                </div>
        
                  :
                null
              }
          <div className="row">

            <div className="col-sm-9">
              {
                this.props.studentData
                  ?
                  <button action="form_student" className=" mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-edit"></i> {isLoading ? 'Loading...' : 'Update Student'}
                  </button>
                  :
                  <button action="form_student" className=" mb-2  mb-2 btn alc-btn w-100 alc-btn-action alc-btn-larg" disabled={isLoading}>
                    <i className="fa fa-plus-circle"></i> {isLoading ? 'Loading...' : 'Add Student'}
                  </button>
              }
            </div>
            <div className="col-sm-3">
              <button onClick={() => this.closeModal()} type="button" className="md-btn btn btn-danger btn-block">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    );
  }
}

function validate(formProps) {
  const errors = {}

  if (!formProps.firstName) {
    errors.firstName = 'Required First Name';
  }
  if (!formProps.lastName) {
    errors.lastName = 'Required Last Name';
  }
  if (!formProps.std) {
    errors.std = 'Required std';
  }
  if (!formProps.age) {
    errors.age = 'Required Age';
  } else {
    if (isNaN(formProps.age)) {
      errors.age = 'Age must be numeric only!';
    }
  }
  if (!formProps.username) {
    errors.username = 'Required Unique User Name';
  }

  if (!formProps.address) {
    errors.address = 'Required Address';
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  var iniStudent = {};
  //console.log("Props ===>",ownProps);
  iniStudent.username = state?.account?.unique_username?.unique
  if (ownProps.studentData) {
    var iniStudent = ownProps.studentData;
    var subjectData = [];
    for (var i in iniStudent.subjectList) {
      subjectData.push(iniStudent.subjectList[i]);
    }
  
    var stringValueYouWant = subjectData.join(',');
    iniStudent.subjects = stringValueYouWant;
  }
  return {
    errorMessage: state.account.error,
    initialValues: iniStudent,
    teacher_subject_list: state.parent.all_subject_list,
  }
}

StudentFormModal = reduxForm({
  form: 'form_student',
  validate: validate
})(StudentFormModal);

export default connect(mapStateToProps, { addStudent, updateStudent, getAllSubjects, getProfile, getUniqueUserName, validationNull })(StudentFormModal);