import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../library/elements';
import { getTaskList } from './teacher_action';
import { modal } from '../library/modal';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import DeshHeader from './desh_header';
// must manually import the stylesheets for each plugin

import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import moment from "moment";
import BatchInformationModal from './modal/model_batch_information';

class StudentTaskCalender extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      calendarWeekends: true,
     
    };
    this.applySortBy = this.applySortBy.bind(this);
  }
  componentWillMount() {
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getTaskList("",function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  toggleWeekends = () => {
    this.setState({
      // update a property
      calendarWeekends: !this.state.calendarWeekends
    });
  };

  gotoPast = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
  };

  handleEventClick(arg) {
    var batchData = {};
    batchData.batchDate = arg.event.start;
    batchData.name = arg.event.title;
    batchData._id = arg.event._def.extendedProps.batchId;
    batchData.description = arg.event._def.extendedProps.description;
    batchData.status = arg.event._def.extendedProps.status;
    batchData.subjectId = arg.event._def.extendedProps.subjectId;
    batchData.studentList = arg.event._def.extendedProps.studentList;
    batchData.endTime = arg.event._def.extendedProps.endtime;
    batchData.startTime = arg.event._def.extendedProps.starttime;
    modal.add(BatchInformationModal, {
      title:  arg.event.title,
      size: 'large',
      closeOnOutsideClick: false,
      hideCloseButton: false,
      batchData: batchData,
    });
  };

  applySortBy(e) {
    this.setState({ isLoading: true });
    var _this = this;
    this.setState({ isLoading: true });
    this.props.getTaskList(e.target.value,function (err, result) {
      if (result) {
        _this.setState({ isLoading: false });
      }
    });
  }

  render() {
    const { student_task_list } = this.props;
    const { isLoading } = this.state;

    if (!student_task_list) {
      return (
        <div className="wallet-undefined"><Loading /></div>
      );
    }

    var calendarEventsarray = [];
    for (var i in student_task_list) {
       var date = 'Thu Feb 11 2021 11:19:04 GMT+0530 (India Standard Time)';
      calendarEventsarray.push(
        {
          title: student_task_list[i].name,
          batchId: student_task_list[i]._id,
          start: student_task_list[i].batchDate,
          description: student_task_list[i].description,
          status: student_task_list[i].status,
          endtime: student_task_list[i].endTime,
          starttime: student_task_list[i].startTime,
          subjectId: student_task_list[i].subjectId,
          studentList: student_task_list[i].studentList
        });
    }
    return (

      <div className="page material-ui" id="page-batch-info">
      {isLoading ? <Loading /> : null}
      
     <div className="wrapper ">
        <DeshHeader />
          <div className="main-panel">
        
            <div className="content container mt-4">
              <div className="page" id="page-batch">
                  <div className="demo-app-calendar">
                    <div className="row mb-3">
                      <div className="col-md-4 col-sm-12">
                      </div>
                      <div className="col-md-4 col-xs-12"></div>
                      <div className="col-md-4 col-xs-12">
                        <div className="text-right">
                          <div className="ml-1 select-style pull-right">
                            <select onChange={(e) => this.applySortBy(e)} className="form-select">
                              <option value="">-- Choose --</option>
                              <option value="Pending">Upcoming</option>
                              <option value="Ongoing">InProgress</option>
                              <option value="Completed">Complete</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <FullCalendar
                      defaultView="dayGridMonth"
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                      headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                      }}
                      displayEventTime={false}
                      selectable={true}
                      ref={this.calendarComponentRef}
                      weekends={this.state.calendarWeekends}
                      events={calendarEventsarray}
                      eventClick={this.handleEventClick}
    
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
   





    );

  }
}

function mapStateToProps(state) {

  return {
    student_task_list: state.teacher.student_task_list,

  }
}
export default connect(mapStateToProps, { getTaskList })(StudentTaskCalender);
