import * as config from '../system/config';

import axios from 'axios';
import { change } from 'redux-form';
import cookie from 'react-cookie';
import { createAlert } from '../library/function';

export const GET_BATCH_LIST = "GET_BATCH_LIST";
export const GET_BATCHES_LIST = "GET_BATCHES_LIST";
export const GET_All_SUBJECTS = "GET_All_SUBJECTS";

export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const GET_STUDENT_LIST = "GET_STUDENT_LIST";
export const GET_NEWSLETTER_STATUS = "GET_NEWSLETTER_STATUS";
export const GET_COULAGE_LIST = "GET_COULAGE_LIST";
export const GET_DASHBOARD_LIST = "GET_DASHBOARD_LIST";

export const FETCH_STUDENT_INFO = "FETCH_STUDENT_INFO";
export const FETCH_TEACHER_STUDENT_LIST = "FETCH_TEACHER_STUDENT_LIST";
export const FETCH_STUDENT_CHART_DATA = "FETCH_STUDENT_CHART_DATA";
export const GET_TEACHER_SUBJECTS = "GET_TEACHER_SUBJECTS";
export const FETCH_TEACHER_ACCOUNT_PROFILE = "FETCH_TEACHER_ACCOUNT_PROFILE";

export const GET_STUDENT_TASK_LIST = "GET_STUDENT_TASK_LIST";
export const GET_TEACHER_STUDENT_TASK_INFO = "GET_TEACHER_STUDENT_TASK_INFO";
export const GET_TEACHER_TASK_STUDENTS = "GET_TEACHER_TASK_STUDENTS";

export const GET_CONTENT_LIST = "GET_CONTENT_LIST";
export const GET_CONTENT_LIST_FFMPEG = "GET_CONTENT_LIST_FFMPEG";
export const FETCH_CONTENT_INFO = "FETCH_CONTENT_INFO";
export const FETCH_REINFORCEMENT_INFO = "FETCH_REINFORCEMENT_INFO";
export const GET_GOAL_LIST = "GET_GOAL_LIST";

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const FETCH_GOAL_INFO = "FETCH_GOAL_INFO";
export const GET_REINFORCEMENT_LIST = "GET_REINFORCEMENT_LIST";

export const GET_EVENT_LIST = "GET_EVENT_LIST";

export const GET_ADMIN_SUBJECT_LIST = "GET_ADMIN_SUBJECT_LIST";
export const GET_ADMIN_SUBSCRIBER_LIST = "GET_ADMIN_SUBSCRIBER_LIST";
export const GET_ADMIN_CONTACTUS_LIST = "GET_ADMIN_CONTACTUS_LIST";
export const GET_ADMIN_CATEGORY_LIST = "GET_ADMIN_CATEGORY_LIST";
export const GET_ADMIN_CONTENT_LIST = "GET_ADMIN_CONTENT_LIST";
export const GET_ADMIN_CONTENT_INFO = "GET_ADMIN_CONTENT_INFO";
export const GET_ADMIN_EVENTS_LIST = "GET_ADMIN_EVENTS_LIST";
export const GET_ADMIN_REINFORCEMENT_LIST = "GET_ADMIN_REINFORCEMENT_LIST";
export const GET_ADMIN_PACKAGE_PURCHASED_LIST = "GET_ADMIN_PACKAGE_PURCHASED_LIST";
export const GET_ADMIN_STUDENT_LIST = "GET_ADMIN_STUDENT_LIST";
export const GET_ADMIN_PARENT_LIST = "GET_ADMIN_PARENT_LIST";
export const GET_ADMIN_TEACHER_LIST = "GET_ADMIN_TEACHER_LIST";
export const GET_ADMIN_GOALS_LIST = "GET_ADMIN_GOALS_LIST";
export const GET_ADMIN_BATCH_LIST = "GET_ADMIN_BATCH_LIST";
// Student Module 

// students list for teacher 

export function getStudents(callback) {
  //console.log(`${config.API_URL + config.API_GET_STUDENTS}?_id=${cookie.load('teacher_id')}`,cookie.load('teacher'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENTS}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_STUDENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
      });
  }
}
// end 

export function updateStudent(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_INSERT_STUDENT}?_id=${cookie.load('teacher_id')}`,
      data: {
        studentId: data.studentId,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        username: data.username,
        address: data.address,
        parentId: data.parentId,
        std: data.std,
        userId: data.user_id,
        status: data.status,
        // email: data.email,
        grade: data.grade,
        subjectList: data.subjectList,
        age: data.age
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.error && response.data) {
          dispatch(getStudents());
          callback(null, response);
          createAlert({ message: 'You Student has been Updated.' });
        } else {
          dispatch(validation(response.data.error));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addStudent(data, callback) {
  return function (dispatch) {
    // console.log(`${config.API_URL + config.API_UPDATE_STUDENT}?_id=${cookie.load('teacher_id')}`,JSON.stringify({firstName: data.firstName,
    //   lastName: data.lastName,
    //   dob: data.dob,
    //   username: data.username,
    //   address: data.address,
    //   parentId: data.parentId,
    //   teacherId: cookie.load('teacher_id'),
    //   std: data.std,
    //   // email: data.email,
    //   grade: data.grade,
    //   subjectList: data.subjectList,
    //   age: data.age,}));
    //   return false;
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_UPDATE_STUDENT}?_id=${cookie.load('teacher_id')}`,
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        username: data.username,
        address: data.address,
        parentId: data.parentId,
        teacherId: cookie.load('teacher_id'),
        std: data.std,
        // email: data.email,
        grade: data.grade,
        subjectList: data.subjectList,
        age: data.age,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getStudents());
          callback(null, response);
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function getTeacherStudentList(callback) {
  //console.log(`${config.API_URL + config.API_GET_TEACHER_STUDENT_LIST + '?_id=' + cookie.load('teacher_id')}`);
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_TEACHER_STUDENT_LIST + '?_id=' + cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: FETCH_TEACHER_STUDENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getStudentChartData(data,callback) {
  console.log("Request ====>",`${config.API_URL + config.API_GET_STUDENT_CHART_DETAILS + '?_id=' + data.studentId}`,data);
  return function (dispatch) {
    axios({
      method: 'GET',
      url: `${config.API_URL + config.API_GET_STUDENT_CHART_DETAILS + '?_id=' + data.studentId}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        console.log("Student Chart Data",response.data);
        dispatch({ type: FETCH_STUDENT_CHART_DATA, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getStudentInfo(student_id, callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_INFO + '/' + student_id}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: FETCH_STUDENT_INFO, payload: response.data.data[0] });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
// Student Module End


export function getProfile() {
  //console.log(`${config.API_URL + config.API_GET_TEACHER_PROFILE}?_id=${cookie.load("teacher_id")}`,cookie.load('teacher'));
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_TEACHER_PROFILE}?_id=${cookie.load("teacher_id")}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (response !== undefined && response.data !== undefined) {
            dispatch({ type: FETCH_TEACHER_ACCOUNT_PROFILE, payload: response.data });
        }
      })
      .catch(response => {
        console.log(response);
      });
  }
}


export function updateTeacherProfile(formData, callback) {

  return function (dispatch) {
    dispatch(validationNull());
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_UPDATE_TEACHER_PROFILE}?_id=${cookie.load("teacher_id")}`,
      data: formData,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          createAlert({ message: response.data.msg });
          dispatch(getProfile());
          callback(null, true);
        } else {
          dispatch(validation(response.data.err));
        }
      })
      .catch(response => {
      });
  }
}

export function getTeacherSubjects() {
  console.log(`${config.API_URL + config.API_GET_TEACHER_SUBJECT_LIST}`,JSON.stringify({"_id": cookie.load("teacher_id")}));
  return function (dispatch) {
    axios({
      method: 'get',
      data : {"_id": cookie.load("teacher_id")},
      url: `${config.API_URL + config.API_GET_TEACHER_SUBJECT_LIST}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {

        dispatch({ type: GET_TEACHER_SUBJECTS, payload: response.data });
      })
      .catch(response => {
        console.log(response);
      });
  }
}

export function getBatches(filter_keyword, callback) {
  //console.log("Teacher Batch List Action ===> ",`${config.API_URL + config.API_GET_BATCH + '?_id='+cookie.load('teacher_id')+'&keyword=' + filter_keyword}`);
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_BATCH + '?_id='+cookie.load('teacher_id')+'&keyword=' + filter_keyword}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_BATCH_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log(response);
      });
  }
}

export function getBatchList(student_id,filter_keyword, callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_STUDENT_BATCH + '?_id='+cookie.load('teacher_id')+"&student_id="+student_id+'&keyword=' + filter_keyword}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_BATCH_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log(response);
      });
  }
}


export function updateBatch(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BATCH}?_id=${cookie.load("teacher_id")}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getBatches(undefined, callback));
          callback(null, response);
          createAlert({ message: 'You Batch has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addBatch(data, callback) {

  // console.log(`${config.API_URL + config.API_INSERT_UPDATE__DETELE_BATCH}?_id=${cookie.load("teacher_id")}`,data);
  // return false;

  return function (dispatch) {

    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BATCH}?_id=${cookie.load("teacher_id")}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getBatches(undefined, callback));
          callback(null, response);
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function deleteBatch(batchId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_BATCH}?_id=${cookie.load("teacher_id")}`,
      data: {
        batchId: batchId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function getAllSubjects() {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_SUBJECT_LIST}`,
      headers: {},
    })
      .then(response => {
        dispatch({ type: GET_All_SUBJECTS, payload: response.data });
      })
      .catch(response => {
        console.log(response);
      });
  }

}
// -----------------Task Releted Work--------------------------------//
export function getTaskList(filter_keyword, callback) {
  return function (dispatch) {

    //console.log(`${config.API_URL + config.API_GET_TASK_LIST + '?status=' + filter_keyword}`,cookie.load('teacher'));

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_TASK_LIST + '?status=' + filter_keyword}&_id=${cookie.load("teacher_id")}`,

      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_STUDENT_TASK_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function updateTask(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      headers: {
        Authorization: cookie.load('teacher'),
      },
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_TASK}?_id=${cookie.load("teacher_id")}`,
      data: data
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getTaskList("", callback));
          callback(null, response);
          createAlert({ message: 'Task has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addTask(data, callback) {
  return function (dispatch) {
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_TASK}?_id=${cookie.load("teacher_id")}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getTaskList("", callback));
          callback(null, response);
          createAlert({ message: 'Task has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}
export function deleteTask(taskId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_TASK}?_id=${cookie.load("teacher_id")}`,
      data: {
        taskId: taskId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function getTeacherStudentTaskInfo(task_id, student_id, callback) {
  return function (dispatch) {
    axios({
      method: 'GET',

      url: `${config.API_URL + config.API_GET_TEACHER_STUDENT_TASK_INFO + '?taskId=' + task_id + '&studentId=' + student_id}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_TEACHER_STUDENT_TASK_INFO, payload: response.data.data[0] });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function addTaskTimelog(data, callback) {
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_INSERT_TIME_LOG}?_id=${cookie.load("teacher_id")}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
      data: {
        description: data.description,
        dateTime: data.dateTime,
        taskId: data.taskId
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          createAlert({ message: 'Success: Task Time Log added successfully!' });
          dispatch(getTeacherStudentTaskInfo(data.taskId, data.student_id, callback));

          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
          callback(true, null);
        }
      })
      .catch(response => {


        callback(true, null);
      });
  }
}

export function addTaskComment(data, task_id, student_id, callback) {
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_INSERT_TASK_COMMENT}?_id=${cookie.load("teacher_id")}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {

        if (!response.data.err && response.data) {
          createAlert({ message: 'Success: Task comment added successfully!' });
          dispatch(getTeacherStudentTaskInfo(task_id, student_id, callback));
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
          callback(true, null);
        }
      })
      .catch(response => {
        console.log("Comment add error>>>>>>>>", response);

        callback(true, null);
      });
  }
}

export function addTaskDocuments(data, task_id, student_id, callback) {
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_INSERT_TASK_DOCUMENTS}?_id=${cookie.load("teacher_id")}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {

        if (!response.data.err && response.data) {
          createAlert({ message: 'Success: Task Document added successfully!' });
          dispatch(getTeacherStudentTaskInfo(task_id, student_id, callback));
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
          callback(true, null);
        }
      })
      .catch(response => {
        console.log("Comment add error>>>>>>>>", response);

        callback(true, null);
      });
  }
}

// -----------------end -Task Releted Work--------------------------------//

export function getTeacherCoulagelist(callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_COULAGE_LIST}?_id=${cookie.load("teacher_id")}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_COULAGE_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
      });
  }
}

export function teacherDashboardData(callback) {
  //console.log("Teacher Dashboard URL : ",`${config.API_URL + config.API_GET_TEACHER_DASHBOARD}?_id=${cookie.load("teacher_id")}`);
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_TEACHER_DASHBOARD}?_id=${cookie.load("teacher_id")}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_DASHBOARD_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("Dashboard List Errors",response);
      });
  }
}

export function deleteAttachments(data, callback) {
  return function (dispatch) {

    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_TASK_TEACHER_DOCUMENT_DETELE}?_id=${cookie.load("teacher_id")}`,
      data: {
        taskId: data.taskId,
        attachmentLink: data.link
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function deleteTeacherComment(data, callback) {
  return function (dispatch) {

    axios({
      method: 'put',
      url: `${config.API_URL}teacher/task-comment-remove?_id=${cookie.load("teacher_id")}`,
      data: {
        commentId: data.commentId,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function getTeacherTaskStudentList(task_id, status, callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_TEACHER_TASK_STUDENTS + '?taskId=' + task_id + '&status=' + status}`,

      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_TEACHER_TASK_STUDENTS, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function deleteTaskStudent(taskId, student_id, callback) {
  return function (dispatch) {

    axios({
      method: 'put',
      url: `${config.API_URL + config.API_GET_TEACHER_TASK_STUDENTS}?_id=${cookie.load("teacher_id")}`,
      data: {
        taskId: taskId,
        studentId: student_id,
        isRequiredToAdd: false
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {

        callback(null, response.data);
        dispatch(getTeacherTaskStudentList(taskId, undefined, callback));
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function updateTaskComment(data, task_id, student_id, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: 'https://learning3ly.com/api/v1/teacher/task-comment-update',
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          createAlert({ message: 'Success: Task Document added successfully!' });
          dispatch(getTeacherStudentTaskInfo(task_id, student_id, callback));
          callback(null, response);
        } else {
          dispatch(validation(response.data.err));
          callback(true, null);
        }
      })
      .catch(response => {
        console.log("Comment add error>>>>>>>>", response);

        callback(true, null);
      });
  }
}

// Content Action
export function updateConversation(object,callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      data: object,
      url: `${config.API_URL + config.API_GET_CONTENT_LIST_FFMPEG}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_CONTENT_LIST_FFMPEG, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log(response);
      });
  }
}


export function getContentList(callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_CONTENT_LIST}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_CONTENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function getContentListbyCategory(categoryId, callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_CONTENT_LIST_BY_CATEGORY + '?categoryId=' + categoryId}`,

      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_CONTENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}
export function getContentDetails(contentId, callback) {

  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_FETCH_CONTENT_INFO + '/' + contentId}`,

      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: FETCH_CONTENT_INFO, payload: response.data.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function updateContent(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      headers: {
        Authorization: cookie.load('teacher'),
      },
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_CONTENT}?_id=${cookie.load('teacher_id')}`,
      data: {
        title: data.title,
        description: data.description,
        isActive: data.isActive,
        category: data.category,
        contentId: data.contentId,
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getContentList(callback));
          callback(null, response);
          createAlert({ message: 'Content has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addContent(data, callback) {
  return function (dispatch) {
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_CONTENT}?_id=${cookie.load('teacher_id')}`,
      data: {
        title: data.title,
        description: data.description,
        isActive: data.isActive,
        category: data.category,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getContentList(callback));
          callback(null, response);
          createAlert({ message: 'Content has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}
export function deleteContent(contentId, callback) {
  return function () {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_CONTENT}?_id=${cookie.load("teacher_id")}`,
      data: {
        contentId: contentId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
// attachment releted work 2021
export function addContentAttachment(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + '/teacher/content/add-attachment'}?_id=${cookie.load("teacher_id")}`,
      data: data,

      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getContentList(callback));
          callback(null, response);
          createAlert({ message: 'Content has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}
export function updateontentAttachment(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + '/teacher/content/update-attachment'}?_id=${cookie.load("teacher_id")}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getContentList(callback));
          callback(null, response);
          createAlert({ message: 'Content has been Update.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function deleteContentAttachment(contentId, indexId, callback) {
  return function (dispatch) {

    axios({
      method: 'put',
      url: `${config.API_URL + '/teacher/content/remove-attachment'}?_id=${cookie.load("teacher_id")}`,
      data: {
        contentId: contentId,
        indexId: indexId,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch(getContentList(callback));
        callback(null, response.data);
        createAlert({ message: ' Attchment Row has been Deleted.' });
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}



// 03-6-2021 audio Work



export function deleteContentAddRow(contentId, row_id, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + config.API_DETELE_CONTENT_ADD_ROW}?_id=${cookie.load("teacher_id")}`,
      data: {
        contentId: contentId,
        indexId: row_id,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getContentList(callback));
          callback(null, response);
          createAlert({ message: 'Content Attchment Row has been Deleted.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

// end 003-06-2021

export function deleteContentImage(image_url, content_id, callback) {
  return function (dispatch) {

    axios({
      method: 'put',
      url: `${config.API_URL + config.API_CONTENT_IMAGE_DETELE}?_id=${cookie.load("teacher_id")}`,
      data: {
        link: image_url,
        contentId: content_id,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getContentList(callback));
          callback(null, response);
          createAlert({ message: 'Content Image has been Deleted.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
// end Content

// start goal management
export function getGoalList(student_id, callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_GOAL_LIST + '?studentId=' + student_id}&_id=${cookie.load("teacher_id")}`,

      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {

        dispatch({ type: GET_GOAL_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

//getAssessmentList


export function updateGoal(data, student_id, callback) {
  console.log(`${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL}?_id=${cookie.load("teacher_id")}`,data,cookie.load('teacher'));
  return function (dispatch) {
    axios({
      method: 'put',
      headers: {
        Authorization: cookie.load('teacher'),
      },
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL}?_id=${cookie.load("teacher_id")}`,
      data: data
    })
      .then(response => {

        if (!response.data.err && response.data.data) {
          dispatch(getGoalList(student_id, callback));
          callback(null, response);
          createAlert({ message: 'Content has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addGoal(data, student_id, callback) {
  // console.log(`${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL}?_id=${cookie.load("teacher_id")}`);
  // console.log(cookie.load('teacher'));
  // console.log(data,student_id);
  // return false;
  return function (dispatch) {
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL}?_id=${cookie.load("teacher_id")}`,
      data: {
        "title": data.title,
        "description": data.description,
        "isActive": data.isActive,
        "studentId": student_id,
        "dateIntroduce": data.dateIntroduce,
        "dateMastered": data.dateMastered,
        "prompting": data.prompting,
        "reInforcementId": data.reInforcementId,
        "categoryId": data.categoryId,
        "contentId": data.contentId,
        "wordsArray": data.wordsArray,
        "imagesArray": data.imagesArray
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getGoalList(student_id, callback));
          callback(null, response);
          createAlert({ message: 'Goal has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}
export function deleteGoal(goalId, student_id, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL}?_id=${cookie.load("teacher_id")}`,
      data: {
        goalId: goalId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch(getGoalList(student_id, callback));
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function deleteGoalImage(image_url, goal_id, student_id, callback) {
  return function (dispatch) {

    axios({
      method: 'put',
      url: `${config.API_URL + config.API_GOAL_IMAGE_DETELE}?_id=${cookie.load("teacher_id")}`,
      data: {
        link: image_url,
        goalId: goal_id,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getGoalList(student_id, callback));
          callback(null, response);
          createAlert({ message: 'Goal Image has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}


export function getGoalInfo(goal_id, callback) {

  console.log("Get Goal Details ",`${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL + '/' + goal_id}?_id=${cookie.load("teacher_id")}`,cookie.load('teacher'))

  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL + '/' + goal_id}?_id=${cookie.load("teacher_id")}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: FETCH_GOAL_INFO, payload: response.data.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
// end goal managment


// Category Action

export function getCategoryList(callback) {
  return function (dispatch) {
    console.log(`${config.API_URL + config.API_INSERT_UPDATE__DETELE_LIST_CATEGORY}?_id=${cookie.load('teacher_id')}`);
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_LIST_CATEGORY}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_CATEGORY_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}

export function updateCategory(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      headers: {
        Authorization: cookie.load('teacher'),
      },
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_LIST_CATEGORY}?_id=${cookie.load("teacher_id")}`,
      data: {
        category: data.category,
        isActive: data.isActive,
        categoryId: data.categoryId
      }
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getCategoryList(callback));
          callback(null, response);
          createAlert({ message: 'Category has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addCategory(data, callback) {
  return function (dispatch) {
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_LIST_CATEGORY}?_id=${cookie.load("teacher_id")}`,
      data: {
        category: data.category,
        isActive: data.isActive,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getCategoryList(callback));
          callback(null, response);
          createAlert({ message: 'Category has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}
export function deleteCategory(categoryId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_INSERT_UPDATE__DETELE_LIST_CATEGORY}?_id=${cookie.load("teacher_id")}`,
      data: {
        categoryId: categoryId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

// end Category

export function getReinforcementList(callback) {
  return function (dispatch) {

    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_REINFORCEMENT_LIST}?_id=${cookie.load('teacher_id')}`,

      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_REINFORCEMENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}


export function updateReinforcement(data, callback) {
  
  return function (dispatch) {
    axios({
      method: 'put',
      headers: {
        Authorization: cookie.load('teacher'),
      },
      url: `${config.API_URL + config.API_INSERT_UPDATE_DETELE_LIST_REINFORCEMENT}?_id=${cookie.load('teacher_id')}`,
      data: data
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getReinforcementList(callback));
          callback(null, response);
          createAlert({ message: 'Reinforcement has been Updated.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addReinforcement(data, callback) {
  // console.log("Data ===>",data);
  // return false;
  return function (dispatch) {
    axios({
      method: 'POST',
      url: `${config.API_URL + config.API_INSERT_UPDATE_DETELE_LIST_REINFORCEMENT}?_id=${cookie.load('teacher_id')}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getReinforcementList(callback));
          callback(null, response);
          createAlert({ message: 'Reinforcement has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function deleteReinforcement(reinforcementId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_INSERT_UPDATE_DETELE_LIST_REINFORCEMENT}?_id=${cookie.load("teacher_id")}`,
      data: {
        reinforcementId: reinforcementId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}
export function deleteReinforcementImage(image_url, reinforcementId, callback) {
  return function (dispatch) {

    axios({
      method: 'put',
      url: `${config.API_URL + config.API_REINFORCEMENT_IMAGE_DETELE}?_id=${cookie.load("teacher_id")}`,
      data: {
        link: image_url,
        reinforcementId: reinforcementId,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data.data) {
          dispatch(getReinforcementList(callback));
          callback(null, response);
          createAlert({ message: 'Reinforcement Image has been Deleted.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function updateGoalRenforcement(data, callback) {
  console.log("-----data", data)
  return function (dispatch) {
    axios({
      method: 'PUT',
      url: `${config.API_URL + config.API_ADD_GOAL_REINFORCEMENT}?_id=${cookie.load("teacher_id")}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getGoalInfo(data.goal_id, callback));
          callback(null, response);
          createAlert({ message: 'Goal Reinforcement has been Added.' });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function getEventList(callback) {
  return function (dispatch) {
    console.log("Events List",`${config.API_URL + config.AP_EVENT}?_id=${cookie.load('teacher_id')}`);
    axios({
      method: 'get',
      url: `${config.API_URL + config.AP_EVENT}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        console.log("Response Events List ===>",response.data);
        dispatch({ type: GET_EVENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        //(response);
      });
  }
}
export function updateEvent(data, callback) {
  return function (dispatch) {
    axios({
      method: 'put',
      url: `${config.API_URL + config.AP_EVENT}?_id=${cookie.load("teacher_id")}`,
      data: {
        "eventId": data.eventId,
        "eventName": data.eventName,
        "date": data.eventDate,
        "startTime": data.startTime,
        "endTime": data.endTime,
        "description": data.description,
        "isReminder": data.isReminder,
        "students": data?.students,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.error && response.data) {
          dispatch(getEventList());
          callback(null, response);
          createAlert({ message: 'You Event has been Updated.' });
        } else {
          dispatch(validation(response.data.error));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}

export function addEvent(data, callback) {
  return function (dispatch) {

    // console.log("Add Event ===>",`${config.API_URL + config.AP_EVENT}?_id=${cookie.load("teacher_id")}`,JSON.stringify({
    //   "eventName": data.eventName,
    //   "eventDate": data.eventDate,
    //   "startTime": data.startTime,
    //   "endTime": data.endTime,
    //   "description": data.description,
    //   "createdBy" : cookie.load('teacher_id'),
    //   "isReminder": data.isReminder,
    //   "students": data?.students,
    // }),cookie.load("teacher"));

    axios({
      method: 'POST',
      url: `${config.API_URL + config.AP_EVENT}?_id=${cookie.load("teacher_id")}`,
      data: {
        "eventName": data.eventName,
        "eventDate": data.eventDate,
        "startTime": data.startTime,
        "endTime": data.endTime,
        "description": data.description,
        "createdBy" : cookie.load('teacher_id'),
        "isReminder": data.isReminder,
        "students": data?.students,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        if (!response.data.err && response.data) {
          dispatch(getEventList());
          callback(null, response);
          createAlert({ message: response.data.msg });
        } else {
          dispatch(validation(response.data.err));
          callback(null, false);
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(validation("Enter Your Valid  Data"));
          callback(null, false);
        }
      });
  }
}


export function deleteEvent(eventId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.AP_EVENT}?_id=${cookie.load("teacher_id")}`,
      data: {
        eventId: eventId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch(getEventList());
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

// ASSESSMENT WORK START BY DIXIT \
// INFO API
export function getAssessmentInfo(goal_id, callback) {
  console.log("-----goal_id", goal_id);

  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL + '/' + goal_id}?_id=${cookie.load("teacher_id")}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: FETCH_GOAL_INFO, payload: response.data.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function subscribeNewsletter(data,callback) {
  return function (dispatch) {
    axios({
      method: 'post',
      url: `${config.API_URL + '/teacher/subscribe_newsletter'}`,
      data: data,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_NEWSLETTER_STATUS, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("Newsletter Subscriber Response",response);
      });
  }
}


export function validation(error) {
  return {
    type: VALIDATION_ERROR,
    payload: error
  }
}
export function validationNull() {
  return {
    type: VALIDATION_ERROR,
    payload: null
  }
}
export function ContentListNull() {
  return {
    type: GET_CONTENT_LIST,
    payload: []
  }
}
export function ContentDetailsNull() {
  return {
    type: FETCH_CONTENT_INFO,
    payload: []
  }
}

export function ReinforcementListNull() {
  return {
    type: FETCH_REINFORCEMENT_INFO,
    payload: []
  }
}

export function changeInputValue(form, field, value) {
  return function (dispatch) {
    dispatch(change(form, field, value))
  }
}

// Functions for administrators //

export function getSubjectList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_SUBJECTS}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        console.log("Response Admin Subject List ===>",response.data);
        dispatch({ type: GET_ADMIN_SUBJECT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function deleteSubject(subjectId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.ADMIN_SUBJECTS}?_id=${cookie.load("teacher_id")}`,
      data: {
        subjectId: subjectId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch(getSubjectList());
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getSubscriberList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_SUBSCRIBERS}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        console.log("Response Admin Subscriber List ===>",response.data);
        dispatch({ type: GET_ADMIN_SUBSCRIBER_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function deleteSubscriber(subscriberId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.ADMIN_SUBSCRIBERS}?_id=${cookie.load("teacher_id")}`,
      data: {
        subscriberId: subscriberId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch(getSubscriberList());
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getcontactusList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_CONTACTUS}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        console.log("Response Admin Contact List ===>",response.data);
        dispatch({ type: GET_ADMIN_CONTACTUS_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function deleteContact(contactId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.ADMIN_CONTACTUS}?_id=${cookie.load("teacher_id")}`,
      data: {
        contactId: contactId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch(getcontactusList());
        callback(null, response.data);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getcategoryList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_CATEGORY}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        console.log("Response Admin Category List ===>",response.data);
        dispatch({ type: GET_ADMIN_CATEGORY_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminContentList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_CONTENT}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        console.log("Response Admin Content List ===>",response.data);
        dispatch({ type: GET_ADMIN_CONTENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminContentInfo(contentId,callback) {
  console.log(`${config.API_URL + config.ADMIN_CONTENT_INFO + '/' + contentId}?_id=${cookie.load("teacher_id")}`);
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_CONTENT_INFO + '/' + contentId}?_id=${cookie.load("teacher_id")}`,
      data: {
        contentId: contentId,
      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_CONTENT_INFO, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminEventsList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_EVENTS}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_EVENTS_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminReinforcementList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_REINFORCEMENT}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_REINFORCEMENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminPackagePurchasedList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_PACKAGE_PURCHASED}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_PACKAGE_PURCHASED_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminStudentList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_STUDENT_LIST}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_STUDENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminParentList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_PARENT_LIST}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_PARENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getAdminTeacherList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_TEACHER_LIST}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_TEACHER_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}


export function getAdminGoalsList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_GOALS_LIST}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_GOALS_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function deleteAdminGoal(goalId, callback) {
  return function (dispatch) {

    axios({
      method: 'delete',
      url: `${config.API_URL + config.API_GET_INSERT_UPDATE__DETELE_GOAL}?_id=${cookie.load("teacher_id")}`,
      data: {
        goalId: goalId,

      },
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch(getAdminGoalsList(callback));
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}

export function getAdminBatchList(callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.ADMIN_BATCH_LIST}?_id=${cookie.load('teacher_id')}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: GET_ADMIN_BATCH_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(response => {
        console.log("response ==> ",response);
      });
  }
}

export function getStudentsByTeacher(teacherId,callback) {
  return function (dispatch) {
    axios({
      method: 'get',
      url: `${config.API_URL + config.API_GET_TEACHER_STUDENT_LIST + '?_id=' + teacherId}`,
      headers: {
        Authorization: cookie.load('teacher'),
      },
    })
      .then(response => {
        dispatch({ type: FETCH_TEACHER_STUDENT_LIST, payload: response.data });
        callback(null, response);
      })
      .catch(error => {
        if (error.response) {
          callback(null, false);
        }
      });
  }
}