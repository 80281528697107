

import {
    AUTHENTICATE_USER,
    AUTHENTICATE_PARENT,
    AUTHENTICATE_STUDENT,

    UNAUTHENTICATE_USER,
    UNAUTHENTICATE_PARENT,
    UNAUTHENTICATE_STUDENT,

    VALIDATION_ERROR,
    GET_UNIQUE_USERNAME,
    GET_TEACHER_DETAILS_BY_EMAIL,
    GET_PARENTS
} from './account_action';

export default function AccountReducer(state = {}, action) {

    switch (action.type) {
        case VALIDATION_ERROR:
            return { ...state, error: action.payload }
        case AUTHENTICATE_USER:
            return { ...state, error: '', authenticated: true, token: action.payload }
        case AUTHENTICATE_PARENT:
            return { ...state, error: '', authenticated_parent: true, token: action.payload }
        case AUTHENTICATE_STUDENT:
            return { ...state, error: '', authenticated_student: true, token: action.payload }
        case UNAUTHENTICATE_USER:
            return { ...state, error: '', authenticated: false, token: false }
        case UNAUTHENTICATE_PARENT:
            return { ...state, error: '', authenticated_parent: false, token: false }
        case UNAUTHENTICATE_STUDENT:
            return { ...state, error: '', authenticated_student: false, token: false }

        case GET_UNIQUE_USERNAME:
            return { ...state, unique_username: action.payload.data }
        case GET_PARENTS:
            return { ...state, parent_list: action.payload.data }
        case GET_TEACHER_DETAILS_BY_EMAIL:
            return { ...state, teacher_details: action.payload.data }
        default:
            return state;

    }
}